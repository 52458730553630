<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.594 2.08619C11.8525 1.97127 12.1477 1.97127 12.4062 2.08619L21.4062 6.08619C21.8378 6.278 22.0764 6.7456 21.9783 7.20759C21.8803 7.66958 21.4724 8 21.0001 8H3.0001C2.52782 8 2.11993 7.66958 2.02188 7.20759C1.92384 6.7456 2.16239 6.278 2.59396 6.08619L11.594 2.08619ZM7.71231 6H16.2879L12.0001 4.09432L7.71231 6ZM2.0001 10C2.0001 9.44772 2.44781 9 3.0001 9H21.0001C21.5524 9 22.0001 9.44772 22.0001 10C22.0001 10.5523 21.5524 11 21.0001 11V20C21.5524 20 22.0001 20.4477 22.0001 21C22.0001 21.5523 21.5524 22 21.0001 22H3.0001C2.44781 22 2.0001 21.5523 2.0001 21C2.0001 20.4477 2.44781 20 3.0001 20V11C2.44781 11 2.0001 10.5523 2.0001 10ZM5.0001 11V20H19.0001V11H5.0001ZM8.0001 13C8.55238 13 9.0001 13.4477 9.0001 14V17C9.0001 17.5523 8.55238 18 8.0001 18C7.44781 18 7.0001 17.5523 7.0001 17V14C7.0001 13.4477 7.44781 13 8.0001 13ZM12.0001 13C12.5524 13 13.0001 13.4477 13.0001 14V17C13.0001 17.5523 12.5524 18 12.0001 18C11.4478 18 11.0001 17.5523 11.0001 17V14C11.0001 13.4477 11.4478 13 12.0001 13ZM16.0001 13C16.5524 13 17.0001 13.4477 17.0001 14V17C17.0001 17.5523 16.5524 18 16.0001 18C15.4478 18 15.0001 17.5523 15.0001 17V14C15.0001 13.4477 15.4478 13 16.0001 13Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconLibrary",
  props: ["height", "width"],
}
</script>
