<template>
  <StatCard
    :title="$t(i18nbase + '.likes_evolution.title')"
    :description="$t(i18nbase + '.likes_evolution.description')"
    icon="IconLike"
  >
    <template
      v-if="showGraph"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".likes_evolution.total", {
              nb: $string.formatNumberToHumanString(stats.current.like_count),
            })
          }}
        </span>
        <span
          v-if="showPreviousData"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".likes_evolution.previous_total", {
              nb: $string.formatNumberToHumanString(stats.last_year.like_count),
            })
          }}
        </span>
      </div>
    </template>

    <Line
      ref="chartRef"
      :class="!showGraph && '!hidden'"
      :data="data"
      :options="chartOptions"
    />
    <div
      :class="showGraph && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + noDataKey) }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { ref, type PropType } from "vue"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js"
import { Line, type ChartComponentRef } from "vue-chartjs"

// Layout
//

// Components
import StatCard from "../StatCard.vue"
import { DisplayStat, type ILikeStats } from "@/types/Stats.types"
import type { FilterCode } from "@/static/Dashboard"
import {
  initShowingPreviousData,
  displayStats,
  generateTransformedData,
  getTransformationType,
  type GenericDataItem,
  getGraphUnit,
  TransformationType,
} from "@/plugins/GraphPlugin"

// Stores
import { mapStores } from "pinia"
import { useListingStore } from "@/stores/listing"

// Types
//

// Data
import * as chartConfig from "@/static/dashboard/LikeNumber"
import moment from "moment"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

export default {
  name: "LikesEvolution",

  components: { Line, StatCard },

  props: {
    stats: {
      type: Object as PropType<ILikeStats>,
      required: true,
    },

    currentFilter: {
      type: String as PropType<FilterCode>,
      required: true,
    },

    showPreviousData: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const chartRef = ref<ChartComponentRef>(null)
    const legendItems = ref(
      chartConfig.legends
        .filter((item) => !item.label.includes("-1"))
        .map((item) => {
          item.hidden = false
          return item
        })
    )

    const setupPreviousStats = (showPreviousData: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      if (chart.data.datasets.length > 0) {
        initShowingPreviousData(chart, chartConfig.legends, showPreviousData, legendItems.value)
      }
    }

    const legendClicked = (index: number, legend, previousStat: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      // Hide current stats
      const realIndex = chartConfig.legends.findIndex((item) => item.label === legend.label)
      displayStats(chart, realIndex, DisplayStat.AUTO, (action) => {
        if (action === "hide") {
          legendItems.value[index].hidden = true
        } else {
          legendItems.value[index].hidden = false
        }
      })

      // Hide previous stats
      if (previousStat) {
        const indexLastYear = chartConfig.legends.findIndex((item) => item.label === legend.label + " n-1")
        displayStats(chart, indexLastYear, DisplayStat.AUTO)
      }
    }

    return {
      chartRef,
      legendItems,
      setupPreviousStats,
      legendClicked,
      ...chartConfig,
    }
  },

  data() {
    return {
      i18nbase: "home.facebook",
      chartConfig: chartConfig,
      isLoading: true,
    }
  },

  computed: {
    ...mapStores(useListingStore),

    data() {
      if (this.showGraph) {
        const transformedData = generateTransformedData(
          this.stats.current.like_evolution as unknown as GenericDataItem[],
          getTransformationType(this.currentFilter),
          true
        )
        const previousTransformedData = generateTransformedData(
          this.stats.last_year.like_evolution as unknown as GenericDataItem[],
          getTransformationType(this.currentFilter),
          true,
          transformedData.ranges
        )

        // ATTENTION : l'ordre des datasets doit correspondre à l'ordre des legends
        return {
          labels: transformedData.data.map((item) => item.startDate),
          datasets: [
            {
              data: transformedData.data.map((item) => item.count),
              ...chartConfig.likeConfig,
            },
            {
              data: previousTransformedData.data.map((item) => item.count),
              ...chartConfig.previousLikeConfig,
            },
          ],
        }
      } else {
        return {
          labels: [],
          datasets: [],
        }
      }
    },

    chartOptions() {
      let opts = this.chartConfig.options
      opts.scales.x.time.unit = getGraphUnit(this.currentFilter)
      opts.scales.x.title.text = getGraphUnit(this.currentFilter)
      opts.plugins = {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = this.$t(this.i18nbase + ".likes_evolution.legend." + context.dataset.label) || ""
              if (label) {
                label += " : "
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y
              }
              return label
            },
            title: (context) => {
              let date = moment(context[0].label)
              if (context[0].dataset.label.includes("n-1")) {
                date = date.subtract(1, "year")
              }
              return date.format("MMMM YYYY")
            },
          },
        },
        ...opts.plugins,
      }
      return opts
    },

    showGraph() {
      return this.stats.current.like_evolution.length > 0
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus("FACEBOOK") === "CONNECTED"
        ? "likes_evolution.no_data"
        : "not_connected"
    },
  },

  watch: {
    showPreviousData() {
      this.setupPreviousStats(this.showPreviousData)
    },
  },

  mounted() {
    this.$waiting.simple(() => {
      this.setupPreviousStats(this.showPreviousData)
      this.isLoading = false
    })
  },
}
</script>
@/static/dashboard/LikeNumber
