<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 6C2 4.34315 3.34315 3 5 3H15C16.6569 3 18 4.34315 18 6V7H19C20.6569 7 22 8.34315 22 10V16C22 17.6569 20.6569 19 19 19H18V22C18 22.4045 17.7564 22.7691 17.3827 22.9239C17.009 23.0787 16.5789 22.9931 16.2929 22.7071L12.5858 19H9C8.53722 19 8.09842 18.8948 7.70677 18.7074C7.42077 18.9932 6.99085 19.0786 6.61732 18.9239C6.24364 18.7691 6 18.4045 6 18V15H5C3.34315 15 2 13.6569 2 12V6ZM9.41421 17H13C13.2652 17 13.5196 17.1054 13.7071 17.2929L16 19.5858V18C16 17.4477 16.4477 17 17 17H19C19.5523 17 20 16.5523 20 16V10C20 9.44772 19.5523 9 19 9H18V12C18 13.6569 16.6569 15 15 15H11.4142L9.41421 17ZM16 6C16 5.44772 15.5523 5 15 5H5C4.44772 5 4 5.44772 4 6V12C4 12.5523 4.44772 13 5 13H7C7.55228 13 8 13.4477 8 14V15.5858L10.2929 13.2929C10.4804 13.1054 10.7348 13 11 13H15C15.5523 13 16 12.5523 16 12V6Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconChatAlt2",
  props: ["height", "width"],
}
</script>
