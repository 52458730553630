<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.58579 2.58579C2.96086 2.21071 3.46957 2 4 2H20C20.5304 2 21.0391 2.21071 21.4142 2.58579C21.7893 2.96086 22 3.46957 22 4V6.586C21.9999 7.11634 21.7891 7.62513 21.4141 8.00011C21.4141 8.00014 21.4141 8.00007 21.4141 8.00011L15.0001 14.4141L15 17C15 17.2652 14.8946 17.5196 14.7071 17.7071L10.7071 21.7071C10.4211 21.9931 9.99099 22.0787 9.61732 21.9239C9.24364 21.7691 9 21.4045 9 21V14.4142L2.586 8.00021C2.58596 8.00018 2.58604 8.00025 2.586 8.00021C2.21097 7.62524 2.00011 7.11655 2 6.58621V4C2 3.46957 2.21071 2.96086 2.58579 2.58579ZM20 4H4V6.58579L10.414 12.9998C10.414 12.9998 10.414 12.9998 10.414 12.9998C10.789 13.3748 10.9999 13.8834 11 14.4138V18.5858L13 16.5858V14.414C13.0001 13.8837 13.2109 13.3749 13.5859 12.9999C13.5859 12.9999 13.5859 12.9999 13.5859 12.9999L19.9999 6.58589L20 4Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconFilter",
  props: ["height", "width"],
}
</script>
