<template>
  <span
    v-if="connected"
    class="text-base text-center text-success-500"
    >Connecté</span
  >
  <a
    v-else-if="url"
    :href="url"
    target="_blank"
    class="button-primary text-xs relative"
    :class="disbaled ? 'disabled' : ''"
  >
    <slot></slot>
  </a>

  <Button
    v-else
    type="button"
    class="text-xs"
    @click="$emit('invite')"
  >
    <slot></slot>
  </Button>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout

// Components
import Icon from "@/components/Icon.vue"
import Tooltip from "@/components/Tooltip.vue"
import Button from "@/components/Button.vue"

// Stores
import { useListingStore } from "@/stores/listing"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "ListingItemConnectState",

  components: { Icon, Tooltip, Button },

  props: {
    connected: {
      type: Boolean,
      required: true,
    },

    url: {
      type: String,
      default: "",
    },

    disbaled: {
      type: Boolean,
      default: false,
    },

    i18nbase: {
      type: String,
      default: "establishments/listing.states",
    },
  },
  emits: ["invite"],

  computed: {
    ...mapStores(useListingStore),
  },
})
</script>
