<template>
  <div class="flex flex-col items-center gap">
    <div class="relative flex items-center justify-center text-gray-400 h-[130px] w-[130px] rounded-full bg-gray-100">
      <Icon
        :icon-name="icon"
        class="h-[30px] w-[30px]"
      />
      <span class="text-xl">0</span>
    </div>
    <span class="text-base text-gray-400">
      {{ message }}
    </span>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "../Icon.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "EmptyData",

  components: { Icon },

  props: {
    message: {
      type: String,
      required: true,
    },

    icon: {
      type: String,
      default: "IconChatAlt2",
    },
  },

  computed: {
    ...mapStores(useDashboardStore),
  },
})
</script>
