export interface INotification {
  id: number
  data: INotificationMetadata
  notifiable_id: number
  notifiable_type: string
  type: NotificationType
  created_at: string
}

// string given by the api in notif.type param
export enum NotificationType {
  REVIEW = "App\\Notifications\\ReviewNotification",
  SOLICITATION = "App\\Notifications\\SolicitationNotification",
  TIC = "App\\Notifications\\LocalDiffuserNotification", // TIC = "App\\Notifications\\TicNotification",
}

interface INotificationMetadata {
  content: string
  title: NotificationTitle
  link: string
  logo: string
  establishmentId: string | null
  pathName: string | null
}

export enum NotificationTitle {
  NEW_REVIEW = "NEW_REVIEW",
  TIC_ANSWER = "TIC_ANSWER",
}

/**
 * Notification metadata specific for review notification
 */
export interface IReviewMetadata extends INotificationMetadata {
  directory: string
  author: string
  rating: number
}

export function isReviewMetadata(notif: any): notif is IReviewMetadata {
  if (notif && notif.type === NotificationType.REVIEW) {
    return true
  }
  return false
}

export interface ITicMetadata extends INotificationMetadata {}

export function isTicMetadata(notif: any): notif is ITicMetadata {
  if (notif && notif.type === NotificationType.TIC) {
    return true
  }
  return false
}
