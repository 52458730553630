<template>
  <div class="flex gap-sm items-center w-full justify-center">
    <div
      v-if="isFreemium"
      class="bg-white p-[2px] rounded-sm mx-sm"
    >
      <Tooltip
        variant-x="centerX"
        variant-y="up"
        size="tiny"
      >
        <template #main>
          <TicLogoContainer :tic-logo="logo" />
        </template>
        <template #content>{{ name }}</template>
      </Tooltip>
    </div>
    <span
      v-if="isFreemium"
      class="text-gray-500 text-xs"
      >&</span
    >
    <div
      id="aside-logo-container"
      class="-z-[1] flex justify-center flex-col items-center relative"
    >
      <span
        v-if="$t('common.beta') !== ''"
        class="text-error-500 font-light absolute -top-[20px] w-max"
      >
        {{ $t("common.beta") }}
      </span>
      <RouterLink
        :to="{ name: 'home' }"
        class="h-[54px]"
      >
        <img
          alt="logo-uska"
          class="h-[54px]"
          src="@/assets/images/logo.svg"
        />
      </RouterLink>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Tooltip from "@/components/Tooltip.vue"
import TicLogoContainer from "./TicLogoContainer.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "LogoContainer",

  components: {
    Tooltip,
    TicLogoContainer,
  },

  props: {
    isFreemium: {
      type: Boolean,
      default: false,
    },

    logo: {
      type: String,
      default: null,
    },

    name: {
      type: String,
      default: null,
    },
  },
})
</script>
