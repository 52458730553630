<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.46077 4.2263C9.18126 3.22164 11.1865 2.81619 13.1621 3.07351C15.1378 3.33082 16.9722 4.23635 18.378 5.64819C19.7838 7.06002 20.6814 8.89836 20.9302 10.8751C20.9991 11.4231 20.6108 11.9232 20.0629 11.9922C19.5149 12.0611 19.0148 11.6728 18.9458 11.1249C18.7523 9.58734 18.0541 8.15748 16.9607 7.05935C15.8673 5.96122 14.4405 5.2569 12.9038 5.05676C11.3671 4.85662 9.80749 5.17197 8.46929 5.9534C7.58529 6.46961 6.83092 7.16992 6.25324 7.99999H9C9.55228 7.99999 10 8.44771 10 8.99999C10 9.55227 9.55228 9.99999 9 9.99999H4C3.44772 9.99999 3 9.55227 3 8.99999V3.99999C3 3.44771 3.44772 2.99999 4 2.99999C4.55228 2.99999 5 3.44771 5 3.99999V6.34021C5.68094 5.49796 6.51288 4.77981 7.46077 4.2263ZM3.93641 12.0079C4.48432 11.9385 4.98472 12.3265 5.05408 12.8744C5.24865 14.4114 5.94739 15.8404 7.04091 16.9379C8.13442 18.0353 9.56098 18.7392 11.0972 18.9392C12.6335 19.1393 14.1928 18.8243 15.5309 18.0436C16.4143 17.5281 17.1684 16.8289 17.7463 16H15C14.4477 16 14 15.5523 14 15C14 14.4477 14.4477 14 15 14H20C20.5523 14 21 14.4477 21 15V20C21 20.5523 20.5523 21 20 21C19.4477 21 19 20.5523 19 20V17.6585C18.3188 18.5003 17.4867 19.2179 16.5388 19.771C14.8185 20.7747 12.814 21.1797 10.839 20.9225C8.86395 20.6653 7.02997 19.7604 5.62416 18.3496C4.21835 16.9387 3.32006 15.1015 3.06992 13.1256C3.00056 12.5777 3.3885 12.0773 3.93641 12.0079Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconRefresh",
  props: ["height", "width"],
}
</script>
