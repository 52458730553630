<template>
  <div class="w-full p-sm pb md:p flex flex-col gap bg-stat rounded-md border-2 border-gray-100">
    <div class="flex justify-between items-end px-md pt-md xl:p-0">
      <div class="w-full xl:w-max flex flex-col xl:flex-row items-start xl:items-center gap-md">
        <div
          class="w-full flex flex-col md:flex-row gap md:gap-0 justify-between items-start md:items-center xl:block xl:w-min"
        >
          <Icon
            :icon-name="icon"
            class="h-[40px] w-[40px]"
          />
          <div class="xl:hidden">
            <slot name="actions"></slot>
          </div>
        </div>
        <div class="flex flex-col">
          <span class="text-2xl text-gray-900">
            {{ title }}
          </span>
          <span
            v-if="description"
            class="text-sm text-gray-500"
          >
            {{ description }}
          </span>
        </div>
      </div>
      <div class="hidden xl:block w-fit">
        <slot name="actions"></slot>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import Icon from "../Icon.vue"

// Layout
//

// Components
//

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "DashboardSection",

  components: { Icon },

  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      required: true,
    },
  },

  data() {
    return {}
  },
})
</script>
