<template>
  <form
    id="login-form"
    class="h-full w-full flex flex-col justify-center items-center gap"
    @submit.prevent="$emit('submit', v$.$validate(), form)"
  >
    <div class="flex flex-col md:flex-row justify-between gap-md w-full">
      <FormWidget
        :required="requiredNames"
        :errors="v$.form.firstname.$errors"
      >
        <label>{{ $t("common.form.firstname.label") }}</label>
        <input
          v-model="v$.form.firstname.$model"
          type="text"
          :placeholder="$t('common.form.firstname.placeholder')"
          @input="handleInputChange('firstname')"
        />
      </FormWidget>

      <FormWidget
        :required="requiredNames"
        :errors="v$.form.lastname.$errors"
      >
        <label>{{ $t("common.form.lastname.label") }}</label>
        <input
          v-model="v$.form.lastname.$model"
          type="text"
          class="uppercase"
          :placeholder="$t('common.form.lastname.placeholder')"
          @input="handleInputChange('lastname')"
        />
      </FormWidget>
    </div>

    <FormWidget
      :required="true"
      :errors="v$.form.establishment_name.$errors"
    >
      <label>{{ $t("common.form.establishment_name.label") }}</label>
      <input
        v-model="v$.form.establishment_name.$model"
        type="text"
        :placeholder="$t('common.form.establishment_name.placeholder')"
        @input="handleInputChange('establishment_name')"
      />
    </FormWidget>

    <div class="flex flex-col md:flex-row justify-between gap-md w-full">
      <FormWidget
        :required="true"
        :errors="v$.form.email.$errors"
      >
        <label>{{ $t("common.form.email.label") }}</label>
        <input
          v-model="v$.form.email.$model"
          type="email"
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          :placeholder="$t('common.form.email.placeholder')"
          @input="handleInputChange('email')"
        />
      </FormWidget>

      <FormWidget
        :required="true"
        :errors="v$.form.phone.$errors"
      >
        <label>{{ $t("common.form.phone.label") }}</label>
        <input
          v-model="v$.form.phone.$model"
          type="tel"
          :placeholder="$t('common.form.phone.placeholder')"
          @input="handleInputChange('phone')"
        />
      </FormWidget>
    </div>

    <FormWidget
      v-if="showMessageInput"
      :required="requiredMessageInput"
    >
      <label>{{ $t("common.form.contact_message.label") }}</label>
      <textarea
        v-model="v$.form.message.$model"
        :placeholder="$t('common.form.contact_message.placeholder')"
        @input="handleInputChange('message')"
      >
      </textarea>
    </FormWidget>

    <Button
      type="submit"
      class="!w-full"
      :disabled="v$.form.$invalid"
      :loading="isButtonLoading"
    >
      <span v-if="button">
        {{ button }}
      </span>
      <span v-else>
        {{ $t(i18nbase + ".submit_button") }}
      </span>
    </Button>
  </form>
</template>

<script lang="ts">
// Vue
import { defineComponent, type PropType } from "vue"
import { useVuelidate } from "@vuelidate/core"

// Layout
import { contactValidateForm } from "@/validations/premium/contact"
import FormWidget from "@/components/form/FormWidget.vue"

// Components
import Button from "@/components/Button.vue"

// Stores
import { useAuthenticationStore } from "@/stores/authentication"
import { useEstablishmentStore } from "@/stores/establishment"
import { mapStores } from "pinia"

// Types
//

// Data
import { CommunicationCode } from "@/static/Establishment"

export default defineComponent({
  name: "ContactForm",

  components: {
    FormWidget,
    Button,
  },

  props: {
    isButtonLoading: {
      type: Boolean,
      required: true,
    },

    defaultEstablishmentName: {
      type: String,
      required: false,
      default: "",
    },

    showMessageInput: {
      type: Boolean,
      required: false,
      default: false,
    },

    requiredNames: {
      type: Boolean,
      default: true,
    },

    requiredMessageInput: {
      type: Boolean,
      default: false,
    },

    defaultForm: {
      type: Object as PropType<{
        firstname: string
        lastname: string
        email: string
      }>,
      default: null,
    },

    button: {
      type: String,
      default: "",
    },
  },
  emits: ["submit"],

  setup() {
    return { v$: useVuelidate() }
  },

  data() {
    return {
      i18nbase: "freemium/contact",

      form: {
        firstname: "",
        lastname: "",
        email: "",
        establishment_name: "",
        phone: "",
        message: "",
      },
    }
  },

  validations() {
    return contactValidateForm(this.requiredMessageInput, this.requiredNames)
  },

  computed: {
    ...mapStores(useAuthenticationStore, useEstablishmentStore),
  },

  created() {
    const currentUser = this.authenticationStore.user
    if (currentUser !== undefined && currentUser !== null && currentUser?.email) {
      this.form.firstname = currentUser.first_name ? currentUser.first_name : ""
      this.form.lastname = currentUser.last_name ? currentUser.last_name.toUpperCase() : ""
      this.form.email = currentUser.email
    }

    const currentEstablishment = this.establishmentStore.currentEstablishment
    if (currentEstablishment && currentEstablishment.id) {
      const phoneNumber = currentEstablishment.communications.find((comm) => comm.code === CommunicationCode.PHONE)
      if (phoneNumber) {
        this.form.phone = phoneNumber.value
      }
      this.form.establishment_name =
        this.defaultEstablishmentName === "" ? currentEstablishment.name : this.defaultEstablishmentName
    }

    if (this.defaultForm) {
      this.form.email = this.defaultForm.email
      this.form.firstname = this.defaultForm.firstname
      this.form.lastname = this.defaultForm.lastname
    }
  },

  methods: {
    /**
     * Update form attribute $dirty property and show save button on input event
     * @param key name of form attribute changed
     */
    handleInputChange(key: string) {
      this.v$.form[key].$dirty = true
    },
  },
})
</script>
