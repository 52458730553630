<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 21 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.5 0C7.05228 0 7.5 0.447715 7.5 1V2H13.5V1C13.5 0.447715 13.9477 0 14.5 0C15.0523 0 15.5 0.447715 15.5 1V2H17.5C18.2957 2 19.0587 2.31607 19.6213 2.87868C20.1839 3.44129 20.5 4.20435 20.5 5V17C20.5 17.7957 20.1839 18.5587 19.6213 19.1213C19.0587 19.6839 18.2957 20 17.5 20H3.5C2.70435 20 1.94129 19.6839 1.37868 19.1213C0.81607 18.5587 0.5 17.7957 0.5 17V5C0.5 4.20435 0.81607 3.44129 1.37868 2.87868C1.94129 2.31607 2.70435 2 3.5 2H5.5V1C5.5 0.447715 5.94772 0 6.5 0ZM5.5 4H3.5C3.23478 4 2.98043 4.10536 2.79289 4.29289C2.60536 4.48043 2.5 4.73478 2.5 5V17C2.5 17.2652 2.60536 17.5196 2.79289 17.7071C2.98043 17.8946 3.23478 18 3.5 18H17.5C17.7652 18 18.0196 17.8946 18.2071 17.7071C18.3946 17.5196 18.5 17.2652 18.5 17V5C18.5 4.73478 18.3946 4.48043 18.2071 4.29289C18.0196 4.10536 17.7652 4 17.5 4H15.5V5C15.5 5.55228 15.0523 6 14.5 6C13.9477 6 13.5 5.55228 13.5 5V4H7.5V5C7.5 5.55228 7.05228 6 6.5 6C5.94772 6 5.5 5.55228 5.5 5V4ZM4.5 9C4.5 8.44772 4.94772 8 5.5 8H15.5C16.0523 8 16.5 8.44772 16.5 9C16.5 9.55229 16.0523 10 15.5 10H5.5C4.94772 10 4.5 9.55229 4.5 9Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCalendar",
  props: ["height", "width"],
}
</script>
