<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 18C8.08323 18 4.74752 15.4964 3.51201 12C4.74752 8.50359 8.08323 6 12 6C15.9177 6 19.2525 8.50354 20.488 12C19.2525 15.4965 15.9177 18 12 18ZM12 4C7.07378 4 2.90509 7.23851 1.50394 11.7004C1.4427 11.8954 1.4427 12.1046 1.50394 12.2996C2.90509 16.7615 7.07378 20 12 20C16.9273 20 21.0949 16.7614 22.4961 12.2996C22.5573 12.1046 22.5573 11.8954 22.4961 11.7004C21.0949 7.23856 16.9273 4 12 4ZM10.5858 10.5858C10.9609 10.2107 11.4696 10 12 10C12.5304 10 13.0391 10.2107 13.4142 10.5858C13.7893 10.9609 14 11.4696 14 12C14 12.5304 13.7893 13.0391 13.4142 13.4142C13.0391 13.7893 12.5304 14 12 14C11.4696 14 10.9609 13.7893 10.5858 13.4142C10.2107 13.0391 10 12.5304 10 12C10 11.4696 10.2107 10.9609 10.5858 10.5858ZM12 8C10.9391 8 9.92172 8.42143 9.17157 9.17157C8.42143 9.92172 8 10.9391 8 12C8 13.0609 8.42143 14.0783 9.17157 14.8284C9.92172 15.5786 10.9391 16 12 16C13.0609 16 14.0783 15.5786 14.8284 14.8284C15.5786 14.0783 16 13.0609 16 12C16 10.9391 15.5786 9.92172 14.8284 9.17157C14.0783 8.42143 13.0609 8 12 8Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconEye",
  props: ["height", "width"],
}
</script>
