import { createRouter, createWebHistory, type NavigationGuardNext, type RouteLocationNormalized } from "vue-router"
import { useAuthenticationStore } from "@/stores/authentication"
import HomeView from "../views/HomeView.vue"
import { i18n, loadLanguageAsync } from "@/i18n"
import auth from "@/middlewares/auth"
import admin from "@/middlewares/admin"
import review from "@/middlewares/review"
import publication from "@/middlewares/publication"
import config from "@/middlewares/config"
import information from "@/middlewares/information"
import UberallDataService from "@/services/UberallDataService"
import * as Sentry from "@sentry/vue"
import { PageTitleParamsType, type IPageTitleParams } from "@/types/Router.types"

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/:diffuserType?",
      name: "home",
      component: HomeView,
      meta: {
        requiresConfig: true,
        middleware: [auth, config],
      },
    },

    // CONFIGURATION
    {
      path: "/welcome",
      name: "config-welcome",
      component: () => import("@/views/configuration/Welcome.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/configuration/contact",
      name: "config-contact",
      component: () => import("@/views/configuration/Contact.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/configuration/begin",
      name: "config-begin",
      component: () => import("@/views/configuration/Begin.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/configuration/connect/:diffuserType?",
      name: "config-connect",
      component: () => import("@/views/configuration/Connexion.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/configuration/establishment",
      name: "config-establishment",
      component: () => import("@/views/configuration/Establishment.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/configuration/listing",
      name: "config-listing",
      component: () => import("@/views/configuration/Listing.vue"),
      meta: {
        middleware: [auth],
      },
    },

    {
      path: "/welcome/free",
      name: "config-freemium",
      component: () => import("@/views/configuration/freemium/Establishments.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/welcome/free/introduction",
      name: "config-freemium-introduction",
      component: () => import("@/views/configuration/freemium/Introduction.vue"),
      meta: {
        middleware: [auth],
      },
    },

    // PROFESSIONAL SPACE
    {
      path: "/professional-space/:contactToken/webform/:webformToken?",
      name: "professional-space-open-form",
      component: () => import("@/views/professional-space/InformationsOpenForm.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/professional-space/inscription",
      name: "professional-space-inscription",
      component: () => import("@/views/professional-space/Inscription.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/professional-space/authentification",
      name: "professional-space-authentication",
      component: () => import("@/views/professional-space/Connexion.vue"),
      meta: {
        hideIntercom: true,
      },
    },

    // ERRORS
    {
      path: "/error",
      name: "error",
      component: () => import("@/views/Error.vue"),
    },
    {
      path: "/error/version",
      name: "error-version",
      component: () => import("@/views/errors/Error.vue"),
    },

    // ESTABLISHMENTS
    {
      path: "/establishments",
      name: "establishment-management",
      component: () => import("@/views/establishments/EstablishmentsManagement.vue"),
      meta: {
        middleware: [auth],
      },
    },
    {
      path: "/establishment/new",
      name: "new-establishment",
      component: () => import("@/views/establishments/NewEstablishment.vue"),
      meta: {
        middleware: [auth],
        title: "establishment/new.page_title",
      },
    },
    {
      path: "/establishment/new/form",
      name: "new-establishment-premium",
      component: () => import("@/views/establishments/NewEstablishmentPremium.vue"),
      meta: {
        middleware: [auth],
        title: "establishment/new.page_title",
      },
    },
    {
      path: "/establishment",
      name: "establishment",
      component: () => import("@/views/establishments/EstablishmentFormView.vue"),
      meta: {
        middleware: [auth, information],
        title: "establishments/formulaire.page_title",
      },
    },
    {
      path: "/establishments/listing/:diffuserType?",
      name: "establishment-listing",
      component: () => import("@/views/establishments/DiffusersListing.vue"),
      meta: {
        middleware: [auth],
        title: "establishments/listing.page_title",
      },
    },

    // PUBLICATIONS
    {
      path: "/publications",
      name: "publication-management",
      component: () => import("@/views/publications/PublicationsManagement.vue"),
      meta: {
        middleware: [auth, publication],
        title: "publications.page_title",
      },
    },

    {
      path: "/publications/form/:type/:id?",
      name: "publication-form",
      component: () => import("@/views/publications/PublicationsCreate.vue"),
      meta: {
        middleware: [auth],
        title: "publications/form.page_title",
        hasTitleParams: {
          type: PageTitleParamsType.KEY,
          name: "type",
        },
      },
    },

    // REVIEWS
    {
      path: "/reviews",
      name: "review-management",
      component: () => import("@/views/reviews/ReviewsManagement.vue"),
      meta: {
        middleware: [auth, review],
        title: "reviews.page_title",
      },
    },

    // ANALYSE FREEMIUM
    {
      path: "/analyse",
      name: "analyse",
      component: () => import("@/views/analyse/AnalyseManagement.vue"),
      meta: {
        middleware: [auth],
        title: "analyse.page_title",
      },
    },

    // SOLICITATIONS
    {
      path: "/solicitations",
      name: "solicitations",
      component: () => import("@/views/solicitations/SolicitationsManagement.vue"),
      meta: {
        middleware: [auth],
        title: "solicitations.page_title",
      },
    },
    {
      path: "/solicitations/maj/:token",
      name: "maj-form",
      component: () => import("@/views/solicitations/CmajForm.vue"),
      meta: {
        middleware: [auth],
        title: "Compléter mes informations | Uska",
      },
    },
    {
      path: "/solicitations/adh/:token",
      name: "adh-form",
      component: () => import("@/views/solicitations/CadhForm.vue"),
      meta: {
        middleware: [auth],
        title: "Voir les offres | Uska",
      },
    },

    // SUBSCRIPTIONS
    {
      path: "/subscriptions",
      name: "subscription-management",
      component: () => import("@/views/subscriptions/SubscriptionsManagement.vue"),
      meta: {
        middleware: [auth],
        title: "subscriptions.page_title",
      },
    },

    // CONTACT
    {
      path: "/contactus",
      name: "app-contact",
      component: () => import("@/views/configuration/Contact.vue"),
    },

    // USERS
    {
      path: "/profile",
      name: "user-profil",
      component: () => import("@/views/user/Profil.vue"),
      meta: {
        middleware: [auth],
        title: "profile.page_title",
      },
    },
    {
      path: "/usermanagement",
      name: "user-management",
      component: () => import("@/views/user/UsersManagement.vue"),
      meta: {
        middleware: [auth],
        title: "usermanagement.page_title",
      },
    },
    {
      path: "/usermanagement/user/:id?/:user?",
      name: "user-management-details",
      component: () => import("@/views/user/Create.vue"),
      meta: {
        middleware: [auth, admin],
        title: "usermanagement/user.page_title",
        hasTitleParams: {
          type: PageTitleParamsType.PARAM,
          name: "user",
        },
      },
    },
    {
      path: "/newuser",
      name: "user-create",
      component: () => import("@/views/user/Create.vue"),
      meta: {
        middleware: [auth, admin],
        title: "newuser.page_title",
      },
    },

    // ONBOARDING
    // {
    //   path: "/onboarding/introduction",
    //   name: "onboarding-introduction",
    //   component: () => import("@/modules/onboarding/views/Introduction.vue"),
    //   meta: {
    //     hideIntercom: true,
    //   },
    // },
    {
      path: "/onboarding/freemium",
      name: "onboarding-freemium",
      component: () => import("@/modules/onboarding/views/Introduction.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/onboarding/begin",
      name: "onboarding-begin",
      component: () => import("@/modules/onboarding/views/Begin.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/onboarding/establishment",
      name: "onboarding-establishment",
      component: () => import("@/modules/onboarding/views/Establishment.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/onboarding/analyse",
      name: "onboarding-result",
      component: () => import("@/modules/onboarding/views/Result.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/onboarding/howtocreatemyaccount",
      name: "onboarding-account",
      component: () => import("@/modules/onboarding/views/Account.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/onboarding/contactus/:variant?", // variant: ContactVariant
      name: "onboarding-contact",
      component: () => import("@/modules/onboarding/views/Contact.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/onboarding/contactend",
      name: "onboarding-contact-end",
      component: () => import("@/modules/onboarding/views/ContactEnd.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },

    // AUTHENTICATION
    {
      path: "/authentication",
      name: "auth",
      component: () => import("@/views/Authentication.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/inscription/:account?",
      name: "auth-register",
      component: () => import("@/modules/authentication/views/Inscription.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/google/callback",
      name: "google",
      component: () => import("@/modules/authentication/views/GoogleValidation.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/inscription/validation/:token", // validation de l'email après l'inscription (utilisé dans l'api)
      name: "auth-validate",
      component: () => import("@/modules/authentication/views/ValidateEmail.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/first-connexion/:token", // création du mot de passe après invitation (utilisé dans l'api)
      name: "auth-first-connexion",
      component: () => import("@/modules/authentication/views/FirstConnexion.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/authentication/resetpassword/email",
      name: "auth-reset-email",
      component: () => import("@/modules/authentication/views/EmailReset.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/authentication/resetpassword/validation/:token", // réinitialisation du mot de passe (mot de passe oublié) (utilisé dans l'api)
      name: "auth-reset-password",
      component: () => import("@/modules/authentication/views/PasswordReset.vue"),
      meta: {
        hideIntercom: true,
      },
    },
    {
      path: "/authentication/contact",
      name: "auth-contact",
      component: () => import("@/modules/authentication/views/Contact.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },
    {
      path: "/unsubscribe/:token",
      name: "unsubscribe",
      component: () => import("@/modules/authentication/views/Unsubscribe.vue"),
      meta: {
        transition: "fade",
        hideIntercom: true,
      },
    },

    // FREEMIUM
    {
      path: "/freemium/convert",
      name: "freemium-convert",
      component: () => import("@/views/freemium/FreemiumConvert.vue"),
      meta: {
        middleware: [auth],
        title: "freemium/convert.page_title",
      },
    },
    {
      path: "/freemium/comparison",
      name: "freemium-comparison",
      component: () => import("@/views/freemium/PremiumComparison.vue"),
      meta: {
        middleware: [auth],
        title: "freemium/comparison.page_title",
      },
    },

    {
      path: "/freemium/contact",
      name: "freemium-contact",
      component: () => import("@/views/freemium/Contact.vue"),
      meta: {
        middleware: [auth],
        title: "freemium/contact.page_title",
      },
    },
    {
      path: "/freemium/contact-convert",
      name: "freemium-contact-convert",
      component: () => import("@/views/freemium/ContactForPremium.vue"),
      meta: {
        middleware: [auth],
        title: "freemium/contact-convert.page_title",
      },
    },
    {
      path: "/freemium/meeting",
      name: "freemium-meeting",
      component: () => import("@/views/freemium/HubspotMeeting.vue"),
      meta: {
        middleware: [auth],
        title: "freemium/meeting.page_title",
      },
    },
  ],
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach(async (to, from, next) => {
  await loadLanguages()

  if (!["error-version", "auth"].includes(to.name)) {
    await loadCredentials(to)
  }

  let path = handleMiddlewares(to, next)

  if (to.name !== "error-version" && to.meta.middleware && !to.meta.middleware.includes(auth)) {
    const status = await getUberallStatus()

    if (!status && to.path !== "/error") {
      path = "/error"
    }

    if (status && to.path === "/error") {
      path = "/"
    }
  }

  changePageTitle(to)

  return next(path)
})

async function loadLanguages() {
  let locale = i18n.global.locale.value
  if (locale === undefined) {
    locale = import.meta.env.VITE_APP_I18N_LOCALE
  }

  return loadLanguageAsync(locale as string)
}

function handleMiddlewares(to: RouteLocationNormalized, next: NavigationGuardNext) {
  const middlewares = to.meta.middleware as any

  let path = null

  let middlewareBlock = false
  let errorPath = ""

  if (middlewares) {
    middlewares.forEach((middleware: (arg0: any) => any) => {
      if (!middlewareBlock) {
        const currentMiddleware = middleware(next)
        if (currentMiddleware.success === false) {
          middlewareBlock = true
          errorPath = currentMiddleware.path
        }
      }
    })
  }

  if (middlewareBlock) {
    path = errorPath
  }

  return path
}

/**
 * Modifier le titre de l'onglet du navigateur de la page courante
 */
function changePageTitle(to: RouteLocationNormalized) {
  const DEFAULT_TITLE = "common.appname"
  if (to.meta.title) {
    const params = to.meta.hasTitleParams as IPageTitleParams
    if (params) {
      /**
       * Le paramètre de type param permet d'utiliser la valeur de la requête dans le titre
       */
      if (params.type === PageTitleParamsType.PARAM) {
        document.title = i18n.global.t(to.meta.title!.toString(), {
          [params.name]: convertSlugToString(to.params[params.name.toString()].toString()),
        })
      } else if (params.type === PageTitleParamsType.KEY) {
        /**
         * Le paramètre de type key permet d'utiliser la valeur de la requête dans le chemin de la traduction i18n
         */
        document.title = i18n.global.t(to.meta.title!.toString() + "." + to.params[params.name.toString()])
      }
    } else {
      document.title = i18n.global.t(to.meta.title!.toString())
    }
  } else {
    document.title = i18n.global.t(DEFAULT_TITLE)
  }
}

async function loadCredentials(to: RouteLocationNormalized) {
  const authStore = useAuthenticationStore()

  if (to.query.token !== undefined) {
    // authStore.logout()
    localStorage.setItem("token", to.query.token!.toString())
    localStorage.setItem("tokenType", "Bearer")
    await loadUser()
  } else if (
    to.meta !== undefined &&
    to.meta.middleware !== undefined &&
    to.meta.middleware!.includes(auth) &&
    authStore.user
  ) {
    await loadUser()
  }
}

async function loadUser() {
  const authStore = useAuthenticationStore()
  const env = import.meta.env.VITE_ENV

  const callback = () => {
    const userConf = {
      user_id: "uska-" + authStore.user!.id,
      name: authStore.user!.first_name + " " + authStore.user!.last_name,
      email: authStore.user!.email,
      role: authStore.user!.role.code,
      account_configured: authStore.user!.account.configured,
    }

    setTimeout(() => {
      if (window.intercomSettings) {
        Object.assign(window.intercomSettings, userConf)
      }
    }, 2000)

    if (env === "production") {
      Sentry.setContext("user", {
        id: authStore.user!.id,
        email: authStore.user!.email,
      })
    }
  }
  await authStore.me(callback)
}

async function getUberallStatus() {
  const authStore = useAuthenticationStore()
  const res = await UberallDataService.getStatus(authStore.currentHeaders)

  return res.data.success
}

function convertSlugToString(text: String): String {
  return text.replace(/-/, " ")
}

export default router
