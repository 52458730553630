import http from "@/http-common"

class NotificationDataService {
  getNotifications(headers: {}): Promise<any> {
    return http.get(`/auth/notifications`, { headers: headers })
  }

  deleteNotification(id: number): Promise<any> {
    return http.delete(`/auth/notifications/${id}`)
  }

  getPreferences(): Promise<any> {
    return http.get(`/auth/notifications/preferences`)
  }

  updatePreferences(preferences: []): Promise<any> {
    const payload = {
      preferences: preferences,
    }

    return http.post(`/auth/notifications/preferences/update`, payload)
  }
}

export default new NotificationDataService()
