<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6 12.0004C21.6 14.5465 20.5886 16.9883 18.7882 18.7886C16.9879 20.589 14.5461 21.6004 12 21.6004C9.45395 21.6004 7.01215 20.589 5.2118 18.7886C3.41145 16.9883 2.40002 14.5465 2.40002 12.0004C2.40002 9.45431 3.41145 7.01252 5.2118 5.21217C7.01215 3.41182 9.45395 2.40039 12 2.40039C14.5461 2.40039 16.9879 3.41182 18.7882 5.21217C20.5886 7.01252 21.6 9.45431 21.6 12.0004ZM8.40002 9.60039C8.40002 9.28213 8.52645 8.97691 8.7515 8.75186C8.97654 8.52682 9.28176 8.40039 9.60002 8.40039C9.91828 8.40039 10.2235 8.52682 10.4486 8.75186C10.6736 8.97691 10.8 9.28213 10.8 9.60039V14.4004C10.8 14.7187 10.6736 15.0239 10.4486 15.2489C10.2235 15.474 9.91828 15.6004 9.60002 15.6004C9.28176 15.6004 8.97654 15.474 8.7515 15.2489C8.52645 15.0239 8.40002 14.7187 8.40002 14.4004V9.60039ZM14.4 8.40039C14.0818 8.40039 13.7765 8.52682 13.5515 8.75186C13.3265 8.97691 13.2 9.28213 13.2 9.60039V14.4004C13.2 14.7187 13.3265 15.0239 13.5515 15.2489C13.7765 15.474 14.0818 15.6004 14.4 15.6004C14.7183 15.6004 15.0235 15.474 15.2486 15.2489C15.4736 15.0239 15.6 14.7187 15.6 14.4004V9.60039C15.6 9.28213 15.4736 8.97691 15.2486 8.75186C15.0235 8.52682 14.7183 8.40039 14.4 8.40039Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPause",
  props: ["height", "width"],
}
</script>
