<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.11763 5.09173C9.70578 4.19869 11.5567 3.8383 13.3804 4.06702C15.2041 4.29574 16.8975 5.10066 18.1951 6.35562C19.4927 7.61059 20.3213 9.24467 20.5509 11.0018C20.6146 11.4889 20.2562 11.9334 19.7503 11.9947C19.2445 12.056 18.7829 11.7109 18.7192 11.2238C18.5406 9.8571 17.8961 8.5861 16.8868 7.60999C15.8775 6.63388 14.5605 6.00781 13.142 5.82991C11.7235 5.65201 10.2838 5.93232 9.04858 6.62693C8.23257 7.08578 7.53624 7.70828 7.00299 8.44612H9.53846C10.0483 8.44612 10.4615 8.84409 10.4615 9.33501C10.4615 9.82593 10.0483 10.2239 9.53846 10.2239H4.92308C4.41328 10.2239 4 9.82593 4 9.33501V4.89056C4 4.39964 4.41328 4.00167 4.92308 4.00167C5.43288 4.00167 5.84615 4.39964 5.84615 4.89056V6.97076C6.47471 6.22209 7.24266 5.58373 8.11763 5.09173ZM4.86438 12.0087C5.37014 11.9471 5.83205 12.2919 5.89608 12.7789C6.07568 14.1451 6.72067 15.4154 7.73007 16.3909C8.73947 17.3664 10.0563 17.9921 11.4744 18.1699C12.8925 18.3477 14.3318 18.0677 15.567 17.3737C16.3824 16.9156 17.0785 16.294 17.612 15.5572H15.0769C14.5671 15.5572 14.1538 15.1593 14.1538 14.6683C14.1538 14.1774 14.5671 13.7795 15.0769 13.7795H19.6923C20.2021 13.7795 20.6154 14.1774 20.6154 14.6683V19.1128C20.6154 19.6037 20.2021 20.0017 19.6923 20.0017C19.1825 20.0017 18.7692 19.6037 18.7692 19.1128V17.0315C18.1404 17.7797 17.3723 18.4176 16.4973 18.9092C14.9094 19.8015 13.059 20.1614 11.236 19.9328C9.41287 19.7042 7.71998 18.8998 6.4223 17.6457C5.12463 16.3916 4.29544 14.7586 4.06454 13.0022C4.00051 12.5152 4.35861 12.0704 4.86438 12.0087Z"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7296 10.8524C16.0901 10.5053 16.0901 9.94249 15.7296 9.59536C15.3692 9.24822 14.7847 9.24822 14.4242 9.59536L11.3846 12.5224L10.1912 11.3731C9.83069 11.026 9.24623 11.026 8.88575 11.3731C8.52526 11.7203 8.52526 12.2831 8.88575 12.6302L10.7319 14.408C11.0924 14.7551 11.6768 14.7551 12.0373 14.408L15.7296 10.8524Z"/>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconAlmostInSync",
  props: ["height", "width"],
}
</script>
