<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6485 20.0484C11.4235 20.2734 11.1183 20.3997 10.8001 20.3997C10.4819 20.3997 10.1768 20.2734 9.95173 20.0484L2.75173 12.8484C2.52677 12.6234 2.40039 12.3182 2.40039 12C2.40039 11.6818 2.52677 11.3766 2.75173 11.1516L9.95173 3.9516C10.1781 3.73301 10.4812 3.61206 10.7958 3.61479C11.1105 3.61753 11.4114 3.74373 11.6339 3.96622C11.8564 4.18871 11.9826 4.48968 11.9853 4.80432C11.9881 5.11895 11.8671 5.42208 11.6485 5.6484L6.49693 10.8H20.4001C20.7184 10.8 21.0236 10.9264 21.2487 11.1515C21.4737 11.3765 21.6001 11.6817 21.6001 12C21.6001 12.3183 21.4737 12.6235 21.2487 12.8485C21.0236 13.0736 20.7184 13.2 20.4001 13.2H6.49693L11.6485 18.3516C11.8735 18.5766 11.9999 18.8818 11.9999 19.2C11.9999 19.5182 11.8735 19.8234 11.6485 20.0484Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconArrowLeft",
  props: ["height", "width"],
}
</script>
