<template>
  <div id="global-statistics">
    <div class="flex-col lg:flex-row flex justify-between items-start lg:items-end mb-sm gap-sm lg:gap">
      <div class="flex justify-between md:justify-start items-center gap-sm">
        <span class="text-xl text-gray-900">
          {{ $t(i18nbase + ".title") }}
        </span>
        <Modal variant="large">
          <template #action>
            <Icon
              icon-name="IconInformationCircle"
              class="text-gray-700"
            />
          </template>

          <template #header>
            <div class="flex flex-col items-center justify-center gap-sm">
              <Icon
                icon-name="IconCheck"
                class="text-primary-500"
                :height="30"
                :width="30"
              />
              <span class="text-xl text-center text-gra-700">
                {{ $t(i18nbase + ".title") }}
              </span>
            </div>
          </template>

          <div class="flex flex-col gap">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-md">
              <GlobalStatisticModalItem
                :icon="dashboardStore.viewsStats.icon"
                :title="$t(i18nbase + '.views_title')"
              >
                <span class="text-base text-gray-500">{{ $t(i18nbase + ".views_description") }}</span>
              </GlobalStatisticModalItem>

              <GlobalStatisticModalItem
                :icon="dashboardStore.clickStat.icon"
                :title="$t(i18nbase + '.clics_title')"
              >
                <span class="text-base text-gray-500">{{ $t(i18nbase + ".clics_description") }}</span>
              </GlobalStatisticModalItem>

              <GlobalStatisticModalItem
                :icon="dashboardStore.noteStat.icon"
                :title="$t(i18nbase + '.note_title')"
              >
                <span class="text-base text-gray-500">{{ $t(i18nbase + ".note_description") }}</span>
              </GlobalStatisticModalItem>
            </div>
          </div>
        </Modal>
      </div>
      <slot
        v-if="oneConnection"
        name="period"
      ></slot>
    </div>
    <div
      v-if="!oneConnection"
      class="border brder-gray-200 rounded-sm w-full p-lg flex items-center justify-center"
    >
      <span class="test-base text-gray-500">
        Connectez-vous pour voir votre nombre de vues, votre nombre de clics et votre note moyenne
      </span>
    </div>
    <div
      v-else
      class="relative grid grid-col-1 min-[1000px]:grid-cols-3 gap-sm"
    >
      <GlobalStatisticItem
        :stat="dashboardStore.viewsStats"
        :disabled="disabled"
      />
      <GlobalStatisticItem
        :stat="dashboardStore.clickStat"
        :disabled="disabled"
      />
      <GlobalStatisticItem
        :stat="dashboardStore.noteStat"
        :disabled="disabled"
      />
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
// import moment from "moment"

// Layout
//

// Components
import GlobalStatisticItem from "@/modules/app/components/dashboard/GlobalStatisticItem.vue"
import Icon from "@/components/Icon.vue"
import Modal from "@/components/Modal.vue"
import GlobalStatisticModalItem from "@/modules/app/components/dashboard/GlobalStatistiqueModalItem.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

// Types
//

// Data

export default defineComponent({
  name: "GlobalStatistics",

  components: {
    GlobalStatisticItem,
    Icon,
    Modal,
    GlobalStatisticModalItem,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    oneConnection: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      i18nbase: "home.global_stats",
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useAccountStore),
  },
})
</script>
