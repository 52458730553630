<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.5 3.99969C18.1021 3.99969 17.7205 4.15776 17.4391 4.43913L16.6462 5.23202L18.768 7.35381L19.5609 6.56092C19.8423 6.27955 20.0003 5.89794 20.0003 5.50002C20.0003 5.10211 19.8423 4.7205 19.5609 4.43913C19.2795 4.15776 18.8979 3.99969 18.5 3.99969ZM20.9751 7.97513C21.6315 7.31869 22.0003 6.42837 22.0003 5.50002C22.0003 4.57168 21.6315 3.68136 20.9751 3.02492C20.3187 2.36848 19.4283 1.99969 18.5 1.99969C17.5717 1.99969 16.6813 2.36848 16.0249 3.02492L14.5254 4.5244C14.5256 4.52422 14.5252 4.52457 14.5254 4.5244C14.5252 4.52457 14.5245 4.52526 14.5244 4.52544L2.29289 16.7569C2.10536 16.9445 2 17.1988 2 17.464V21.036C2 21.5883 2.44772 22.036 3 22.036H6.5C6.76522 22.036 7.01957 21.9307 7.20711 21.7431L20.9751 7.97513ZM17.3538 8.76802L15.232 6.64624L4 17.8782V20.036H6.08579L17.3538 8.76802Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPencil",
  props: ["height", "width"],
}
</script>
