<template>
  <StatCard
    :title="$t(i18nbase + '.clicks.title')"
    :description="$t(i18nbase + '.description')"
    icon="IconCursorClick"
    :loading="isLoading"
  >
    <template
      v-if="showGraph"
      #actions
    >
      <div class="flex items-center gap-sm">
        <FilterItem
          v-for="(legend, index) in legendItems"
          :key="'legend-' + index"
          :legend="legend"
          :index="index"
          @clicked="legendClicked(index, legend, showPreviousData)"
        >
          {{ $t(i18nbase + ".clicks.legend." + legend.label) }}
        </FilterItem>
      </div>
    </template>

    <template
      v-if="showGraph"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".clicks.total", {
              nb: total,
            })
          }}
        </span>
        <span
          v-if="showPreviousData && previousTotal"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".clicks.previous_total", {
              nb: previousTotal,
            })
          }}
        </span>
      </div>
    </template>

    <Bar
      ref="chartRef"
      :class="!showGraph || selectedLegends.length === 0 ? '!hidden' : ''"
      :data="data"
      :options="chartOptions"
    />
    <div
      :class="showGraph && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + noDataKey) }}
      </span>
    </div>
    <div
      :class="selectedLegends.length > 0 && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + ".clicks.no_filter") }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { ref, type PropType } from "vue"
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, TimeScale } from "chart.js"
import { Bar, type ChartComponentRef } from "vue-chartjs"
import "chartjs-adapter-moment"

// Layout
//

// Components
import StatCard from "../StatCard.vue"
import Icon from "@/components/Icon.vue"
import FilterItem from "../FilterItem.vue"

// Stores
import { useListingStore } from "@/stores/listing"

// Types
//

// Data
import * as chartConfig from "@/static/dashboard/ClickNumber"
import { DisplayStat, type IClickStatsForDashboard } from "@/types/Stats.types"
import {
  displayStats,
  generateTransformedData,
  initShowingPreviousData,
  type GenericDataItem,
  getTransformationType,
  getGraphUnit,
} from "@/plugins/GraphPlugin"
import type { FilterCode } from "@/static/Dashboard"
import moment from "moment"
import { mapStores } from "pinia"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale)

export default {
  name: "ClicksNumber",

  components: {
    Bar,
    StatCard,
    Icon,
    FilterItem,
  },

  props: {
    stats: {
      type: Object as PropType<IClickStatsForDashboard>,
      required: true,
    },

    currentFilter: {
      type: String as PropType<FilterCode>,
      required: true,
    },

    showPreviousData: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const chartRef = ref<ChartComponentRef>(null)
    const legendItems = ref(
      chartConfig.legends
        .filter((item) => !item.label.includes("-1"))
        .map((item) => {
          item.hidden = false
          return item
        })
    )

    const setupPreviousStats = (showPreviousData: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      if (chart.data.datasets.length > 0) {
        initShowingPreviousData(chart, chartConfig.legends, showPreviousData, legendItems.value)
      }
    }

    const legendClicked = (index: number, legend, previousStat: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      // Hide current stats
      const realIndex = chartConfig.legends.findIndex((item) => item.label === legend.label)
      displayStats(chart, realIndex, DisplayStat.AUTO, (action) => {
        if (action === "hide") {
          legendItems.value[index].hidden = true
        } else {
          legendItems.value[index].hidden = false
        }
      })

      // Hide previous stats
      if (previousStat) {
        const indexLastYear = chartConfig.legends.findIndex((item) => item.label === legend.label + " n-1")
        displayStats(chart, indexLastYear, DisplayStat.AUTO)
      }
    }

    return {
      chartRef,
      legendItems,
      setupPreviousStats,
      legendClicked,
      ...chartConfig,
    }
  },

  data() {
    return {
      i18nbase: "home.google",
      chartConfig: chartConfig,
      isLoading: true,
    }
  },

  computed: {
    ...mapStores(useListingStore),
    data() {
      if (this.showGraph) {
        const transformedData = generateTransformedData(
          this.stats.current_click_evolution as unknown as GenericDataItem[],
          getTransformationType(this.currentFilter)
        )

        const previousTransformedData = generateTransformedData(
          this.stats.last_year_click_evolution as unknown as GenericDataItem[],
          getTransformationType(this.currentFilter),
          false,
          transformedData.ranges
        )

        // ATTENTION : l'ordre des datasets doit correspondre à l'ordre des legends
        return {
          labels: transformedData.data.map((item) => new Date(item.startDate)),
          datasets: [
            {
              data: previousTransformedData.data.map((item) => item.clickWebsite),
              ...chartConfig.previousWebsiteConfig,
            },
            {
              data: transformedData.data.map((item) => item.clickWebsite),
              ...chartConfig.websiteConfig,
            },
            {
              data: previousTransformedData.data.map((item) => item.clickPhone),
              ...chartConfig.previousPhoneConfig,
            },
            {
              data: transformedData.data.map((item) => item.clickPhone),
              ...chartConfig.phoneConfig,
            },
            {
              data: previousTransformedData.data.map((item) => item.clickDrive),
              ...chartConfig.previousDriveConfig,
            },
            {
              data: transformedData.data.map((item) => item.clickDrive),
              ...chartConfig.driveConfig,
            },
          ],
        }
      } else {
        return {
          labels: [],
          datasets: [],
        }
      }
    },

    chartOptions() {
      let opts = this.chartConfig.options
      opts.scales.x.time.unit = getGraphUnit(this.currentFilter)
      opts.scales.x.title.text = getGraphUnit(this.currentFilter)
      opts.plugins = {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = this.$t(this.i18nbase + ".clicks.legend." + context.dataset.label) || ""
              if (label) {
                label += " : "
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y
              }
              return label
            },
            title: (context) => {
              let date = moment(context[0].label)
              if (context[0].dataset.label.includes("n-1")) {
                date = date.subtract(1, "year")
              }
              return date.format("MMMM YYYY")
            },
          },
        },
        ...opts.plugins,
      }
      return opts
    },

    total() {
      let total = 0
      const available = this.legendItems.filter((item) => item.hidden === false)

      available.forEach((element) => {
        if (element.label === "website" && this.stats.current_click_total_website !== null) {
          total += this.stats.current_click_total_website
        }
        if (element.label === "phone" && this.stats.current_click_total_phone !== null) {
          total += this.stats.current_click_total_phone
        }
        if (element.label === "drive" && this.stats.current_click_total_drive !== null) {
          total += this.stats.current_click_total_drive
        }
      })

      return this.$string.formatNumberToHumanString(total)
    },

    previousTotal() {
      let total = 0
      const available = this.legendItems.filter((item) => item.hidden === false)

      available.forEach((element) => {
        if (element.label === "website" && this.stats.last_year_click_total_website !== null) {
          total += this.stats.last_year_click_total_website
        }
        if (element.label === "phone" && this.stats.last_year_click_total_phone !== null) {
          total += this.stats.last_year_click_total_phone
        }
        if (element.label === "drive" && this.stats.last_year_click_total_drive !== null) {
          total += this.stats.last_year_click_total_drive
        }
      })

      return this.$string.formatNumberToHumanString(total)
    },

    showGraph() {
      return this.stats.current_click_evolution.length > 0 && this.stats.last_year_click_evolution.length > 0
    },

    selectedLegends() {
      return this.legendItems.filter((item) => item.hidden === false)
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus("GOOGLE") === "CONNECTED" ? "clicks.no_data" : "not_connected"
    },
  },

  watch: {
    showPreviousData() {
      this.setupPreviousStats(this.showPreviousData)
    },
  },

  mounted() {
    this.$waiting.simple(() => {
      this.setupPreviousStats(this.showPreviousData)
      this.isLoading = false
    })
  },
}
</script>
@/static/dashboard/ClickNumber
