<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="24"
      height="24"
      rx="12"
      fill="#C8DCFC"
    />
    <g clip-path="url(#clip0_2570_73360)">
      <path
        d="M15.9058 9.63135H7.84906C7.65763 9.63135 7.50244 9.78227 7.50244 9.96845V15.6573C7.50244 15.8434 7.65763 15.9944 7.84906 15.9944H15.9058C16.0973 15.9944 16.2524 15.8434 16.2524 15.6573V9.96845C16.2524 9.78227 16.0973 9.63135 15.9058 9.63135Z"
        fill="#4989F5"
      />
      <path
        d="M15.8047 9.5835H7.9405C7.69689 9.5835 7.50732 11.4171 7.50732 11.654L11.814 15.9943H15.8047C16.0491 15.9937 16.247 15.8012 16.2476 15.5635V10.0142C16.2469 9.77658 16.049 9.58415 15.8047 9.5835Z"
        fill="url(#paint0_linear_2570_73360)"
      />
      <path
        d="M9.50049 10.697H12.0005V7.50586H9.87483L9.50049 10.697Z"
        fill="#3C4BA6"
      />
      <path
        d="M14.5005 10.697H12.0005V7.50586H14.1261L14.5005 10.697ZM16.2526 8.11077L16.2557 8.12165C16.2549 8.11781 16.2534 8.11425 16.2526 8.11077Z"
        fill="#7BABF7"
      />
      <path
        d="M16.2555 8.12165L16.2524 8.11077C16.1731 7.7578 15.8519 7.50609 15.4804 7.50586H14.126L14.5 10.6971H17L16.2555 8.12165Z"
        fill="#3F51B5"
      />
      <path
        d="M7.74822 8.11084L7.74512 8.12172C7.74587 8.11788 7.74737 8.11431 7.74822 8.11084Z"
        fill="#7BABF7"
      />
      <path
        d="M7.74502 8.12165L7.74812 8.11077C7.82738 7.7578 8.14862 7.50609 8.52011 7.50586H9.87462L9.5 10.6971H7L7.74502 8.12165Z"
        fill="#7BABF7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.50445 10.6963C9.50445 11.3673 8.94512 11.9113 8.25515 11.9113C7.56519 11.9113 7.00586 11.3673 7.00586 10.6963H9.50445Z"
        fill="#709BE0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.003 10.6963C12.003 11.3673 11.4437 11.9113 10.7537 11.9113C10.0637 11.9113 9.50439 11.3673 9.50439 10.6963H12.003Z"
        fill="#3C4BA6"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.5015 10.6963C14.5015 11.3673 13.9422 11.9113 13.2522 11.9113C12.5623 11.9113 12.0029 11.3673 12.0029 10.6963H14.5015Z"
        fill="#709BE0"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0001 10.6963C17.0001 11.3673 16.4407 11.9113 15.7508 11.9113C15.0608 11.9113 14.5015 11.3673 14.5015 10.6963H17.0001Z"
        fill="#3C4BA6"
      />
      <path
        d="M15.7507 13.9881C15.7463 13.9302 15.7389 13.8774 15.7285 13.8099H14.3657C14.3657 13.9953 14.3657 14.1976 14.365 14.383H15.1544C15.1377 14.4694 15.1035 14.5518 15.0539 14.6254C15.0043 14.6989 14.9403 14.7622 14.8654 14.8116C14.8654 14.8116 14.8654 14.8084 14.8649 14.8083C14.7682 14.87 14.6594 14.9113 14.5453 14.9296C14.431 14.95 14.3137 14.9495 14.1995 14.9281C14.0836 14.9048 13.9739 14.8586 13.8771 14.7922C13.7342 14.6937 13.6241 14.5568 13.5601 14.3983C13.5552 14.3859 13.5507 14.3735 13.5461 14.3609V14.3595L13.5473 14.3586C13.4852 14.1813 13.485 13.989 13.5468 13.8116C13.5904 13.6872 13.662 13.5738 13.7565 13.4799C13.9782 13.2568 14.3094 13.173 14.6148 13.2625C14.7319 13.2974 14.8389 13.3587 14.9271 13.4413L15.1934 13.1822C15.2404 13.1361 15.2892 13.0914 15.3344 13.0437C15.1994 12.9212 15.0408 12.8257 14.8679 12.7627C14.5531 12.6517 14.2088 12.6488 13.892 12.7544C13.881 12.758 13.87 12.7618 13.8591 12.7658C13.5174 12.8907 13.2363 13.1357 13.0713 13.4524C13.0131 13.5645 12.9707 13.6838 12.9452 13.8069C12.7944 14.536 13.2518 15.2538 13.9904 15.4467C14.2319 15.5096 14.4885 15.508 14.7319 15.4543C14.953 15.4057 15.1574 15.3024 15.3253 15.1544C15.5005 14.9976 15.626 14.7888 15.6918 14.5669C15.7468 14.3789 15.7668 14.1829 15.7507 13.9881Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2570_73360"
        x1="7.50732"
        y1="12.7888"
        x2="16.2476"
        y2="12.7888"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.03"
          stop-color="#4079D8"
        />
        <stop
          offset="1"
          stop-color="#4989F5"
        />
      </linearGradient>
      <clipPath id="clip0_2570_73360">
        <rect
          width="10"
          height="8.5"
          fill="white"
          transform="translate(7 7.5)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconGooglePublication",
  props: ["height", "width"],
}
</script>
