<template>
  <Tooltip
    variant-x="centerX"
    variant-y="up"
    variant-mobile-x="centerX"
    variant-mobile-y="up"
  >
    <template #main>
      <div
        class="transition-all cursor-pointer flex flex-col gap-1"
        :class="legend.hidden && 'opacity-30'"
        @click="$emit('clicked')"
      >
        <Icon
          :icon-name="legend.icon"
          class="text-gray-500"
        />
        <div
          class="w-full h-2 rounded-sm"
          :style="{ 'background-color': legend.legendColor }"
        ></div>
      </div>
    </template>
    <template #content> <slot></slot> </template>
  </Tooltip>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import Icon from "../Icon.vue"

// Layout
//

// Components
import Tooltip from "../Tooltip.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "FilterItem",

  components: { Icon, Tooltip },

  props: {
    legend: {
      type: Object,
      required: true,
    },

    index: {
      type: Number,
      required: true,
    },
  },

  emits: ["clicked"],

  data() {
    return {}
  },
})
</script>
