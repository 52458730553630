<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.9999 21.6004C14.546 21.6004 16.9878 20.589 18.7881 18.7886C20.5885 16.9883 21.5999 14.5465 21.5999 12.0004C21.5999 9.45431 20.5885 7.01252 18.7881 5.21217C16.9878 3.41182 14.546 2.40039 11.9999 2.40039C9.45382 2.40039 7.01203 3.41182 5.21168 5.21217C3.41133 7.01252 2.3999 9.45431 2.3999 12.0004C2.3999 14.5465 3.41133 16.9883 5.21168 18.7886C7.01203 20.589 9.45382 21.6004 11.9999 21.6004ZM16.4483 10.4488C16.6669 10.2225 16.7878 9.91935 16.7851 9.60471C16.7824 9.29007 16.6562 8.9891 16.4337 8.76661C16.2112 8.54412 15.9102 8.41792 15.5956 8.41518C15.2809 8.41245 14.9778 8.5334 14.7515 8.75199L10.7999 12.7036L9.2483 11.152C9.02198 10.9334 8.71886 10.8124 8.40422 10.8152C8.08958 10.8179 7.78861 10.9441 7.56612 11.1666C7.34363 11.3891 7.21743 11.6901 7.21469 12.0047C7.21196 12.3193 7.33291 12.6225 7.5515 12.8488L9.9515 15.2488C10.1765 15.4738 10.4817 15.6001 10.7999 15.6001C11.1181 15.6001 11.4233 15.4738 11.6483 15.2488L16.4483 10.4488Z"
      fill="#1398B5"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.4338 10.434C16.6524 10.2077 16.7734 9.9046 16.7707 9.58996C16.7679 9.27533 16.6417 8.97435 16.4192 8.75186C16.1967 8.52937 15.8958 8.40317 15.5811 8.40044C15.2665 8.3977 14.9634 8.51866 14.7371 8.73725L10.7854 12.6888L9.23385 11.1372C9.00753 10.9187 8.7044 10.7977 8.38977 10.8004C8.07513 10.8032 7.77416 10.9294 7.55167 11.1519C7.32918 11.3744 7.20297 11.6753 7.20024 11.99C7.19751 12.3046 7.31846 12.6077 7.53705 12.834L9.93705 15.234C10.1621 15.459 10.4673 15.5854 10.7854 15.5854C11.1036 15.5854 11.4088 15.459 11.6338 15.234L16.4338 10.434Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCheckCircleColored",
  props: ["height", "width"],
}
</script>
