<template>
  <StatisticCard
    :title="$t(i18nbase + '.title')"
    :info="$t(i18nbase + '.info_bubble')"
  >
    <template
      v-if="accountStore.isFreemium"
      #actions
    >
      <Button
        color="gray"
        :outline="true"
        @click="$router.push({ name: 'establishment' })"
      >
        Mes informations
      </Button>
    </template>
    <div
      v-if="dashboardStore.profil.isLoading"
      class="h-full flex items-center"
    >
      <LottieAnimation
        variant="buttonPrimary"
        :height="50"
        :width="50"
      />
    </div>
    <div
      v-else-if="accountStore.isFreemium"
      class="flex flex-col md:flex-row lg:flex-col xl:flex-row items-center gap stat-circle h-full justify-center"
    >
      <div
        class="absolute left-0 bottom-0 h-1/3 w-full opacity-40 rounded-b-md"
        :class="gradientColor"
      ></div>
      <div class="relative min-w-[120] z-20">
        <CircleProgress
          :percent="dashboardStore.profil.completion"
          :fill-color="fillColor"
          empty-color="#F6F6F7"
          :size="120"
          :border-width="8"
          :border-bg-width="8"
          :transition="500"
          :is-shadow="false"
          :show-percent="true"
        />
        <div
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[96px] w-[96px] rounded-full -z-10"
          :class="bgColor"
        ></div>
      </div>
      <div class="flex flex-col gap-sm w-full z-20">
        <span class="text-xl text-gray-700 text-center md:text-start lg:text-center xl:text-start">
          {{ message }}
        </span>
        <div
          v-if="dashboardStore.profil.missing_fields.length > 0"
          class="flex flex-wrap"
        >
          <span class="text-sm text-gray-500 mr-xs text-center md:text-start lg:text-center xl:text-start">
            {{ $t(i18nbase + ".score_description") }}
            <span
              class="text-bold"
              :class="fieldColor"
              >{{ fields }}</span
            >
          </span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col items-center gap stat-circle h-full justify-center min-h-[300px]"
    >
      <div
        class="absolute left-0 bottom-0 h-1/2 w-full rounded-b-md"
        :class="gradientColor"
      ></div>
      <div class="relative min-w-[80px] md:min-w-[100px] z-20">
        <CircleProgress
          :percent="dashboardStore.profil.completion"
          :fill-color="fillColor"
          empty-color="#ECEDF0"
          :size="120"
          :border-width="8"
          :border-bg-width="8"
          :transition="500"
          :is-shadow="false"
          :show-percent="true"
        />
        <div
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[96px] w-[96px] rounded-full -z-10"
          :class="bgColor"
        ></div>
      </div>
      <div class="flex flex-col gap-sm w-full z-20">
        <span class="text-xl text-gray-700 text-center">
          {{ message }}
        </span>
        <div
          v-if="dashboardStore.profil.missing_fields.length > 0"
          class="flex flex-wrap"
        >
          <span class="text-sm text-gray-500 text-center mr-xs">
            {{ $t(i18nbase + ".score_description") }}
            <span
              class="text-bold"
              :class="fieldColor"
              >{{ fields }}</span
            >
          </span>
        </div>
      </div>
      <Button
        class="z-20 text-gray-700 !p-md !py-sm"
        color="gray"
        :outline="true"
        @click="$router.push({ name: 'establishment' })"
      >
        <small>Mes informations</small>
      </Button>
    </div>
  </StatisticCard>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import CircleProgress from "vue3-circle-progress"

// Layout
//

// Components
import StatisticCard from "./StatisticCard.vue"
import LottieAnimation from "@/components/LottieAnimation.vue"
import Button from "@/components/Button.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "ProfilStatisticItem",

  components: { StatisticCard, CircleProgress, LottieAnimation, Button },

  data() {
    return {
      i18nbase: "home.profil",
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useAccountStore),

    /**
     * Get the main color form the calculated percent
     */
    fillColor() {
      if (this.dashboardStore.profil.completion < 50) {
        return "#EF4444"
      } else if (this.dashboardStore.profil.completion < 75) {
        return "#F8BA43"
      } else {
        return "#34CAA1"
      }
    },

    bgColor() {
      if (this.dashboardStore.profil.completion < 50) {
        return "bg-error-100"
      } else if (this.dashboardStore.profil.completion < 75) {
        return "bg-warning-100"
      } else {
        return "bg-secondary-100"
      }
    },

    fieldColor() {
      if (this.dashboardStore.profil.completion < 50) {
        return "text-error-500"
      } else if (this.dashboardStore.profil.completion < 75) {
        return "text-tertiary-500"
      } else {
        return "text-secondary-500"
      }
    },

    gradientColor() {
      if (this.dashboardStore.profil.completion < 50) {
        return "bg-profilError"
      } else if (this.dashboardStore.profil.completion < 75) {
        return "bg-profilWarning"
      } else {
        return "bg-profilSuccess"
      }
    },

    message() {
      if (this.dashboardStore.profil.completion < 50) {
        return this.$t(this.i18nbase + ".score_title_under_percent_50")
      } else if (this.dashboardStore.profil.completion < 75) {
        return this.$t(this.i18nbase + ".score_title_under_percent_75")
      } else if (this.dashboardStore.profil.completion < 100) {
        return this.$t(this.i18nbase + ".score_title_under_percent_100")
      } else {
        return this.$t(this.i18nbase + ".score_title_at_100")
      }
    },

    fields() {
      const translated = this.dashboardStore.profil.missing_fields.map((element) => {
        return this.$t(this.i18nbase + ".field_" + element)
      })

      return translated.toString().replaceAll(",", ", ")
    },
  },
})
</script>
