<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.07676 4.85962C7.65214 3.28424 9.78882 2.39919 12.0168 2.39919C14.2447 2.39919 16.3814 3.28424 17.9568 4.85962C19.5321 6.43501 20.4172 8.57169 20.4172 10.7996C20.4172 13.0276 19.5321 15.1642 17.9568 16.7396L12.0168 22.6796L6.07676 16.7396C5.29665 15.9596 4.67784 15.0336 4.25564 14.0144C3.83345 12.9952 3.61615 11.9028 3.61615 10.7996C3.61615 9.69645 3.83345 8.60407 4.25564 7.58488C4.67784 6.56569 5.29665 5.63965 6.07676 4.85962ZM12.0168 13.1996C12.6533 13.1996 13.2637 12.9468 13.7138 12.4967C14.1639 12.0466 14.4168 11.4361 14.4168 10.7996C14.4168 10.1631 14.1639 9.55265 13.7138 9.10257C13.2637 8.65248 12.6533 8.39962 12.0168 8.39962C11.3802 8.39962 10.7698 8.65248 10.3197 9.10257C9.86961 9.55265 9.61676 10.1631 9.61676 10.7996C9.61676 11.4361 9.86961 12.0466 10.3197 12.4967C10.7698 12.9468 11.3802 13.1996 12.0168 13.1996Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconLocationMarker",
  props: ["height", "width"],
}
</script>
