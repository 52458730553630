<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.3516 4.45108C12.5766 4.22604 12.8819 4.09961 13.2001 4.09961H19.2001C19.5184 4.09961 19.8236 4.22604 20.0487 4.45108C20.2737 4.67612 20.4001 4.98135 20.4001 5.29961V11.2996C20.4001 11.6179 20.2737 11.9231 20.0487 12.1481C19.8236 12.3732 19.5184 12.4996 19.2001 12.4996C18.8819 12.4996 18.5766 12.3732 18.3516 12.1481C18.1266 11.9231 18.0001 11.6179 18.0001 11.2996V8.19641L10.4485 15.748C10.3378 15.8626 10.2054 15.954 10.059 16.0169C9.9126 16.0798 9.75514 16.1129 9.5958 16.1143C9.43647 16.1157 9.27845 16.0853 9.13098 16.025C8.9835 15.9647 8.84952 15.8756 8.73685 15.7629C8.62418 15.6502 8.53507 15.5162 8.47474 15.3688C8.4144 15.2213 8.38404 15.0633 8.38542 14.9039C8.38681 14.7446 8.41991 14.5871 8.4828 14.4407C8.54569 14.2943 8.63711 14.1619 8.75172 14.0512L16.3033 6.49961H13.2001C12.8819 6.49961 12.5766 6.37318 12.3516 6.14814C12.1266 5.92309 12.0001 5.61787 12.0001 5.29961C12.0001 4.98135 12.1266 4.67612 12.3516 4.45108ZM4.30304 7.20258C4.75313 6.75249 5.36358 6.49963 6.0001 6.49963H9.6001C9.91836 6.49963 10.2236 6.62606 10.4486 6.85111C10.6737 7.07615 10.8001 7.38137 10.8001 7.69963C10.8001 8.01789 10.6737 8.32312 10.4486 8.54816C10.2236 8.77321 9.91836 8.89963 9.6001 8.89963H6.0001V18.4996H15.6001V14.8996C15.6001 14.5814 15.7265 14.2761 15.9516 14.0511C16.1766 13.8261 16.4818 13.6996 16.8001 13.6996C17.1184 13.6996 17.4236 13.8261 17.6486 14.0511C17.8737 14.2761 18.0001 14.5814 18.0001 14.8996V18.4996C18.0001 19.1362 17.7472 19.7466 17.2972 20.1967C16.8471 20.6468 16.2366 20.8996 15.6001 20.8996H6.0001C5.36358 20.8996 4.75313 20.6468 4.30304 20.1967C3.85295 19.7466 3.6001 19.1362 3.6001 18.4996V8.89963C3.6001 8.26311 3.85295 7.65266 4.30304 7.20258Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconExternalLink",
  props: ["height", "width"],
}
</script>
