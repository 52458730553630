import { required, email, requiredIf } from "@/validations/i18n-validators"
import { mustBePhone } from "../custom-validators"
import { helpers } from "@vuelidate/validators"
import { i18n } from "@/i18n"

export const contactValidateForm = (requiredMessage: Boolean, requiredNames: Boolean) => {
  return {
    form: {
      firstname: {
        required: requiredIf(function () {
          return requiredNames === true
        }),
      },
      lastname: {
        required: requiredIf(function () {
          return requiredNames === true
        }),
      },
      email: { required, email },
      establishment_name: { required },
      phone: { required, phone: helpers.withMessage(i18n.global.t("validations.phone"), mustBePhone("FR")) },
      message: {
        required: requiredIf(function () {
          return requiredMessage === true
        }),
      },
    },
  }
}
