<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0485 6.35163C20.2734 6.57667 20.3998 6.88184 20.3998 7.20003C20.3998 7.51823 20.2734 7.8234 20.0485 8.04843L10.4485 17.6484C10.2234 17.8734 9.91826 17.9998 9.60006 17.9998C9.28186 17.9998 8.9767 17.8734 8.75166 17.6484L3.95166 12.8484C3.73307 12.6221 3.61212 12.319 3.61485 12.0044C3.61759 11.6897 3.74379 11.3887 3.96628 11.1663C4.18877 10.9438 4.48974 10.8176 4.80438 10.8148C5.11902 10.8121 5.42214 10.933 5.64846 11.1516L9.60006 15.1032L18.3517 6.35163C18.5767 6.12667 18.8819 6.00029 19.2001 6.00029C19.5183 6.00029 19.8234 6.12667 20.0485 6.35163Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCheck",
  props: ["height", "width"],
}
</script>
