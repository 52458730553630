export enum LocalDiffuserConnexionType {
  TIC = "TIC",
}

export enum LocalDiffuserConnexionState {
  PENDING_CONNECTION = "PENDING_CONNECTION",
  CONNECTED = "CONNECTED",
  REFUSED = "REFUSED",
  IGNORED = "IGNORED",

  NOT_CONNECTED = "NOT_CONNECTED",
}
