import { i18n } from "@/i18n"
import colorLib from "@kurkle/color"

export const previousWebsiteConfig = {
  label: "website n-1",
  icon: "IconGlobeAlt",
  backgroundColor: colorLib("#FACF7B").alpha(0.4).rgbString(),
  legendColor: "#FACF7B",
  stack: "Stack -1",
  borderRadius: 4,
  barThickness: 8,
}

export const websiteConfig = {
  label: "website",
  icon: "IconGlobeAlt",
  backgroundColor: "#FACF7B",
  legendColor: "#FACF7B",
  stack: "Stack 0",
  borderRadius: 4,
  // barThickness: 12,
}

export const previousPhoneConfig = {
  label: "phone n-1",
  icon: "IconPhoneOutlined",
  backgroundColor: colorLib("#5AB7CB").alpha(0.4).rgbString(),
  legendColor: "#5AB7CB",
  stack: "Stack -1",
  borderRadius: 4,
  barThickness: 8,
}

export const phoneConfig = {
  label: "phone",
  icon: "IconPhoneOutlined",
  backgroundColor: "#5AB7CB",
  legendColor: "#5AB7CB",
  stack: "Stack 0",
  borderRadius: 4,
  // barThickness: 12,
}

export const previousDriveConfig = {
  label: "drive n-1",
  icon: "IconX",
  backgroundColor: colorLib("#71DABD").alpha(0.4).rgbString(),
  legendColor: "#71DABD",
  stack: "Stack -1",
  borderRadius: 4,
  barThickness: 8,
}

export const driveConfig = {
  label: "drive",
  icon: "IconLocationMarkerOutline",
  backgroundColor: "#71DABD",
  legendColor: "#71DABD",
  stack: "Stack 0",
  borderRadius: 4,
  // barThickness: 12,
}

export const legends = [
  previousWebsiteConfig,
  websiteConfig,
  previousPhoneConfig,
  phoneConfig,
  previousDriveConfig,
  driveConfig,
]

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      type: "time",
      display: true,
      offsetAfterAutoskip: true,
      offset: true,
      ticks: {
        source: "data",
      },
      time: {
        // unit: "day",
        displayFormats: {
          day: "DD MMM",
          week: "DD MMM",
        },
        tooltipFormat: "YYYY-MM-DD", // Format des tooltips
      },
      title: {
        display: false,
      },
    },
    y: {
      stacked: true,
      ticks: {
        beginAtZero: false,
        precision: 0, // Spécifiez la précision (nombre de décimales)
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}
