<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6 12C21.6 16.6392 17.3016 20.4 12 20.4C10.2961 20.4072 8.61546 20.0038 7.1004 19.224L2.4 20.4L4.0056 16.6524C2.9916 15.3204 2.4 13.7208 2.4 12C2.4 7.3608 6.6984 3.6 12 3.6C17.3016 3.6 21.6 7.3608 21.6 12ZM8.4 12C8.4 11.3373 7.86274 10.8 7.2 10.8V10.8C6.53726 10.8 6 11.3373 6 12V12C6 12.6627 6.53726 13.2 7.2 13.2V13.2C7.86274 13.2 8.4 12.6627 8.4 12V12V12ZM18 12C18 11.3373 17.4627 10.8 16.8 10.8V10.8C16.1373 10.8 15.6 11.3373 15.6 12V12C15.6 12.6627 16.1373 13.2 16.8 13.2V13.2C17.4627 13.2 18 12.6627 18 12V12ZM10.8 12C10.8 11.3373 11.3373 10.8 12 10.8V10.8C12.6627 10.8 13.2 11.3373 13.2 12V12C13.2 12.6627 12.6627 13.2 12 13.2V13.2C11.3373 13.2 10.8 12.6627 10.8 12V12Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconChat",
  props: ["height", "width"],
}
</script>
