<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.87868 3.87868C4.44129 3.31607 5.20435 3 6 3H18C18.7956 3 19.5587 3.31607 20.1213 3.87868C20.6839 4.44129 21 5.20435 21 6V13.999C21 13.9997 21 14.0003 21 14.001V18C21 18.7957 20.6839 19.5587 20.1213 20.1213C19.5587 20.6839 18.7957 21 18 21H6C5.20435 21 4.44129 20.6839 3.87868 20.1213C3.31607 19.5587 3 18.7956 3 18V16.0002C3 16.0001 3 16.0003 3 16.0002V6C3 5.20435 3.31607 4.44129 3.87868 3.87868ZM5 16.4142V18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H18C18.2652 19 18.5196 18.8946 18.7071 18.7071C18.8946 18.5196 19 18.2652 19 18V14.4142L17.707 13.1212C17.707 13.1212 17.707 13.1212 17.707 13.1212C17.5195 12.9338 17.2651 12.8284 17 12.8284C16.7349 12.8284 16.4806 12.9337 16.2931 13.1211C16.2931 13.1211 16.2931 13.1211 16.2931 13.1211L15.4142 14L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L10.707 12.1212C10.707 12.1212 10.707 12.1212 10.707 12.1212C10.5195 11.9338 10.2651 11.8284 10 11.8284C9.73489 11.8284 9.48062 11.9337 9.29311 12.1211C9.29307 12.1211 9.29314 12.1211 9.29311 12.1211L5 16.4142ZM14 12.5858L12.1211 10.7069C11.5585 10.1445 10.7955 9.82843 10 9.82843C9.20451 9.82843 8.44158 10.1444 7.879 10.7068L5 13.5858V6C5 5.73478 5.10536 5.48043 5.29289 5.29289C5.48043 5.10536 5.73478 5 6 5H18C18.2652 5 18.5196 5.10536 18.7071 5.29289C18.8946 5.48043 19 5.73478 19 6V11.5924C18.4515 11.1017 17.7398 10.8284 17 10.8284C16.2045 10.8284 15.4416 11.1444 14.879 11.7068L14 12.5858ZM13 8C13 7.44772 13.4477 7 14 7H14.01C14.5623 7 15.01 7.44772 15.01 8C15.01 8.55228 14.5623 9 14.01 9H14C13.4477 9 13 8.55228 13 8Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPhotograph",
  props: ["height", "width"],
}
</script>
