/**
 * TicStatus
 * Status de mise à jour des informations sur le SIT
 */
export enum TicStatus {
  /**
   * Réponse reçue et non traitée par l’OT
   */
  ANSWERED = "ANSWERED",

  /**
   * Mis à jour sur Apidae
   */
  SENT_APIDAE = "SENT_APIDAE",

  /**
   * Mis à jour sur Tourinsoft
   */
  SENT_TOURINSOFT = "SENT_TOURINSOFT",

  /**
   * Réponse traitée par l’OT
   */
  TREATED = "TREATED",

  /**
   * Formulaire en état de brouillon
   */
  DRAFT = "DRAFT",

  /**
   * Réponse en cours d’enregistrement
   */
  SAVING = "SAVING",
  SAVING_DRAFT = "SAVING_DRAFT",

  /**
   * Pas de formulaire ouvert de créé
   */
  NO_FORM = "NO_FORM",
}
