<template>
  <div
    class="absolute z-40 h-full w-full top-0 left-0 bg-opacity-80 backdrop-blur-[1px] rounded-sm"
    :class="'bg-' + color"
  ></div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "FreemiumBlur",

  props: {
    color: {
      type: String,
      default: "gray-100",
    },
  },
})
</script>
