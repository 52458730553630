<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.17157 5.17157C3.92172 4.42143 4.93913 4 6 4H18C19.0609 4 20.0783 4.42143 20.8284 5.17157C21.5786 5.92172 22 6.93913 22 8V16C22 17.0609 21.5786 18.0783 20.8284 18.8284C20.0783 19.5786 19.0609 20 18 20H6C4.93913 20 3.92172 19.5786 3.17157 18.8284C2.42143 18.0783 2 17.0609 2 16V8C2 6.93913 2.42143 5.92172 3.17157 5.17157ZM4 11V16C4 16.5304 4.21071 17.0391 4.58579 17.4142C4.96086 17.7893 5.46957 18 6 18H18C18.5304 18 19.0391 17.7893 19.4142 17.4142C19.7893 17.0391 20 16.5304 20 16V11H4ZM20 9H4V8C4 7.46957 4.21071 6.96086 4.58579 6.58579C4.96086 6.21071 5.46957 6 6 6H18C18.5304 6 19.0391 6.21071 19.4142 6.58579C19.7893 6.96086 20 7.46957 20 8V9ZM6 15C6 14.4477 6.44772 14 7 14H8C8.55228 14 9 14.4477 9 15C9 15.5523 8.55228 16 8 16H7C6.44772 16 6 15.5523 6 15ZM11 15C11 14.4477 11.4477 14 12 14H13C13.5523 14 14 14.4477 14 15C14 15.5523 13.5523 16 13 16H12C11.4477 16 11 15.5523 11 15Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCreditCard",
  props: ["height", "width"],
}
</script>
