<template>
  <div
    v-if="show"
    class="relative p-md rounded-sm flex justify-between items-center gap-sm h-[150px] md:h-[70px]"
    :class="mainBg"
  >
    <div
      :class="[previousStep ? 'opacity-1 cursor-pointer hover:scale-125 transition-all' : 'opacity-0', textColor]"
      @click="$emit('prev')"
    >
      <Icon icon-name="IconChevronLeft" />
    </div>

    <div class="container w-full flex flex-col gap-md md:gap-0 items-center justify-center">
      <div class="w-full flex flex-col md:flex-row gap-xs md:gap-sm items-center justify-center">
        <div class="flex items-center gap-sm">
          <span :class="isPremium ? 'text-xs text-white opacity-50' : 'text-xs opacity-30'">
            {{ currentStep + 1 }}/{{ total }}
          </span>
          <Icon
            v-if="step.reached"
            class="text-success-500"
            icon-name="IconCheckCircle"
          />
        </div>
        <span
          class="step-title text-center"
          :class="textColor"
        >
          {{ $t(i18nbase + ".checklist." + step.code) }}
        </span>

        <!-- Nom et prénom -->
        <div v-if="!step.reached && isName">
          <Button
            class="!py-xs !px-sm text-sm"
            @click="$router.push({ name: 'user-profil' })"
          >
            {{ $t(i18nbase + ".checklist.completedName_add_button") }}
          </Button>
        </div>

        <!-- Google -->
        <div v-if="!step.reached && isGoogleConnexion">
          <a
            v-if="connectUrl"
            :href="connectUrl"
            target="_blank"
            class="button-primary !py-xs !px-sm text-sm"
          >
            {{ $t(i18nbase + ".checklist.connectedGoogle_connect_button") }}
          </a>
          <div v-else>
            <Icon
              icon-name="IconQuestionMarkCircle"
              :height="16"
              :width="16"
              class="text-gray-600 hover:text-gray-900 transition-all cursor-pointer"
              @click="showGoogleConnexionModal = true"
            />
          </div>

          <Modal
            v-model="showGoogleConnexionModal"
            :scrollable="false"
          >
            <template #header>
              <div class="flex flex-col items-center justify-center gap-sm">
                <Icon
                  icon-name="IconQuestionMarkCircle"
                  class="text-primary-500"
                  :height="30"
                  :width="30"
                />
                <span class="text-xl text-center text-primary-500">
                  {{ $t(i18nbase + ".checklist.connectedGoogle_modal.title") }}
                </span>
              </div>
            </template>
            {{ $t(i18nbase + ".checklist.connectedGoogle_modal.description") }}
          </Modal>
        </div>

        <!-- Facebook -->
        <div
          v-if="!step.reached && isFacebookConnexion"
          class="flex items-center gap-sm"
        >
          <a
            v-if="connectUrl"
            :href="connectUrl"
            target="_blank"
            class="button-primary !py-xs !px-sm text-sm"
          >
            {{ $t(i18nbase + ".checklist.connectedFacebook_connect_button") }}
          </a>
          <div>
            <Icon
              icon-name="IconQuestionMarkCircle"
              :height="16"
              :width="16"
              class="text-gray-600 hover:text-gray-900 transition-all cursor-pointer"
              @click="showFacebookConnexionModal = true"
            />
          </div>

          <Modal
            v-model="showFacebookConnexionModal"
            variant="large"
            @close="showFacebookContactForm = false"
          >
            <template #header>
              <div class="flex flex-col items-center justify-center gap-sm">
                <Icon
                  icon-name="IconQuestionMarkCircle"
                  class="text-primary-500"
                  :height="30"
                  :width="30"
                />
                <span class="text-xl text-center text-primary-500">
                  {{ $t(i18nbase + ".checklist.connectedFacebook_modal.title") }}
                </span>
              </div>
            </template>

            <div
              v-if="showSuccessCard"
              class="text-base text-center text-gray-700"
            >
              {{ $t(i18nbase + ".checklist.connectedFacebook_modal.contact_form_success_message") }}
            </div>

            <ContactForm
              v-else-if="showFacebookContactForm"
              :show-message-input="true"
              :is-button-loading="isButtonLoading"
              :required-message-input="true"
              @submit="sendContactRequest"
            />

            <div
              v-else
              class="flex flex-col items-center gap-md py"
            >
              <span class="text-base text-gray-700">
                {{ $t(i18nbase + ".checklist.connectedFacebook_modal.description") }}
              </span>
              <Button @click="showFacebookContactForm = true">
                {{ $t(i18nbase + ".checklist.connectedFacebook_modal.contact_button") }}
              </Button>
            </div>
          </Modal>
        </div>

        <!-- Solicitations -->
        <div v-if="!step.reached && isSolicitation">
          <Button
            class="!py-xs !px-sm text-sm"
            @click="$router.push({ name: 'solicitations' })"
          >
            {{ $t(i18nbase + ".checklist.viewedSolicitations_button") }}
          </Button>
        </div>

        <!-- Analyse -->
        <div v-if="!step.reached && isAnalyse">
          <Button
            class="!py-xs !px-sm text-sm"
            @click="$router.push({ name: 'analyse' })"
          >
            {{ $t(i18nbase + ".checklist.analysed_button") }}
          </Button>
        </div>

        <!-- Newsletter -->
        <div v-if="isLn">
          <Button
            class="!py-xs !px-sm text-sm"
            @click="showNewsletterModal = true"
          >
            {{ $t(i18nbase + ".checklist." + step.code + "_button") }}
          </Button>
          <Modal
            v-model="showNewsletterModal"
            variant="small"
            @close="showNewsletterModal = false"
          >
            <template #header>
              <div class="flex flex-col items-center justify-center gap-sm">
                <Icon
                  icon-name="IconMail"
                  class="text-primary-500"
                  :height="30"
                  :width="30"
                />
                <span class="text-xl text-center text-primary-500">
                  {{ $t(i18nbase + ".checklist." + step.code + "_modal.title") }}
                </span>
              </div>
            </template>

            <div v-if="step.reached">
              <span class="text-base">
                {{
                  $t(i18nbase + ".checklist." + step.code + "_modal.success_message", {
                    mail: authenticationStore.user?.email,
                  })
                }}
              </span>
            </div>
            <div
              v-else
              class="flex flex-col items-center gap"
            >
              <span class="text-base">
                {{ $t(i18nbase + ".checklist." + step.code + "_modal.description") }}
              </span>
              <Button @click="showNewsletterModal = true">
                {{ $t(i18nbase + ".checklist." + step.code + "_modal.button") }}
              </Button>
            </div>
          </Modal>
        </div>

        <!-- FREEMIUM -->
        <div v-if="!step.reached && isPremium">
          <a
            class="button-primary p-xs text-sm"
            :href="analyseUrlRedirect"
            target="_blank"
          >
            {{ $t("common.checklist_premium_button") }}
            <Icon
              icon-name="IconExternalLink"
              :height="16"
              :width="16"
            />
          </a>
        </div>
      </div>

      <!-- EMAIL -->
      <div
        v-if="!step.reached && isEmail"
        class="flex items-center flex-col md:flex-row gap-0 md:gap-md"
      >
        <div
          class="cursor-pointer flex"
          @click="showResendValidateEmailModal = true"
        >
          <span class="text-xs !text-center text-primary-500 hover:text-primary-700 transition-all">
            {{ $t(i18nbase + ".checklist.validatedEmail_no_email_received_button") }}
          </span>
        </div>

        <div
          class="cursor-pointer flex"
          @click="showValidateEmailModal = true"
        >
          <span class="text-xs !text-center text-primary-500 hover:text-primary-700 transition-all">
            {{ $t(i18nbase + ".checklist.validatedEmail_how_to_button") }}
          </span>
        </div>

        <Modal
          v-model="showResendValidateEmailModal"
          :scrollable="false"
          variant="small"
          @close="isLoadingSendingEmail = false"
        >
          <template #header>
            <div class="flex flex-col items-center justify-center gap-sm">
              <Icon
                icon-name="IconMail"
                class="text-primary-500"
                :height="30"
                :width="30"
              />
              <span class="text-xl text-center text-primary-500">
                {{ $t(i18nbase + ".checklist.validatedEmail_resend_modal.title") }}
              </span>
            </div>
          </template>
          <div class="flex flex-col gap justify-center items-center">
            <span class="text-gray-700 text-base text-center">
              {{ $t(i18nbase + ".checklist.validatedEmail_resend_modal.description") }}
            </span>
            <Button
              :loading="isLoadingSendingEmail"
              @click="resendEmail"
            >
              {{ $t(i18nbase + ".checklist.validatedEmail_resend_modal.button") }}
            </Button>
          </div>
        </Modal>

        <Modal
          v-model="showValidateEmailModal"
          variant="small"
          :scrollable="false"
        >
          <template #header>
            <div class="flex flex-col items-center justify-center gap-sm">
              <Icon
                icon-name="IconMail"
                class="text-primary-500"
                :height="30"
                :width="30"
              />
              <span class="text-xl text-center text-primary-500">
                {{ $t(i18nbase + ".checklist.validatedEmail_how_to_modal.title") }}
              </span>
            </div>
          </template>
          <div class="flex flex-col gap justify-center items-center">
            <span class="text-gray-700 text-base text-center">
              {{ $t(i18nbase + ".checklist.validatedEmail_how_to_modal.description") }}
            </span>
          </div>
        </Modal>
      </div>
    </div>

    <div
      :class="[nextStep ? 'opacity-1 cursor-pointer hover:scale-125 transition-all' : 'opacity-0', textColor]"
      @click="$emit('next')"
    >
      <Icon icon-name="IconChevronRight" />
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent, type PropType } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import Modal from "@/components/Modal.vue"
import Button from "@/components/Button.vue"
import ContactForm from "@/modules/user/components/ContactForm.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useDiffuserStore } from "@/stores/diffuser"
import { useAccountStore } from "@/stores/account"
import { useAuthenticationStore } from "@/stores/authentication"
import { useEstablishmentStore } from "@/stores/establishment"
import { mapStores } from "pinia"

// Types
import type { IChecklistStep } from "@/types/Dashboard.types"
import { ChecklistStepCode } from "@/static/Dashboard"
import { ConnectRedirectionUrl, DiffuserType } from "@/static/Diffuser"
import { AlertType } from "@/static/Alert"
import { ContactVariant } from "@/static/onboarding/Contact"
import type IContactForm from "@/types/Contact.types"

// Data
//

export default defineComponent({
  name: "CheclistItem",

  components: { Icon, Modal, Button, ContactForm },

  props: {
    i18nbase: {
      type: String,
      required: true,
    },

    /**
     * Current selected step item index
     */
    currentStep: {
      type: Number,
      required: true,
    },

    /**
     * Current item in list
     */
    step: {
      type: Object as PropType<IChecklistStep>,
      required: true,
    },

    /**
     * Previous step item in list
     */
    previousStep: {
      type: Object as PropType<IChecklistStep>,
      default: undefined,
    },

    /**
     * Next step item in list
     */
    nextStep: {
      type: Object as PropType<IChecklistStep>,
      default: undefined,
    },

    /**
     * Is this checklist item si showable ?
     * Is this checklist item the current selected step item ?
     */
    show: {
      type: Boolean,
      required: true,
    },

    /**
     * The total number of checklist items
     */
    total: {
      type: Number,
      required: true,
    },
  },

  emits: ["prev", "next", "connect-google"],

  data() {
    return {
      isLoading: false,
      connectUrl: "",
      claimUrl: "",

      showGoogleConnexionModal: false,
      showFacebookConnexionModal: false,
      showFacebookContactForm: false,
      showSuccessCard: false,
      showValidateEmailModal: false,
      showResendValidateEmailModal: false,
      showNewsletterModal: false,
      isLoadingSendingEmail: false,

      isButtonLoading: false,
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useDiffuserStore, useEstablishmentStore, useAccountStore, useAuthenticationStore),

    analyseUrlRedirect() {
      return import.meta.env.VITE_HUBSPOT_ANALYSE_URL
    },

    isName() {
      return this.step.code === ChecklistStepCode.NAME
    },

    isEmail() {
      return this.step.code === ChecklistStepCode.EMAIL
    },

    isGoogleConnexion() {
      return this.step.code === ChecklistStepCode.GOOGLE
    },

    isFacebookConnexion() {
      return this.step.code === ChecklistStepCode.FACEBOOK
    },

    isSolicitation() {
      return this.step.code === ChecklistStepCode.SOLICITATIONS
    },

    isAnalyse() {
      return this.step.code === ChecklistStepCode.ANALYSE
    },

    isLn() {
      return this.step.code === ChecklistStepCode.NEWSLETTER
    },

    isPremium() {
      return this.step.code === ChecklistStepCode.PREMIUM
    },

    mainBg() {
      if (this.isPremium) {
        return "bg-premium"
      } else {
        if (this.step.reached) {
          return "bg-success-100 border border-success-300"
        } else {
          return "bg-warning-100 border border-tertiary-300"
        }
      }
    },

    textColor() {
      if (this.isPremium) {
        return "text-white"
      } else if (this.step.reached) {
        return "text-success-500"
      } else {
        return "text-tertiary-500"
      }
    },
  },

  created() {
    this.getUrlConnexion()
  },

  methods: {
    resendEmail() {
      this.isLoadingSendingEmail = true
      this.authenticationStore.sendValidationEmail(() => {
        this.showResendValidateEmailModal = false
      })
    },

    getUrlConnexion() {
      if (!this.step.reached && this.step.code === ChecklistStepCode.GOOGLE) {
        this.isLoading = true
        this.diffuserStore.getDiffuserConnexionUrl(
          this.establishmentStore.currentEstablishment.id,
          DiffuserType.GOOGLE,
          ConnectRedirectionUrl.HOME,
          (connect) => {
            this.setUrls(connect)
            this.isLoading = false
          }
        )
      } else if (!this.step.reached && this.step.code === ChecklistStepCode.FACEBOOK) {
        this.isLoading = true
        this.diffuserStore.getDiffuserConnexionUrl(
          this.establishmentStore.currentEstablishment.id,
          DiffuserType.FACEBOOK,
          ConnectRedirectionUrl.HOME,
          (connect) => {
            this.setUrls(connect)
            this.isLoading = false
          }
        )
      }
    },

    setUrls(connect: { connect_url: string; claim_url: string }) {
      if (connect.connect_url) {
        this.connectUrl = connect.connect_url
      }
      if (connect.claim_url) {
        this.claimUrl = connect.claim_url
      }
    },

    async sendContactRequest(validated, form) {
      this.isButtonLoading = true
      if (!validated) {
        this.$alert.push(AlertType.error, "Le formulaire contient des erreurs.")
        this.isButtonLoading = false
      } else {
        const premiumConvertForm: IContactForm = {
          first_name: form.firstname,
          last_name: form.lastname.toUpperCase(),
          establishment_name: form.establishment_name,
          email: form.email,
          phone: form.phone,
          message: form.message,
          source: ContactVariant.FREEMIUM_CONTACT,
        }
        this.accountStore.contactHubspot(premiumConvertForm, () => {
          this.isButtonLoading = false
          this.showFacebookContactForm = false
          this.showSuccessCard = true
        })
      }
    },
  },
})
</script>
