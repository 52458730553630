import type { IChecklistStep, IGlobalStatisticGrowthCharte } from "@/types/Dashboard.types"

export enum GlobalStatisticGrowth {
  LESS = "LESS",
  PLUS = "PLUS",
  EQUAL = "EQUAL",
  NO_DATA = "NO_DATA",
}

export enum FilterCode {
  DEFAULT = "DEFAULT",
  LAST_WEEK = "LAST_WEEK",
  LAST_MONTH = "LAST_MONTH",
  LAST_6_MONTH = "LAST_6_MONTH",
  LAST_YEAR = "LAST_YEAR",
}

export enum FilterPlatformCode {
  ALL = "ALL",
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK",
}

export enum ChecklistStepCode {
  EMAIL = "validatedEmail",
  GOOGLE = "connectedGoogle",
  FACEBOOK = "connectedFacebook",
  NAME = "completedName",
  ANALYSE = "analysed",
  SOLICITATIONS = "viewedSolicitations",
  PREMIUM = "goPremium",
  NEWSLETTER = "subscribedToLN",
}

export const globalStatisticGrowthCharte: {
  [name: string]: IGlobalStatisticGrowthCharte
} = {
  [GlobalStatisticGrowth.EQUAL]: {
    color: "text-gray-500",
    bgColor: "bg-gray-100",
    icon: "IconEqual",
  },
  [GlobalStatisticGrowth.PLUS]: {
    color: "text-success-600",
    bgColor: "bg-success-200",
    icon: "IconTrendingUp",
  },
  [GlobalStatisticGrowth.LESS]: {
    color: "text-error-600",
    bgColor: "bg-error-100",
    icon: "IconTrendingDown",
  },
}

const emailChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.EMAIL,
  reached: false,
}

const nameChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.NAME,
  reached: false,
}

const googleChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.GOOGLE,
  reached: false,
}

const facebookChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.FACEBOOK,
  reached: false,
}

const solicitationsChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.SOLICITATIONS,
  reached: false,
}

const analyseChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.ANALYSE,
  reached: false,
}

const premiumChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.PREMIUM,
  reached: false,
}

const newsletterChecklistStep: IChecklistStep = {
  code: ChecklistStepCode.NEWSLETTER,
  reached: false,
}

export const checklistSteps: IChecklistStep[] = [
  emailChecklistStep,
  nameChecklistStep,
  solicitationsChecklistStep,
  analyseChecklistStep,
]

export const freemiumChecklistSteps: IChecklistStep[] = [
  emailChecklistStep,
  nameChecklistStep,
  googleChecklistStep,
  facebookChecklistStep,
  solicitationsChecklistStep,
  analyseChecklistStep,
  // newsletterChecklistStep,
  premiumChecklistStep,
]
