<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.92899 1.27314C7.46242 1.13007 8.01083 1.44652 8.1539 1.97995L8.9309 4.87695C9.07398 5.41038 8.75753 5.95879 8.22409 6.10187C7.69066 6.24494 7.14225 5.92849 6.99918 5.39506L6.22218 2.49806C6.07911 1.96462 6.39556 1.41621 6.92899 1.27314ZM14.6571 3.3429C15.0477 3.73342 15.0477 4.36659 14.6571 4.75711L12.5351 6.87911C12.1446 7.26963 11.5115 7.26963 11.1209 6.87911C10.7304 6.48858 10.7304 5.85542 11.1209 5.4649L13.2429 3.3429C13.6335 2.95237 14.2666 2.95237 14.6571 3.3429ZM1.27216 6.92903C1.41518 6.39559 1.96357 6.07909 2.49701 6.22212L5.39501 6.99912C5.92845 7.14214 6.24495 7.69053 6.10193 8.22397C5.9589 8.75742 5.41052 9.07391 4.87707 8.93089L1.97907 8.15389C1.44563 8.01086 1.12913 7.46248 1.27216 6.92903ZM8.29293 8.2929C8.56766 8.01817 8.97665 7.92743 9.34178 8.06021L20.3418 12.0602C20.7315 12.2019 20.9933 12.5695 20.9999 12.9841C21.0065 13.3988 20.7565 13.7745 20.3714 13.9285L16.7795 15.3652L20.7071 19.2929C21.0977 19.6834 21.0977 20.3166 20.7071 20.7071C20.3166 21.0976 19.6835 21.0976 19.2929 20.7071L15.3653 16.7795L13.9285 20.3714C13.7745 20.7564 13.3988 21.0065 12.9842 20.9999C12.5695 20.9933 12.202 20.7315 12.0602 20.3417L8.06025 9.34175C7.92747 8.97661 8.01821 8.56762 8.29293 8.2929ZM10.6721 10.6721L13.0445 17.1962L14.0716 14.6286C14.1732 14.3745 14.3745 14.1732 14.6287 14.0715L17.1962 13.0445L10.6721 10.6721ZM6.87781 11.1206C7.26852 11.5109 7.26882 12.1441 6.87848 12.5348L4.75848 14.6568C4.36814 15.0475 3.73498 15.0478 3.34427 14.6574C2.95356 14.2671 2.95326 13.6339 3.3436 13.2432L5.4636 11.1212C5.85394 10.7305 6.48711 10.7302 6.87781 11.1206Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCursorClick",
  props: ["height", "width"],
}
</script>
