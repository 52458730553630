<template>
  <img
    v-if="ticLogo !== null"
    :src="ticLogo"
    class="max-h-[40px] max-w-[80px] aspect-ratio rounded-sm"
  />
  <img
    v-else
    src="/src/assets/images/socialmedia/tic.png"
    class="max-h-[40px] max-w-[80px] aspect-ratio rounded-sm"
  />
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
//

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "TicLogoContainer",

  props: {
    ticLogo: {
      type: String,
      default: null,
    },
  },
})
</script>
