<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 5C2 3.34315 3.34315 2 5 2H8.27924C9.1401 2 9.90438 2.55086 10.1766 3.36755L11.6744 7.86098C11.9892 8.80521 11.5617 9.83718 10.6715 10.2823L9.31494 10.9606C10.2016 12.5092 11.4908 13.7984 13.0394 14.6851L13.7177 13.3285C14.1628 12.4383 15.1948 12.0108 16.139 12.3256L20.6325 13.8234C21.4491 14.0956 22 14.8599 22 15.7208V19C22 20.6569 20.6569 22 19 22H18C9.16344 22 2 14.8366 2 6V5ZM5 4C4.44772 4 4 4.44772 4 5V6C4 13.732 10.268 20 18 20H19C19.5523 20 20 19.5523 20 19V15.7208L15.5066 14.2229L14.3779 16.4802C14.1379 16.9602 13.5618 17.1652 13.0725 16.9446C10.4054 15.7421 8.25786 13.5946 7.05538 10.9275C6.83479 10.4382 7.03975 9.86209 7.51979 9.62207L9.77705 8.49344L8.27924 4H5Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPhoneOutlined",
  props: ["height", "width"],
}
</script>
