<template>
  <div class="flex flex-col gap-sm md:flex-row">
    <!-- Platforms -->
    <Dropdown
      size="sm"
      position-x="right"
      width="full"
      class="min-w-[200px]"
    >
      <template #title>
        <div
          v-if="dashboardStore.currentPlatformFilter.name"
          class="flex items-center gap-xs"
        >
          <img
            v-if="charteDiffusers[dashboardStore.currentPlatformFilter.code]"
            :alt="'logo' + dashboardStore.currentPlatformFilter.name"
            height="20"
            width="20"
            :src="getPlatformLogo(dashboardStore.currentPlatformFilter.code)"
          />
          <span class="whitespace-nowrap">{{ dashboardStore.currentPlatformFilter.name }}</span>
        </div>
        <span
          v-else
          class="text-sm whitespace-nowrap"
          >{{ $t(i18nbase + ".filter.platform.title") }}</span
        >
      </template>

      <div class="p-xs flex flex-col gap-sm">
        <div v-if="!dashboardStore.isSyncLoading">
          <DropdownItem
            v-for="filter in platformFilters"
            :key="filter.code"
            :class="getInactivPlatformDropdownClass(filter.code)"
            @click="setPlatformFilter(filter)"
          >
            <img
              v-if="charteDiffusers[filter.code]"
              :alt="'logo' + filter.name"
              height="20"
              width="20"
              :src="getPlatformLogo(filter.code)"
            />
            {{ filter.name }}
          </DropdownItem>
        </div>

        <DropdownItem
          v-if="accountStore.isFreemium"
          variant="primary"
          :background="true"
          to="freemium-comparison"
        >
          <Icon icon-name="IconCrownOutlined" />
          {{ $t(i18nbase + ".filter.platform.premium") }}
        </DropdownItem>
      </div>
    </Dropdown>

    <!-- Periods -->
    <Dropdown
      size="sm"
      position-x="right"
      width="full"
      class="min-w-[200px]"
    >
      <template #title>
        <span
          v-if="dashboardStore.currentPeriodFilter.name"
          class="no-event whitespace-nowrap"
          >{{ dashboardStore.currentPeriodFilter.name }}</span
        >
        <span
          v-else
          class="text-sm no-event whitespace-nowrap"
          >{{ $t(i18nbase + ".filter.period.title") }}</span
        >
      </template>

      <div class="p-xs flex flex-col gap-sm">
        <DropdownItem
          v-for="filter in periodFilters"
          :key="filter.code"
          :class="getInactivPeriodDropdownClass"
          @click="setPeriodFilter(filter)"
        >
          {{ filter.name }}
        </DropdownItem>

        <DropdownItem
          v-if="accountStore.isFreemium"
          to="freemium-comparison"
          variant="primary"
          :background="true"
        >
          <Icon icon-name="IconCrownOutlined" />
          {{ $t(i18nbase + ".filter.period.premium") }}
        </DropdownItem>
      </div>
    </Dropdown>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import Dropdown from "@/components/Dropdown.vue"
import DropdownItem from "@/components/DropdownItem.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

// Types
//

// Data
import { charteDiffusers } from "@/static/Diffuser"
import { FilterPlatformCode } from "@/static/Dashboard"
import type { IFilterPeriod, IFilterPlatform } from "@/types/Dashboard.types"

export default defineComponent({
  name: "Filters",

  components: {
    Dropdown,
    DropdownItem,
    Icon,
  },

  props: {
    platformFilters: {
      type: Array<{ name: String; code: FilterPlatformCode }>,
      required: true,
    },

    periodFilters: {
      type: Array<IFilterPeriod>,
      required: true,
    },

    hasAtLeastOneDiffuserConnected: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      i18nbase: "home",
      charteDiffusers: charteDiffusers,
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useAccountStore),

    /**
     * Return inactiv class for period filter
     * Is inactiv if no plateform are connected
     */
    getInactivPeriodDropdownClass(): String {
      if (!this.hasAtLeastOneDiffuserConnected || this.accountStore.isFreemium) {
        return "opacity-20 grayscale no-event"
      } else {
        return ""
      }
    },
  },

  methods: {
    /**
     * Get the given plateform icon
     * @param name
     */
    getPlatformLogo(name: string) {
      const diffuser = this.charteDiffusers[name]
      if (diffuser) {
        return this.charteDiffusers[name].icon
      } else {
        return undefined
      }
    },

    /**
     * Return inactiv class for plateform filter
     * Is inactiv if given plateform is not connected
     * @param code
     */
    getInactivPlatformDropdownClass(code: string) {
      if (
        code !== FilterPlatformCode.ALL &&
        (!this.dashboardStore.isPlatformConnected(code) || this.accountStore.isFreemium)
      ) {
        return "opacity-20 grayscale no-event"
      }
    },

    /**
     * Update statistics after changing plateforme filter
     * @param filter
     */
    setPlatformFilter(filter: IFilterPlatform) {
      this.dashboardStore.currentPlatformFilter = filter
      this.dashboardStore.statistics()
    },

    /**
     * Update current period filter
     * Reload all stats
     * @param filter
     */
    setPeriodFilter(filter: IFilterPeriod) {
      this.dashboardStore.currentPeriodFilter = filter
      this.dashboardStore.statistics()
    },
  },
})
</script>
