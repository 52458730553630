<template>
  <div
    id="onboarding-main-header"
    :class="variant"
  >
    <div
      id="bordered-container"
      class="md:border-b flex items-center"
      :class="$slots.before && 'container-with-icon'"
    >
      <div
        id="flex-container"
        class="flex justify-between items-center gap-md"
      >
        <!-- Classic header -->
        <div class="flex items-center gap-sm md:gap">
          <slot name="before"></slot>
          <div class="flex items-end gap-sm">
            <h1
              class="text-lg md:text-2xl"
              :class="
                (truncateTitle && 'break-words line-clamp-1',
                variant === 'professional-space' ? 'text-white md:text-gray-700' : 'text-gray-700')
              "
            >
              <slot name="header"></slot>
            </h1>
            <div class="pb-[2px]">
              <slot name="after"></slot>
            </div>
          </div>
        </div>

        <div
          v-if="$slots.actions"
          id="actions"
        >
          <slot name="actions"></slot>
        </div>

        <!-- Mobile chat bubble -->
        <div
          v-if="hasMobileChat"
          id="info"
          @click="mobileStore.setShowContact(!mobileStore.showContact)"
        >
          <Icon
            icon-name="IconChatReverse"
            class="text-primary-500"
            :height="36"
            :width="36"
            @click="mobileStore.setShowContact(!mobileStore.showContact)"
          />
        </div>
      </div>
    </div>

    <!-- Bulle de contact sur mobile -->
    <div
      v-if="hasMobileChat"
      id="header-contact-mobile"
      class="px-md"
      :class="mobileStore.showContact && 'active'"
    >
      <div class="bg-gray-900 p-md rounded-md">
        <div class="flex flex-col gap-sm h-fit">
          <p class="text-base text-gray-200">
            {{ $t("common.contact_panel.description") }}
          </p>
          <RouterLink
            :to="{ name: 'onboarding-contact' }"
            class="button-outline-white"
          >
            <span>{{ $t("common.contact_panel.button") }}</span>
          </RouterLink>
        </div>
      </div>
      <div id="bubble-shape">
        <svg
          width="34"
          height="17"
          viewBox="0 0 34 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M33.5 17H0L6.21152 15.2918C15.1443 12.8353 22.9933 7.45019 28.5 0L28.5403 0.765662C28.8422 6.50108 30.5451 12.0751 33.5 17Z"
            fill="#272C3D"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import { RouterLink } from "vue-router"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"

// Stores
import { useOnboardingStore } from "@/stores/onboarding"
import { useMobileStore } from "@/stores/mobile"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "Header",
  components: {
    Icon,
    RouterLink,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    animateAction: {
      type: Boolean,
      default: false,
    },

    hasMobileChat: {
      type: Boolean,
      default: true,
    },

    variant: {
      type: String,
      validators: (value: string) => ["app", "professional-space"].includes(value),
      default: "app",
    },

    truncateTitle: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapStores(useOnboardingStore, useMobileStore),
  },
  mounted() {
    this.mobileStore.closeContactPopup("info")
  },
})
</script>
