<template>
  <div
    id="app-menu"
    class="flex flex-col gap-sm"
  >
    <MenuItem :item="dashboardMenuItem"></MenuItem>

    <MenuItem
      v-if="hasSolicitationsMenuItem"
      :item="solicitationMenu"
    ></MenuItem>

    <MenuItem
      v-if="hasInformationMenuItem"
      :item="informationMenuItem"
    ></MenuItem>

    <MenuItem
      v-if="hasReviewMenuItem"
      :show-premium-icon="accountStore.isFreemium"
      :item="reviewMenu"
    ></MenuItem>

    <MenuItem
      v-if="hasPublicationMenuItem"
      :show-premium-icon="accountStore.isFreemium"
      :item="publicationMenuItem"
    ></MenuItem>

    <MenuItem
      v-if="hasAnalyseMenuItem"
      :item="analyseMenuItem"
    ></MenuItem>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import MenuItem from "@/modules/app/components/MenuItem.vue"

// Stores
import { mapStores } from "pinia"
import { useNotificationsStore } from "@/stores/notifications"
import { useAuthenticationStore } from "@/stores/authentication"
import { useAccountStore } from "@/stores/account"
import { useListingStore } from "@/stores/listing"
import { useEstablishmentStore } from "@/stores/establishment"

// Types
import type { IUserAccount } from "@/types/Account.types"
import type { IFeature } from "@/types/Users.types"

// Data
import {
  reviewMenuItem,
  publicationMenuItem,
  informationMenuItem,
  analyseMenuItem,
  dashboardMenuItem,
  solicitationsMenuItem,
} from "@/static/Menu"
import type { ILocalListing } from "@/types/Listing.types"
import { LocalDiffuserConnexionState } from "@/static/LocalDiffuser"

export default defineComponent({
  name: "Menu",

  components: {
    Icon,
    MenuItem,
  },

  data() {
    return {
      dashboardMenuItem,
      solicitationsMenuItem,
      informationMenuItem,
      reviewMenuItem,
      publicationMenuItem,
      analyseMenuItem,

      hasTicConnected: false,

      user: {} as IUserAccount,
      features: [] as IFeature[],
    }
  },

  computed: {
    ...mapStores(
      useAuthenticationStore,
      useNotificationsStore,
      useAccountStore,
      useListingStore,
      useEstablishmentStore
    ),

    hasReviewMenuItem() {
      return (
        this.accountStore.isFreemium ||
        (this.features.find((feature) => feature.code === "REVIEW") && this.user.reviews_access)
      )
    },

    hasPublicationMenuItem() {
      return (
        this.accountStore.isFreemium ||
        (this.features.find((feature) => feature.code === "PUBLICATION") && this.user.publications_access)
      )
    },

    hasInformationMenuItem() {
      return this.features.find((feature) => feature.code === "INFORMATION")
    },

    hasAnalyseMenuItem() {
      return this.accountStore.hasFreemium
    },

    hasSolicitationsMenuItem() {
      return this.accountStore.hasFreemium || this.hasTicConnected
    },

    establishmentNotifications() {
      return this.notificationsStore.notifications.filter(
        (notif) => notif.data.establishmentId == this.establishmentStore.currentEstablishment.id
      )
    },

    solicitationMenu() {
      let menu = this.solicitationsMenuItem

      menu.notification = this.establishmentNotifications.filter(
          (notif) => notif.type === "App\\Notifications\\SolicitationNotification"
      ).length

      return menu
    },

    reviewMenu() {
      let menu = this.reviewMenuItem

      menu.notification = this.establishmentNotifications.filter(
        (notif) => notif.type === "App\\Notifications\\ReviewNotification"
      ).length

      return menu
    }
  },

  created() {
    this.user = this.authenticationStore.user

    if (this.user) {
      this.features = this.user.access_features
    }

    this.listingStore.getLocalDiffusers(this.establishmentStore.currentEstablishment.id, (list: ILocalListing[]) => {
      if (list.length > 0) {
        this.hasTicConnected = true
        list.forEach((localDiffuser) => {
          if (localDiffuser.state !== LocalDiffuserConnexionState.CONNECTED) {
            this.hasTicConnected = false
          }
        })
      } else {
        this.hasTicConnected = false
      }
    })
  },
})
</script>
