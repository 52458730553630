<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 4C9.87827 4 7.84344 4.84285 6.34315 6.34315C4.84285 7.84344 4 9.87827 4 12C4 13.0506 4.20693 14.0909 4.60896 15.0615C5.011 16.0321 5.60028 16.914 6.34315 17.6569C7.08601 18.3997 7.96793 18.989 8.93853 19.391C9.90914 19.7931 10.9494 20 12 20C13.0506 20 14.0909 19.7931 15.0615 19.391C16.0321 18.989 16.914 18.3997 17.6569 17.6569C18.3997 16.914 18.989 16.0321 19.391 15.0615C19.7931 14.0909 20 13.0506 20 12C20 9.87827 19.1571 7.84344 17.6569 6.34315C16.1566 4.84285 14.1217 4 12 4ZM4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 13.3132 21.7413 14.6136 21.2388 15.8268C20.7362 17.0401 19.9997 18.1425 19.0711 19.0711C18.1425 19.9997 17.0401 20.7362 15.8268 21.2388C14.6136 21.7413 13.3132 22 12 22C10.6868 22 9.38642 21.7413 8.17317 21.2388C6.95991 20.7362 5.85752 19.9997 4.92893 19.0711C4.00035 18.1425 3.26375 17.0401 2.7612 15.8268C2.25866 14.6136 2 13.3132 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893ZM12 8C10.5281 8 9.47188 8.70629 9.13259 9.42628C8.89716 9.92587 8.30131 10.14 7.80172 9.90459C7.30213 9.66916 7.08798 9.07331 7.32341 8.57372C8.08212 6.96371 9.98791 6 12 6C13.3004 6 14.5158 6.39386 15.4288 7.07866C16.3421 7.76372 17 8.78636 17 10C17 12.0701 15.1653 13.5081 13.1827 13.889L13.1824 13.8891C13.1095 13.9031 13.0516 13.9391 13.0203 13.9722C13.0062 13.9872 13.0013 13.9974 13.0002 13.9999C13.0001 14.0001 13 14.0002 13 14.0003C12.9998 14.5525 12.5522 15 12 15C11.4477 15 11 14.5523 11 14C11 12.8564 11.9107 12.0966 12.8056 11.9249C14.2788 11.6418 15 10.7298 15 10C15 9.55664 14.7629 9.07928 14.2287 8.67859C13.6942 8.27764 12.9096 8 12 8ZM11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconQuestionMarkCircle",
  props: ["height", "width"],
}
</script>
