<template>
  <Tooltip
    variant-x="centerX"
    variant-y="down"
  >
    <template #main>
      <div>
        <div
          class="flex items-center gap-sm justify-end"
          :class="style.color"
        >
          <Icon
            :title="state"
            :class="style.color"
            :icon-name="style.icon"
            class="min-w-[24px]"
          />
          <span v-if="showTitle">{{ $t("establishments/listing.states." + state) }}</span>
        </div>
        <span
          v-if="showAdditionalInfo"
          class="text-gray-400 text-sm max-w-[260px] block text-end"
          >{{ $t("establishments/listing." + additionalInfo) }}</span
        >
      </div>
    </template>
    <template #content>
      <span>{{ $t("establishments/listing.states." + state) }}</span>
    </template>
  </Tooltip>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout

// Components
import Icon from "@/components/Icon.vue"

// Stores
import { useListingStore } from "@/stores/listing"
import { mapStores } from "pinia"

// Types
import type { IListingStyle } from "@/types/Listing.types"
import type { ListingStatus } from "@/static/Listing"
import Tooltip from "@/components/Tooltip.vue"
import { listingStateStyle } from "@/static/Establishment"
import type { TicStatus } from "@/static/TouristicInformationCenter"

// Data

export default defineComponent({
  name: "ListingItemSyncState",
  components: { Icon, Tooltip },
  props: {
    state: {
      type: String, // ListingStatus || TicStatus
      required: true,
    },

    showTitle: {
      type: Boolean,
      default: true,
    },

    isDashboard: {
      type: Boolean,
      default: false,
    },

    isLocalDiffuser: {
      type: Boolean,
      default: false,
    },

    i18nbase: {
      type: String,
      default: "establishments/listing.states",
    },

    showAdditionalInfo: {
      type: Boolean,
      default: false,
    },

    additionalInfo: {
      type: String,
      default: "sync_in_progress_information",
    },
  },

  computed: {
    ...mapStores(useListingStore),

    style() {
      if (this.isLocalDiffuser) {
        return this.listingStore.localListingStyle(this.state as TicStatus) as IListingStyle
      } else {
        if (this.state === "CONNECTED") {
          return listingStateStyle.successStyle
        } else {
          return this.listingStore.style(this.state as ListingStatus) as IListingStyle
        }
      }
    },

    tooltipPosition() {
      if (this.isDashboard) {
        return {
          x: "centerX",
          y: "down",
        }
      } else {
        return {
          x: "left",
          y: "centerY",
        }
      }
    },
  },
})
</script>
