<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.0155 3H4.98449C2.23163 3 0 5.23386 0 7.98948V15.0105C0 17.7661 2.23163 20 4.98449 20H19.0155C21.7684 20 24 17.7661 24 15.0105V7.98948C24 5.23386 21.7684 3 19.0155 3ZM15.6445 11.8416L9.08177 14.9748C8.9069 15.0583 8.7049 14.9306 8.7049 14.7367V8.27454C8.7049 8.07786 8.91221 7.95039 9.08744 8.0393L15.6502 11.3683C15.8453 11.4673 15.8419 11.7474 15.6445 11.8416Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconYoutube",
  props: ["height", "width"],
}
</script>
