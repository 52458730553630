<template>
  <div class="flex flex-col gap">
    <div class="flex flex-col lg:flex-row items-start gap">
      <div class="relative w-full self-stretch flex flex-col gap">
        <SyncStatus
          v-if="!accountStore.isFreemium"
          :class="dashboardStore.sync.length <= 0 && 'h-full'"
        />
        <SynchronisationStatisticItem
          :class="dashboardStore.sync.length > 0 ? 'h-fit lg:min-h-[350px] xl:min-h-[250px]' : 'h-full'"
        />
      </div>
      <ProfilStatisticItem class="w-full self-stretch" />
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import ProfilStatisticItem from "./ProfilStatisticItem.vue"
import SynchronisationStatisticItem from "./SynchronisationStatisticItem.vue"
import SyncStatus from "@/components/dashboard/SyncStatus.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "Statistics",

  components: { ProfilStatisticItem, SynchronisationStatisticItem, SyncStatus },

  computed: {
    ...mapStores(useDashboardStore, useAccountStore),
  },
})
</script>
