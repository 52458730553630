<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1820_4569)">
      <path
        d="M12 24.0001C18.6274 24.0001 24 18.6275 24 12.0001C24 5.37264 18.6274 6.10352e-05 12 6.10352e-05C5.37258 6.10352e-05 0 5.37264 0 12.0001C0 18.6275 5.37258 24.0001 12 24.0001Z"
        fill="#3C5A9A"
      />
      <path
        d="M15.9019 3.68335H13.2436C11.6661 3.68335 9.9114 4.34683 9.9114 6.63352C9.9191 7.43028 9.9114 8.19335 9.9114 9.05214H8.08643V11.9562H9.96788V20.3166H13.4251V11.901H15.7071L15.9135 9.04399H13.3656C13.3656 9.04399 13.3713 7.77305 13.3656 7.40396C13.3656 6.50031 14.3058 6.55206 14.3624 6.55206C14.8098 6.55206 15.6798 6.55337 15.9032 6.55206V3.68335H15.9019Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1820_4569">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconFacebook",
  props: ["height", "width"],
}
</script>
