<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 1.905C8 0.852716 8.85271 0 9.905 0H10C10.7956 0 11.5587 0.31607 12.1213 0.87868C12.6839 1.44129 13 2.20435 13 3V7H16.764C17.2753 7.00002 17.7781 7.13072 18.2247 7.37968C18.6713 7.62865 19.0469 7.98763 19.3157 8.42254C19.5846 8.85745 19.7378 9.35386 19.7609 9.86464C19.7839 10.3754 19.6761 10.8836 19.4475 11.341L15.9475 18.341C15.9475 18.3411 15.9476 18.3409 15.9475 18.341C15.6983 18.8396 15.315 19.2591 14.8408 19.5521C14.3667 19.8451 13.8203 20.0002 13.263 20C13.2628 20 13.2632 20 13.263 20H9.246C9.00179 20 8.75733 19.9701 8.51852 19.9102C8.51851 19.9102 8.51853 19.9102 8.51852 19.9102C8.51804 19.91 8.51752 19.9099 8.51703 19.9098L4.87693 19H3C2.20435 19 1.44129 18.6839 0.87868 18.1213C0.31607 17.5587 0 16.7957 0 16V10C0 9.20435 0.31607 8.44129 0.87868 7.87868C1.44129 7.31607 2.20435 7 3 7H5.13129L7.5606 3.35533C7.8473 2.92636 8 2.42185 8 1.905ZM4 9H3C2.73478 9 2.48043 9.10536 2.29289 9.29289C2.10536 9.48043 2 9.73478 2 10V16C2 16.2652 2.10536 16.5196 2.29289 16.7071C2.48043 16.8946 2.73478 17 3 17H4V9ZM6 17.2192V9.30273L9.2234 4.46667C9.22324 4.46692 9.22357 4.46642 9.2234 4.46667C9.71274 3.73421 9.98098 2.87771 9.99902 2C10.2642 2 10.5196 2.10536 10.7071 2.29289C10.8946 2.48043 11 2.73478 11 3V7H10C9.44771 7 9 7.44772 9 8C9 8.55228 9.44771 9 10 9H16.764C16.764 9 16.764 9 16.764 9C16.9344 9.00001 17.102 9.04358 17.2509 9.12656C17.3997 9.20955 17.5249 9.32921 17.6145 9.47418C17.7041 9.61915 17.7552 9.78462 17.7629 9.95488C17.7706 10.1251 17.7347 10.2944 17.6586 10.4468C17.6585 10.4469 17.6586 10.4467 17.6586 10.4468L14.1586 17.4468C14.0755 17.613 13.9477 17.753 13.7896 17.8507C13.6315 17.9484 13.4493 18.0001 13.2635 18H9.246C9.16472 18 9.08368 17.99 9.00497 17.9702L6 17.2192Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconLike",
  props: ["height", "width"],
}
</script>
