<template>
  <div class="flex flex-col items-center justify-center h-full w-full col-span-full gap px">
    <span
      v-if="$slots.title"
      class="text-primary-700 text-lg text-center"
    >
      <slot name="title"></slot>
    </span>

    <span class="text-lg text-gray-700 text-center md:w-1/2">
      <slot></slot>
    </span>

    <slot name="actions"></slot>

    <Button
      v-if="showPremiumButton"
      @click="$router.push({ name: 'freemium-comparison' })"
    >
      <Icon icon-name="IconCrownOutlined" />
      {{ $t("common.premium_button") }}
    </Button>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Button from "./Button.vue"
import Icon from "./Icon.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "EmptyScreenMessage",

  components: { Button, Icon },

  props: {
    showPremiumButton: {
      type: Boolean,
      default: true,
    },
  },
})
</script>
