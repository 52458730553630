<template>
  <RouterLink
    v-if="to"
    :to="{ name: to, params: params }"
    class="no-link"
    @click="mobileStore.menu = false"
  >
    <div
      class="dropdown-item px-md py-sm flex items-center gap-sm rounded-sm"
      :class="[active && 'active', background && 'full-bg', variant]"
    >
      <slot></slot>
    </div>
  </RouterLink>
  <div
    v-else
    class="hover:cursor-pointer"
    @click="mobileStore.menu = false"
  >
    <div
      class="dropdown-item px-md py-sm flex items-center gap-sm rounded-sm"
      :class="[active && 'active', background && 'full-bg', variant]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
//

// Stores
import { useMobileStore } from "@/stores/mobile"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "DropdownItem",

  props: {
    to: {
      type: String,
    },

    params: {
      type: Object,
    },

    active: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      validators: (value: string) => ["default", "error", "primary"].includes(value),
      default: "default",
    },

    background: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapStores(useMobileStore),
  },
})
</script>
