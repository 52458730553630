<template>
  <component
    :is="iconName"
    :height="height"
    :width="width"
    :color="color"
    :class="'min-w-[' + width + 'px]'"
  ></component>
</template>

<script lang="ts">
import IconAnnotation from "@/assets/icons/IconAnnotation.vue"
import IconArrowLeft from "@/assets/icons/IconArrowLeft.vue"
import IconBadgeCheck from "@/assets/icons/IconBadgeCheck.vue"
import IconBell from "@/assets/icons/IconBell.vue"
import IconBookingColors from "@/assets/icons/IconBookingColors.vue"
import IconBookmark from "@/assets/icons/IconBookmark.vue"
import IconBookOpen from "@/assets/icons/IconBookOpen.vue"
import IconBurger from "@/assets/icons/IconBurger.vue"
import IconArrowDown from "@/assets/icons/IconArrowDown.vue"
import IconChartBar from "@/assets/icons/IconChartBar.vue"
import IconChat from "@/assets/icons/IconChat.vue"
import IconChatAlt2 from "@/assets/icons/IconChatAlt2.vue"
import IconChatOutline from "@/assets/icons/IconChatOutline.vue"
import IconChatReverse from "@/assets/icons/IconChatReverse.vue"
import IconChatSimple from "@/assets/icons/IconChatSimple.vue"
import IconCheck from "@/assets/icons/IconCheck.vue"
import IconCheckCircle from "@/assets/icons/IconCheckCircle.vue"
import IconChevronLeft from "@/assets/icons/IconChevronLeft.vue"
import IconChevronRight from "@/assets/icons/IconChevronRight.vue"
import IconClipboardCheck from "@/assets/icons/IconClipboardCheck.vue"
import IconClipboardList from "@/assets/icons/IconClipboardList.vue"
import IconClock from "@/assets/icons/IconClock.vue"
import IconCreditCard from "@/assets/icons/IconCreditCard.vue"
import IconCrownOutlined from "@/assets/icons/IconCrownOutlined.vue"
import IconCrown from "@/assets/icons/IconCrown.vue"
import IconCurrencyEuro from "@/assets/icons/IconCurrencyEuro.vue"
import IconCursorClick from "@/assets/icons/IconCursorClick.vue"
import IconQuestionMarkCircle from "@/assets/icons/IconQuestionMarkCircle.vue"
import IconStar from "@/assets/icons/IconStar.vue"
import IconStarOutline from "@/assets/icons/IconStarOutline.vue"
import IconDotsHorizontal from "@/assets/icons/IconDotsHorizontal.vue"
import IconDotsHorizontalOutline from "@/assets/icons/IconDotsHorizontalOutline.vue"
import IconDotsVertical from "@/assets/icons/IconDotsVertical.vue"
import IconDeviceMobile from "@/assets/icons/IconDeviceMobile.vue"
import IconDeviceComputer from "@/assets/icons/IconDeviceComputer.vue"
import IconDuplicate from "@/assets/icons/IconDuplicate.vue"
import IconEqual from "@/assets/icons/IconEqual.vue"
import IconExclamationCircle from "@/assets/icons/IconExclamationCircle.vue"
import IconFacebook from "@/assets/icons/IconFacebook.vue"
import IconFacebookColors from "@/assets/icons/IconFacebookColors.vue"
import IconFingerPrint from "@/assets/icons/IconFingerPrint.vue"
import IconFlag from "@/assets/icons/IconFlag.vue"
import IconFlagEn from "@/assets/icons/IconFlagEn.vue"
import IconFlagFr from "@/assets/icons/IconFlagFr.vue"
import IconGlobe from "@/assets/icons/IconGlobe.vue"
import IconGlobeAlt from "@/assets/icons/IconGlobeAlt.vue"
import IconGoogle from "@/assets/icons/IconGoogle.vue"
import IconGoogleColors from "@/assets/icons/IconGoogleColors.vue"
import IconGoogleColored from "@/assets/icons/IconGoogleColored.vue"
import IconHeart from "@/assets/icons/IconHeart.vue"
import IconHome from "@/assets/icons/IconHome.vue"
import IconHourglass from "@/assets/icons/IconHourglass.vue"
import IconInformationCircle from "@/assets/icons/IconInformationCircle.vue"
import IconInbox from "@/assets/icons/IconInbox.vue"
import IconInstagram from "@/assets/icons/IconInstagram.vue"
import IconInstagramColors from "@/assets/icons/IconInstagramColors.vue"
import IconKey from "@/assets/icons/IconKey.vue"
import IconEye from "@/assets/icons/IconEye.vue"
import IconFilter from "@/assets/icons/IconFilter.vue"
import IconLibrary from "@/assets/icons/IconLibrary.vue"
import IconLightBulb from "@/assets/icons/IconLightBulb.vue"
import IconLightninBolt from "@/assets/icons/IconLightninBolt.vue"
import IconLocationMarker from "@/assets/icons/IconLocationMarker.vue"
import IconLocationMarkerOutline from "@/assets/icons/IconLocationMarkerOutline.vue"
import IconLogin from "@/assets/icons/IconLogin.vue"
import IconLogout from "@/assets/icons/IconLogout.vue"
import IconMail from "@/assets/icons/IconMail.vue"
import IconMapsColored from "@/assets/icons/IconMapsColored.vue"
import IconMinus from "@/assets/icons/IconMinus.vue"
import IconPencilAlt from "@/assets/icons/IconPencilAlt.vue"
import IconPaperPlane from "@/assets/icons/IconPaperPlane.vue"
import IconPause from "@/assets/icons/IconPause.vue"
import IconPencil from "@/assets/icons/IconPencil.vue"
import IconPhone from "@/assets/icons/IconPhone.vue"
import IconPhoneOutlined from "@/assets/icons/IconPhoneOutlined.vue"
import IconPhotograph from "@/assets/icons/IconPhotograph.vue"
import IconPresentationChartBar from "@/assets/icons/IconPresentationChartBar.vue"
import IconRefresh from "@/assets/icons/IconRefresh.vue"
import IconSearch from "@/assets/icons/IconSearch.vue"
import IconShare from "@/assets/icons/IconShare.vue"
import IconShareSolid from "@/assets/icons/IconShareSolid.vue"
import IconSparkles from "@/assets/icons/IconSparkles.vue"
import IconSpeakerphone from "@/assets/icons/IconSpeakerphone.vue"
import IconPlus from "@/assets/icons/IconPlus.vue"
import IconTemplate from "@/assets/icons/IconTemplate.vue"
import IconThumbUp from "@/assets/icons/IconThumbUp.vue"
import IconTrash from "@/assets/icons/IconTrash.vue"
import IconTrendingDown from "@/assets/icons/IconTrendingDown.vue"
import IconTrendingUp from "@/assets/icons/IconTrendingUp.vue"
import IconTripadvisorColors from "@/assets/icons/IconTripadvisorColors.vue"
import IconUser from "@/assets/icons/IconUser.vue"
import IconUserCircle from "@/assets/icons/IconUserCircle.vue"
import IconUsers from "@/assets/icons/IconUsers.vue"
import IconX from "@/assets/icons/IconX.vue"
import IconXCircle from "@/assets/icons/IconXCircle.vue"
import IconYoutube from "@/assets/icons/IconYoutube.vue"
import IconYoutubeColors from "@/assets/icons/IconYoutubeColors.vue"
import IconCalendar from "@/assets/icons/IconCalendar.vue"
import IconGenderMan from "@/assets/icons/IconGenderMan.vue"
import IconGenderWoman from "@/assets/icons/IconGenderWoman.vue"
import IconFlash from "@/assets/icons/IconFlash.vue"
import IconCheckCircleColored from "@/assets/icons/IconCheckCircleColored.vue"
import IconExclamationCircleColored from "@/assets/icons/IconExclamationCircleColored.vue"
import IconExternalLink from "@/assets/icons/IconExternalLink.vue"
import IconInstagramPublication from "@/assets/icons/IconInstagramPublication.vue"
import IconGooglePublication from "@/assets/icons/IconGooglePublication.vue"
import IconLike from "@/assets/icons/IconLike.vue"
import IconComment from "@/assets/icons/IconComment.vue"
import IconLink from "@/assets/icons/IconLink.vue"
import IconDragDrop from "@/assets/icons/IconDragDrop.vue"
import IconAlmostInSync from "@/assets/icons/IconAlmostInSync.vue"

export default {
  name: "Icon",
  components: {
    IconCalendar,
    IconAnnotation,
    IconArrowLeft,
    IconBadgeCheck,
    IconBell,
    IconBookingColors,
    IconBookmark,
    IconBookOpen,
    IconArrowDown,
    IconBurger,
    IconChartBar,
    IconChat,
    IconChatAlt2,
    IconChatOutline,
    IconChatReverse,
    IconChatSimple,
    IconCheck,
    IconCheckCircle,
    IconChevronLeft,
    IconChevronRight,
    IconClipboardCheck,
    IconClipboardList,
    IconClock,
    IconCreditCard,
    IconCrownOutlined,
    IconCrown,
    IconCursorClick,
    IconQuestionMarkCircle,
    IconStar,
    IconStarOutline,
    IconDotsHorizontal,
    IconDotsHorizontalOutline,
    IconDotsVertical,
    IconDeviceMobile,
    IconDeviceComputer,
    IconDuplicate,
    IconEqual,
    IconExclamationCircle,
    IconFacebook,
    IconFacebookColors,
    IconEye,
    IconFilter,
    IconFingerPrint,
    IconFlag,
    IconFlagEn,
    IconFlagFr,
    IconGenderMan,
    IconGenderWoman,
    IconGlobe,
    IconGlobeAlt,
    IconGoogleColors,
    IconGoogleColored,
    IconHeart,
    IconHome,
    IconHourglass,
    IconCurrencyEuro,
    IconInbox,
    IconInformationCircle,
    IconInstagram,
    IconInstagramColors,
    IconKey,
    IconLibrary,
    IconLightBulb,
    IconLightninBolt,
    IconLocationMarker,
    IconLocationMarkerOutline,
    IconLogin,
    IconLogout,
    IconMail,
    IconMapsColored,
    IconMinus,
    IconPencilAlt,
    IconPaperPlane,
    IconPause,
    IconPencil,
    IconPhone,
    IconPhoneOutlined,
    IconPhotograph,
    IconPresentationChartBar,
    IconRefresh,
    IconSearch,
    IconShare,
    IconShareSolid,
    IconSparkles,
    IconSpeakerphone,
    IconPlus,
    IconTemplate,
    IconThumbUp,
    IconTrash,
    IconTrendingDown,
    IconTrendingUp,
    IconTripadvisorColors,
    IconUser,
    IconUserCircle,
    IconUsers,
    IconX,
    IconXCircle,
    IconYoutube,
    IconYoutubeColors,
    IconFlash,
    IconCheckCircleColored,
    IconExclamationCircleColored,
    IconExternalLink,
    IconInstagramPublication,
    IconGooglePublication,
    IconLike,
    IconComment,
    IconGoogle,
    IconLink,
    IconDragDrop,
    IconAlmostInSync
  },
  props: {
    iconName: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 24,
    },
    width: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: "currentColor",
    },
  },
}
</script>
