<template>
  <div
    class="alert-toast"
    :class="alert.type"
  >
    <div class="alert-container">
      <div v-if="alert.title">
        <small>{{ alert.title }}</small>
        <br />
      </div>
      <span v-html="alert.content"></span>
    </div>
    <div
      class="alert-close"
      @click="removeAlert"
    >
      <Icon icon-name="IconX" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import type { PropType } from "vue"

// Stores
import { useAlertStore } from "@/stores/alert"
import { mapStores } from "pinia"
import Icon from "./Icon.vue"
import type IAlert from "@/types/Alert.types"

export default defineComponent({
  name: "AlertItem",
  components: { Icon },
  props: {
    alert: {
      type: Object as PropType<IAlert>,
      required: true,
    },
  },
  computed: {
    ...mapStores(useAlertStore),
  },
  methods: {
    removeAlert() {
      this.alertStore.pop(this.alert)
    },
  },
})
</script>
