export const previousMapsConfig = {
  label: "maps n-1",
  icon: "IconMapsColored",
  backgroundColor: "#5AB7CB",
  borderColor: "#5AB7CB",
  legendColor: "#5AB7CB",
  pointStyle: "circle",
  pointRadius: 1,
  borderWidth: 2.5,
  cubicInterpolationMode: "monotone",
  borderDash: [5, 5],
  yAxisID: "y1",
}

export const mapsConfig = {
  label: "maps",
  icon: "IconMapsColored",
  backgroundColor: function (context: { chart: any }) {
    const chart = context.chart
    const { ctx, chartArea } = chart

    if (!chartArea) {
      // This case happens on initial chart load
      return
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, "rgba(90, 183, 203, 0.6)")
    gradient.addColorStop(0.5, "rgba(90, 183, 203, 0.00)")

    return gradient
  },
  borderColor: "#5AB7CB",
  legendColor: "#5AB7CB",
  pointStyle: "circle",
  pointRadius: 3,
  pointHoverRadius: 8,
  cubicInterpolationMode: "monotone",
  fill: true,
  yAxisID: "y",
}

export const previousGoogleConfig = {
  label: "search n-1",
  icon: "IconGoogleColored",
  backgroundColor: "#AEEAD9",
  borderColor: "#AEEAD9",
  legendColor: "#AEEAD9",
  pointStyle: "circle",
  pointRadius: 1,
  borderWidth: 2.5,
  cubicInterpolationMode: "monotone",
  borderDash: [5, 5],
  yAxisID: "y1",
}

export const googleConfig = {
  label: "search",
  icon: "IconGoogleColored",
  backgroundColor: function (context: { chart: any }) {
    const chart = context.chart
    const { ctx, chartArea } = chart

    if (!chartArea) {
      // This case happens on initial chart load
      return
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, "rgba(174, 234, 217, 0.6)")
    gradient.addColorStop(0.5, "rgba(174, 234, 217, 0.00)")

    return gradient
  },
  borderColor: "#AEEAD9",
  legendColor: "#AEEAD9",
  pointStyle: "circle",
  pointRadius: 3,
  pointHoverRadius: 8,
  cubicInterpolationMode: "monotone",
  fill: true,
  yAxisID: "y",
}

export const legends = [mapsConfig, previousMapsConfig, googleConfig, previousGoogleConfig]

export const options = {
  type: "line",
  maintainAspectRatio: false,
  animation: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      beginAtZero: true,
      type: "time",
      display: true,
      offset: false,
      ticks: {
        source: "data",
      },
      time: {
        // unit: "day",
        displayFormats: {
          day: "DD MMM",
          week: "DD MMM",
        },
        tooltipFormat: "YYYY-MM-DD", // Format des tooltips
      },
      title: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
    y1: {
      stacked: true,
      display: false,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}
