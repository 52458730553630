<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.11105 5.17993C9.26219 4.41078 10.6156 4.00024 12 4.00024C13.3845 4.00024 14.7378 4.41078 15.889 5.17993C17.0401 5.94909 17.9373 7.04232 18.4671 8.32138C18.997 9.60044 19.1356 11.0079 18.8655 12.3657C18.5955 13.7236 17.9288 14.9709 16.9499 15.9499L12.7065 20.1932C12.6139 20.286 12.5038 20.3596 12.3827 20.4098C12.2615 20.46 12.1316 20.4859 12.0005 20.4859C11.8694 20.4859 11.7395 20.46 11.6184 20.4098C11.4972 20.3596 11.3867 20.2855 11.294 20.1928L7.05003 15.9498C6.07115 14.9708 5.40454 13.7236 5.13448 12.3657C4.86441 11.0079 5.00306 9.60044 5.53288 8.32138C6.06271 7.04232 6.95991 5.94909 8.11105 5.17993ZM20.8271 12.7559C20.4799 14.5017 19.6228 16.1053 18.3641 17.364L14.1211 21.6071C13.8428 21.8856 13.5123 22.1066 13.1485 22.2574C12.7845 22.4082 12.3945 22.4859 12.0005 22.4859C11.6066 22.4859 11.2165 22.4082 10.8525 22.2574C10.4886 22.1065 10.158 21.8854 9.87955 21.6067L5.63588 17.364C4.37725 16.1053 3.52013 14.5017 3.1729 12.7559C2.82567 11.0101 3.00393 9.20049 3.68513 7.55599C4.36633 5.91148 5.51989 4.5059 6.99992 3.51699C8.47995 2.52807 10.22 2.00024 12 2.00024C13.78 2.00024 15.5201 2.52807 17.0001 3.51699C18.4801 4.5059 19.6337 5.91148 20.3149 7.55599C20.9961 9.20049 21.1743 11.0101 20.8271 12.7559ZM10.5858 9.58572C10.9609 9.21065 11.4696 8.99994 12 8.99994C12.5304 8.99994 13.0391 9.21065 13.4142 9.58572C13.7893 9.9608 14 10.4695 14 10.9999C14 11.5304 13.7893 12.0391 13.4142 12.4142C13.0391 12.7892 12.5304 12.9999 12 12.9999C11.4696 12.9999 10.9609 12.7892 10.5858 12.4142C10.2107 12.0391 10 11.5304 10 10.9999C10 10.4695 10.2107 9.9608 10.5858 9.58572ZM12 6.99994C10.9391 6.99994 9.92172 7.42137 9.17157 8.17151C8.42143 8.92166 8 9.93907 8 10.9999C8 12.0608 8.42143 13.0782 9.17157 13.8284C9.92172 14.5785 10.9391 14.9999 12 14.9999C13.0609 14.9999 14.0783 14.5785 14.8284 13.8284C15.5786 13.0782 16 12.0608 16 10.9999C16 9.93907 15.5786 8.92166 14.8284 8.17151C14.0783 7.42137 13.0609 6.99994 12 6.99994Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconLocationMarkerOutline",
  props: ["height", "width"],
}
</script>
