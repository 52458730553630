<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_287_13090)">
      <path
        d="M21.7618 12.1871C21.7618 11.3677 21.6954 10.7697 21.5515 10.1497H12.1948V13.848H17.687C17.5763 14.7671 16.9783 16.1512 15.6496 17.0813L15.6309 17.2051L18.5893 19.4969L18.7943 19.5174C20.6767 17.7789 21.7618 15.221 21.7618 12.1871Z"
        fill="#4285F4"
      />
      <path
        d="M12.1947 21.9313C14.8854 21.9313 17.1442 21.0454 18.7941 19.5174L15.6494 17.0813C14.8079 17.6682 13.6784 18.0779 12.1947 18.0779C9.55932 18.0779 7.3226 16.3395 6.52527 13.9366L6.4084 13.9466L3.33222 16.3273L3.29199 16.4391C4.93077 19.6945 8.29695 21.9313 12.1947 21.9313Z"
        fill="#34A853"
      />
      <path
        d="M6.52526 13.9366C6.31488 13.3165 6.19313 12.6521 6.19313 11.9656C6.19313 11.279 6.31488 10.6147 6.51419 9.9946L6.50862 9.86254L3.39389 7.4436L3.29198 7.49208C2.61656 8.84299 2.229 10.36 2.229 11.9656C2.229 13.5712 2.61656 15.0881 3.29198 16.439L6.52526 13.9366Z"
        fill="#FBBC05"
      />
      <path
        d="M12.1947 5.85336C14.066 5.85336 15.3283 6.66168 16.048 7.33718L18.8605 4.59107C17.1332 2.9855 14.8853 2 12.1947 2C8.29695 2 4.93077 4.23672 3.29199 7.49214L6.51421 9.99466C7.3226 7.59183 9.55932 5.85336 12.1947 5.85336Z"
        fill="#EB4335"
      />
    </g>
    <defs>
      <clipPath id="clip0_287_13090">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(2 2)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconGoogleColors",
  props: ["height", "width"],
}
</script>
