<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.87868 4.37868C4.44129 3.81607 5.20435 3.5 6 3.5H18C18.7956 3.5 19.5587 3.81607 20.1213 4.37868C20.6839 4.94129 21 5.70435 21 6.5V18.5C21 19.2957 20.6839 20.0587 20.1213 20.6213C19.5587 21.1839 18.7957 21.5 18 21.5H6C5.20435 21.5 4.44129 21.1839 3.87868 20.6213C3.31607 20.0587 3 19.2956 3 18.5V6.5C3 5.70435 3.31607 4.94129 3.87868 4.37868ZM6 5.5C5.73478 5.5 5.48043 5.60536 5.29289 5.79289C5.10536 5.98043 5 6.23478 5 6.5V12.5H6.586C7.11634 12.5001 7.62513 12.7109 8.00011 13.0859C8.00007 13.0859 8.00014 13.0859 8.00011 13.0859L10.4141 15.4999L13.5858 15.5L15.9998 13.086C15.9998 13.086 15.9998 13.086 15.9998 13.086C16.3748 12.711 16.8834 12.5001 17.4138 12.5H19V6.5C19 6.23478 18.8946 5.98043 18.7071 5.79289C18.5196 5.60536 18.2652 5.5 18 5.5H6ZM19 14.5H17.4142L15.0002 16.914C15.0002 16.914 15.0002 16.914 15.0002 16.914C14.6252 17.289 14.1166 17.4999 13.5862 17.5H10.414C9.88366 17.4999 9.37487 17.2891 8.99989 16.9141C8.99986 16.9141 8.99993 16.9141 8.99989 16.9141L6.58589 14.5001L5 14.5V18.5C5 18.7652 5.10536 19.0196 5.29289 19.2071C5.48043 19.3946 5.73478 19.5 6 19.5H18C18.2652 19.5 18.5196 19.3946 18.7071 19.2071C18.8946 19.0196 19 18.7652 19 18.5V14.5Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconInbox",
  props: ["height", "width"],
}
</script>
