<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 21.6004C14.5461 21.6004 16.9879 20.589 18.7882 18.7886C20.5886 16.9883 21.6 14.5465 21.6 12.0004C21.6 9.45431 20.5886 7.01252 18.7882 5.21217C16.9879 3.41182 14.5461 2.40039 12 2.40039C9.45395 2.40039 7.01215 3.41182 5.2118 5.21217C3.41145 7.01252 2.40002 9.45431 2.40002 12.0004C2.40002 14.5465 3.41145 16.9883 5.2118 18.7886C7.01215 20.589 9.45395 21.6004 12 21.6004ZM8.40002 10.8004H6.00002V13.2004H8.40002V10.8004ZM18 10.8004H15.6V13.2004H18V10.8004ZM10.8 10.8004H13.2V13.2004H10.8V10.8004Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconDotsHorizontal",
  props: ["height", "width"],
}
</script>
