import http from "@/http-common"
import type IContactForm from "@/types/Contact.types"
import type { IOnboardingEstablishment } from "@/types/Establishment.types"
import type { ICitySelectModel, IProviderData } from "@/types/Onboarding.types"
import type { IResultSource } from "@/types/Onboarding.types"
import type { IResultLine } from "@/types/Onboarding.types"

class OnboardingDataService {
  /**
   * Find address from establihment informations
   * @param establishmentName
   * @param city
   * @returns
   */
  searchAddress(establishmentName: string, city: ICitySelectModel): Promise<any> {
    return http.post(`/onboarding/online-data`, {
      name: establishmentName,
      commune: city.text,
    })
  }

  /**
   * Get all app enable sources
   * @param token
   * @returns
   */
  fetchExternalProviders(token: string): Promise<any> {
    return http.get(`/onboarding/providers/` + token)
  }

  /**
   * Get the current provider(s) when user come from Hubo
   * @returns
   */
  fetchdiffusers(): Promise<any> {
    return http.get(`/onboarding/diffusers`)
  }

  /**
   * Send contact request
   * @param form
   * @returns
   */
  sendContactRequest(form: IContactForm): Promise<any> {
    return http.post(`/onboarding/contact`, form)
  }

  /**
   * Get all establishment's sources for calculation of scoring
   * @param establishment
   * @returns
   */
  getEstablishmentOnlinePresence(establishment: IOnboardingEstablishment, diffusers: string[]): Promise<any> {
    return http.post(`/onboarding/online-presence`, {
      name: establishment.name,
      city: establishment.city ?? establishment.location.city,
      targeted_sources: diffusers,
    })
  }

  /**
   * Get the provider data from given source
   * @param source
   * @returns
   */
  getProviderData(source: IResultSource): Promise<any> {
    return http.post(`/onboarding/online-presence/providerdata`, source)
  }

  /**
   * Get the calculated score from providers list
   * @param informationLines IResultLine[][]
   * @returns
   */
  getEstablishmentScoringGlobal(establishmentId: number, informationLines: IResultLine[][]): Promise<any> {
    return http.post(`/onboarding/scoring/global`, {
      fields: informationLines,
      establishment_id: establishmentId,
    })
  }

  /**
   * Get all the differences for the given establishment to detail the score
   * @param providers
   * @returns
   */
  getDifferences(providers: IProviderData[]): Promise<any> {
    return http.post(`/onboarding/online-presence/diffs`, {
      provider_datas: providers,
    })
  }

  createReport(establishmentId: number, fields: IResultLine[][], score: number): Promise<any> {
    return http.post(`auth/analyse/report`, {
      establishment_id: establishmentId,
      fields: fields,
      score: score,
    })
  }

  getEstablishmentReport(establishmentId: number): Promise<any> {
    return http.get(`auth/analyse/report/` + establishmentId)
  }

  getApidaeData(establishmentId: number): Promise<any> {
    return http.get(`auth/local-diffusers/provider-data/` + establishmentId)
  }

  getCountries(): Promise<any> {
    return http.get(`locales/countries`)
  }

  getCurrentCountry(): Promise<any> {
    return http.get(`http://ip-api.com/json/`)
  }
}

export default new OnboardingDataService()
