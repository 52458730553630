<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8588 3.51197C11.2188 2.40677 12.7824 2.40677 13.1412 3.51197L14.4252 7.46238C14.5036 7.70296 14.6561 7.91259 14.8608 8.0613C15.0656 8.21001 15.3121 8.29019 15.5652 8.29038H19.7196C20.8824 8.29038 21.3648 9.77838 20.4252 10.4624L17.0652 12.9032C16.86 13.052 16.7072 13.262 16.6288 13.503C16.5503 13.744 16.5502 14.0037 16.6284 14.2448L17.9124 18.1952C18.2724 19.3004 17.0064 20.2208 16.0644 19.5368L12.7044 17.096C12.4994 16.9472 12.2526 16.867 11.9994 16.867C11.7461 16.867 11.4993 16.9472 11.2944 17.096L7.93436 19.5368C6.99356 20.2208 5.72876 19.3004 6.08756 18.1952L7.37156 14.2448C7.44977 14.0037 7.44963 13.744 7.37116 13.503C7.29269 13.262 7.13993 13.052 6.93476 12.9032L3.57596 10.4636C2.63636 9.77958 3.11996 8.29157 4.28156 8.29157H8.43476C8.68802 8.29164 8.93481 8.21158 9.13979 8.06285C9.34478 7.91412 9.49745 7.70436 9.57596 7.46357L10.86 3.51317L10.8588 3.51197Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconStar",
  props: ["height", "width"],
}
</script>
