<template>
  <Modal
    v-model="open"
    variant="small"
  >
    <template #header>
      <div class="flex flex-col items-center justify-center gap-sm">
        <Icon
          icon-name="IconXCircle"
          class="text-error-500"
          :height="30"
          :width="30"
        />
        <span class="text-xl text-center text-error-500">
          {{ $t("common.errors.modal_error_title", { diffuser: diffuser }) }}
        </span>
      </div>
    </template>
    <span class="text-gray-700">{{ $t("common.errors." + code) }}</span>
  </Modal>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout

// Components
import Modal from "@/components/Modal.vue"
import Icon from "@/components/Icon.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "ModalConnexionError",

  components: { Modal, Icon },

  props: {
    diffuser: {
      type: String,
      required: true,
    },
    code: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      open: this.show,
    }
  },

  watch: {
    show() {
      this.open = this.show
    },
  },
})
</script>
