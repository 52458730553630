import AuthDataService from "@/services/AuthDataService"
import { AlertType } from "@/static/Alert"
import { UserRole } from "@/static/User"
import type { IUserAccount } from "@/types/Account.types"
import { defineStore } from "pinia"
import { useAlertStore } from "@/stores/alert"
import { i18n } from "@/i18n"
import { ConfigurationState, configurationStateRouteName } from "@/static/Configuration"
import ReloadPlugin from "@/plugins/ReloadPlugin"

export const useAuthenticationStore = defineStore("authentication", {
  state: () => ({
    /** @type {string} current authenticated user email */
    email: "",

    /** @type {IUserAccount | null} current authenticated user from local storage */
    user: JSON.parse(localStorage.getItem("user") || "{}") as IUserAccount | null,

    /** @type {string} current authenticated user token */
    token: localStorage.getItem("token"),

    /** @type {string} current authenticated user token type */
    tokenType: localStorage.getItem("tokenType"),
  }),

  getters: {
    /**
     * Get the current authenticated authorization as query header
     * @param state authenticationStore state
     * @returns {{Authorization: string}} The complete header
     */
    currentHeaders: (state): { Authorization: string } => {
      return { Authorization: state.tokenType + " " + state.token }
    },

    /**
     * Check if current authenticated user is an administrator
     * @param state authenticationStore state
     * @returns {Boolean}
     */
    isAdmin: (state): boolean => {
      return state.user?.role.code === UserRole.admin
    },
  },

  actions: {
    /**
     * Clear all store states
     */
    clear() {
      this.$patch({
        email: "",
        user: null,
        token: "",
        tokenType: "",
      })
      localStorage.clear()
    },

    /**
     * Check reason of 401 request error and logout
     * @param code
     * @param callback
     */
    authenticationError(code: string, callback: () => void) {
      const alerts = useAlertStore()
      switch (code) {
        case "NOT_FOUND_TOKEN":
          alerts.push(AlertType.error, i18n.global.t("common.errors.no_connected_user"))
          this._authenticationErrorLogout()
          break
        case "EXPIRED_TOKEN":
          alerts.push(AlertType.error, i18n.global.t("common.errors.user_session_expired"))
          this._authenticationErrorLogout()
          break
        case "INVALID_TOKEN":
          alerts.push(AlertType.error, i18n.global.t("common.errors.user_session_invalid"))
          this._authenticationErrorLogout()
          break
        default:
          callback()
          break
      }
    },

    /**
     * Logout after 401 request error
     * @private
     */
    _authenticationErrorLogout() {
      this.logout()
      this.clear()
      this.router.push({ name: "auth" })
    },

    // CONNEXION
    /**
     * Initialize authenticated session variables
     * @param password
     * @param firstAttempt is the current user first connexion on current device
     */
    login(password: string, firstAttempt: boolean, errorCallback?: () => void) {
      AuthDataService.login(this.email, password).then(
        (res) => {
          const token = res.data.access_token
          const tokenType = res.data.token_type

          this.storeLoginUser(token, tokenType, firstAttempt)
        },
        (e) => {
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              this.authenticationError(e.response.code, () => {
                switch (e.response.data.message) {
                  case "Email not verified":
                    alerts.push(
                      AlertType.error,
                      i18n.global.t("authentication.connexion_form.error_email_not_verified", e.message)
                    )
                    break
                  case "Bad credentials":
                    alerts.push(
                      AlertType.error,
                      i18n.global.t("authentication.connexion_form.error_wrong_email_or_password", e.message)
                    )
                    break
                  default:
                    alerts.push(AlertType.error, i18n.global.t("common.errors.user_access"))
                    break
                }
              })
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
          if (errorCallback) {
            errorCallback()
          }
        }
      )
    },

    storeLoginUser(token: string, tokenType: string, firstAttempt: Boolean) {
      const alerts = useAlertStore()

      this.token = token
      this.tokenType = tokenType

      localStorage.setItem("token", token)
      localStorage.setItem("tokenType", tokenType)

      this.me(() => {
        // Si l'utilisateur a configuré son compte
        if (this.user!.account.configured) {
          this.router.push({ name: "home" })
        } else {
          const state = this.user!.account.configuration_state

          // Si c'est la première connexion de l'utilisateur sur cet appareil
          if (firstAttempt) {
            if (state === ConfigurationState.START_FREEMIUM) {
              this.router.push({ name: "config-freemium" })
            } else {
              this.router.push({ name: "config-welcome" })
            }
          } else {
            // Si l'état courant de la configuration est défini
            if (state) {
              this.router.push({ name: configurationStateRouteName[state] })
            } else {
              this.logout()
              alerts.push(AlertType.error, "Une erreur est survenue lors de la redirection (configuration_state null)")
            }
          }
        }
      })
    },

    /**
     * Logout
     */
    logout() {
      if (this.currentHeaders.Authorization !== null && this.currentHeaders.Authorization !== " ") {
        AuthDataService.logout(this.currentHeaders).then(
          () => {
            localStorage.clear()
            sessionStorage.clear()
          },
          (e) => {
            const alerts = useAlertStore()
            switch (e.response.status) {
              case 401:
                break
              default:
                alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
          }
        )
      }
    },

    /**
     * Retrieve the current authenticated user
     * @param callback
     */
    me(callback: () => void) {
      const hop = () => {
        return AuthDataService.me(this.currentHeaders).then(
          (res) => {
            const user = res.data.data.user
            this.user = user

            localStorage.setItem("user", JSON.stringify(user))
            callback()
          },
          (e) => {
            const alerts = useAlertStore()
            if (e.response) {
              switch (e.response.status) {
                case 426:
                  this.router.push({ name: "error-version" })
                  alerts.push(AlertType.info, "Merci de patienter encore quelques instants...")
                  break
                case 401:
                  break
                default:
                  alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                  break
              }
            } else {
              this.router.push({ name: "error" })
            }
          }
        )
      }

      if (
        (this.token === null && this.tokenType === null && localStorage.getItem("token") !== undefined) ||
        (this.token !== null && this.tokenType !== null)
      ) {
        if (localStorage.getItem("token") !== undefined) {
          return hop()
        }
      } else {
        return hop()
      }
    },

    /**
     * Validate the new user email
     * @param token
     * @param callback
     * @param callbackError
     */
    validate(token: string, callback: () => void, callbackError: (message: string) => void) {
      AuthDataService.validate(token).then(
        (res) => {
          if (!res.data.success) {
            callbackError(res.data.message)
          } else {
            callback(res.data.data)
          }
        },
        (e) => {
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 400:
              alerts.push(AlertType.error, "Le lien de validation n'existe plus")
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    sendValidationEmail(callback: () => void) {
      if (this.user) {
        const alerts = useAlertStore()
        AuthDataService.resendValidationEmail(this.user?.email).then(
          (res) => {
            alerts.push(
              AlertType.success,
              i18n.global.t("common.messages.validation_mail_sent", { email: this.user?.email })
            )
            callback()
          },
          (e) => {
            switch (e.response.status) {
              case 401:
                break
              default:
                alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
            callback()
          }
        )
      }
    },

    // FORGET PASSWORD
    /**
     * Send email with link to reset forgotten password
     * @param callback
     */
    forgetPasswordSendEmail(callback: () => void, errorCallback?: () => void) {
      AuthDataService.forgetPasswordSendEmail(this.email).then(
        (res) => {
          callback()
        },
        (e) => {
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 400:
              alerts.push(AlertType.error, i18n.global.t("common.errors.email_unknown"))
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
          if (errorCallback) {
            errorCallback()
          }
        }
      )
    },

    /**
     * Check if given forgotten password token is valid
     * @param token
     * @param callback
     * @param callbackError
     */
    checkForgetToken(token: string, callback: (res: any) => void, callbackError: () => void) {
      const alerts = useAlertStore()
      AuthDataService.forgetPasswordCheckToken(token, this.currentHeaders).then(
        (res) => {
          if (res.data.data.exist) {
            callback(res)
          } else {
            callbackError()
            alerts.push(AlertType.error, i18n.global.t("authentication/resetpassword/validation.error.token_expired"))
          }
        },
        (e) => {
          callbackError()
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Reset the forgotten password
     * @param token
     * @param password
     * @param callback
     */
    resetPassword(token: string, password: string, callback: () => void) {
      AuthDataService.resetPassword(token, password, this.currentHeaders).then(
        () => {
          callback()
        },
        (e) => {
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 400:
              alerts.push(AlertType.error, i18n.global.t("authentication/resetpassword/validation.error.token_expired"))
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    // SETUP PASSWORD
    /**
     * Check if given new password token is valid
     * @param token
     * @param callback
     * @param callbackError
     */
    checkSetupToken(token: string, callback: (email: string) => void, callbackError: () => void) {
      const alerts = useAlertStore()
      AuthDataService.setupPasswordCheckToken(token, this.currentHeaders).then(
        (res) => {
          if (res.data.data.exist) {
            callback(res.data.data.email)
          } else {
            callbackError()
            alerts.push(AlertType.error, i18n.global.t("authentication/resetpassword/validation.error.token_expired"))
          }
        },
        (e) => {
          callbackError()
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Set the new user password
     * @param token
     * @param password
     * @param callback
     */
    setupPassword(token: string, password: string, callback: () => void) {
      AuthDataService.setupPassword(token, password).then(
        (res) => {
          callback()
        },
        (e) => {
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            case 400:
              alerts.push(AlertType.error, i18n.global.t("authentication/resetpassword/validation.error.token_expired"))
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },
  },

  persist: {
    enabled: true,
  },
})
