<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 18 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.3362 0.0582582C10.7343 0.200355 11 0.577382 11 1.00005V7.00005H17C17.3864 7.00005 17.7382 7.22265 17.9037 7.57182C18.0691 7.92099 18.0186 8.33424 17.774 8.63329L8.77397 19.6333C8.50632 19.9604 8.0619 20.0839 7.66382 19.9418C7.26575 19.7998 7.00001 19.4227 7.00001 19.0001V13.0001H1.00001C0.613621 13.0001 0.261805 12.7775 0.0963413 12.4283C-0.0691225 12.0791 -0.0186229 11.6659 0.226054 11.3668L9.22605 0.366816C9.4937 0.0396858 9.93813 -0.0838385 10.3362 0.0582582ZM3.11025 11.0001H8.00001C8.5523 11.0001 9.00001 11.4478 9.00001 12.0001V16.1986L14.8898 9.00005H10C9.44773 9.00005 9.00001 8.55234 9.00001 8.00005V3.80146L3.11025 11.0001Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconFlash",
  props: ["height", "width"],
}
</script>
