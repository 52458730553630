<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20 19.4999H4C3.44772 19.4999 3 19.0522 3 18.4999V8.24137C3 8.15228 3.10771 8.10766 3.17071 8.17066L8.38949 13.3894C8.44109 13.441 8.5288 13.4218 8.55403 13.3533L11.9062 4.25464C11.9383 4.1674 12.0617 4.1674 12.0938 4.25464L15.446 13.3533C15.4712 13.4218 15.5589 13.441 15.6105 13.3894L20.8293 8.17066C20.8923 8.10766 21 8.15228 21 8.24137V18.4999C21 19.0522 20.5523 19.4999 20 19.4999Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9678 3.90893C11.3214 2.94931 12.6786 2.94929 13.0322 3.90893L15.898 11.6877L20.1222 7.46355L20.8245 8.16585L20.1222 7.46354C20.8151 6.7706 22 7.26136 22 8.24137V18.4999C22 19.6045 21.1046 20.4999 20 20.4999H4C2.89543 20.4999 2 19.6045 2 18.4999V8.24137C2 7.26137 3.18486 6.77059 3.87782 7.46355C3.87782 7.46355 3.87782 7.46355 3.87782 7.46355L8.10196 11.6877L10.9678 3.90893ZM9.09603 12.6818L9.09659 12.6823L8.4602 13.3187L9.0966 12.6823C9.09641 12.6821 9.09622 12.6819 9.09603 12.6818ZM12 6.89258L9.49237 13.699C9.21485 14.4523 8.25001 14.6642 7.68238 14.0965L4 10.4142V18.4999H20V10.4142L16.3176 14.0965C15.75 14.6642 14.7852 14.4523 14.5076 13.699L15.446 13.3533L14.5076 13.699L12 6.89258Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCrown",
  props: ["height", "width"],
}
</script>
