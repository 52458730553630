<template>
  <RouterLink
    :to="{ name: item.to, hash: item.hash }"
    class="no-link"
    @click="mobileStore.menu = false"
  >
    <div
      class="menu-item flex items-center gap-sm p-md rounded-sm"
      :class="isActive && 'active'"
    >
      <div class="relative">
        <Icon
          :icon-name="item.icon"
          class="text-gray-400"
        />
        <NotificationBadge
          v-if="mobileStore.isMobileSize && item.notification! > 0"
          size="small"
        />
        <NotificationBadge v-else-if="item.notification && item.notification! > 0">{{
          item.notification
        }}</NotificationBadge>
      </div>

      <span class="text-base text-gray-400 relative">
        {{ $t("menu.app." + item.code) }}

        <Icon
          v-if="showPremiumIcon"
          icon-name="IconCrownOutlined"
          class="!text-tertiary-500 h-[14px] w-[14px] absolute -right-[24px] top-0"
        />
      </span>
    </div>
  </RouterLink>
</template>

<script lang="ts">
// Vue
import { defineComponent, type PropType } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"

// Stores
import { useMobileStore } from "@/stores/mobile"
import { mapStores } from "pinia"

// Types
import type { IMenuItem } from "@/types/Menu.types"
import NotificationBadge from "@/components/NotificationBadge.vue"

// Data
//

export default defineComponent({
  name: "MenuItem",

  components: {
    Icon,
    NotificationBadge,
  },

  props: {
    item: {
      type: Object as PropType<IMenuItem>,
      required: true,
    },

    showPremiumIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isActive: false,
    }
  },

  computed: {
    ...mapStores(useMobileStore),
  },

  created() {
    if (this.$route.name === this.item.to) {
      this.isActive = true
    } else {
      this.isActive = false
    }
  },
})
</script>
