<template>
  <StatCard
    :title="$t(i18nbase + '.views.title')"
    :description="$t(i18nbase + '.description')"
    icon="IconEye"
    :loading="isLoading"
  >
    <template
      v-if="showGraph"
      #actions
    >
      <div class="flex items-center gap-sm">
        <FilterItem
          v-for="(legend, index) in legendItems"
          :key="'legend-' + index"
          :legend="legend"
          :index="index"
          @clicked="legendClicked(index, legend, showPreviousData)"
        >
          {{ $t(i18nbase + ".views.legend." + legend.label) }}
        </FilterItem>
      </div>
    </template>

    <template
      v-if="showGraph"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".views.total", {
              nb: total,
            })
          }}
        </span>
        <span
          v-if="showPreviousData && previousTotal"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".views.previous_total", {
              nb: previousTotal,
            })
          }}
        </span>
      </div>
    </template>

    <Line
      ref="chartRef"
      :class="!showGraph || selectedLegends.length === 0 ? '!hidden' : ''"
      :data="data"
      :options="showPreviousData ? chartPreviousOptions : chartOptions"
    />
    <div
      :class="showGraph && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + noDataKey) }}
      </span>
    </div>
    <div
      :class="selectedLegends.length > 0 && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + ".views.no_filter") }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { ref, type PropType } from "vue"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js"
import { Line, type ChartComponentRef } from "vue-chartjs"

// import moment from "moment"
// import "moment/locale/fr"
import { mapStores } from "pinia"

// Layout
//

// Components
import StatCard from "../StatCard.vue"
import FilterItem from "../FilterItem.vue"

// Stores
import { useListingStore } from "@/stores/listing"

// Types
//

// Data
import * as chartConfig from "@/static/dashboard/ViewsNumber"
import { DisplayStat, type IViewStatsForDashboard } from "@/types/Stats.types"
import {
  displayStats,
  generateTransformedData,
  getTransformationType,
  initShowingPreviousData,
  type GenericDataItem,
  getGraphUnit,
} from "@/plugins/GraphPlugin"
import type { FilterCode } from "@/static/Dashboard"
import moment from "moment"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler)

export default {
  name: "ViewsNumber",

  components: {
    Line,
    StatCard,
    FilterItem,
  },

  props: {
    stats: {
      type: Object as PropType<IViewStatsForDashboard>,
      required: true,
    },

    currentFilter: {
      type: String as PropType<FilterCode>,
      required: true,
    },

    showPreviousData: {
      type: Boolean,
      default: false,
    },

    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const chartRef = ref<ChartComponentRef>(null)
    const legendItems = ref(
      chartConfig.legends
        .filter((item) => !item.label.includes("-1"))
        .map((item) => {
          item.hidden = false
          return item
        })
    )

    const setupPreviousStats = (showPreviousData: Boolean, options, optionsPrevious) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      if (chart.data.datasets.length > 0) {
        initShowingPreviousData(chart, chartConfig.legends, showPreviousData, legendItems.value)
      }

      if (showPreviousData) {
        chart.options = optionsPrevious
      } else {
        chart.options = options
      }
      chart.update()
    }

    const legendClicked = (index: number, legend, previousStat: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      // Hide current stats
      const realIndex = chartConfig.legends.findIndex((item) => item.label === legend.label)
      displayStats(chart, realIndex, DisplayStat.AUTO, (action) => {
        if (action === "hide") {
          legendItems.value[index].hidden = true
        } else {
          legendItems.value[index].hidden = false
        }
      })

      // Hide previous stats
      if (previousStat) {
        const indexLastYear = chartConfig.legends.findIndex((item) => item.label === legend.label + " n-1")
        displayStats(chart, indexLastYear, DisplayStat.AUTO)
      }
    }

    return {
      chartRef,
      legendItems,
      setupPreviousStats,
      legendClicked,
      ...chartConfig,
    }
  },

  data() {
    return {
      i18nbase: "home.google",
      chartConfig: chartConfig,
      isLoading: true,
    }
  },

  computed: {
    ...mapStores(useListingStore),
    data() {
      if (this.showGraph) {
        // Définir la locale française pour Moment.js
        const transformedData = generateTransformedData(
          this.stats.current_view_evolution as unknown as GenericDataItem[],
          getTransformationType(this.currentFilter)
        )

        const previousTransformedData = generateTransformedData(
          this.stats.last_year_view_evolution as unknown as GenericDataItem[],
          getTransformationType(this.currentFilter),
          false,
          transformedData.ranges
        )

        // ATTENTION : l'ordre des datasets doit correspondre à l'ordre des legends
        return {
          labels: transformedData.data.map((item) => item.startDate),
          datasets: [
            {
              data: transformedData.data.map((item) => item.view_desktop_maps + item.view_mobile_maps),
              ...chartConfig.mapsConfig,
            },
            {
              data: previousTransformedData.data.map((item) => item.view_desktop_maps + item.view_mobile_maps),
              ...chartConfig.previousMapsConfig,
            },
            {
              data: transformedData.data.map((item) => item.view_desktop_search + item.view_mobile_search),
              ...chartConfig.googleConfig,
            },
            {
              data: previousTransformedData.data.map((item) => item.view_desktop_search + item.view_mobile_search),
              ...chartConfig.previousGoogleConfig,
            },
          ],
        }
      } else {
        return {
          labels: [],
          datasets: [],
        }
      }
    },

    chartOptions() {
      let opts = this.chartConfig.options
      opts.scales.x.time.unit = getGraphUnit(this.currentFilter)
      opts.scales.x.title.text = getGraphUnit(this.currentFilter)
      opts.plugins = {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = this.$t(this.i18nbase + ".views.legend." + context.dataset.label) || ""
              if (label) {
                label += " : "
              }
              if (context.parsed.y !== null) {
                label += context.parsed.y
              }
              return label
            },
            title: (context) => {
              let date = moment(context[0].label)
              if (context[0].dataset.label.includes("n-1")) {
                date = date.subtract(1, "year")
              }
              return date.format("MMMM YYYY")
            },
          },
        },
        ...opts.plugins,
      }

      if (this.data.datasets.length > 0) {
        const cumuled = this.data.datasets[0].data.map((num, idx) => {
          return num + this.data.datasets[2].data[idx]
        })
        const currentMax = Math.max(...cumuled.map((item) => item))

        opts.scales.y.min = 0
        opts.scales.y1.min = 0

        opts.scales.y.max = currentMax * 2
        opts.scales.y1.max = currentMax * 2
      }

      return opts
    },

    chartPreviousOptions() {
      let opts = this.chartOptions

      if (this.data.datasets.length > 0) {
        const cumuled = this.data.datasets[0].data.map((num, idx) => {
          return num + this.data.datasets[2].data[idx]
        })
        const currentMax = Math.max(...cumuled.map((item) => item))

        const previousCumuled = this.data.datasets[1].data.map((num, idx) => {
          return num + this.data.datasets[3].data[idx]
        })
        const previousMax = Math.max(...previousCumuled.map((item) => item))

        const max = Math.max(currentMax, previousMax)

        opts.scales.y.min = 0
        opts.scales.y1.min = 0

        opts.scales.y.max = max
        opts.scales.y1.max = max
      }

      return opts
    },

    total() {
      let total = 0
      const available = this.legendItems.filter((item) => item.hidden === false)

      available.forEach((element) => {
        if (element.label === "maps" && this.stats.current_view_total_maps !== null) {
          total += this.stats.current_view_total_maps
        }
        if (element.label === "search" && this.stats.current_view_total_search !== null) {
          total += this.stats.current_view_total_search
        }
      })

      return this.$string.formatNumberToHumanString(total)
    },

    previousTotal() {
      let total = 0
      const available = this.legendItems.filter((item) => item.hidden === false)

      available.forEach((element) => {
        if (element.label === "maps" && this.stats.last_year_view_total_maps !== null) {
          total += this.stats.last_year_view_total_maps
        }
        if (element.label === "search" && this.stats.last_year_view_total_maps !== null) {
          total += this.stats.last_year_view_total_search
        }
      })

      return this.$string.formatNumberToHumanString(total)
    },

    showGraph() {
      return this.stats.current_view_evolution.length > 0 && this.stats.last_year_view_evolution.length > 0
    },

    selectedLegends() {
      return this.legendItems.filter((item) => item.hidden === false)
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus("GOOGLE") === "CONNECTED" ? "views.no_data" : "not_connected"
    },
  },

  watch: {
    showPreviousData() {
      this.setupPreviousStats(this.showPreviousData, this.chartOptions, this.chartPreviousOptions)
    },
  },

  mounted() {
    this.$waiting.simple(() => {
      this.setupPreviousStats(this.showPreviousData, this.chartOptions, this.chartPreviousOptions)
      this.isLoading = false
    })
  },
}
</script>
@/static/dashboard/ViewsNumber
