<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.58286 3.59963C2.58286 3.28137 2.70929 2.97614 2.93433 2.7511C3.15938 2.52606 3.4646 2.39963 3.78286 2.39963H6.36646C6.65051 2.39976 6.9253 2.50064 7.14196 2.68434C7.35861 2.86803 7.50308 3.12263 7.54966 3.40283L8.43766 8.72483C8.48009 8.97829 8.44003 9.23868 8.32338 9.46766C8.20673 9.69665 8.01964 9.88214 7.78966 9.99683L5.93206 10.9244C6.59819 12.5752 7.59026 14.0748 8.84899 15.3335C10.1077 16.5922 11.6073 17.5843 13.2581 18.2504L14.1869 16.3928C14.3015 16.1631 14.4868 15.9761 14.7155 15.8595C14.9443 15.7429 15.2044 15.7027 15.4577 15.7448L20.7797 16.6328C21.0599 16.6794 21.3145 16.8239 21.4982 17.0405C21.6818 17.2572 21.7827 17.532 21.7829 17.816V20.3996C21.7829 20.7179 21.6564 21.0231 21.4314 21.2482C21.2063 21.4732 20.9011 21.5996 20.5829 21.5996H18.1829C9.56686 21.5996 2.58286 14.6156 2.58286 5.99963V3.59963Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPhone",
  props: ["height", "width"],
}
</script>
