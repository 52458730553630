// Vue
import { i18n } from "@/i18n"
import moment from "moment"

// Types
import type {
  IChecklistOutput,
  IFilterPeriod,
  IFilterPlatform,
  IGlobalStatistic,
  IProfil,
  IStatistics,
  ISync,
} from "@/types/Dashboard.types"

// Stores
import { defineStore } from "pinia"
import { useAlertStore } from "./alert"
import { useAuthenticationStore } from "./authentication"
import { useListingStore } from "./listing"
import { useDiffuserStore } from "./diffuser"
import { useEstablishmentStore } from "./establishment"

// Data
import { AlertType } from "@/static/Alert"
import { charteDiffusers, AllDiffuser } from "@/static/Diffuser"
import { ListingStatus } from "@/static/Listing"
import { FilterPlatformCode } from "@/static/Dashboard"
import example from "@/static/examples/dashboard-12months.json"

// Services
import DashboardDataService from "@/services/DashboardDataService"
import EstablishmentDataService from "@/services/EstablishmentDataService"
import type {
  IClickStats,
  IClickStatsForDashboard,
  IEngagementStats,
  IEngagementStatsForDashboard,
  IGenderStats,
  IGenderStatsForDashboard,
  ILikeStats,
  IReviewStatsForDashboard,
  IViewStats,
  IViewStatsForDashboard,
} from "@/types/Stats.types"
import { useAccountStore } from "./account"

export const useDashboardStore = defineStore("dashboard", {
  state: () => ({
    /**
     * Statistiques sur le nombre de vues
     * @type {IGlobalStatistic}
     */
    viewsStats: {
      name: "Vues",
      icon: "IconEye",
      diffusers: [AllDiffuser.GOOGLE, AllDiffuser.GOOGLE_MAPS, AllDiffuser.FACEBOOK],
    } as IGlobalStatistic,

    /**
     * Statistiques sur l'évolution du nombre de vues
     * @type {IViewStats}
     */
    viewEvolution: {} as IViewStatsForDashboard,

    /**
     * Statistiques sur le nombre de clics sur la fiche google
     * @type {IGlobalStatistic}
     */
    clickStat: {
      name: "Clics",
      icon: "IconCursorClick",
      diffusers: [AllDiffuser.GOOGLE, AllDiffuser.GOOGLE_MAPS, AllDiffuser.FACEBOOK],
    } as IGlobalStatistic,

    /**
     * Statistiques sur l'évolution du nombre de clicks
     * @type {IClickStatsForDashboard}
     */
    clickEvolution: {} as IClickStatsForDashboard,

    /**
     * Statistiques sur la note moyenne
     * @type {IGlobalStatistic}
     */
    noteStat: {
      code: "note",
      name: "Note moyenne",
      icon: "IconStarOutline",
      diffusers: [AllDiffuser.GOOGLE, AllDiffuser.FACEBOOK],
    } as IGlobalStatistic,

    /**
     * Statistiques sur les avis et leurs réponses
     * @type {IReviewStatsForDashboard}
     */
    reviewsStats: {} as IReviewStatsForDashboard,

    /**
     * Statistiques sur le profil
     * @type {IGloIProfilbalStatistic}
     */
    profil: {
      isLoading: true,
      completion: 0,
      missing_fields: [],
      notifs: {
        general: [],
        specific: [],
        hours: [],
        medias: [],
        contact: [],
        platforms: [],
      },
    } as IProfil,

    /**
     * Statistiques sur l'évolution du taux d'engagement des publications
     * @type {IEngagementStatsForDashboard}
     */
    engagementEvolution: {} as IEngagementStatsForDashboard,

    /**
     * Statistiques sur l'évolution du nombre de mentions j'aime sur la page
     * @type {ILikeStats}
     */
    likeEvolution: {} as ILikeStats,

    /**
     * Statistiques sur le nombre de fans homme/femme par tranche d'âge
     * @type {IGenderStatsForDashboard}
     */
    fans: {} as IGenderStatsForDashboard,

    /**
     * Statistiques sur la synchro
     * @type {ISync}
     */
    sync: [] as ISync[],

    /**
     * Statistiques sur les status des plateformes
     */
    platformsStatus: {
      total: 0,
      success: 0,
      warning: 0,
      error: 0,
    },

    /**
     * Is the dahsboard statistics are loading ?
     * @type {boolean}
     */
    isLoading: true,

    /**
     * Is the dahsboard sync statistics are loading ?
     * @type {boolean}
     */
    isSyncLoading: true,

    /**
     * The current selected period filters item
     * @type {IFilterPeriod}
     */
    currentPeriodFilter: {} as IFilterPeriod,

    /**
     * The current selected platform filters item
     * @type {IFilterPlatform}
     */
    currentPlatformFilter: {} as IFilterPlatform,

    /**
     * The current establishment last sync
     * @type {string}
     */
    lastSync: "",

    /**
     * Is the checklist are loading ?
     * @type {boolean}
     */
    isChecklistLoading: true,

    /**
     * The current establishment (and current user) checklist status
     * @type {IChecklistOutput}
     */
    checklistStatus: {} as IChecklistOutput,

    /**
     * If at least one checklist step is unreached, showchecklist should be true
     */
    showchecklist: false,

    reloaded: 0,
  }),

  actions: {
    /**
     * Récupère les statistiques d'un établissement
     * Statistiques globales (vues, recherches, clics, note)
     * Complétude du profil
     * @param establishmentId
     */
    statistics(successCallback?: () => void) {
      const auth = useAuthenticationStore()
      const establishmentStore = useEstablishmentStore()
      this.isLoading = true

      if (establishmentStore.currentEstablishment !== undefined && establishmentStore.currentEstablishment.id) {
        if (establishmentStore.currentEstablishment.last_sync) {
          this.lastSync = moment(establishmentStore.currentEstablishment.last_sync, "YYYY-MM-DD").format("DD/MM/YYYY")
        }

        const alerts = useAlertStore()

        const formatedStartDate = moment(this.currentPeriodFilter.startDate, "DD/MM/YYYY").format("YYYY-MM-DD")
        const formatedEndDate = moment(this.currentPeriodFilter.endDate, "DD/MM/YYYY").format("YYYY-MM-DD")

        const promises: Promise<any>[] = []

        const accountStore = useAccountStore()

        if (accountStore.isFreemium) {
          this.isLoading = false
        } else {
          promises.push(
            DashboardDataService.getStatistics(
              establishmentStore.currentEstablishment.id,
              formatedStartDate,
              formatedEndDate,
              this.currentPeriodFilter.code,
              this.currentPlatformFilter.code,
              auth.currentHeaders
            ).then(
              (res) => {
                if (res.data.data) {
                  // Tester avec des fausses données
                  // this._formatData(JSON.parse(JSON.stringify(example.data)))
                  this._formatData(res.data.data)
                  this.isLoading = false
                }
              },
              (e) => {
                switch (e.response.status) {
                  case 401:
                    break
                  default:
                    alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                    break
                }
              }
            )
          )
        }

        this.profil.isLoading = true

        promises.push(this.getProfil())

        Promise.all(promises).then(() => {
          if (successCallback) {
            successCallback()
          }
        })
      }
    },

    getProfil() {
      const establishmentStore = useEstablishmentStore()
      const alerts = useAlertStore()
      const auth = useAuthenticationStore()

      this.profil.isLoading = true

      return DashboardDataService.getCompletenessProfil(
        establishmentStore.currentEstablishment.id,
        auth.currentHeaders
      ).then(
        (res) => {
          this.profil.completion = res.data.data.completion
          this.profil.missing_fields = res.data.data.missing_fields

          this.profil.notifs = {
            general: this.profil.missing_fields.filter((val) =>
              [
                "field_DESCRIPTION_SHORT",
                "field_DESCRIPTION_LONG",
                "field_CATEGORIES",
                "field_STREET",
                "field_CITY",
              ].includes("field_" + val)
            ),
            specific: this.profil.missing_fields.filter((val) => ["field_ATTRIBUTES"].includes("field_" + val)),
            hours: this.profil.missing_fields.filter((val) => ["field_OPENINGHOURS"].includes("field_" + val)),
            medias: this.profil.missing_fields.filter((val) => ["field_PHOTOS"].includes("field_" + val)),
            contact: this.profil.missing_fields.filter((val) =>
              ["field_EMAIL", "field_PHONE"].includes("field_" + val)
            ),
            platforms: this.profil.missing_fields.filter((val) =>
              ["field_WEBSITE", "field_INSTAGRAM", "field_FACEBOOK"].includes("field_" + val)
            ),
          }

          this.profil.isLoading = false
        },
        (e) => {
          const auth = useAuthenticationStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Get current establishment checklist status
     * @param establishmentId
     */
    checklist(establishmentId: number, callback: () => void) {
      this.isChecklistLoading = true

      const establishmentStore = useEstablishmentStore()

      EstablishmentDataService.getChecklist(establishmentId).then(
        (res) => {
          this.checklistStatus = res.data.data as IChecklistOutput

          // connection Google et FB non dispo en freemium (après les 3 mois du trial freemium)
          if (establishmentStore.currentEstablishment.product_plan.code === "FREEMIUM") {
            delete this.checklistStatus.connectedGoogle
            delete this.checklistStatus.connectedFacebook
          }

          const values = [...new Set(Object.values(this.checklistStatus))]

          // S'il reste au moins 1 tâche à false, alors on affcihe la checklist
          this.showchecklist = values.length > 1 || (values.length === 1 && values[0] === false)

          this.isChecklistLoading = false

          callback()
        },
        (e) => {
          const auth = useAuthenticationStore()
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Check the dashboard checklist solicitations when user went for the first time to seen solicitations tab
     */
    hasSeenSolicitations(establishmentId: number) {
      EstablishmentDataService.updateViewedSolicitations(establishmentId).then(
        (res) => {
          //
        },
        (e) => {
          const auth = useAuthenticationStore()
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Format endpoint output into local js object
     * @param data
     */
    _formatData(data: IStatistics) {
      // Vues
      this.viewsStats.sum = data.views_sum
      this.viewsStats.variationType = data.view_variation.sign
      this.viewsStats.variation = data.view_variation.value
      this._formatViewEvolution(data.view_evolution)

      // Clics
      this.clickStat.sum = data.clicks_sum
      this.clickStat.variationType = data.click_variation.sign
      this.clickStat.variation = data.click_variation.value
      this._formatClickEvolution(data.click_evolution)

      // Note moyenne
      if (data.note > 0) {
        this.noteStat.sum = data.note
      } else {
        this.noteStat.sum = null
      }
      this.noteStat.variationType = data.note_variation.sign
      this.noteStat.variation = data.note_variation.value

      // Avis
      this.reviewsStats = {
        current_total: data.reviews.current.reviews_answered + data.reviews.current.reviews_not_answered,
        last_year_total: data.reviews.last_year.reviews_answered + data.reviews.last_year.reviews_not_answered,
        ...data.reviews,
      }

      // Publications
      this._formatEngagementEvolution(data.engagement_evolution)

      this.likeEvolution = data.like

      // Fans
      this._formatGender(data.gender_age_partition)
    },

    _formatViewEvolution(data: IViewStats) {
      let currentViewData = {
        current_view_total_maps: null as null | Number,
        current_view_total_search: null as null | Number,
      }
      let previousViewData = {
        last_year_view_total_maps: null as null | Number,
        last_year_view_total_search: null as null | Number,
      }
      if (data.current_view_evolution.length > 0 && data.last_year_view_evolution.length > 0) {
        const hasCurrentData = data.current_view_evolution.some(
          (item) =>
            ![
              item.view_mobile_maps,
              item.view_desktop_maps,
              item.view_mobile_search,
              item.view_desktop_search,
            ].includes(null)
        )
        if (hasCurrentData) {
          const currentData = data.current_view_evolution.filter(
            (item) =>
              ![
                item.view_mobile_maps,
                item.view_desktop_maps,
                item.view_mobile_search,
                item.view_desktop_search,
              ].includes(null)
          )
          currentViewData = {
            current_view_total_maps: currentData.reduce(
              (sum, item) => sum + item.view_desktop_maps + item.view_mobile_maps,
              0
            ),
            current_view_total_search: currentData.reduce(
              (sum, item) => sum + item.view_desktop_search + item.view_mobile_search,
              0
            ),
          }
        }

        const hasPreviousData = data.last_year_view_evolution.some(
          (item) =>
            ![
              item.view_mobile_maps,
              item.view_desktop_maps,
              item.view_mobile_search,
              item.view_desktop_search,
            ].includes(null)
        )
        if (hasPreviousData) {
          const previousData = data.last_year_view_evolution.filter(
            (item) =>
              ![
                item.view_mobile_maps,
                item.view_desktop_maps,
                item.view_mobile_search,
                item.view_desktop_search,
              ].includes(null)
          )
          previousViewData = {
            last_year_view_total_maps: previousData.reduce(
              (sum, item) => sum + item.view_desktop_maps + item.view_mobile_maps,
              0
            ),
            last_year_view_total_search: previousData.reduce(
              (sum, item) => sum + item.view_desktop_search + item.view_mobile_search,
              0
            ),
          }
        }
      }
      this.viewEvolution = {
        current_view_total_maps: currentViewData.current_view_total_maps,
        current_view_total_search: currentViewData.current_view_total_search,
        last_year_view_total_maps: previousViewData.last_year_view_total_maps,
        last_year_view_total_search: previousViewData.last_year_view_total_search,
        ...data,
      }
    },

    _formatClickEvolution(data: IClickStats) {
      let currentData = {
        current_click_total_drive: null as null | Number,
        current_click_total_phone: null as null | Number,
        current_click_total_website: null as null | Number,
      }
      let previousData = {
        last_year_click_total_drive: null as null | Number,
        last_year_click_total_phone: null as null | Number,
        last_year_click_total_website: null as null | Number,
      }
      if (data.current_click_evolution.length > 0 && data.last_year_click_evolution.length > 0) {
        const hasCurrentData = data.current_click_evolution.some(
          (item) => ![item.clickDrive, item.clickPhone, item.clickWebsite].includes(null)
        )
        if (hasCurrentData) {
          currentData = {
            current_click_total_drive: data.current_click_evolution.reduce((sum, item) => sum + item.clickDrive, 0),
            current_click_total_phone: data.current_click_evolution.reduce((sum, item) => sum + item.clickPhone, 0),
            current_click_total_website: data.current_click_evolution.reduce((sum, item) => sum + item.clickWebsite, 0),
          }
        }

        const hasPreviousData = data.last_year_click_evolution.some(
          (item) => ![item.clickDrive, item.clickPhone, item.clickWebsite].includes(null)
        )
        if (hasPreviousData) {
          previousData = {
            last_year_click_total_drive: data.last_year_click_evolution.reduce((sum, item) => sum + item.clickDrive, 0),
            last_year_click_total_phone: data.last_year_click_evolution.reduce((sum, item) => sum + item.clickPhone, 0),
            last_year_click_total_website: data.last_year_click_evolution.reduce(
              (sum, item) => sum + item.clickWebsite,
              0
            ),
          }
        }
      }
      this.clickEvolution = {
        current_click_total_drive: currentData.current_click_total_drive,
        current_click_total_phone: currentData.current_click_total_phone,
        current_click_total_website: currentData.current_click_total_website,
        last_year_click_total_drive: previousData.last_year_click_total_drive,
        last_year_click_total_phone: previousData.last_year_click_total_phone,
        last_year_click_total_website: previousData.last_year_click_total_website,
        ...data,
      }
    },

    _formatEngagementEvolution(data: IEngagementStats) {
      let currentData = {
        currentTotal: null as null | Number,
      }
      let previousData = {
        previousTotal: null as null | Number,
      }
      if (data.current_engagement_evolution !== null) {
        const hasCurrentData = data.current_engagement_evolution.some((item) => ![item.count].includes(null))
        if (hasCurrentData) {
          currentData = {
            currentTotal: data.current_engagement_evolution.reduce((sum, item) => sum + item.count, 0),
          }
        }

        const hasPreviousData = data.last_year_engagement_evolution.some((item) => ![item.count].includes(null))
        if (hasPreviousData) {
          previousData = {
            previousTotal: data.last_year_engagement_evolution.reduce((sum, item) => sum + item.count, 0),
          }
        }
      }
      this.engagementEvolution = {
        currentTotal: currentData.currentTotal,
        previousTotal: previousData.previousTotal,
        ...data,
      }
    },

    _formatGender(data: IGenderStats) {
      let currentData = {
        currentManTotal: null as null | Number,
        currentWomanTotal: null as null | Number,
      }
      let previousData = {
        previousManTotal: null as null | Number,
        previousWomanTotal: null as null | Number,
      }
      if (data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_MALE !== null) {
        const hasCurrentData = !Object.values(data.current_gender_age_partition).includes(null)
        if (hasCurrentData) {
          currentData = {
            currentManTotal: [
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_MALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_EIGHTEEN_TO_TWENTY_FOUR_MALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_MALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_MALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_MALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE,
            ].reduce((sum, item) => sum + item, 0),
            currentWomanTotal: [
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_FEMALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_EIGHTEEN_TO_TWENTY_FOUR_FEMALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_FEMALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_FEMALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_FEMALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE,
              data.current_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE,
            ].reduce((sum, item) => sum + item, 0),
          }
        }

        const hasPreviousData = !Object.values(data.last_year_gender_age_partition).includes(null)
        if (hasPreviousData) {
          previousData = {
            previousManTotal: [
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_MALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_EIGHTEEN_TO_TWENTY_FOUR_MALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_MALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_MALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_MALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE,
            ].reduce((sum, item) => sum + item, 0),
            previousWomanTotal: [
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_FEMALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_EIGHTEEN_TO_TWENTY_FOUR_FEMALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_FEMALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_FEMALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_FEMALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE,
              data.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE,
            ].reduce((sum, item) => sum + item, 0),
          }
        }
      }
      this.fans = {
        currentManTotal: currentData.currentManTotal,
        currentWomanTotal: currentData.currentWomanTotal,
        previousManTotal: previousData.previousManTotal,
        previousWomanTotal: previousData.previousWomanTotal,
        ...data,
      }
    },

    /**
     * Récupère les statistiques de synchronisation des 3 principaux canaux
     * Permet d'identifer les canaux non connectés
     */
    syncStatistics(successCallback?: () => void) {
      this.isSyncLoading = true
      const listingStore = useListingStore()
      this.sync = []

      listingStore.allListing(
        () => {
          const all = listingStore.mainEstablishmentListing.concat(listingStore.secondaryEstablishmentListing)

          const ok = all.filter((item) =>
            [ListingStatus.SYNCHRONIZED, ListingStatus.SYNC_IN_PROGRESS].includes(item.status)
          )
          const warning = all.filter((item) =>
            [ListingStatus.SUBMITTED, ListingStatus.BEING_UPDATED].includes(item.status)
          )
          const error = all.filter(
            (item) =>
              ![
                ListingStatus.SYNCHRONIZED,
                ListingStatus.SUBMITTED,
                ListingStatus.WAIT_FOR_SYNC,
                ListingStatus.BEING_UPDATED,
                ListingStatus.SYNC_IN_PROGRESS,
              ].includes(listingStore._status(item))
          )

          this.platformsStatus = {
            total: all.length,
            success: ok.length,
            warning: warning.length,
            error: error.length,
          }

          const google = listingStore.mainEstablishmentListing.find((diffuser) => diffuser.type === "GOOGLE")
          const facebook = listingStore.mainEstablishmentListing.find((diffuser) => diffuser.type === "FACEBOOK")
          const instagram = listingStore.mainEstablishmentListing.find((diffuser) => diffuser.type === "INSTAGRAM")

          const diffuserStore = useDiffuserStore()

          if (google) {
            this.sync.push({
              type: "GOOGLE",
              diffuser: charteDiffusers[AllDiffuser.GOOGLE],
              status: google.status,
              logo: null,
            })

            diffuserStore.connectedDiffusers.push({
              diffuser: AllDiffuser.GOOGLE,
              connected: this.isPlatformConnected(AllDiffuser.GOOGLE),
            })
          }
          if (facebook) {
            this.sync.push({
              type: "FACEBOOK",
              diffuser: charteDiffusers[AllDiffuser.FACEBOOK],
              status: facebook.status,
              logo: null,
            })

            diffuserStore.connectedDiffusers.push({
              diffuser: AllDiffuser.FACEBOOK,
              connected: this.isPlatformConnected(AllDiffuser.FACEBOOK),
            })
          }
          if (instagram) {
            this.sync.push({
              type: "INSTAGRAM",
              diffuser: charteDiffusers[AllDiffuser.INSTAGRAM],
              status: instagram.status,
              logo: null,
            })

            diffuserStore.connectedDiffusers.push({
              diffuser: AllDiffuser.INSTAGRAM,
              connected: this.isPlatformConnected(AllDiffuser.INSTAGRAM),
            })
          }

          this.isSyncLoading = false

          if (successCallback) {
            successCallback()
          }
        },
        () => {
          this.isSyncLoading = false
        }
      )
    },

    /**
     * Check if given platform is active
     * Return true if platform is in a filter (if filter is active) and if it's connected
     * @param code
     */
    isPlatformActive(code: string): boolean {
      if (code === AllDiffuser.GOOGLE_MAPS) {
        code = AllDiffuser.GOOGLE
      }

      let isInFilter = false
      if (this.currentPlatformFilter.code === code) {
        isInFilter = true
      }

      const isConnected = this.isPlatformConnected(code)

      return !((!isInFilter && this.currentPlatformFilter.code !== FilterPlatformCode.ALL) || !isConnected)
    },

    /**
     * Check if given platform is connected
     * @param code
     */
    isPlatformConnected(code: string): boolean {
      const diffuserSync = this.sync.find((syncItem: ISync) => syncItem.type === code)
      if (diffuserSync && diffuserSync.status === ListingStatus.ERROR_NOT_FOUND) {
        return false
      } else {
        return true
      }
    },

    /**
     * Tout est dans le nom :)
     * @returns
     */
    hasAtLeatOnePlateformConnected() {
      const connectedPlateform = this.sync.find(
        (syncItem: ISync) =>
          syncItem.status !== ListingStatus.ERROR_NOT_FOUND && syncItem.status !== ListingStatus.ERROR_NOT_CONNECTED
      )
      return connectedPlateform !== undefined
    },
  },

  persist: {
    enabled: true,
  },
})
