<template>
  <div
    class="rounded-sm gap-sm p-md w-full cursor-pointer"
    :class="charteNotifications[notification.type].container"
    @click="handleClick"
  >
    <img
      v-if="metadata.logo"
      class="mb-5 h-[50px] rounded-sm"
      :src="metadata.logo"
    />

    <div class="flex gap-md">
      <Icon
        :icon-name="charteNotifications[notification.type].icon"
        :class="charteNotifications[notification.type].iconColor"
      />
      <div class="flex flex-col gap-sm w-full">
        <div class="flex items-center justify-between w-full">
          <span>{{ $t("common.notifications." + metadata.title) }}</span>
          <Icon
            icon-name="IconX"
            class="cursor-pointer"
            @click.stop="deleteNotification"
          />
        </div>

        <!-- For review notifications -->
        <div
          v-if="isReviewNotification"
          class="flex flex-col gap-sm"
        >
          <div class="flex items-center gap-sm">
            <RoundedDiffuserIcon
              :size="24"
              :diffuser="charteDiffusers[reviewMetadata.directory]"
            />
            <div class="flex flex-col gap-xs">
              <span class="text-xs text-gray-600">{{ reviewMetadata.author }}</span>
              <Rating :rating="reviewMetadata.rating" />
            </div>
          </div>
          <span
            class="text-sm text-gray-500 line-clamp-2"
            v-html="reviewMetadata.content"
          ></span>
        </div>

        <!-- Général notifications -->
        <span
          v-else
          class="text-sm text-gray-500"
          v-html="metadata.content"
        ></span>
        <div class="flex items-center gap-xs">
          <Icon
            icon-name="IconClock"
            class="text-gray-700"
            :height="14"
            :width="14"
          />
          <span class="text-gray-700 text-xs">
            {{ timeSinceNotified }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import type { PropType } from "vue"
import { defineComponent } from "vue"
import { formatDistance } from "date-fns"
import fr from "date-fns/locale/fr"

// Layout
//

// Components
import Icon from "./Icon.vue"
import RoundedDiffuserIcon from "./RoundedDiffuserIcon.vue"

// Stores
import { useNotificationsStore } from "@/stores/notifications"
import { useEstablishmentStore } from "@/stores/establishment"
import { mapStores } from "pinia"

// Types
import type { INotification, IReviewMetadata } from "@/types/Notifications.types"
import { isReviewMetadata, isTicMetadata } from "@/types/Notifications.types"

// Data
import { charteDiffusers } from "@/static/Diffuser"
import Rating from "@/modules/reviews/components/Rating.vue"
import type { ITicMetadata } from "@/types/Notifications.types"
import { charteNotifications } from "@/static/Notification"

export default defineComponent({
  name: "NotificationItem",

  components: { Icon, RoundedDiffuserIcon, Rating },

  props: {
    notification: {
      type: Object as PropType<INotification>,
      required: true,
    },
  },

  emits: ["delete"],

  data() {
    return {
      charteDiffusers,
      charteNotifications,
      metadata: this.notification.data,
    }
  },

  computed: {
    ...mapStores(useNotificationsStore, useEstablishmentStore),
    timeSinceNotified() {
      // using date-fns util localized to translate distance
      // between now and when the comment was posted
      return formatDistance(new Date(), new Date(this.notification.created_at), {
        locale: fr,
      })
    },

    isReviewNotification() {
      return isReviewMetadata(this.notification)
    },

    reviewMetadata() {
      return this.metadata as IReviewMetadata
    },

    isTicNotification() {
      return isTicMetadata(this.notification)
    },

    ticMetadata() {
      return this.metadata as ITicMetadata
    },
  },

  methods: {
    deleteNotification() {
      this.notificationsStore.deleteNotification(this.notification.id)
    },

    async handleClick() {
      // set the current establishment depending of the establishment notification
      if (
        this.metadata.establishmentId !== undefined &&
        this.metadata.establishment_id !== null &&
        this.establishmentStore.currentEstablishment.id !== parseInt(this.metadata.establishmentId)
      ) {
        await this.establishmentStore.initEstablishment(this.metadata.establishmentId)
      }

      this.deleteNotification()

      if (this.metadata.pathName) {
        this.$router.push({ name: this.metadata.pathName })
      } else if (this.metadata.link) {
        window.document.location = this.metadata.link
      }
    },
  },
})
</script>
