import http from "@/http-common"

class DashboardDataService {
  getStatistics(
    establishmentId: number,
    startDate: String,
    endDate: String,
    code: string,
    platform: String,
    headers: {}
  ): Promise<any> {
    return http.post(
      `/auth/establishments/dashboard-stats`,
      {
        establishment_id: establishmentId,
        start_date: startDate,
        end_date: endDate,
        code: code,
        platform: platform,
      },
      { headers: headers }
    )
  }

  getCompletenessProfil(establishmentId: number, headers: {}): Promise<any> {
    return http.get(`/auth/establishments/profile-completeness/${establishmentId}`, { headers: headers })
  }
}

export default new DashboardDataService()
