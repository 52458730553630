import { FilterCode } from "@/static/Dashboard"
import { DisplayStat } from "@/types/Stats.types"
import moment from "moment"
import "moment/dist/locale/fr"
import _ from "lodash"

// moment.locale("fr")

export const displayStats = (chart, index: number, type: DisplayStat, callback?: (action: String) => void) => {
  if (index !== null) {
    switch (type) {
      case DisplayStat.HIDE:
        chart.hide(index)
        if (callback) {
          callback("hide")
        }
        break
      case DisplayStat.SHOW:
        chart.show(index)
        if (callback) {
          callback("hide")
        }
        break
      default:
        if (chart.isDatasetVisible(index)) {
          chart.hide(index)
          if (callback) {
            callback("hide")
          }
        } else {
          chart.show(index)
          if (callback) {
            callback("show")
          }
        }
        break
    }
  }
}

export const initShowingPreviousData = (chart, legends, showPreviousData: Boolean, legendItems) => {
  if (showPreviousData) {
    for (let index = 0; index < legends.length; index++) {
      // Prendre en compte les filtres sélectionnés
      let legendItem = legendItems.find((item) => item.label === legends[index].label)
      if (legends[index].label.includes("n-1")) {
        legendItem = legendItems.find((item) => item.label + " n-1" === legends[index].label)
      }

      if (!legendItem.hidden) {
        displayStats(chart, index, DisplayStat.SHOW)
      }
    }
  } else {
    const indexes = legends.map((item, i) => (item.label.includes("n-1") ? i : -1)).filter((index) => index !== -1)
    indexes.forEach((index) => {
      displayStats(chart, index, DisplayStat.HIDE)
    })
  }
}

export interface GenericDataItem {
  [key: string]: number | string // Les propriétés peuvent être des nombres ou des chaînes
  date: string
}

interface TransformedDataItem {
  startDate: string
  endDate: string
  [key: string]: number // Les propriétés peuvent être des nombres
}

interface DateRange {
  startDate: moment.Moment
  endDate: moment.Moment
}

export enum TransformationType {
  DAY,
  FORTNIGHT,
  MONTH,
}

export const getGraphUnit = (filter: FilterCode) => {
  switch (filter) {
    case FilterCode.LAST_6_MONTH:
      return "month"
    case FilterCode.LAST_YEAR:
      return "month"
    default:
      return "day"
  }
}

export const getTransformationType = (filter: FilterCode): TransformationType => {
  switch (filter) {
    case FilterCode.LAST_6_MONTH:
      return TransformationType.FORTNIGHT
    case FilterCode.LAST_YEAR:
      return TransformationType.MONTH
    default:
      return TransformationType.DAY
  }
}

export const generateTransformedData = (
  rawData: GenericDataItem[],
  transformation: TransformationType,
  useMedian = false,
  baseRanges: DateRange[] = []
): { data: TransformedDataItem[]; ranges: DateRange[] } => {
  moment.locale("fr")

  const transformedData: TransformedDataItem[] = []

  // TransformationType DAY
  if (transformation === TransformationType.DAY) {
    rawData.forEach((item) => {
      const currentDate = moment(item.date)
      transformedData.push({
        startDate: currentDate,
        endDate: currentDate,
        ...item,
      })
    })

    return { data: transformedData, ranges: [] }
  } else {
    // TransformationType FORTNIGHT pour 6 mois et MONTH pour 12 mois
    const firstDate = rawData[0].date
    const lastDate = rawData[rawData.length - 1].date

    let ranges = getMonthRanges(firstDate, lastDate, transformation)

    if (baseRanges.length - ranges.length > 0) {
      ranges = baseRanges
      ranges.map((range) => {
        return {
          startDate: range.startDate.subtract(1, "year"),
          endDate: range.endDate.subtract(1, "year"),
        }
      })
    }

    ranges.forEach((range) => {
      let sum: { [key: string]: number } = {}

      if (!useMedian) {
        sum = getCountBetweenDates(range, rawData)
      } else {
        const median = getMedianBetweenDates(range, rawData)

        sum = median !== undefined ? { count: median } : {}
      }

      // if (!_.isEmpty(sum)) {
      transformedData.push({
        startDate: range.startDate,
        endDate: range.endDate,
        ...sum,
      })
      // }
    })

    return { data: transformedData, ranges: ranges }
  }
}

const getMonthRanges = (startDate: Date, endDate: Date, transformation: TransformationType): DateRange[] => {
  const start = moment(startDate).startOf("month")
  const end = moment(endDate).endOf("month")
  const ranges: DateRange[] = []

  let current = start.clone()

  while (current.isSameOrBefore(end) && (current.month() !== end.month() || current.year() !== end.year())) {
    const endOfMonth = current.clone().endOf("month")
    const middleOfMonth = current.clone().date(15)

    if (transformation === TransformationType.FORTNIGHT) {
      // du 1er au 15 du mois
      ranges.push({
        startDate: current.clone(),
        endDate: middleOfMonth.clone().endOf("day"),
      })

      // du 16 au dernier jour du mois
      ranges.push({
        startDate: middleOfMonth.clone().add(1, "day").startOf("day"),
        endDate: endOfMonth.clone().endOf("day"),
      })
    } else {
      // du 1er au dernier jour du mois
      ranges.push({
        startDate: current.clone(),
        endDate: endOfMonth.clone().endOf("day"),
      })
    }

    current = endOfMonth.add(1, "day").startOf("month")
  }

  return ranges
}

const getCountBetweenDates = (dateRange: DateRange, rawData: GenericDataItem[]) => {
  const sum: { [key: string]: number } = {}

  rawData.forEach((item) => {
    if (moment(item.date).isBetween(dateRange.startDate, dateRange.endDate, undefined, "[]")) {
      Object.keys(item).forEach((key) => {
        if (key !== "date") {
          if (!sum[key]) {
            sum[key] = 0
          }

          sum[key] += item[key] as number
        }
      })
    }
  })

  return sum
}

const getMedianBetweenDates = (dateRange: DateRange, rawData: GenericDataItem[]) => {
  let values = []
  let median: number | undefined

  rawData.forEach((item) => {
    if (moment(item.date).isBetween(dateRange.startDate, dateRange.endDate, undefined, "[]")) {
      values.push(item.count)
    }
  })

  values = values.sort((a, b) => a - b)

  if (values.length) {
    const mid = Math.floor(values.length / 2)

    if (values.length % 2 === 0) {
      const midValues = values.slice(mid - 1, mid + 1)
      median = (midValues[0] + midValues[1]) / 2
    } else {
      median = values[mid]
    }
  }

  return median
}
