<template>
  <Dropdown
    type="icon"
    position-x="right"
    width="max"
  >
    <template #title>
      <Button color="white">
        <Icon icon-name="IconBell" />
        <NotificationBadge v-if="notificationsStore.notifications.length > 0">
          {{ notificationsStore.notifications.length }}
        </NotificationBadge>
      </Button>
    </template>

    <div
      v-if="notificationsStore.notifications.length > 0"
      class="p-md flex flex-col gap-md w-full md:w-[400px] max-h-[70vh] overflow-auto"
    >
      <TransitionGroup name="list">
        <template
          v-for="notification in notificationsStore.notifications"
          :key="notification.id"
        >
          <NotificationItem :notification="notification" />
        </template>
      </TransitionGroup>
    </div>
    <div
      v-else
      class="flex items-center justify-center p"
    >
      <span class="text-sm text-gray-400">Vous n'avez aucune notification</span>
    </div>
  </Dropdown>
</template>

<script lang="ts">
import Icon from "./Icon.vue"
import Dropdown from "./Dropdown.vue"
import Button from "./Button.vue"
import NotificationBadge from "./NotificationBadge.vue"
import NotificationItem from "./NotificationItem.vue"

import { useNotificationsStore } from "@/stores/notifications"
import { mapStores } from "pinia"

export default {
  name: "BellNotifications",

  components: {
    Icon,
    Dropdown,
    Button,
    NotificationBadge,
    NotificationItem,
  },

  props: {
    icon: String,

    color: {
      type: String,
      validators: (value: string) => ["primary", "gray", "error", "success", "warning", "light"].includes(value),
      default: "primary",
    },

    type: {
      type: String,
      validators: (value: string) => ["default", "outlined", "dashed"].includes(value),
      default: "default",
    },

    message: {
      type: String,
      default: null,
    },
  },

  computed: {
    ...mapStores(useNotificationsStore),
  },

  created() {
    this.notificationsStore.getNotifications()
  },
}
</script>
