<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.44662 5.42007C3.79696 4.52293 5.58036 4 7.5 4C9.16769 4 10.7326 4.39467 12 5.08651C13.2674 4.39467 14.8323 4 16.5 4C18.4207 4 20.2031 4.52296 21.5534 5.42007C21.8323 5.60541 22 5.91809 22 6.253V19.253C22 19.6215 21.7973 19.9602 21.4726 20.1343C21.1478 20.3084 20.7536 20.2899 20.4466 20.0859C19.4609 19.431 18.0733 19 16.5 19C14.9276 19 13.539 19.4311 12.5534 20.0859C12.2181 20.3087 11.7819 20.3087 11.4466 20.0859C10.461 19.4311 9.07236 19 7.5 19C5.92764 19 4.53904 19.4311 3.55338 20.0859C3.24644 20.2899 2.85221 20.3084 2.52744 20.1343C2.20268 19.9602 2 19.6215 2 19.253V6.253C2 5.91809 2.16766 5.60541 2.44662 5.42007ZM11 6.81949C10.0603 6.31667 8.84766 6 7.5 6C6.15234 6 4.93967 6.31667 4 6.81949V17.6261C5.0538 17.2225 6.24792 17 7.5 17C8.75208 17 9.9462 17.2225 11 17.6261V6.81949ZM13 17.6261C14.0538 17.2225 15.2479 17 16.5 17C17.7527 17 18.9465 17.2224 20 17.626V6.81947C19.0605 6.31664 17.8485 6 16.5 6C15.1523 6 13.9397 6.31667 13 6.81949V17.6261Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconBookOpen",
  props: ["height", "width"],
}
</script>
