<template>
  <div
    id="app-aside"
    class="bg-gray-900 p text-white"
    :class="mobileStore.menu && 'active'"
  >
    <div class="flex flex-col justify-between h-full">
      <div class="flex flex-col gap">
        <!-- Parameters dropdown -->
        <AuthenticatedUser
          class="relative"
          :blocked="blocked"
        />

        <!-- Establishments list -->
        <div
          v-if="!blocked"
          class="hidden md:block"
        >
          <Dropdown
            v-if="accountStore.establishments.length > 1"
            class="w-full bg-gray-800 rounded-sm p-sm text-center font-light"
            :outline="false"
          >
            <template #title>
              <div class="flex items-center gap-sm">
                <img
                  class="h-[24px] w-[24px] rounded-full object-fill"
                  :src="establishmentStore.getIcon(establishmentStore.currentEstablishment)"
                />
                <span class="line-clamp-2 p-xs">{{ establishmentStore.currentEstablishment.name }}</span>
              </div>
            </template>
            <div class="flex flex-col max-h-[70vh] overflow-y-auto">
              <DropdownItem
                v-for="establishment in accountStore.establishments"
                :key="establishment.id"
                :active="establishment.id === establishmentStore.currentEstablishment.id"
                @click="establishmentStore.changeCurrentEstablishment(establishment)"
              >
                <div class="flex items-center gap-sm">
                  <img
                    class="h-[24px] w-[24px] rounded-full object-fill"
                    :src="establishmentStore.getIcon(establishment)"
                  />
                  {{ establishment.name }}
                </div>
              </DropdownItem>
            </div>
          </Dropdown>
          <div
            v-else
            class="w-full bg-gray-800 rounded-sm p-sm text-center font-light"
          >
            {{ establishmentStore.currentEstablishment.name }}
          </div>
        </div>

        <!-- App menu -->
        <Menu v-if="!blocked"></Menu>
      </div>

      <LogoContainer
        :is-freemium="accountStore.isFreemium"
        :logo="localDiffuserStore.freemiumLocalDiffuserLogoUrl"
        :name="
          (establishmentStore.currentEstablishment.local_diffusers &&
            establishmentStore.currentEstablishment.local_diffusers.length) > 0
            ? establishmentStore.currentEstablishment.local_diffusers[0].label
            : ''
        "
      />

      <!-- Mobile close button -->
      <div
        id="mobile-close-menu"
        class="bg-gray-900 rounded-sm h-[55px] w-[55px] flex items-center justify-center"
        @click="mobileStore.menu = false"
      >
        <Icon
          icon-name="IconX"
          class="text-white"
          :height="40"
          :width="40"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import AuthenticatedUser from "@/modules/app/components/AuthenticatedUser.vue"
import Menu from "@/modules/app/components/Menu.vue"
import LogoContainer from "./LogoContainer.vue"
import Dropdown from "@/components/Dropdown.vue"
import DropdownItem from "@/components/DropdownItem.vue"

// Stores
import { useMobileStore } from "@/stores/mobile"
import { useAccountStore } from "@/stores/account"
import { useLocalDiffuserStore } from "@/stores/localDiffuser"
import { useEstablishmentStore } from "@/stores/establishment"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "Aside",

  components: {
    Icon,
    AuthenticatedUser,
    Menu,
    LogoContainer,
    Dropdown,
    DropdownItem,
  },

  props: {
    blocked: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapStores(useMobileStore, useEstablishmentStore, useAccountStore, useLocalDiffuserStore),
  },
})
</script>
