<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.2615 7.81538H8.73846L12.0615 10.7692L15.2615 7.81538Z" />
    <path d="M15.2615 16.1846L12.0615 13.2308L8.73846 16.1846H15.2615Z" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.2615 6.03077H9.07237C8.85031 6.03077 8.62879 6.0527 8.41104 6.09625C7.22289 6.33388 6.63074 7.67866 7.25769 8.71553L7.31819 8.8156C7.48477 9.09108 7.68824 9.34249 7.92295 9.56284L10.5846 12.0615L7.56923 14.8308L7.47333 14.9469C6.52391 16.0962 7.25342 17.839 8.73846 17.9692H15.3846L15.4984 17.9579C16.8226 17.8254 17.5128 16.315 16.7464 15.2272C16.6184 15.0455 16.4707 14.8787 16.3058 14.7297L13.3538 12.0615L16.4054 9.25412C16.4223 9.23858 16.4378 9.22162 16.4518 9.20343L16.6786 8.90861C17.583 7.73292 16.7448 6.03077 15.2615 6.03077ZM12.0615 10.7692L8.73846 7.81538H15.2615L12.0615 10.7692ZM12.0615 13.2308L15.2615 16.1846H8.73846L12.0615 13.2308Z"
    />
    <path
      d="M15.2615 7.81538H8.73846L12.0615 10.7692L15.2615 7.81538Z"
      stroke-width="0.271795"
    />
    <path
      d="M15.2615 16.1846L12.0615 13.2308L8.73846 16.1846H15.2615Z"
      stroke-width="0.271795"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.2615 6.03077H9.07237C8.85031 6.03077 8.62879 6.0527 8.41104 6.09625C7.22289 6.33388 6.63074 7.67866 7.25769 8.71553L7.31819 8.8156C7.48477 9.09108 7.68824 9.34249 7.92295 9.56284L10.5846 12.0615L7.56923 14.8308L7.47333 14.9469C6.52391 16.0962 7.25342 17.839 8.73846 17.9692H15.3846L15.4984 17.9579C16.8226 17.8254 17.5128 16.315 16.7464 15.2272C16.6184 15.0455 16.4707 14.8787 16.3058 14.7297L13.3538 12.0615L16.4054 9.25412C16.4223 9.23858 16.4378 9.22162 16.4518 9.20343L16.6786 8.90861C17.583 7.73292 16.7448 6.03077 15.2615 6.03077ZM12.0615 10.7692L8.73846 7.81538H15.2615L12.0615 10.7692ZM12.0615 13.2308L15.2615 16.1846H8.73846L12.0615 13.2308Z"
      stroke-width="0.271795"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconHourglass",
  props: ["height", "width"],
}
</script>
