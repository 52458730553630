<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.99739 18.098C12.4823 18.098 14.4966 16.0836 14.4966 13.5987C14.4966 11.1138 12.4823 9.09946 9.99739 9.09946C7.51253 9.09946 5.49814 11.1138 5.49814 13.5987C5.49814 16.0836 7.51253 18.098 9.99739 18.098ZM9.99739 19.998C13.5316 19.998 16.3966 17.1329 16.3966 13.5987C16.3966 10.0645 13.5316 7.19946 9.99739 7.19946C6.46318 7.19946 3.59814 10.0645 3.59814 13.5987C3.59814 17.1329 6.46318 19.998 9.99739 19.998Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.4473 4.39971C11.4473 3.87504 11.8726 3.44971 12.3973 3.44971H19.1965C19.7211 3.44971 20.1465 3.87504 20.1465 4.39971V11.3989C20.1465 11.9236 19.7211 12.3489 19.1965 12.3489C18.6718 12.3489 18.2465 11.9236 18.2465 11.3989V6.69321L15.0688 9.87089C14.6978 10.2419 14.0963 10.2419 13.7253 9.87089C13.3543 9.49989 13.3543 8.89839 13.7253 8.52739L16.903 5.34971H12.3973C11.8726 5.34971 11.4473 4.92438 11.4473 4.39971Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconGenderWoman",
  props: ["height", "width"],
}
</script>
