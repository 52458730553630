<template>
  <Tooltip
    variant-x="centerX"
    variant-mobile-x="centerX"
    variant-y="down"
    variant-mobile-y="down"
    size="tiny"
  >
    <template #main>
      <div
        v-if="diffuser"
        class="flex items-center justify-center rounded-full overflow-hidden p-xs"
        :class="[defaultSizeClass, diffuser.bgcolor]"
      >
        <img
          v-if="logo"
          :src="logo"
          :alt="diffuser.name ? diffuser.name : 'logo'"
          class="h-full w-full scale-125"
        />
        <img
          v-else
          :src="diffuser.icon"
          :height="size"
          :width="size"
          :alt="diffuser.name ? diffuser.name : 'logo'"
        />
      </div>
      <div
        v-else
        class="flex items-center justify-center rounded-full overflow-hidden bg-gray-200"
        :class="defaultSizeClass"
      >
        <Icon
          class="text-gray-100"
          icon-name="IconPhotograph"
        />
      </div>
    </template>
    <template #content>
      <span v-if="customTooltipContent">{{ customTooltipContent }}</span>
      <span v-else-if="diffuser">{{ diffuser.name ? diffuser.name : "logo" }}</span>
      <span v-else>unknown</span>
    </template>
  </Tooltip>
</template>

<script lang="ts">
import type { IDiffuser } from "@/types/Diffuser.types"
import { defineComponent, type PropType } from "vue"
import Tooltip from "./Tooltip.vue"
import Icon from "./Icon.vue"

export default defineComponent({
  name: "RoundedDiffuserIcon",

  components: { Tooltip, Icon },

  props: {
    diffuser: {
      type: Object as PropType<IDiffuser>,
      required: true,
    },

    size: {
      type: Number,
      default: 48,
    },

    logo: {
      type: String,
      default: null,
    },

    customTooltipContent: {
      type: String,
      default: "",
    },
  },

  computed: {
    defaultSizeClass() {
      return "w-[" + this.size + "px] h-[" + this.size + "px] " + "min-w-[" + this.size + "px]"
    },
  },
})
</script>
