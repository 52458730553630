<template>
  <div
    :tabindex="tabindex"
    class="custom-select"
    @blur="open = false"
  >
    <div
      class="selected flex items-center justify-between w-full rounded-sm cursor-pointer"
      :class="className"
      @click="open = !open"
    >
      <slot name="title"></slot>
      <Icon
        v-if="type === 'button'"
        id="down-icon"
        icon-name="IconArrowDown"
        class="no-event min-w-[24px]"
      />
    </div>
    <Transition name="dropdown">
      <div
        v-if="open"
        :class="[positionY, positionX, width]"
        class="items absolute mt-xs bg-white rounded-sm text-gray-700 p-sm flex flex-col gap-sm w-full"
      >
        <slot></slot>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

import Icon from "@/components/Icon.vue"

export default defineComponent({
  name: "Dropdown",

  components: { Icon },

  props: {
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },

    type: {
      type: String,
      validators: (value: string) => ["button", "icon"].includes(value),
      default: "button",
    },

    buttonColor: {
      type: String,
      validators: (value: string) => ["light", "gray"].includes(value),
      default: "gray",
    },

    bgColor: {
      type: String,
      default: "",
    },

    outline: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      validators: (value: string) => ["default", "sm"].includes(value),
      default: "default",
    },

    positionY: {
      type: String,
      validators: (value: string) => ["bottom", "top"].includes(value),
      default: "bottom",
    },

    positionX: {
      type: String,
      validators: (value: string) => ["left", "right"].includes(value),
      default: "left",
    },

    width: {
      type: String,
      validators: (value: string) => ["full", "fit", "max"].includes(value),
      default: "full",
    },
  },

  emits: ["input"],

  data() {
    return {
      open: false,
    }
  },

  computed: {
    color() {
      return this.buttonColor
    },

    className() {
      let className = this.type + " " + this.buttonColor + " " + this.size

      if (this.open) {
        className += " open"
      }

      if (this.outline) {
        className += " outlined"
      }

      if (this.bgColor) {
        className += " " + this.bgColor
      }

      return className
    },
  },
})
</script>
