<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    class="no-event"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.4 12C2.4 16.6392 6.6984 20.4 12 20.4C13.7039 20.4072 15.3845 20.0038 16.8996 19.224L21.6 20.4L19.9944 16.6524C21.0084 15.3204 21.6 13.7208 21.6 12C21.6 7.3608 17.3016 3.6 12 3.6C6.6984 3.6 2.4 7.3608 2.4 12ZM15.6 12C15.6 11.3373 16.1373 10.8 16.8 10.8V10.8C17.4627 10.8 18 11.3373 18 12V12C18 12.6627 17.4627 13.2 16.8 13.2V13.2C16.1373 13.2 15.6 12.6627 15.6 12V12V12ZM6 12C6 11.3373 6.53726 10.8 7.2 10.8V10.8C7.86274 10.8 8.4 11.3373 8.4 12V12C8.4 12.6627 7.86274 13.2 7.2 13.2V13.2C6.53726 13.2 6 12.6627 6 12V12ZM13.2 12C13.2 11.3373 12.6627 10.8 12 10.8V10.8C11.3373 10.8 10.8 11.3373 10.8 12V12C10.8 12.6627 11.3373 13.2 12 13.2V13.2C12.6627 13.2 13.2 12.6627 13.2 12V12Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconChat",
  props: ["height", "width"],
}
</script>
