<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.82275 18.2396C7.82275 17.7149 8.24808 17.2896 8.77275 17.2896H14.0881C14.6128 17.2896 15.0381 17.7149 15.0381 18.2396C15.0381 18.7642 14.6128 19.1896 14.0881 19.1896H8.77275C8.24808 19.1896 7.82275 18.7642 7.82275 18.2396Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.3333 13.7666C13.7817 13.7666 15.7666 11.7817 15.7666 9.33328C15.7666 6.88485 13.7817 4.9 11.3333 4.9C8.88485 4.9 6.9 6.88485 6.9 9.33328C6.9 11.7817 8.88485 13.7666 11.3333 13.7666ZM11.3333 15.6666C14.8311 15.6666 17.6666 12.8311 17.6666 9.33328C17.6666 5.83551 14.8311 3 11.3333 3C7.83551 3 5 5.83551 5 9.33328C5 12.8311 7.83551 15.6666 11.3333 15.6666Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.5311 14.5186C12.0557 14.5186 12.4811 14.9439 12.4811 15.4686V20.9999C12.4811 21.5246 12.0557 21.9499 11.5311 21.9499C11.0064 21.9499 10.5811 21.5246 10.5811 20.9999V15.4686C10.5811 14.9439 11.0064 14.5186 11.5311 14.5186Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconGenderMan",
  props: ["height", "width"],
}
</script>
