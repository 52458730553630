<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_343_71783)">
      <path
        d="M22 5H2V20H22V5Z"
        fill="url(#paint0_linear_343_71783)"
      />
      <path
        d="M22.0001 5H11.5239V20H22.0001V5Z"
        fill="url(#paint1_linear_343_71783)"
      />
      <path
        d="M8.66667 5H2V20H8.66667V5Z"
        fill="url(#paint2_linear_343_71783)"
      />
      <path
        d="M15.3332 5H8.6665V20H15.3332V5Z"
        fill="url(#paint3_linear_343_71783)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_343_71783"
        x1="12"
        y1="5"
        x2="12"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="#F0F0F0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_343_71783"
        x1="16.762"
        y1="5"
        x2="16.762"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F44653" />
        <stop
          offset="1"
          stop-color="#EE2A39"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_343_71783"
        x1="5.33333"
        y1="5"
        x2="5.33333"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#1035BB" />
        <stop
          offset="1"
          stop-color="#042396"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_343_71783"
        x1="11.9998"
        y1="5"
        x2="11.9998"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="#F0F0F0"
        />
      </linearGradient>
      <clipPath id="clip0_343_71783">
        <rect
          x="2"
          y="5"
          width="20"
          height="15"
          rx="2"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconFlagFr",
  props: ["height", "width"],
}
</script>
