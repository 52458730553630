import { i18n } from "@/i18n"
import DiffuserDataService from "@/services/DiffuserDataService"
import { AlertType } from "@/static/Alert"
import { AllDiffuser } from "@/static/Diffuser"
import { listingStateStyle } from "@/static/Establishment"
import {
  ListingClaimStatus,
  ListingConnectStatus,
  ListingFacebookStatus,
  ListingGoogleStatus,
  ListingStatus,
  ListingSyncStatus,
} from "@/static/Listing"
import type { IListing, ILocalListing } from "@/types/Listing.types"
import { defineStore } from "pinia"
import { useAlertStore } from "./alert"
import { useAuthenticationStore } from "./authentication"
import { useEstablishmentStore } from "./establishment"
import { LocalDiffuserConnexionState } from "@/static/LocalDiffuser"
import LocalDiffuserDataService from "@/services/LocalDiffuserDataService"
import { TicStatus } from "@/static/TouristicInformationCenter"
import { useAccountStore } from "./account"
import { useDashboardStore } from "./dashboard"

export const useListingStore = defineStore("listing", {
  state: () => ({
    /**
     * All diffuser to set in mainEstablishmentListing
     * @type {String[]}
     */
    mainListing: ["GOOGLE", "FACEBOOK", "TRIP_ADVISOR", "TRUST_PILOT", "GOOGLE_MAPS", "WAZE", "INSTAGRAM"],

    /**
     * list of all main listing for the current establishment
     * @type {IListing[]}
     */
    mainEstablishmentListing: [] as IListing[],

    /**
     * list of all secondary listing for the current establishment
     * @type {IListing[]}
     */
    secondaryEstablishmentListing: [] as IListing[],

    /**
     * list of all local diffusers
     * @type {ILocalListing[]}
     */
    localListing: [] as ILocalListing[],

    listingTotalCount: 0,
  }),

  actions: {
    initListing() {
      this.mainEstablishmentListing = [] as IListing[]
      this.secondaryEstablishmentListing = [] as IListing[]
      this.localListing = [] as ILocalListing[]
    },

    /**
     * Get all diffuser listing
     * @param successCallback
     */
    allListing(successCallback?: () => void) {
      const establishmentStore = useEstablishmentStore()

      let id: string | undefined
      if (establishmentStore.currentEstablishment.id === undefined) {
        id = undefined
      } else {
        id = establishmentStore.currentEstablishment.id!.toString()
      }

      this.mainEstablishmentListing = []
      this.secondaryEstablishmentListing = []

      establishmentStore.initEstablishment(id, () => {
        this.getLocalDiffusers(establishmentStore.currentEstablishment.id)
        this.getDiffusers(establishmentStore.currentEstablishment.id, successCallback ? successCallback : () => {})
      })
    },

    getDiffusers(establishmentId: number, successCallback: () => void, errorCallback?: () => void) {
      const alertStore = useAlertStore()
      const authStore = useAuthenticationStore()

      this.mainEstablishmentListing = [] as IListing[]

      DiffuserDataService.getEstablishmentStatus(establishmentId, authStore.currentHeaders).then(
        (res) => {
          if (res.data.listings) {
            if (res.data.listings.length === 0) {
              if (successCallback) {
                successCallback()
              }
            } else {
              this.mainEstablishmentListing = []
              this.secondaryEstablishmentListing = []
              const formattedListings = this._formatListing(res.data.listings)
              this.listingTotalCount = formattedListings.length
              this._filterListing(formattedListings)
              this._sortListing(this.mainEstablishmentListing)
              this._sortListing(this.secondaryEstablishmentListing)
              if (successCallback) {
                successCallback()
              }
            }
          } else {
            if (successCallback) {
              successCallback()
            }
          }

          // else {
          //   alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), "Aucune plateforme trouvée")
          // }
        },
        (e) => {
          const dashboardStore = useDashboardStore()

          switch (e.response.status) {
            case 401:
              authStore.authenticationError(e.response.code, () => {
                alertStore.push(AlertType.error, i18n.global.t("common.errors.user_access"))
              })
              break
            case 404:
              if (dashboardStore.reloaded === 0) {
                dashboardStore.reloaded += 1
                window.location.reload()
              } else {
                if (errorCallback) {
                  errorCallback()
                }
              }
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    getLocalDiffusers(establishmentId: number, successCallback?: (localDiffusers: ILocalListing[]) => void) {
      if (establishmentId) {
        const establishmentStore = useEstablishmentStore()

        const localDiffusers: ILocalListing[] = establishmentStore.currentEstablishment.local_diffusers

        if (localDiffusers.length > 0) {
          this.localListing = localDiffusers
        } else {
          // non géré pour le moment. Concerne la connexion au diffuseur local en premium
          // const tourismInformationCenterStore = useTourismInformationCenterStore()
          // tourismInformationCenterStore.getByEstablishment(establishmentId, (ot) => {
          //   this.localListing.push({
          //     id: ot.id,
          //     connected_at: null,
          //     created_at: ot.created_at,
          //     establishment_id: establishmentId,
          //     label: ot.name,
          //     state: ListingStatus.ERROR_NOT_FOUND,
          //     type: LocalDiffuserConnexionType.TIC,
          //     logo: charteDiffusers[AllDiffuser[LocalDiffuserConnexionType.TIC]].icon!,
          //   } as ILocalListing)
          // })
        }

        this.localListing = [...new Set(this.localListing)]

        if (successCallback) {
          successCallback(this.localListing)
        }
      }
    },

    getLocalDiffusersStatus(establishmentId: number, successCallback: (state: TicStatus) => void) {
      LocalDiffuserDataService.getLocalDiffuserStatus(establishmentId).then(
        (res) => {
          const establishmentLocalDifuserState = res.data.data.state as TicStatus
          successCallback(establishmentLocalDifuserState)
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    /**
     * Filter all listing in two categories
     * @param listing
     */
    _filterListing(listing: IListing[]) {
      listing.forEach((item) => {
        if (this.mainListing.includes(item.type)) {
          this.mainEstablishmentListing.push(item)
        } else {
          this.secondaryEstablishmentListing.push(item)
        }
      })
    },

    /**
     * For all listing items :
     * Get facebook and google status
     * Get item status
     * @param array
     * @returns
     */
    _formatListing(array: IListing[]): IListing[] {
      const self = this
      return array.map(function (listing: IListing) {
        listing.status = self._status(listing)
        return listing
      })
    },

    /**
     * Sort listing by custom order
     * @param array
     * @returns
     */
    _sortListing(array: IListing[]): IListing[] {
      const statusExceptionDiffusers = [
        "ALEXA",
        "BONIAL_FR",
        "CBANQUE",
        "FOURSQUARE",
        "NEXT_DOOR",
        "NOKIA_HERE",
        "ORANGE_118_712",
        "SHOPPING_TIME_NETWORK",
        "TOMTOM",
        "TRIP_ADVISOR",
        "UBER",
        "GM",
        "BMW",
        "BAIDU",
        "VW",
        "FIAT",
        "FORD",
        "AUDI",
        "TOYOTA",
      ]

      // Suite aux explication d’uberall, pour plusieurs diffuseurs il est en effet impossible à vérifier si le listing a été publié ou non
      // Ils peuvent uniquement savoir si eux ont bien envoyé l’info
      // Pour cette liste de diffuseurs, le sync statut restera donc en permanence en NO_ONLINE_STATUS
      // Pour ne pas qu’ils restent en orange en permanence, il faudrait donc automatiquement les passer en “Informations mise à jour”, dès qu’on a le sync statut NO_ONLINE_STATUS
      array.map((item) => {
        if (statusExceptionDiffusers.includes(item.type) && item.status === ListingStatus.SUBMITTED) {
          item.status = ListingStatus.SYNCHRONIZED
        }
        return item
      })

      const order = {
        [ListingStatus.ERROR_CLAIMED_BY_OTHERS]: 1,
        [ListingStatus.ERROR_FIELDS]: 2,
        [ListingStatus.ERROR_NOT_CONNECTED]: 3,
        [ListingStatus.ERROR_NOT_FOUND]: 4,
        [ListingStatus.ERROR_CLAIMED_BY_ANOTHER_US]: 5,
        [ListingStatus.ERROR]: 6,

        [ListingStatus.SYNCHRONIZED]: 7,

        [ListingStatus.BEING_UPDATED]: 8,
        [ListingStatus.SUBMITTED]: 9,

        [ListingStatus.WAIT_FOR_SYNC]: 10,
      }

      array.sort((a, b) => order[a.status] - order[b.status] || a.typeName.localeCompare(b.typeName))

      return array
    },

    /**
     * Calculate the current diffuser item status
     */
    _status(item: IListing): ListingStatus {
      item.googleStatus = this._googleStatus(item)
      item.facebookStatus = this._facebookStatus(item)

      const establishmentStore = useEstablishmentStore()

      if (item.isStatusForced) {
        if (item.syncStatus === ListingSyncStatus.SYNC_IN_PROGRESS) return ListingStatus.SYNC_IN_PROGRESS
        else if (item.syncStatus === ListingSyncStatus.IN_SYNC) return ListingStatus.SYNCHRONIZED
        else if (item.syncStatus === ListingSyncStatus.NOT_IN_SYNC) return ListingStatus.BEING_UPDATED
        else return ListingStatus.ERROR
      }
      //
      else if (
        (item.googleStatus && item.googleStatus !== ListingGoogleStatus.VERIFIED) ||
        (item.facebookStatus && item.facebookStatus !== ListingFacebookStatus.FOUNDED)
      ) {
        return ListingStatus.ERROR_NOT_FOUND
      }
      //
      else if (item.claimStatus === ListingClaimStatus.CLAIMED_BY_OTHERS) {
        return ListingStatus.ERROR_CLAIMED_BY_OTHERS
      }
      //
      else if (item.claimStatus === ListingClaimStatus.CLAIMED_BY_ANOTHER_US) {
        return ListingStatus.ERROR_CLAIMED_BY_ANOTHER_US
      }
      //
      else if (item.mandatoryFields.length > 1) {
        return ListingStatus.ERROR_FIELDS
      }
      //
      else if (item.connectStatus === ListingConnectStatus.NOT_CONNECTED) {
        return ListingStatus.ERROR_NOT_CONNECTED
      }
      //
      else if (establishmentStore.currentEstablishment.last_sync === null) {
        return ListingStatus.WAIT_FOR_SYNC
      }
      //
      else if (item.syncStatus === ListingSyncStatus.IN_SYNC) {
        return ListingStatus.SYNCHRONIZED
      }
      //
      else if (item.syncStatus === ListingSyncStatus.NO_ONLINE_LISTING) {
        return ListingStatus.SUBMITTED
      }
      //
      else if (
        item.syncStatus === ListingSyncStatus.NOT_IN_SYNC ||
        (item.claimStatus === ListingClaimStatus.UNKNOWN && item.syncStatus === ListingSyncStatus.NOT_FOUND) ||
        (item.claimStatus === ListingClaimStatus.NOT_CLAIMABLE && item.syncStatus === ListingSyncStatus.NOT_FOUND)
      ) {
        return ListingStatus.BEING_UPDATED
      }
      //
      else {
        return ListingStatus.ERROR
      }
    },

    /**
     * Return error message diffuser
     * @param item
     * @returns
     */
    errorMessage(item: IListing): String {
      if (item.googleStatus && item.googleStatus !== ListingGoogleStatus.VERIFIED) {
        return i18n.global.t("establishments/listing.errors.GOOGLE_" + item.googleStatus)
      } else if (item.facebookStatus && item.facebookStatus !== ListingFacebookStatus.FOUNDED) {
        return i18n.global.t("establishments/listing.errors.FACEBOOK_" + item.facebookStatus)
      } else {
        return i18n.global.t("establishments/listing.errors." + item.status)
      }
    },

    /**
     * Get the google connected status
     * @param item
     * @returns
     */
    _googleStatus(item: IListing): ListingGoogleStatus | null {
      if (item.type === AllDiffuser.GOOGLE || item.type === AllDiffuser.GOOGLE_SEARCH) {
        if (item.connectStatus === ListingConnectStatus.NOT_CONNECTED) {
          if (item.claimStatus === ListingClaimStatus.UNKNOWN) {
            return ListingGoogleStatus.NOT_CONNECTED
          } else {
            return ListingGoogleStatus.ERROR
          }
        } else {
          switch (item.claimStatus) {
            case ListingClaimStatus.UNKNOWN:
              return ListingGoogleStatus.NOT_FOUND

            case ListingClaimStatus.CLAIMABLE:
              return ListingGoogleStatus.NOT_VERIFIED

            case ListingClaimStatus.CLAIMING_IN_PROGRESS:
              return ListingGoogleStatus.IN_VERIFICATION

            case ListingClaimStatus.CLAIMED_BY_US:
              return ListingGoogleStatus.VERIFIED

            case ListingClaimStatus.CLAIMED_BY_OTHERS:
              return ListingGoogleStatus.NOT_CONNECTED_VERIFIED

            default:
              return ListingGoogleStatus.ERROR
          }
        }
      } else {
        return null
      }
    },

    /**
     * Get the facebook connected status
     * @param item
     * @returns
     */
    _facebookStatus(item: IListing): ListingFacebookStatus | null {
      if (item.type === AllDiffuser.FACEBOOK) {
        //
        if (
          item.connectStatus === ListingConnectStatus.NOT_CONNECTED &&
          item.claimStatus !== ListingClaimStatus.CLAIMED_BY_US
        ) {
          return ListingFacebookStatus.NOT_CONNECTED
        }
        //
        else if (
          item.connectStatus === ListingConnectStatus.CONNECTED &&
          item.claimStatus !== ListingClaimStatus.CLAIMED_BY_US
        ) {
          return ListingFacebookStatus.NOT_FOUND
        }
        //
        else if (
          item.connectStatus === ListingConnectStatus.CONNECTED &&
          item.claimStatus === ListingClaimStatus.CLAIMED_BY_US
        ) {
          return ListingFacebookStatus.FOUNDED
        }
        //
        else {
          return ListingFacebookStatus.ERROR
        }
      } else {
        return null
      }
    },

    /**
     * Get the right style from current status
     * @param status
     * @returns
     */
    style(status: ListingStatus) {
      switch (status) {
        case ListingStatus.SYNCHRONIZED:
          return listingStateStyle.successStyle
        case ListingStatus.SUBMITTED:
          return listingStateStyle.warningStyle
        case ListingStatus.BEING_UPDATED:
          return listingStateStyle.warningStyle
        case ListingStatus.ERROR_CLAIMED_BY_OTHERS:
          return listingStateStyle.errorStyle
        case ListingStatus.ERROR_FIELDS:
          return listingStateStyle.errorStyle
        case ListingStatus.ERROR_NOT_CONNECTED:
          return listingStateStyle.errorStyle
        case ListingStatus.ERROR_NOT_FOUND:
          return listingStateStyle.errorStyle
        case ListingStatus.WAIT_FOR_SYNC:
          return listingStateStyle.pauseStyle
        case ListingStatus.PENDING_CONNECTION:
          return listingStateStyle.warningStyle
        case ListingStatus.SYNC_IN_PROGRESS:
          return listingStateStyle.syncInProgressStyle
        default:
          return listingStateStyle.errorStyle
      }
    },

    localListingStyle(state: TicStatus | LocalDiffuserConnexionState) {
      if (
        [
          TicStatus.ANSWERED,
          TicStatus.SENT_APIDAE,
          TicStatus.SENT_TOURINSOFT,
          TicStatus.TREATED,
          LocalDiffuserConnexionState.CONNECTED,
        ].includes(state)
      ) {
        return listingStateStyle.successStyle
      } else if (
        [
          TicStatus.SAVING,
          TicStatus.SAVING_DRAFT,
          TicStatus.DRAFT,
          LocalDiffuserConnexionState.PENDING_CONNECTION,
        ].includes(state)
      ) {
        return listingStateStyle.warningStyle
      } else if ([TicStatus.NO_FORM, LocalDiffuserConnexionState.IGNORED].includes(state)) {
        return listingStateStyle.pauseStyle
      } else {
        return listingStateStyle.errorStyle
      }
    },

    getListingConnexionStatus(listing: string) {
      const foundListing = this.mainEstablishmentListing.find((_listing) => _listing.type === listing)

      return foundListing !== undefined ? foundListing.connectStatus : "NOT_FOUND"
    },
  },
})
