import type { IMenuItem } from "@/types/Menu.types"

export const reviewMenuItem: IMenuItem = {
  icon: "IconAnnotation",
  code: "reviews",
  to: "review-management",
  notification: 0,
  hash: ''
}

export const publicationMenuItem: IMenuItem = {
  icon: "IconPencilAlt",
  code: "publications",
  to: "publication-management",
  notification: 0,
  hash: ''
}

export const informationMenuItem: IMenuItem = {
  icon: "IconLibrary",
  code: "establishments",
  to: "establishment",
  notification: 0,
  hash: ''
}

export const analyseMenuItem: IMenuItem = {
  icon: "IconPresentationChartBar",
  code: "analyse",
  to: "analyse",
  notification: 0,
  hash: ''
}

export const solicitationsMenuItem: IMenuItem = {
  icon: "IconClipboardList",
  code: "solicitations",
  to: "solicitations",
  notification: 0,
  hash: '#0'
}

export const dashboardMenuItem: IMenuItem = {
  icon: "IconHome",
  code: "dashboard",
  to: "home",
  notification: 0,
  hash: ''
}
