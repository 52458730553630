import {
  codePostalRegex,
  passwordRegex,
  facebookRegex,
  pinterestRegex,
  instagramRegex,
  linkedinRegex,
  youtubeRegex,
  twitterRegex,
} from "@/static/RegexType"
import { helpers } from "@vuelidate/validators"
import { i18n } from "@/i18n"
import { parsePhoneNumber } from "awesome-phonenumber"

const postalCodeRegexValidation = helpers.regex(codePostalRegex)
const passwordRegexValidation = helpers.regex(passwordRegex)

export const mustBePhone = (regionCode: string) => (value: string) => {
  if (value) {
    return parsePhoneNumber(value, { regionCode: regionCode }).valid
  } else {
    return true
  }
}

export enum ValidatorPlatform {
  FACEBOOK,
  PINTEREST,
  LINKEDIN,
  YOUTUBE,
  INSTAGRAM,
  TWITTER,
}

export const mustBePlatform = (platform: ValidatorPlatform) => {
  switch (platform) {
    case ValidatorPlatform.FACEBOOK:
      return helpers.regex(facebookRegex)
    case ValidatorPlatform.PINTEREST:
      return helpers.regex(pinterestRegex)
    case ValidatorPlatform.LINKEDIN:
      return helpers.regex(linkedinRegex)
    case ValidatorPlatform.YOUTUBE:
      return helpers.regex(youtubeRegex)
    case ValidatorPlatform.INSTAGRAM:
      return helpers.regex(instagramRegex)
    case ValidatorPlatform.TWITTER:
      return helpers.regex(twitterRegex)
    default:
      break
  }
}

export const mustNotContainEmojis = () => {
  return helpers.regex(/^[^\u200D]*$/g)
}

export const postalCode = helpers.withMessage(i18n.global.t("validations.postal_code"), postalCodeRegexValidation)
export const password = helpers.withMessage(i18n.global.t("validations.password"), passwordRegexValidation)
