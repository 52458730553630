<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.87879 4.87868C3.4414 4.31607 4.20446 4 5.00011 4H19.0001C19.7958 4 20.5588 4.31607 21.1214 4.87868C21.684 5.44129 22.0001 6.20435 22.0001 7V7.98448C22.0002 7.99422 22.0002 8.00395 22.0001 8.01368V17C22.0001 17.7957 21.684 18.5587 21.1214 19.1213C20.5588 19.6839 19.7958 20 19.0001 20H5.00011C4.20446 20 3.44139 19.6839 2.87879 19.1213C2.31618 18.5587 2.00011 17.7956 2.00011 17V8.01367C1.99996 8.00395 1.99996 7.99422 2.00011 7.98449V7C2.00011 6.20435 2.31618 5.44129 2.87879 4.87868ZM4.00011 9.86852V17C4.00011 17.2652 4.10546 17.5196 4.293 17.7071C4.48054 17.8946 4.73489 18 5.00011 18H19.0001C19.2653 18 19.5197 17.8946 19.7072 17.7071C19.8948 17.5196 20.0001 17.2652 20.0001 17V9.86852L13.6651 14.0919C13.665 14.0919 13.6649 14.092 13.6648 14.0921C13.1719 14.4208 12.5926 14.5963 12.0001 14.5963C11.4076 14.5963 10.8283 14.4208 10.3354 14.0921C10.3353 14.092 10.3352 14.0919 10.3351 14.0919L4.00011 9.86852ZM20.0001 7.46482L12.5554 12.4279L12.5551 12.4282C12.3908 12.5378 12.1977 12.5963 12.0001 12.5963C11.8026 12.5963 11.6094 12.5378 11.4451 12.4282L11.4448 12.4279L4.00011 7.46482V7C4.00011 6.73478 4.10546 6.48043 4.293 6.29289C4.48054 6.10536 4.73489 6 5.00011 6H19.0001C19.2653 6 19.5197 6.10536 19.7072 6.29289C19.8948 6.48043 20.0001 6.73478 20.0001 7V7.46482Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconMail",
  props: ["height", "width"],
}
</script>
