<template>
  <div class="flex flex-col gap-xs border border-gray-100 p-md rounded-sm w-full">
    <div class="flex items-center gap-sm">
      <Icon
        :icon-name="icon"
        class="text-gray-700"
      />
      <span class="text-lg text-gray-700">{{ title }}</span>
    </div>
    <slot></slot>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "GlobalStatisticModalItem",

  components: { Icon },

  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
})
</script>
