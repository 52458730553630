<template>
  <div
    id="confirm"
    class="confirm flex flex-col gap"
  >
    <div class="flex flex-col gap-sm">
      <span
        id="confirmTitle"
        class="text-base text-gray-900"
      ></span>
      <span
        id="confirmMessage"
        class="text-sm text-gray-700"
      ></span>
    </div>
    <div class="flex items-center gap-sm justify-end">
      <Button
        id="confirmNo"
        color="gray"
        :outline="true"
      >
        Annuler
      </Button>
      <Button
        id="confirmYes"
        color="primary"
      >
        Continuer
      </Button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import Button from "@/components/Button.vue"

export default defineComponent({
  name: "Confirm",

  components: {
    Button,
  },
})
</script>
