// Les labels et les background colors doivent être dans le même ordr
export const labels = ["responded", "not_responded"]

export const reviewConfig = {
  label: "Taux de réponses",
  backgroundColor: ["#71DABD", "#EF4444"],
}

export const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
}
