<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_662_76928)">
      <path
        d="M22 4H2V19H22V4Z"
        fill="url(#paint0_linear_662_76928)"
      />
      <path
        d="M21.9983 4H1.99826V19H21.9983V4Z"
        fill="url(#paint1_linear_662_76928)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.76513 14H1.99817V8.99999H6.76513L0.0169983 4.22074L1.08212 2.56266L9.61722 8.60751V2.99999H14.3791V8.60751L22.9142 2.56266L23.9793 4.22074L17.2312 8.99999H21.9982V14H17.2312L23.9793 18.7793L22.9142 20.4373L14.3791 14.3925V20H9.61722V14.3925L1.08212 20.4373L0.0169983 18.7793L6.76513 14Z"
        fill="url(#paint2_linear_662_76928)"
      />
      <path
        d="M15.4633 8.958L24.51 2.70885C24.6198 2.63297 24.6503 2.47794 24.578 2.3626C24.5057 2.24725 24.3581 2.21526 24.2483 2.29114L15.2016 8.54029C15.0917 8.61617 15.0612 8.7712 15.1335 8.88654C15.2058 9.00189 15.3534 9.03388 15.4633 8.958Z"
        fill="#DB1F35"
      />
      <path
        d="M16.16 14.4804L24.2699 20.2201C24.379 20.2972 24.5269 20.267 24.6004 20.1525C24.6739 20.038 24.6451 19.8826 24.536 19.8054L16.4261 14.0658C16.3171 13.9886 16.1691 14.0189 16.0956 14.1334C16.0221 14.2479 16.051 14.4033 16.16 14.4804Z"
        fill="#DB1F35"
      />
      <path
        d="M7.84952 8.52553L-0.609772 2.53945C-0.718819 2.46228 -0.866791 2.49255 -0.940276 2.60704C-1.01377 2.72154 -0.984943 2.87691 -0.875905 2.95407L7.5834 8.94016C7.69244 9.01732 7.84041 8.98706 7.9139 8.87256C7.98739 8.75806 7.95857 8.60269 7.84952 8.52553Z"
        fill="#DB1F35"
      />
      <path
        d="M8.50238 13.9953L-0.874427 20.5245C-0.984008 20.6008 -1.01394 20.7559 -0.941265 20.871C-0.868589 20.9861 -0.720836 21.0175 -0.611246 20.9412L8.76555 14.412C8.87514 14.3357 8.90507 14.1806 8.83239 14.0655C8.75973 13.9504 8.61196 13.919 8.50238 13.9953Z"
        fill="#DB1F35"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1.99826 13H10.5697V19H13.4269V13H21.9983V10H13.4269V4H10.5697V10H1.99826V13Z"
        fill="url(#paint3_linear_662_76928)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_662_76928"
        x1="12"
        y1="4"
        x2="12"
        y2="19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="#F0F0F0"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear_662_76928"
        x1="11.9983"
        y1="4"
        x2="11.9983"
        y2="19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0A17A7" />
        <stop
          offset="1"
          stop-color="#030E88"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear_662_76928"
        x1="11.9982"
        y1="2.56266"
        x2="11.9982"
        y2="20.4373"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop
          offset="1"
          stop-color="#F0F0F0"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear_662_76928"
        x1="11.9983"
        y1="4"
        x2="11.9983"
        y2="19"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E6273E" />
        <stop
          offset="1"
          stop-color="#CF152B"
        />
      </linearGradient>
      <clipPath id="clip0_662_76928">
        <rect
          x="2"
          y="4"
          width="20"
          height="15"
          rx="2"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconFlagEn",
  props: ["height", "width"],
}
</script>
