import colorLib from "@kurkle/color"

export const previousManConfig = {
  label: "male n-1",
  icon: "IconGenderMan",
  backgroundColor: colorLib("#FACF7B").alpha(0.4).rgbString(),
  legendColor: "#FACF7B",
  stack: "Stack -1",
  borderRadius: 4,
  barThickness: 8,
}

export const manConfig = {
  label: "male",
  icon: "IconGenderMan",
  backgroundColor: "#FACF7B",
  legendColor: "#FACF7B",
  stack: "Stack 0",
  borderRadius: 4,
  // barThickness: 12,
}

export const previousWomanConfig = {
  label: "female n-1",
  icon: "IconGenderWoman",
  backgroundColor: colorLib("#5AB7CB").alpha(0.4).rgbString(),
  legendColor: "#5AB7CB",
  stack: "Stack -1",
  borderRadius: 4,
  barThickness: 8,
}

export const womanConfig = {
  label: "female",
  icon: "IconGenderWoman",
  backgroundColor: "#5AB7CB",
  legendColor: "#5AB7CB",
  stack: "Stack 0",
  borderRadius: 4,
  // barThickness: 12,
}

export const legends = [previousManConfig, manConfig, previousWomanConfig, womanConfig]

export const options = {
  responsive: true,
  maintainAspectRatio: false,
  animation: false,
  scales: {
    x: {
      stacked: true,
      grid: {
        display: false,
      },
      display: true,
      offsetAfterAutoskip: true,
      offset: true,
      ticks: {
        source: "data",
      },
      title: {
        display: false,
      },
    },
    y: {
      stacked: true,
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}
