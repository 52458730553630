<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4C22 4.55228 21.5523 5 21 5V16C21 16.5304 20.7893 17.0391 20.4142 17.4142C20.0391 17.7893 19.5304 18 19 18H14.4142L16.7071 20.2929C17.0976 20.6834 17.0976 21.3166 16.7071 21.7071C16.3166 22.0976 15.6834 22.0976 15.2929 21.7071L12 18.4142L8.70711 21.7071C8.31658 22.0976 7.68342 22.0976 7.29289 21.7071C6.90237 21.3166 6.90237 20.6834 7.29289 20.2929L9.58579 18H5C4.46957 18 3.96086 17.7893 3.58579 17.4142C3.21071 17.0391 3 16.5304 3 16V5C2.44772 5 2 4.55228 2 4ZM5 5V16H11.9993C11.9998 16 12.0002 16 12.0007 16H19V5H5ZM16 7C16.5523 7 17 7.44772 17 8V13C17 13.5523 16.5523 14 16 14C15.4477 14 15 13.5523 15 13V8C15 7.44772 15.4477 7 16 7ZM12 9C12.5523 9 13 9.44772 13 10V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V10C11 9.44772 11.4477 9 12 9ZM8 11C8.55228 11 9 11.4477 9 12V13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13V12C7 11.4477 7.44772 11 8 11Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPresentationChartBar",
  props: ["height", "width"],
}
</script>
