<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0004 21.6C14.5465 21.6 16.9883 20.5886 18.7886 18.7882C20.589 16.9879 21.6004 14.5461 21.6004 12C21.6004 9.45395 20.589 7.01215 18.7886 5.2118C16.9883 3.41145 14.5465 2.40002 12.0004 2.40002C9.45431 2.40002 7.01252 3.41145 5.21217 5.2118C3.41182 7.01215 2.40039 9.45395 2.40039 12C2.40039 14.5461 3.41182 16.9879 5.21217 18.7882C7.01252 20.5886 9.45431 21.6 12.0004 21.6ZM16.4488 10.4484C16.6674 10.2221 16.7883 9.91898 16.7856 9.60434C16.7829 9.28971 16.6567 8.98873 16.4342 8.76624C16.2117 8.54375 15.9107 8.41755 15.5961 8.41482C15.2814 8.41208 14.9783 8.53304 14.752 8.75163L10.8004 12.7032L9.24879 11.1516C9.02247 10.933 8.71935 10.8121 8.40471 10.8148C8.09007 10.8176 7.7891 10.9438 7.56661 11.1662C7.34412 11.3887 7.21792 11.6897 7.21518 12.0043C7.21245 12.319 7.3334 12.6221 7.55199 12.8484L9.95199 15.2484C10.177 15.4734 10.4822 15.5998 10.8004 15.5998C11.1186 15.5998 11.4238 15.4734 11.6488 15.2484L16.4488 10.4484Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCheckCircle",
  props: ["height", "width"],
}
</script>
