import http from "@/http-common"

class TourismInformationCenterDataService {
  getTourismInformationCenterByEstablishment(establishmentId: number): Promise<any> {
    return http.get(`/auth/tourism-information-center/${establishmentId}`)
  }

  getLogo(contactToken: string): Promise<any> {
    return http.get(`/auth/tourism-information-center/logo/${contactToken}`)
  }
}

export default new TourismInformationCenterDataService()
