<template>
  <span
    class="notification z-10 text-white"
    :class="[variant, size]"
  >
    <slot></slot>
  </span>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "NotificationBadge",

  props: {
    variant: {
      type: String,
      validators: (value: string) => ["warning", "primary"].includes(value),
      default: "warning",
    },

    size: {
      type: String,
      validators: (value: string) => ["default", "small", "medium"].includes(value),
      default: "default",
    },
  },
})
</script>
