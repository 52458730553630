<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 5C7.46828 5 4 8.24178 4 12C4 13.3513 4.4383 14.6186 5.20913 15.6993C5.40237 15.9702 5.44817 16.3195 5.33133 16.6311L4.56803 18.6666L7.54888 18.0704C7.75977 18.0282 7.97864 18.0552 8.17301 18.1472C9.36797 18.7131 10.6744 19.0045 11.9966 19L12 19C16.5317 19 20 15.7582 20 12C20 8.24178 16.5317 5 12 5ZM2 12C2 6.92222 6.59172 3 12 3C17.4083 3 22 6.92222 22 12C22 17.0772 17.4092 20.9992 12.0017 21H12V20L12.0034 21L12.0017 21C10.4946 21.0049 9.00425 20.6961 7.62499 20.0948L3.19612 20.9806C2.84008 21.0518 2.47342 20.9244 2.2382 20.6478C2.00299 20.3712 1.93618 19.9888 2.06367 19.6489L3.27879 16.4085C2.46826 15.1107 2 13.6065 2 12ZM7 12C7 11.4477 7.44772 11 8 11H8.01C8.56228 11 9.01 11.4477 9.01 12C9.01 12.5523 8.56228 13 8.01 13H8C7.44772 13 7 12.5523 7 12ZM11 12C11 11.4477 11.4477 11 12 11H12.01C12.5623 11 13.01 11.4477 13.01 12C13.01 12.5523 12.5623 13 12.01 13H12C11.4477 13 11 12.5523 11 12ZM15 12C15 11.4477 15.4477 11 16 11H16.01C16.5623 11 17.01 11.4477 17.01 12C17.01 12.5523 16.5623 13 16.01 13H16C15.4477 13 15 12.5523 15 12Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconChatOutline",
  props: ["height", "width"],
}
</script>
