<template>
  <DashboardSection
    icon="IconFacebookColors"
    :title="$t('home.facebook.title')"
    :description="disabled ? $t('home.facebook.premium_description') : $t('home.facebook.description')"
  >
    <template #actions>
      <div v-if="disabled">
        <Button @click="$router.push({ name: 'freemium-comparison' })">
          <Icon icon-name="IconCrownOutlined" />
          {{ $t("common.premium_button") }}
        </Button>
      </div>
      <div
        v-else
        class="switch-group gap-sm"
      >
        <span>{{ $t("home.facebook.button-1") }}</span>
        <Switch
          :checked="showPreviousFacebookStat"
          :disabled="false"
          @update="facebookSwitch"
        />
      </div>
    </template>

    <div
      v-if="!disabled"
      class="grid grid-cols-1 xl:grid-cols-2 gap-sm md:gap"
    >
      <div class="flex flex-col justify-between bg-white rounded-md gap-sm">
        <MentionsNumber
          :show-previous-data="showPreviousFacebookStat"
          :stats="data.likeEvolution"
        />
        <LikesEvolution
          :show-previous-data="showPreviousFacebookStat"
          :current-filter="filter"
          :stats="data.likeEvolution"
        />
      </div>
      <div class="flex flex-col gap-sm md:gap">
        <Engagement
          :show-previous-data="showPreviousFacebookStat"
          :current-filter="filter"
          :stats="data.engagementEvolution"
        />
        <GenderNumber
          v-model="showPreviousFacebookStat"
          :stats="data.fans"
        />
      </div>
    </div>
  </DashboardSection>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import Switch from "@/components/form/Switch.vue"

import DashboardSection from "../DashboardSection.vue"

import MentionsNumber from "./MentionsNumber.vue"
import LikesEvolution from "./LikesEvolution.vue"
import GenderNumber from "./GenderNumber.vue"
import Engagement from "./Engagement.vue"
import type { IFacebookStats } from "@/types/Stats.types"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { mapStores } from "pinia"
import Button from "@/components/Button.vue"

// Types
//

// Data
//

export default defineComponent({
  name: "GoogleSection",

  components: { Icon, Switch, DashboardSection, MentionsNumber, LikesEvolution, GenderNumber, Engagement, Button },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPreviousFacebookStat: false,
    }
  },

  computed: {
    ...mapStores(useDashboardStore),

    data() {
      return {
        engagementEvolution: this.dashboardStore.engagementEvolution,
        likeEvolution: this.dashboardStore.likeEvolution,
        fans: this.dashboardStore.fans,
      } as IFacebookStats
    },

    filter() {
      return this.dashboardStore.currentPeriodFilter.code
    },
  },

  methods: {
    facebookSwitch() {
      this.showPreviousFacebookStat = !this.showPreviousFacebookStat
    },
  },
})
</script>
