<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.59998 5.99926C3.59998 5.681 3.7264 5.37577 3.95145 5.15073C4.17649 4.92568 4.48172 4.79926 4.79998 4.79926H19.2C19.5182 4.79926 19.8235 4.92568 20.0485 5.15073C20.2735 5.37577 20.4 5.681 20.4 5.99926C20.4 6.31751 20.2735 6.62274 20.0485 6.84778C19.8235 7.07283 19.5182 7.19925 19.2 7.19925H4.79998C4.48172 7.19925 4.17649 7.07283 3.95145 6.84778C3.7264 6.62274 3.59998 6.31751 3.59998 5.99926ZM3.59998 11.9993C3.59998 11.681 3.7264 11.3758 3.95145 11.1507C4.17649 10.9257 4.48172 10.7993 4.79998 10.7993H19.2C19.5182 10.7993 19.8235 10.9257 20.0485 11.1507C20.2735 11.3758 20.4 11.681 20.4 11.9993C20.4 12.3175 20.2735 12.6227 20.0485 12.8478C19.8235 13.0728 19.5182 13.1993 19.2 13.1993H4.79998C4.48172 13.1993 4.17649 13.0728 3.95145 12.8478C3.7264 12.6227 3.59998 12.3175 3.59998 11.9993ZM3.59998 17.9993C3.59998 17.681 3.7264 17.3758 3.95145 17.1507C4.17649 16.9257 4.48172 16.7993 4.79998 16.7993H19.2C19.5182 16.7993 19.8235 16.9257 20.0485 17.1507C20.2735 17.3758 20.4 17.681 20.4 17.9993C20.4 18.3175 20.2735 18.6227 20.0485 18.8478C19.8235 19.0728 19.5182 19.1993 19.2 19.1993H4.79998C4.48172 19.1993 4.17649 19.0728 3.95145 18.8478C3.7264 18.6227 3.59998 18.3175 3.59998 17.9993Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconBurger",
  props: ["height", "width"],
}
</script>
