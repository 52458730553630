<template>
  <Vue3Lottie
    :animation-data="animations[variant]"
    :height="height"
    :width="width"
  />
</template>

<script lang="ts">
// Vue
import "vue3-lottie/dist/style.css"

// Layout
//

// Components
import { Vue3Lottie } from "vue3-lottie"

// Stores
//

// Types
//

// Data
import loadingJSONSearch from "@/assets/lotties/loading.json"
import loadingJSONSending from "@/assets/lotties/sending.json"
import loadingJSONMagic from "@/assets/lotties/magic.json"
import loadingJSONPing from "@/assets/lotties/ping.json"
import loadingJSONButton from "@/assets/lotties/button-loading.json"
import loadingJSONButtonPrimary from "@/assets/lotties/button-loading-primary.json"

export default {
  name: "LottieAnimation",
  components: {
    Vue3Lottie,
  },
  props: {
    variant: {
      type: String,
      validators: (value: string) => ["search", "send", "magic", "ping", "button", "buttonPrimary"].includes(value),
      required: true,
    },
    height: {
      type: Number,
      default: 300,
    },
    width: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      animations: {
        search: loadingJSONSearch,
        send: loadingJSONSending,
        magic: loadingJSONMagic,
        ping: loadingJSONPing,
        button: loadingJSONButton,
        buttonPrimary: loadingJSONButtonPrimary,
      } as { [name: string]: Object },
    }
  },
}
</script>
