import OnboardingDataService from "@/services/OnboardingDataService"
import type {
  IProviderData,
  IResultLine,
  IResultSource,
  IOnboardingStep,
  IOnlineData,
  ICitySelectModel,
} from "@/types/Onboarding.types"
import type { IOnboardingEstablishment } from "@/types/Establishment.types"
import { defineStore } from "pinia"
import { useAlertStore } from "./alert"
import { AlertType } from "@/static/Alert"
import { ContactVariant } from "@/static/onboarding/Contact"
import type IContactForm from "@/types/Contact.types"
import { i18n } from "@/i18n"

export const useOnboardingStore = defineStore("onboarding", {
  state: () => ({
    // Steps
    sequence: {} as { [name: string]: IOnboardingStep },
    step: {} as IOnboardingStep,
    next: {} as IOnboardingStep,
    /** @type {'string'} */
    nextUrl: "",

    // Begin substep
    beginSubStep: 1,

    // Default establishment
    establishment: {} as IOnboardingEstablishment,

    // Founded establishments list
    establishments: [] as IOnboardingEstablishment[],

    // Result score
    enablediffusers: [] as string[],
    notFoundDiffusers: [] as string[],
    providersSources: [] as IProviderData[],
    informationsLines: [] as IResultLine[][],
    percent: 0,

    waitingDurationBeforeErrorRedirect: 2000,
  }),

  getters: {
    currentSequence: (state) => state.sequence,

    nextStepUrl: (state) => state.nextUrl,

    beginCurrentSubStep: (state) => state.beginSubStep,
  },

  actions: {
    // SETTERS
    setSequence(sequence: { [name: string]: IOnboardingStep }) {
      this.sequence = sequence
    },

    setCurrentStep(step: IOnboardingStep) {
      this.step = step
    },

    setNextStep(step: IOnboardingStep) {
      this.next = step
    },

    setBeginSubStep(subStep: number) {
      this.beginSubStep = subStep
    },

    setEstablishment(establishment: IOnboardingEstablishment) {
      this.establishment = establishment
    },

    setEstablishments(establishments: IOnboardingEstablishment[]) {
      this.establishments = establishments
    },

    setNextStepUrl(absolutePath = null, parameters: String) {
      if (this.next) {
        this.nextUrl = "/onboarding/" + this.next.slug
      } else {
        this.nextUrl = ""
      }

      if (absolutePath) {
        this.nextUrl = absolutePath
      }

      this.nextUrl += parameters
    },

    /**
     * Reset establishment state
     */
    clearEstablishment() {
      this.$patch({
        establishment: {} as IOnboardingEstablishment,
      })
    },

    /**
     * Reset result states
     */
    clearResult() {
      this.$patch({
        enablediffusers: [] as string[],
        providersSources: [] as IProviderData[],
        informationsLines: [] as IResultLine[][],
        percent: 0,
      })
    },

    /**
     * Reset store states
     */
    clearAll() {
      this.clearEstablishment()
      this.$patch({
        // Steps
        sequence: {} as { [name: string]: IOnboardingStep },
        step: {} as IOnboardingStep,
        next: {} as IOnboardingStep,
        /** @type {'string'} */
        nextUrl: "",

        // Begin substep
        beginSubStep: 1,

        // Founded establishments list
        establishments: [] as IOnboardingEstablishment[],

        // Result score
        enablediffusers: [] as string[],
        providersSources: [] as IProviderData[],
        informationsLines: [] as IResultLine[][],
        percent: 0,
      })
    },

    // INTRODUCTION FROM HUBO
    /**
     * Retrieve hubo provider from url
     * @param token
     * @param callback
     */
    providers(token: string, callback: (res: any) => void, errorCallback?: () => void, showError?: Boolean) {
      const alerts = useAlertStore()
      OnboardingDataService.fetchExternalProviders(token).then(
        (res) => {
          callback(Object.values(res.data.data))
        },
        (e) => {
          switch (e.response.status) {
            case 401:
              break
            case 503:
              if (showError) {
                alerts.push(AlertType.error, i18n.global.t("common.errors.no_establishment"), e.message)
              }
              break
            case 404:
              if (showError) {
                alerts.push(AlertType.error, i18n.global.t("common.errors.no_establishment"), e.message)
              }
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }

          if (errorCallback) {
            errorCallback()
          }
        }
      )
    },

    // BEGIN INFORMATIONS
    address(name: string, city: ICitySelectModel, callback: (data: IOnlineData) => void) {
      const alerts = useAlertStore()
      OnboardingDataService.searchAddress(name, city).then(
        (res) => {
          callback(res.data)
        },
        (e) => {
          switch (e.response.status) {
            case 401:
              break
            case 503:
              alerts.push(AlertType.error, i18n.global.t("common.errors.no_establishment"), e.message)
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
          this.router.push({
            name: "onboarding-contact",
            params: {
              variant: ContactVariant.ERR_LOADING_ESTABLISHMENT,
            },
          })
        }
      )
    },

    // RESULT SCORE
    /**
     * Get the current available diffusers to analyse
     * @param callback
     */
    diffusers(callback: () => void) {
      const alerts = useAlertStore()
      OnboardingDataService.fetchdiffusers().then(
        (res) => {
          this.enablediffusers = res.data.enableDiffusers
          if (this.enablediffusers.length > 0) {
            callback()
          } else {
            // If diffusers list is empty
            alerts.push(AlertType.error, i18n.global.t("common.errors.empty_diffusers_list"))
            setTimeout(() => {
              this.router.push({
                name: "onboarding-contact",
                params: {
                  variant: ContactVariant.DEFAULT,
                },
              })
            }, this.waitingDurationBeforeErrorRedirect)
          }
        },
        (e) => {
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
          setTimeout(() => {
            this.router.push({
              name: "onboarding-contact",
              params: {
                variant: ContactVariant.DEFAULT,
              },
            })
          }, this.waitingDurationBeforeErrorRedirect)
        }
      )
    },

    /**
     * Get the online sources from establishment
     * @param callback
     */
    onlinePresence(callback: (sources: IResultSource[]) => void) {
      const alerts = useAlertStore()
      if (this.establishment.name) {
        OnboardingDataService.getEstablishmentOnlinePresence(this.establishment, this.enablediffusers).then(
          (res) => {
            if (res.data.data && res.data.data.sources && res.data.data.sources.length > 0) {
              callback(res.data.data.sources)
            } else {
              // If sources list is empty
              alerts.push(AlertType.error, i18n.global.t("common.errors.empty_sources_list"))
              setTimeout(() => {
                this.router.push({
                  name: "onboarding-contact",
                  params: {
                    variant: ContactVariant.DEFAULT,
                  },
                })
              }, this.waitingDurationBeforeErrorRedirect)
            }
          },
          (e) => {
            switch (e.response.status) {
              case 401:
                break
              case 422:
                alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
              default:
                alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                break
            }
            setTimeout(() => {
              this.router.push({
                name: "onboarding-contact",
                params: {
                  variant: ContactVariant.DEFAULT,
                },
              })
            }, this.waitingDurationBeforeErrorRedirect)
          }
        )
      } else {
        alerts.push(AlertType.error, i18n.global.t("common.errors.need_more_establishment_info"))
        this.router.go(-1)
      }
    },

    /**
     * Get the provider data from given source
     * @param source
     * @param callback
     * @returns
     */
    providerData(source: IResultSource, callback: (provider: IProviderData) => void): Promise<any> {
      const alerts = useAlertStore()
      const promise = OnboardingDataService.getProviderData(source).then(
        (res) => {
          const data = res.data.data
          if (data) {
            callback(data.provider_data)
          }
        },
        (e) => {
          switch (e.response.status) {
            case 401:
              break
            case 503:
              alerts.push(
                AlertType.error,
                i18n.global.t("common.errors.no_establishment"),
                source.type + " - " + e.message
              )
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), source.type + " - " + e.message)
              break
          }
        }
      )
      return promise
    },

    /**
     * Get all lines for display differences from informations / sources
     * @param providers
     * @param callback
     */
    differences(providers: IProviderData[], callback: (data: any) => void) {
      const alerts = useAlertStore()
      if (providers.length > 0) {
        OnboardingDataService.getDifferences(providers).then(
          (res) => {
            callback(res.data.data)
          },
          (e) => {
            switch (e.response.status) {
              case 401:
                break
              default:
                alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                this.router.push({ name: "onboarding-contact" })
                break
            }
          }
        )
      } else {
        alerts.push(AlertType.error, i18n.global.t("common.errors.bad_analyse"))
        this.router.go(-1)
      }
    },

    /**
     * Get the score from providers list
     * @param establishmentId
     * @param informationLines
     * @param callback
     */
    scoring(establishmentId: number, informationLines: IResultLine[][], callback: (score: number) => void) {
      const alerts = useAlertStore()

      OnboardingDataService.getEstablishmentScoringGlobal(establishmentId, informationLines).then(
        (res) => {
          callback(res.data.score)
        },
        (e) => {
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    // CONTACT
    /**
     * Send contact request from form
     * @param contactForm
     * @param callback
     */
    contact(contactForm: IContactForm, callback: () => void) {
      OnboardingDataService.sendContactRequest(contactForm).then(
        (res) => {
          callback()
        },
        (e) => {
          const alerts = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alerts.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }
        }
      )
    },

    apidaeData(establishmentId: number, callback: (provider: IProviderData) => void): Promise<any> {
      const promise = OnboardingDataService.getApidaeData(establishmentId).then((res) => {
        const data = res.data.data
        if (data) {
          callback(data.provider_data)
        }
      })

      return promise
    },
  },
})
