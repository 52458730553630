<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_1820_4571)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24 4.91589C24 2.47869 21.9798 0.5 19.4926 0.5H4.5074C2.02021 0.5 0 2.47869 0 4.91589V19.0839C0 21.5205 2.02021 23.4998 4.50742 23.4998H19.4926C21.9798 23.4998 24 21.5205 24 19.0839L24 4.91589Z"
        fill="#0C3B7C"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.000854492 14.7885H11.9874V23.4997H0.000863277L0.000854492 14.7885Z"
        fill="#0C3B7C"
      />
      <path
        d="M10.9074 15.7237L8.97443 15.7218V13.457C8.97443 12.9731 9.166 12.7212 9.58861 12.6636H10.9075C11.848 12.6636 12.4564 13.2447 12.4564 14.1852C12.4564 15.1512 11.8634 15.7228 10.9075 15.7237H10.9074ZM8.97443 9.61489V9.01872C8.97443 8.49707 9.19969 8.24898 9.69354 8.21691H10.6832C11.5313 8.21691 12.0396 8.71403 12.0396 9.54697C12.0396 10.1809 11.6911 10.9214 10.714 10.9214H8.97443V9.61489ZM13.3757 11.8751L13.0263 11.6826L13.3315 11.427C13.6867 11.1279 14.2816 10.4554 14.2816 9.2951C14.2816 7.51792 12.8751 6.3718 10.6986 6.3718H8.21488V6.37085H7.93186C7.28687 6.39443 6.76992 6.90854 6.76318 7.54433V17.6283H10.7477C13.1668 17.6283 14.7283 16.3378 14.7283 14.339C14.7283 13.2627 14.2238 12.3429 13.3757 11.8751Z"
        fill="white"
      />
      <path
        d="M15.766 16.2353C15.766 15.465 16.4 14.8417 17.1812 14.8417C17.9644 14.8417 18.6014 15.465 18.6014 16.2353C18.6014 17.0046 17.9644 17.6288 17.1812 17.6288C16.4 17.6288 15.766 17.0046 15.766 16.2353"
        fill="#00BAFC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1820_4571">
        <rect
          width="24"
          height="24"
          fill="white"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconBookingColors",
  props: ["height", "width"],
}
</script>
