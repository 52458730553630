<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="12"
      fill="#F3F3F3"
    />
    <g clip-path="url(#clip0_424_10026)">
      <path
        d="M17.1682 8.05884L17.166 8.06093C17.2121 8.18847 17.255 8.31761 17.2917 8.44934C17.2544 8.31745 17.2144 8.18671 17.1682 8.05884Z"
        fill="#4285F4"
      />
      <path
        d="M12.0004 4.42651L8.11133 6.03763L10.3458 8.27211C10.7825 7.83238 11.376 7.58414 11.9958 7.58202C12.6155 7.57989 13.2108 7.82405 13.6505 8.26078C14.0902 8.69752 14.3385 9.29105 14.3406 9.9108C14.3427 10.5306 14.0985 11.1258 13.6618 11.5655L16.9504 8.27651L17.166 8.06091C16.7835 6.99724 16.0822 6.07745 15.1578 5.42703C14.2333 4.7766 13.1307 4.42721 12.0004 4.42651Z"
        fill="#4285F4"
      />
      <path
        d="M12 12.2603C11.538 12.2602 11.0863 12.1232 10.7021 11.8666C10.3179 11.61 10.0184 11.2453 9.84133 10.8185C9.66428 10.3918 9.61767 9.92214 9.70738 9.46891C9.79709 9.01568 10.0191 8.59921 10.3454 8.27208L8.11095 6.0376C7.59948 6.54772 7.19385 7.15389 6.91739 7.82128C6.64093 8.48866 6.49908 9.2041 6.5 9.92648C6.5 12.3496 7.74581 13.8682 9.2115 15.4265C9.30979 15.527 9.39157 15.6383 9.48425 15.7425L13.6427 11.584C13.206 12.0176 12.6154 12.2608 12 12.2603Z"
        fill="#FFBA00"
      />
      <path
        d="M12 4.42651C10.5413 4.42651 9.14237 5.00598 8.11092 6.03743C7.07947 7.06888 6.50001 8.46782 6.50001 9.92651C6.49889 10.5717 6.60014 11.213 6.79998 11.8265L13.8678 4.75904C13.2693 4.54038 12.6372 4.42785 12 4.42651Z"
        fill="#0066DA"
      />
      <path
        d="M12.4957 19.216C12.9953 17.826 13.7471 16.5401 14.7133 15.4229C16.2888 13.8221 17.5001 12.3498 17.5001 9.92653C17.4989 9.28938 17.3864 8.65734 17.1679 8.05884L9.48438 15.7423C10.369 16.7478 11.0409 17.922 11.4596 19.194C11.4948 19.3024 11.5628 19.3971 11.6542 19.4651C11.7457 19.5331 11.856 19.5709 11.9699 19.5733C12.0838 19.5757 12.1956 19.5426 12.2898 19.4786C12.3841 19.4146 12.456 19.3228 12.4957 19.216Z"
        fill="#00AC47"
      />
      <path
        d="M11.4599 19.1939C11.3474 18.8626 11.2208 18.5405 11.0801 18.2278C11.219 18.5414 11.3474 18.8623 11.4599 19.1939Z"
        fill="#F3F3F3"
      />
      <path
        d="M12.5762 19.0257C12.7051 18.7004 12.8437 18.3809 12.992 18.0671C12.8428 18.3803 12.7051 18.7004 12.5762 19.0257Z"
        fill="#F3F3F3"
      />
      <path
        d="M13.4742 17.147C13.3158 17.4196 13.1717 17.7007 13.0342 17.9853C13.1717 17.7004 13.3164 17.4199 13.4742 17.147Z"
        fill="#F3F3F3"
      />
      <path
        d="M8.11096 6.0376C7.59948 6.54772 7.19386 7.15389 6.9174 7.82128C6.64094 8.48866 6.49909 9.2041 6.50001 9.92648C6.49889 10.5717 6.60014 11.213 6.79998 11.8265L10.35 8.27648L8.11096 6.0376Z"
        fill="#EA4435"
      />
    </g>
    <defs>
      <clipPath id="clip0_424_10026">
        <rect
          width="17.6"
          height="17.6"
          fill="white"
          transform="translate(3.2002 3.19995)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconMapsColored",
  props: ["height", "width"],
}
</script>
