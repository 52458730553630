<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.61091 4.61091C1.46303 6.7588 1.46303 10.2412 3.61091 12.3891L11.293 20.0711C11.6835 20.4616 12.3167 20.4616 12.7072 20.0711L20.3891 12.3891C22.5369 10.2412 22.5369 6.75881 20.3891 4.61093L20.2071 4.43691C18.0487 2.46469 14.6992 2.5227 12.6109 4.61091L12 5.221L11.3891 4.61091C9.2412 2.46303 5.7588 2.46303 3.61091 4.61091ZM19.1161 6.17475C20.3399 7.54916 20.2929 9.65685 18.9749 10.9749L12 17.949L5.02512 10.9749C3.65829 9.60803 3.65829 7.39196 5.02513 6.02513C6.39196 4.65829 8.60804 4.65829 9.9749 6.02513L11.293 7.34321C11.6835 7.73374 12.3167 7.73372 12.7073 7.34315L14.0252 6.02508C15.392 4.65829 17.608 4.65829 18.9749 6.02514L19.1161 6.17475Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconHeart",
  props: ["height", "width"],
}
</script>
