<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 4C4.73478 4 4.48043 4.10536 4.29289 4.29289C4.10536 4.48043 4 4.73478 4 5V14.1716C4.3182 14.0591 4.65606 14 5 14H11.5C11.7652 14 12.0196 14.1054 12.2071 14.2929L12.9142 15H19.382L17.1056 10.4472C16.9648 10.1657 16.9648 9.83431 17.1056 9.55279L19.382 5H13V9C13 9.55228 12.5523 10 12 10C11.4477 10 11 9.55228 11 9V4H5ZM12.9142 3H21C21.3466 3 21.6684 3.17945 21.8507 3.47427C22.0329 3.76909 22.0494 4.13723 21.8944 4.44721L19.118 10L21.8944 15.5528C22.0494 15.8628 22.0329 16.2309 21.8507 16.5257C21.6684 16.8205 21.3466 17 21 17H12.5C12.2348 17 11.9804 16.8946 11.7929 16.7071L11.0858 16H5C4.73478 16 4.48043 16.1054 4.29289 16.2929C4.10536 16.4804 4 16.7348 4 17V21C4 21.5523 3.55228 22 3 22C2.44772 22 2 21.5523 2 21V5C2 4.20435 2.31607 3.44129 2.87868 2.87868C3.44129 2.31607 4.20435 2 5 2H11.5C11.7652 2 12.0196 2.10536 12.2071 2.29289L12.7066 2.79236C12.7069 2.79272 12.7073 2.79307 12.7076 2.79342L12.9142 3Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconFlag",
  props: ["height", "width"],
}
</script>
