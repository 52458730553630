<template>
  <div id="alerts-container">
    <TransitionGroup
      id="alerts"
      name="alert-list"
      tag="div"
      class="flex flex-col-reverse gap-sm"
    >
      <AlertItem
        v-for="(item, key) in alertStore.alerts"
        :key="key"
        :alert="item"
      />
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

// Stores
import { useAlertStore } from "@/stores/alert"
import { mapStores } from "pinia"
import AlertItem from "@/components/AlertItem.vue"
import type IAlert from "@/types/Alert.types"

export default defineComponent({
  name: "Alerts",
  components: { AlertItem },
  data() {
    return {
      list: [] as IAlert[],
    }
  },
  computed: {
    ...mapStores(useAlertStore),
  },
  created() {
    this.list = this.alertStore.alerts
  },
})
</script>
