import { defineStore } from "pinia"

export const useMobileStore = defineStore("mobile", {
  state: () => ({
    contact: false,
    authInfo: false,
    menu: false,
    filters: false,
  }),

  getters: {
    showContact: (state) => state.contact,

    showAuthInfo: (state) => state.authInfo,

    isMobileSize: () => {
      const windowWidth = window.innerWidth
      if (windowWidth < 768) {
        return true
      } else {
        return false
      }
    },

    isMediumSize: () => {
      const windowWidth = window.innerWidth
      if (windowWidth < 1280) {
        return true
      } else {
        return false
      }
    },

    showMenu: (state) => state.menu,

    showFilters: (state) => state.filters,
  },

  actions: {
    setShowContact(contact: boolean) {
      this.contact = contact
    },

    closeContactPopup(exclude: string) {
      document.addEventListener("click", (e) => {
        if (e.target !== document.getElementById(exclude)) {
          this.contact = false
        }
      })
    },

    setShowMenu(menu: boolean) {
      this.menu = menu
    },

    setShowFilters(filters: boolean) {
      this.filters = filters
    },
  },
})
