<template>
  <div class="relative border rounded-sm p-md">
    <div
      class="flex justify-between mb-sm md:mb-0"
      :class="disabled && 'opacity-50'"
    >
      <Icon
        :icon-name="stat.icon"
        class="text-gray-500"
      />
      <div v-if="dashboardStore.isSyncLoading">
        <LottieAnimation
          variant="buttonPrimary"
          :height="20"
          :width="20"
        />
      </div>
      <div
        v-else
        class="flex gap-xs"
      >
        <RoundedDiffuserIcon
          v-for="diffuser in stat.diffusers"
          :key="diffuser"
          :class="getClass(diffuser)"
          :diffuser="charteDiffusers[diffuser]"
          :size="24"
          :custom-tooltip-content="logoName(diffuser)"
        />
      </div>
    </div>

    <span
      class="text-base text-gray-900"
      :class="disabled && 'opacity-50'"
      >{{ stat.name }}</span
    >

    <div
      v-if="stat.sum !== null"
      class="flex items-center gap mt-sm"
      :class="disabled && 'opacity-50'"
    >
      <div class="flex items-center gap-xs">
        <span class="text-3xl text-gray-900">{{ stat.sum }}</span>
      </div>
      <div
        v-if="hasDataVariation"
        class="flex items-center gap-xs"
      >
        <div
          class="p-sm rounded"
          :class="charte.bgColor"
        >
          <Icon
            :icon-name="charte.icon"
            :height="12"
            :width="12"
            :class="charte.color"
          />
        </div>
        <span
          class="text-base"
          :class="charte.color"
          >{{ stat.variation }}</span
        >
      </div>
    </div>
    <div
      v-else-if="stat.code && stat.code === 'note'"
      :class="disabled && 'opacity-50'"
    >
      <small class="text-gray-500">
        {{ $t("home.global_stats.note_empty_data") }}
      </small>
    </div>

    <FreemiumBlur v-if="disabled" />
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent, type PropType } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import RoundedDiffuserIcon from "@/components/RoundedDiffuserIcon.vue"
import LottieAnimation from "@/components/LottieAnimation.vue"
import FreemiumBlur from "@/components/freemium/FreemiumBlur.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { mapStores } from "pinia"

// Types
import type { IGlobalStatistic } from "@/types/Dashboard.types"

// Data
import { GlobalStatisticGrowth, globalStatisticGrowthCharte } from "@/static/Dashboard"
import { charteDiffusers } from "@/static/Diffuser"

export default defineComponent({
  name: "GlobalStatisticItem",

  components: { Icon, RoundedDiffuserIcon, LottieAnimation, FreemiumBlur },

  props: {
    stat: {
      type: Object as PropType<IGlobalStatistic>,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      globalStatisticGrowthCharte,
      charteDiffusers,
    }
  },

  computed: {
    ...mapStores(useDashboardStore),

    charte() {
      if (this.globalStatisticGrowthCharte[this.stat.variationType]) {
        return this.globalStatisticGrowthCharte[this.stat.variationType]
      } else {
        return {
          color: "text-gray-500",
          bgColor: "bg-gray-100",
          icon: "IconEqual",
        }
      }
    },

    hasDataVariation() {
      return this.stat.variationType !== GlobalStatisticGrowth.NO_DATA
    },
  },

  methods: {
    /**
     * Return inactiv class
     * @param code
     */
    getClass(code: string) {
      if (!this.dashboardStore.isPlatformActive(code)) {
        return "opacity-50 grayscale"
      }
    },

    logoName(code: string) {
      const name = this.charteDiffusers[code].name ? this.charteDiffusers[code].name : "plateforme"
      if (this.dashboardStore.isPlatformActive(code)) {
        return name
      } else {
        return name + " non connecté"
      }
    },
  },
})
</script>
