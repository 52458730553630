<template>
  <StatCard
    :title="$t(i18nbase + '.reviews_rating.title')"
    :description="$t(i18nbase + '.reviews_rating.description')"
    icon="IconAnnotation"
    :custom-style="showGraph.show ? '!h-fit' : ''"
  >
    <template
      v-if="showGraph.show"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".reviews_rating.total", {
              nb: $string.formatNumberToHumanString(stats.current.reviews_google_sum),
            })
          }}
        </span>
        <span
          v-if="modelValue && stats.last_year_total > 0"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".reviews_rating.previous_total", {
              nb: $string.formatNumberToHumanString(stats.last_year_total),
            })
          }}
        </span>
      </div>
    </template>

    <div
      v-if="showGraph.show"
      id="dashboard-circle-progress"
      class="flex flex-wrap items-center justify-around gap lg:gap-sm w-full min-h-[200px]"
      :class="!showGraph.show && '!hidden'"
    >
      <div
        v-for="(rate, index) in currentRating"
        :key="'rate-' + index"
        class="min-w-[90px] flex flex-col items-center gap-sm"
      >
        <div
          class="relative w-[80px]"
          :class="rate === 0 && 'opacity-30'"
        >
          <CircleProgress
            :percent="(rate * 100) / stats.current.reviews_google_sum"
            fill-color="#31384C"
            empty-color="#ECEDF0"
            :size="80"
            :border-width="6"
            :border-bg-width="6"
            :transition="2000"
            :is-shadow="false"
            :show-percent="false"
          />
          <div
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[64px] w-[64px] rounded-full flex items-center justify-center"
          >
            <span class="text-gray-700 text-sm">
              {{ rate }}
            </span>
          </div>
        </div>
        <div
          class="text-gray-700 flex items-center"
          :class="rate === 0 && 'opacity-30'"
        >
          <span>{{ currentRating.length - index }}</span>
          <Icon icon-name="IconStar" />
        </div>
        <div
          v-if="modelValue"
          class="h-[40px] w-[40px] rounded-full flex items-center justify-center"
          :class="getLastYearVariationClass(rate, previousRating[index], index, 'container')"
        >
          <small
            v-if="rate - previousRating[index] !== 0"
            :class="getLastYearVariationClass(rate, previousRating[index], index, 'text')"
          >
            {{ rate - previousRating[index] > 0 ? "+" + (rate - previousRating[index]) : rate - previousRating[index] }}
          </small>
          <small
            v-else
            class="text-gray-400"
          >
            =
          </small>
        </div>
      </div>
    </div>

    <EmptyData
      v-else-if="showGraph.message === 'empty_data'"
      :message="$t(i18nbase + '.' + noDataKey)"
    />

    <div
      v-else
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + showGraph.message) }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { defineComponent, type PropType } from "vue"
import CircleProgress from "vue3-circle-progress"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import StatCard from "../StatCard.vue"
import EmptyData from "../EmptyData.vue"

// Stores
import {mapStores} from "pinia"
import {useListingStore} from "@/stores/listing"

// Types
import type { IReviewStatsForDashboard } from "@/types/Stats.types"

// Data
//

export default defineComponent({
  name: "ReviewsRating",

  components: { CircleProgress, Icon, StatCard, EmptyData },

  props: {
    stats: {
      type: Object as PropType<IReviewStatsForDashboard>,
      required: true,
    },

    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      i18nbase: "home.google",
    }
  },

  computed: {
    ...mapStores(
      useListingStore
    ),
    currentRating() {
      return [
        this.stats.current.per_star.five,
        this.stats.current.per_star.four,
        this.stats.current.per_star.three,
        this.stats.current.per_star.two,
        this.stats.current.per_star.one,
      ]
    },
    previousRating() {
      return [
        this.stats.last_year.per_star.five,
        this.stats.last_year.per_star.four,
        this.stats.last_year.per_star.three,
        this.stats.last_year.per_star.two,
        this.stats.last_year.per_star.one,
      ]
    },

    showGraph() {
      let no_data = this.currentRating.filter((item) => item === null).length === this.currentRating.length
      let empty_data = this.currentRating.filter((item) => item === 0).length === this.currentRating.length

      if (this.modelValue) {
        no_data = no_data && this.previousRating.filter((item) => item === null).length === this.previousRating.length
        empty_data =
          empty_data && this.previousRating.filter((item) => item === 0).length === this.previousRating.length
      }

      if (empty_data) {
        return { show: false, message: "empty_data" }
      } else if (no_data) {
        return { show: false, message: "no_data" }
      } else {
        return { show: true, message: "" }
      }
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus('GOOGLE') === 'CONNECTED' ? 'reviews_rating.no_reviews' : 'not_connected'
    }
  },

  methods: {
    getLastYearVariationClass(currentRating: number, lastYearRating: number, index: number, type: string) {
      // logique inverse pour les 3* et plus. La couleur affichée est verte si la variation est négative
      if (index > 1) {
        if (currentRating - lastYearRating > 0) {
          return type === "text" ? "text-error-500" : "bg-error-100"
        } else if (currentRating - lastYearRating === 0) {
          return type === "text" ? "text-gray-500" : "bg-gray-50"
        } else {
          return type === "text" ? "text-success-500" : "bg-success-100"
        }
      } else {
        if (currentRating - lastYearRating > 0) {
          return type === "text" ? "text-success-500" : "bg-success-100"
        } else if (currentRating - lastYearRating === 0) {
          return type === "text" ? "text-gray-500" : "bg-gray-50"
        } else {
          return type === "text" ? "text-error-500" : "bg-error-100"
        }
      }
    },
  },
})
</script>
