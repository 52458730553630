<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.87871 2.87868C10.4413 2.31607 11.2044 2 12 2C12.7957 2 13.5587 2.31607 14.1213 2.87868C14.6043 3.36165 14.9056 3.99234 14.9812 4.66455C16.0719 5.17819 17.0154 5.96673 17.7159 6.95768C18.5515 8.13996 19.0002 9.5522 19 11C19 11 19 11 19 11V14.158C19 14.2935 19.0267 14.4279 19.0785 14.5531C19.1304 14.6783 19.2063 14.7921 19.3021 14.8879C19.3022 14.8879 19.3021 14.8879 19.3021 14.8879L20.7071 16.2929C20.9931 16.5789 21.0787 17.009 20.9239 17.3827C20.7691 17.7564 20.4045 18 20 18H16C16 19.0609 15.5786 20.0783 14.8285 20.8284C14.0783 21.5786 13.0609 22 12 22C10.9392 22 9.92175 21.5786 9.1716 20.8284C8.42146 20.0783 8.00003 19.0609 8.00003 18H4.00003C3.59557 18 3.23093 17.7564 3.07615 17.3827C2.92137 17.009 3.00692 16.5789 3.29292 16.2929L4.69792 14.8879C4.8914 14.6944 5.00003 14.4318 5.00003 14.159V11C5.00003 8.19928 6.64515 5.78419 9.01881 4.66475C9.09439 3.99246 9.39569 3.3617 9.87871 2.87868ZM10 18C10 18.5304 10.2107 19.0391 10.5858 19.4142C10.9609 19.7893 11.4696 20 12 20C12.5305 20 13.0392 19.7893 13.4142 19.4142C13.7893 19.0391 14 18.5304 14 18H10ZM12 4C11.7348 4 11.4805 4.10536 11.2929 4.29289C11.1054 4.48043 11 4.73478 11 5V5.341C11 5.76475 10.7329 6.1425 10.3334 6.28378C8.39031 6.97097 7.00003 8.82501 7.00003 11V14.159C7.00003 14.8276 6.77873 15.4742 6.37664 16H17.6235C17.4638 15.7912 17.3316 15.5621 17.2307 15.3182C17.0784 14.9504 17 14.5561 17 14.158C17 14.1579 17 14.1581 17 14.158V11C17.0002 9.96571 16.6797 8.95669 16.0827 8.11209C15.4857 7.26749 14.6415 6.62872 13.6665 6.28373C13.267 6.1424 13 5.7647 13 5.341V5C13 4.73478 12.8947 4.48043 12.7071 4.29289C12.5196 4.10536 12.2652 4 12 4Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconBell",
  props: ["height", "width"],
}
</script>
