<template>
  <div class="bg-white rounded-md p-md md:p flex flex-col gap">
    <div class="flex flex-col gap-sm">
      <div class="flex flex-col md:flex-row justify-start items-start md:justify-between gap-sm">
        <div class="w-full md:w-max flex flex-col md:flex-row items-start md:items-center gap-sm">
          <div class="w-full md:w-min flex items-center justify-between">
            <Icon
              :icon-name="icon"
              class="text-gray-500"
            />
            <Tooltip
              v-if="description"
              class="md:hidden"
              variant-x="centerX"
              variant-y="up"
              variant-mobile-x="left"
              variant-mobile-y="centerY"
              size="small"
            >
              <template #main>
                <Icon
                  icon-name="IconInformationCircle"
                  class="text-gray-600 min-w-[18px]"
                  :height="18"
                  :width="18"
                />
              </template>
              <template #content>
                {{ description }}
              </template>
            </Tooltip>
          </div>
          <span class="text-xl text-gray-900">
            {{ title }}
          </span>
          <Tooltip
            v-if="description"
            class="hidden md:block"
            variant-x="centerX"
            variant-y="up"
            variant-mobile-x="centerX"
            variant-mobile-y="up"
            size="small"
          >
            <template #main>
              <Icon
                icon-name="IconInformationCircle"
                class="text-gray-600"
                :height="18"
                :width="18"
              />
            </template>
            <template #content>
              <span v-html="description"></span>
            </template>
          </Tooltip>
        </div>
        <div class="hidden md:block">
          <slot name="actions"></slot>
        </div>
      </div>
      <slot name="subtitle"></slot>

      <div class="md:hidden w-full flex justify-end">
        <slot name="actions"></slot>
      </div>
    </div>
    <div
      class="relative transition-all flex justify-center h-[150px] md:h-[200px]"
      :class="[loading ? 'opacity-0' : 'opacity-1', customStyle]"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "../Icon.vue"
import Tooltip from "../Tooltip.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "StatCard",

  components: { Icon, Tooltip },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      default: "",
    },

    icon: {
      type: String,
      required: true,
    },

    loading: {
      type: Boolean,
      default: false,
    },

    customStyle: {
      type: String,
      default: "",
    },
  },

  data() {
    return {}
  },
})
</script>
