<template>
  <StatisticCard
    :title="$t(i18nbase + '.title')"
    :description="description"
    :info="$t(i18nbase + '.info_bubble')"
    :disabled="disabled"
  >
    <template
      v-if="accountStore.isFreemium"
      #actions
    >
      <Button
        color="gray"
        :outline="true"
        :icon-button="mobileStore.isMobileSize ? true : false"
        @click="$router.push({ name: 'establishment-listing' })"
      >
        <span class="hidden md:block">
          {{ $t("home.plateformes.button") }}
        </span>
        <Icon
          icon-name="IconClipboardCheck"
          class="md:hidden"
        />
      </Button>
    </template>

    <div
      v-if="dashboardStore.isSyncLoading"
      class="flex items-center justify-center h-full"
    >
      <LottieAnimation
        variant="buttonPrimary"
        :height="50"
        :width="50"
      />
    </div>

    <div
      v-else-if="showPlatforms"
      class="h-full grid gap-y-md lg:gap-x-md grid-cols-1 xl:grid-cols-2"
    >
      <div
        v-if="dashboardStore.sync.length > 0"
        class="relative grid gap-y-md lg:gap-x-md grid-cols-1 xl:grid-cols-2"
        :class="'col-span-' + dashboardStore.sync.length"
      >
        <SyncDiffuserItem
          v-for="sync in dashboardStore.sync"
          :key="sync.diffuser.name!"
          :item="sync"
          :is-local-diffuser="false"
          :is-freemium="accountStore.isFreemium"
          :disabled="accountStore.isFreemiumButEndingPlan"
        />
      </div>

      <SyncDiffuserItem
        v-if="tic"
        :item="tic"
        :is-local-diffuser="true"
        @invite="invitationSent"
      />
      <div
        v-if="accountStore.isFreemium"
        class="relative w-full border border-gray-100 rounded-sm flex justify-center items-center p-sm bg-premium overflow-hidden"
        :class="dashboardStore.sync.length > 0 ? 'min-h-[100px] xl:h-full' : 'h-[200px] xl:h-full'"
      >
        <Button
          type="button"
          class="text-xs z-20"
          @click="$router.push({ name: 'freemium-comparison' })"
        >
          Ajouter une plateforme
        </Button>
        <img
          class="absolute h-[26px] w-[26px] rounded-full object-cover bottom-3 right-28"
          src="@/assets/images/listing/waze.jpg"
        />
        <img
          class="absolute h-[22px] w-[22px] rounded-full object-cover bottom-2 right-20"
          src="@/assets/images/listing/instagram.jpg"
        />
        <img
          class="absolute h-[20px] w-[20px] rounded-full object-cover bottom-3 right-12"
          src="@/assets/images/listing/google_maps.jpg"
        />
        <img
          class="absolute h-[14px] w-[14px] rounded-full object-cover bottom-2 right-8"
          src="@/assets/images/listing/tripadvisor.png"
        />
        <img
          class="absolute h-[12px] w-[12px] rounded-full object-cover bottom-3 right-2"
          src="@/assets/images/listing/apple_maps.png"
        />
        <img
          class="absolute h-[10px] w-[10px] rounded-full object-cover bottom-2 -right-1"
          src="@/assets/images/listing/orange.png"
        />
      </div>
    </div>

    <div
      v-else
      class="flex items-center justify-center h-[100px]"
    >
      <span class="text-base text-gray-400">
        {{ $t(i18nbase + ".error_message") }}
      </span>
    </div>
  </StatisticCard>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import StatisticCard from "./StatisticCard.vue"
import SyncDiffuserItem from "./SyncDiffuserItem.vue"
import LottieAnimation from "@/components/LottieAnimation.vue"
import Button from "@/components/Button.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useEstablishmentStore } from "@/stores/establishment"
import { useTourismInformationCenterStore } from "@/stores/tourismInformationCenter"
import { useLocalDiffuserStore } from "@/stores/localDiffuser"
import { useListingStore } from "@/stores/listing"
import { useAccountStore } from "@/stores/account"
import { useMobileStore } from "@/stores/mobile"
import { mapStores } from "pinia"

// Types
import type { ISync } from "@/types/Dashboard.types"
import { charteDiffusers, AllDiffuser } from "@/static/Diffuser"
import Icon from "@/components/Icon.vue"

// Data
//

export default defineComponent({
  name: "SynchronisationStatisticItem",

  components: {
    StatisticCard,
    SyncDiffuserItem,
    LottieAnimation,
    Button,
    Icon,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      i18nbase: "home.synchronisation",
      hasTic: false,
      localTic: {} as ISync,
      charteDiffusers,
    }
  },

  computed: {
    ...mapStores(
      useDashboardStore,
      useEstablishmentStore,
      useTourismInformationCenterStore,
      useLocalDiffuserStore,
      useListingStore,
      useAccountStore,
      useMobileStore
    ),

    description() {
      if (this.accountStore.isFreemium) {
        return ""
      } else {
        if (this.dashboardStore.lastSync !== "") {
          return this.$t(this.i18nbase + ".description_last_sync", { date: this.dashboardStore.lastSync })
        } else {
          return this.$t(this.i18nbase + ".description_no_sync")
        }
      }
    },

    tic(): ISync | null {
      if (this.hasTic) {
        return this.localTic
      } else {
        return null
      }
    },

    showPlatforms() {
      let show = this.dashboardStore.sync.length > 0

      if (!show && this.hasTic) {
        show = true
      }

      return show
    },

    facebookDiffuser() {
      return this.charteDiffusers[AllDiffuser.FACEBOOK]
    },

    googleDiffuser() {
      return this.charteDiffusers[AllDiffuser.GOOGLE]
    },
  },

  created() {
    this.dashboardStore.syncStatistics()
    this.initLocalDiffuser()
  },

  methods: {
    initLocalDiffuser() {
      this.listingStore.getLocalDiffusers(this.establishmentStore.currentEstablishment.id, (localDiffusers) => {
        if (localDiffusers.length > 0) {
          this.hasTic = true
          const tic = localDiffusers[0]
          this.localTic = {
            id: tic.id,
            type: "tic",
            diffuser: charteDiffusers[AllDiffuser.TIC],
            status: tic.state,
            logo: tic.diffusable?.logo,
          }
        }
      })
    },

    invitationSent() {
      this.listingStore.localListing = []
      this.initLocalDiffuser()
    },
  },
})
</script>
