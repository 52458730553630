<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.0002 9.5996C18.7312 9.59962 19.4449 9.37712 20.0463 8.96168C20.6478 8.54624 21.1085 7.95755 21.3672 7.27389C21.626 6.59023 21.6705 5.844 21.4948 5.13444C21.3191 4.42488 20.9316 3.78561 20.3838 3.30164C19.836 2.81767 19.1538 2.51194 18.428 2.42509C17.7022 2.33825 16.9671 2.47442 16.3206 2.81549C15.6741 3.15655 15.1467 3.68636 14.8086 4.33445C14.4705 4.98254 14.3377 5.7182 14.4278 6.4436L8.49981 9.4076C7.99129 8.91722 7.34972 8.5873 6.65504 8.45893C5.96036 8.33056 5.24326 8.40942 4.59307 8.68568C3.94288 8.96194 3.38835 9.4234 2.99854 10.0126C2.60873 10.6017 2.40088 11.2926 2.40088 11.999C2.40088 12.7054 2.60873 13.3963 2.99854 13.9854C3.38835 14.5746 3.94288 15.0361 4.59307 15.3123C5.24326 15.5886 5.96036 15.6674 6.65504 15.5391C7.34972 15.4107 7.99129 15.0808 8.49981 14.5904L14.4278 17.5544C14.3228 18.3971 14.5191 19.2498 14.9819 19.9617C15.4448 20.6737 16.1445 21.1991 16.9572 21.4452C17.77 21.6912 18.6436 21.6419 19.4236 21.3062C20.2036 20.9704 20.8398 20.3697 21.2198 19.6102C21.5997 18.8508 21.6989 17.9814 21.4999 17.1559C21.3009 16.3303 20.8165 15.6017 20.1322 15.0988C19.4479 14.5958 18.6079 14.351 17.7606 14.4075C16.9133 14.464 16.1132 14.8183 15.5018 15.4076L9.57381 12.4436C9.61012 12.1487 9.61012 11.8505 9.57381 11.5556L15.5018 8.5916C16.1474 9.2156 17.0282 9.5996 18.0002 9.5996Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconShareSolid",
  props: ["height", "width"],
}
</script>
