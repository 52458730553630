<template>
  <main
    id="app-layout"
    :class="variant"
  >
    <Alerts />
    <Confirm />
    <div id="aside">
      <Aside :blocked="blockApp"> </Aside>
    </div>
    <div
      v-if="$slots.header && !blockApp"
      id="header"
    >
      <Header
        variant="app"
        :has-mobile-chat="false"
      >
        <template #before>
          <div
            v-if="showBackButton"
            id="back-button"
            class="bg-gray-100 md:bg-white rounded-sm p-sm md:p-md"
            @click="back"
          >
            <Icon icon-name="IconChevronLeft" />
          </div>
          <slot
            v-else-if="$slots.before"
            name="before"
          ></slot>
          <div
            v-else
            id="mobile-burger"
            class="bg-gray-900 rounded-sm p-sm relative"
            @click="mobileStore.menu = true"
          >
            <Icon
              icon-name="IconBurger"
              class="text-white"
            />
            <NotificationBadge
              v-if="notificationsStore.notifications.length > 0"
              size="small"
            />
          </div>
        </template>
        <template #header>
          <slot name="header"></slot>
        </template>
        <template #after>
          <slot name="after"></slot>
        </template>
        <template
          v-if="!mobileStore.isMobileSize"
          #actions
        >
          <slot name="actions"></slot>
        </template>
        <template
          v-else
          #actions
        >
          <slot name="mobile"></slot>
        </template>
      </Header>
    </div>
    <div
      v-if="mobileStore.isMobileSize && $slots.actions && !$slots.mobile && !blockApp"
      id="actions"
    >
      <slot name="actions"></slot>
    </div>
    <slot></slot>

    <div
      v-if="!blockApp"
      id="main"
      :class="[variant === 'dashboard' && 'mobile-padding', showPremiumBackground && 'relative overflow-hidden']"
    >
      <Dropdown
        v-if="accountStore.establishments.length > 0"
        :outline="false"
        type="icon"
        width="max"
        position-x="right"
        position-y="bottom"
        class="establishment-floating-icon md:hidden !w-fit"
      >
        <template #title>
          <img
            class="h-[48px] w-[48px] rounded-full object-fill"
            :src="establishmentStore.getIcon(establishmentStore.currentEstablishment)"
          />
        </template>
        <div class="flex flex-col max-h-[70vh] max-w-[70vw] overflow-y-auto">
          <DropdownItem
            v-for="establishment in accountStore.establishments"
            :key="establishment.id"
            :active="establishment.id === establishmentStore.currentEstablishment.id"
            @click="establishmentStore.changeCurrentEstablishment(establishment)"
          >
            <div class="flex items-center gap-sm">
              <img
                class="h-[24px] w-[24px] rounded-full object-fill"
                :src="establishmentStore.getIcon(establishment)"
              />
              {{ establishment.name }}
            </div>
          </DropdownItem>
        </div>
      </Dropdown>

      <slot name="main"></slot>

      <PremiumBackground
        v-if="showPremiumBackground"
        :display="premiumBackground"
      />
    </div>
    <div
      v-else
      class="h-screen w-full flex flex-col gap-md items-center justify-center p"
    >
      <span class="text-xl text-gray-900 text-center">Une erreur est survenue</span>
      <span class="text-base text-gray-700 text-center">Dites nous en plus sur comment vous êtes arrivés là</span>

      <div
        v-if="showSuccessCard"
        class="bg-white p rounded-sm flex flex-col items-center"
      >
        <Icon
          icon-name="IconCheck"
          class="text-success-500"
        />
        <span class="text-center text-success-500 text-base"
          >On a bien reçu votre message, nous vous répondrons dans les meilleurs délais</span
        >
      </div>

      <div
        v-else
        class="w-full lg:w-1/2 pb-lg"
      >
        <ContactForm
          :show-message-input="true"
          :is-button-loading="isButtonLoading"
          :required-message-input="true"
          @submit="sendContactRequest"
        />
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue"

// Components
import Icon from "@/components/Icon.vue"
import Aside from "@/modules/app/components/Aside.vue"
import Header from "@/components/Header.vue"
import Confirm from "@/components/Confirm.vue"
import NotificationBadge from "@/components/NotificationBadge.vue"
import Alerts from "./Alerts.vue"
import Dropdown from "@/components/Dropdown.vue"
import DropdownItem from "@/components/DropdownItem.vue"
import PremiumBackground from "@/components/freemium/PremiumBackground.vue"

// Stores
import { useMobileStore } from "@/stores/mobile"
import { useNotificationsStore } from "@/stores/notifications"
import { useEstablishmentStore } from "@/stores/establishment"
import { useAccountStore } from "@/stores/account"
import { useAppStore } from "@/stores/app"
import { mapStores } from "pinia"

import ContactForm from "@/modules/user/components/ContactForm.vue"
import { AlertType } from "@/static/Alert"
import { ContactVariant } from "@/static/onboarding/Contact"

// Types
import type IContactForm from "@/types/Contact.types"
import Modal from "@/components/Modal.vue"

export default defineComponent({
  name: "AppLayout",

  components: {
    Icon,
    Aside,
    Header,
    Alerts,
    Confirm,
    NotificationBadge,
    Dropdown,
    DropdownItem,
    ContactForm,
    PremiumBackground,
    Modal,
  },

  props: {
    showBackButton: {
      type: Boolean,
      default: false,
    },

    customBackButtonAction: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      validators: (value: string) => ["default", "dashboard"].includes(value),
      default: "default",
    },

    showPremiumBackground: {
      type: Boolean,
      default: false,
    },

    premiumBackground: {
      type: String,
      validators: (value: string) => ["default", "top", "bottom"].includes(value),
      default: "bottom",
    },
  },
  emits: ["back"],

  data() {
    return {
      windowWidth: window.innerWidth,
      isButtonLoading: false,
      showSuccessCard: false,
      blocked: false,
    }
  },

  computed: {
    ...mapStores(useMobileStore, useNotificationsStore, useEstablishmentStore, useAccountStore, useAppStore),
    blockApp() {
      if (this.accountStore.establishments.length === 0 && this.accountStore.establishmentLoaded) {
        return true
      } else {
        return this.blocked
      }
    },
  },

  created() {
    this.$waiting.simple(() => {
      this.$form.requiredEvent()
      this.$form.passwordReveal()
    }, 400)
  },

  methods: {
    back() {
      if (this.customBackButtonAction) {
        this.$emit("back")
      } else {
        this.$router.go(-1)
      }
    },

    async sendContactRequest(validated, form) {
      this.isButtonLoading = true

      if (!validated) {
        this.$alert.push(AlertType.error, "Le formulaire contient des erreurs.")
        this.isButtonLoading = false
      } else {
        const contactForm: IContactForm = {
          first_name: form.firstname,
          last_name: form.lastname.toUpperCase(),
          establishment_name: form.establishment_name,
          email: form.email,
          phone: form.phone,
          message: form.message,
          source: ContactVariant.ACCOUNT_WITHOUT_ESTABLISHMENT,
        }

        this.accountStore.contactHubspot(contactForm, () => {
          this.isButtonLoading = false
          this.showSuccessCard = true
        })
      }
    },
  },
})
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
