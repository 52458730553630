<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3663 2.40012C20.0027 2.40012 20.613 2.65287 21.0631 3.1028C21.513 3.55287 21.7658 4.16321 21.7658 4.7996C21.7658 5.436 21.513 6.04634 21.0631 6.4964L11.9599 15.5996H8.56629V12.206L17.6695 3.1028C18.1196 2.65287 18.7299 2.40012 19.3663 2.40012ZM3.26914 5.50214C2.81905 5.95223 2.5662 6.56268 2.5662 7.1992V19.1992C2.5662 19.8357 2.81905 20.4462 3.26914 20.8963C3.71923 21.3463 4.32968 21.5992 4.9662 21.5992H16.9662C17.6027 21.5992 18.2132 21.3463 18.6633 20.8963C19.1133 20.4462 19.3662 19.8357 19.3662 19.1992V14.3992C19.3662 14.0809 19.2398 13.7757 19.0147 13.5507C18.7897 13.3256 18.4845 13.1992 18.1662 13.1992C17.8479 13.1992 17.5427 13.3256 17.3177 13.5507C17.0926 13.7757 16.9662 14.0809 16.9662 14.3992V19.1992H4.9662V7.1992H9.7662C10.0845 7.1992 10.3897 7.07277 10.6147 6.84773C10.8398 6.62269 10.9662 6.31746 10.9662 5.9992C10.9662 5.68094 10.8398 5.37572 10.6147 5.15067C10.3897 4.92563 10.0845 4.7992 9.7662 4.7992H4.9662C4.32968 4.7992 3.71923 5.05206 3.26914 5.50214Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPencilAlt",
  props: ["height", "width"],
}
</script>
