import { NotificationType } from "@/types/Notifications.types"

export const charteNotifications: { [name: string]: { container: string; icon: string | null; iconColor: string } } = {
  [NotificationType.REVIEW]: {
    container: "bg-primary-50 hover:bg-primary-100",
    icon: "IconAnnotation",
    iconColor: "text-primary-500",
  },
  [NotificationType.TIC]: {
    container: "bg-purple-50 hover:bg-purple-100",
    icon: "IconLink",
    iconColor: "text-purple-500",
  },
  [NotificationType.SOLICITATION]: {
    container: "bg-blue-50 hover:bg-blue-100",
    icon: null,
    iconColor: "text-purple-500",
  },
}
