<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 12C22 12.3788 21.786 12.725 21.4472 12.8944L3.44722 21.8944C3.10217 22.067 2.68866 22.0256 2.38459 21.7882C2.08052 21.5508 1.94013 21.1597 2.02382 20.7831L3.97561 12L2.02382 3.21694C1.94013 2.84035 2.08052 2.44921 2.38459 2.2118C2.68866 1.97439 3.10217 1.93306 3.44722 2.10558L21.4472 11.1056C21.786 11.275 22 11.6212 22 12ZM5.80217 13L4.43195 19.166L18.7639 12L4.43195 4.83402L5.80217 11H13C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13H5.80217Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconPaperPlane",
  props: ["height", "width"],
}
</script>
