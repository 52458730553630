<template>
  <div
    class="alert flex flex-col items-center"
    :class="[color, type]"
  >
    <div class="flex gap-md justify-center">
      <Icon
        v-if="icon"
        :icon-name="icon"
      />
      <slot></slot>
    </div>

    <small v-if="message">{{ message }}</small>
  </div>
</template>

<script lang="ts">
import Icon from "./Icon.vue"

export default {
  name: "Alert",
  components: {
    Icon,
  },
  props: {
    icon: String,

    color: {
      type: String,
      validators: (value: string) => ["primary", "gray", "error", "success", "warning", "light"].includes(value),
      default: "primary",
    },

    type: {
      type: String,
      validators: (value: string) => ["default", "outlined", "dashed"].includes(value),
      default: "default",
    },

    message: {
      type: String,
      default: null,
    },
  },
}
</script>
