<template>
  <DashboardSection
    icon="IconGoogleColored"
    :title="$t('home.google.title')"
    :description="disabled ? $t('home.google.premium_description') : $t('home.google.description')"
  >
    <template #actions>
      <div v-if="disabled">
        <Button @click="$router.push({ name: 'freemium-comparison' })">
          <Icon icon-name="IconCrownOutlined" />
          {{ $t("common.premium_button") }}
        </Button>
      </div>
      <div
        v-else
        class="switch-group gap-sm"
      >
        <span>{{ $t("home.google.button-1") }}</span>
        <Switch
          :checked="showPreviousGoogleStat"
          :disabled="false"
          @update="googleSwitch"
        />
      </div>
    </template>

    <div
      v-if="!disabled"
      class="grid grid-cols-1 xl:grid-cols-2 gap-sm md:gap"
    >
      <ClicksNumber
        :stats="data.clickEvolution"
        :current-filter="filter"
        :show-previous-data="showPreviousGoogleStat"
      />
      <ViewsNumber
        v-model="showPreviousGoogleStat"
        :stats="data.viewEvolution"
        :current-filter="filter"
        :show-previous-data="showPreviousGoogleStat"
      />
      <div
        v-if="!isEssential"
        class="flex flex-col xl:flex-row items-start col-span-1 xl:col-span-2 bg-white rounded-md"
      >
        <ReviewsAnswer
          v-model="showPreviousGoogleStat"
          class="w-full xl:w-[45%]"
          :stats="data.reviews"
        />
        <ReviewsRating
          v-model="showPreviousGoogleStat"
          class="w-full xl:w-[55%]"
          :stats="data.reviews"
        />
      </div>
    </div>
  </DashboardSection>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import Switch from "@/components/form/Switch.vue"

import DashboardSection from "../DashboardSection.vue"

import ClicksNumber from "@/components/dashboard/google/ClicksNumber.vue"
import ViewsNumber from "@/components/dashboard/google/ViewsNumber.vue"
import ReviewsAnswer from "@/components/dashboard/google/ReviewsAnswer.vue"
import ReviewsRating from "@/components/dashboard/google/ReviewsRating.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useEstablishmentStore } from "@/stores/establishment"
import { mapStores } from "pinia"

// Types
import type { IGoogleStats } from "@/types/Stats.types"
import Button from "@/components/Button.vue"
import { EstablishmentProductPlan } from "@/static/Establishment"
// Data
//

export default defineComponent({
  name: "GoogleSection",

  components: { Icon, ClicksNumber, ViewsNumber, ReviewsAnswer, ReviewsRating, Switch, DashboardSection, Button },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showPreviousGoogleStat: false,
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useEstablishmentStore),

    data() {
      return {
        clickEvolution: this.dashboardStore.clickEvolution,
        viewEvolution: this.dashboardStore.viewEvolution,
        reviews: this.dashboardStore.reviewsStats,
      } as IGoogleStats
    },

    filter() {
      return this.dashboardStore.currentPeriodFilter.code
    },

    isEssential() {
      return (
        this.establishmentStore.currentEstablishment &&
        this.establishmentStore.currentEstablishment.product_plan.code === EstablishmentProductPlan.ESSENTIAL
      )
    },
  },

  methods: {
    googleSwitch() {
      this.showPreviousGoogleStat = !this.showPreviousGoogleStat
    },
  },
})
</script>
