import { defineStore } from "pinia"
import NotificationDataService from "@/services/NotificationDataService"
import { useAuthenticationStore } from "./authentication"
import type { INotification } from "@/types/Notifications.types"

export const useNotificationsStore = defineStore("notifications", {
  state: () => ({
    notifications: [] as INotification[],
  }),

  actions: {
    getNotifications() {
      const auth = useAuthenticationStore()
      NotificationDataService.getNotifications(auth.currentHeaders).then((res) => {
        this.notifications = res.data.data
      })
    },

    deleteNotification(id: number) {
      const index = this.notifications.findIndex((notif: INotification) => notif.id === id)
      this.notifications.splice(index, 1)
      NotificationDataService.deleteNotification(id)
    },
  },
})
