import type { AsideListItem } from "@/types/ListItem.types"

// Notifier l'api si il y a une variante en plus ou si une existante change
export enum ContactVariant {
  DEFAULT = "default",
  ERR_LOADING_ESTABLISHMENT = "ERR_LOADING_ESTABLISHMENT",
  ERR_WRONG_ESTABLISHMENT = "ERR_WRONG_ESTABLISHMENT",
  HELP_CREATE_ACCOUNT = "HELP_CREATE_ACCOUNT",
  PREMIUM_CONVERSION = "PREMIUM_CONVERSION",
  PREMIUM_MEETING = "PREMIUM_MEETING",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  FREEMIUM_CONTACT = "FREEMIUM_CONTACT",
  CREATE_ACCOUNT_FROM_DAHUB = "CREATE_ACCOUNT_FROM_DAHUB",
  CREATE_PREMIUM_ESTABLISHMENT = "CREATE_PREMIUM_ESTABLISHMENT",
  SELECT_ESTABLISHMENT_FREEMIUM = "SELECT_ESTABLISHMENT_FREEMIUM",
  ACCOUNT_WITHOUT_ESTABLISHMENT = "ACCOUNT_WITHOUT_ESTABLISHMENT",
}

export enum OfferCode {
  PREMIUM = "PREMIUM",
  ESSENTIAL = "ESSENTIAL",
}

export const defaultAsideList: AsideListItem[] = [
  {
    iconName: "IconChat",
    i18ncode: "first",
  },
  {
    iconName: "IconChartBar",
    i18ncode: "second",
  },
  {
    iconName: "IconCheck",
    i18ncode: "third",
  },
]

export const errorWrongEtabAsideList: AsideListItem[] = [
  {
    iconName: "IconLibrary",
    i18ncode: "first",
  },
  {
    iconName: "IconX",
    i18ncode: "second",
  },
  {
    iconName: "IconPencil",
    i18ncode: "third",
  },
]

export const errorLoadingEtabAsideList: AsideListItem[] = [
  {
    iconName: "IconLibrary",
    i18ncode: "first",
  },
  {
    iconName: "IconX",
    i18ncode: "second",
  },
  {
    iconName: "IconPencil",
    i18ncode: "third",
  },
  {
    iconName: "IconSearch",
    i18ncode: "fourth",
  },
]
