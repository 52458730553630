export interface IGoogleStats {
  clickEvolution: IClickStatsForDashboard
  viewEvolution: IViewStatsForDashboard
  reviews: IReviewStatsForDashboard
}

export interface IFacebookStats {
  engagementEvolution: IEngagementStats
  likeEvolution: ILikeStats
  fans: IGenderStats
}

// ================== Clicks
export interface IClickStats {
  current_click_evolution: IClickStatItem[]
  last_year_click_evolution: IClickStatItem[]
}

export interface IClickStatsForDashboard extends IClickStats {
  current_click_total_drive: number
  current_click_total_phone: number
  current_click_total_website: number
  last_year_click_total_drive: number
  last_year_click_total_phone: number
  last_year_click_total_website: number
}
export interface IClickStatItem {
  date: String
  clickWebsite: number
  clickPhone: number
  clickDrive: number
}

// ================== Views
export interface IViewStats {
  current_view_evolution: IViewStatItem[]
  last_year_view_evolution: IViewStatItem[]
}

export interface IViewStatsForDashboard extends IViewStats {
  current_view_total_maps: number
  current_view_total_search: number
  last_year_view_total_maps: number
  last_year_view_total_search: number
}
export interface IViewStatItem {
  date: String
  view_desktop_maps: number
  view_mobile_maps: number
  view_desktop_search: number
  view_mobile_search: number
}

// ================== Reviews Answer & Notes
export interface IReviewAnswerStats {
  current: IReviewAnswerStatItem
  last_year: IReviewAnswerStatItem
}
export interface IReviewStatsForDashboard extends IReviewAnswerStats {
  current_total: number
  last_year_total: number
}
export interface IReviewAnswerStatItem {
  reviews_google_sum: number
  reviews_facebook_sum: number
  reviews_answered: number
  reviews_not_answered: number
  per_star: IReviewNoteItem
}
export interface IReviewNoteItem {
  one: number
  two: number
  three: number
  four: number
  five: number
}

// ================== Publication engagement
export interface IEngagementStats {
  current_engagement_evolution: IEngagementItem[]
  last_year_engagement_evolution: IEngagementItem[]
}

export interface IEngagementStatsForDashboard extends IEngagementStats {
  currentTotal: number
  previousTotal: number
}

export interface IEngagementItem {
  period: string
  count: number
}

// ================== Mentions
export interface ILikeStats {
  current: ILikeItem
  last_year: ILikeItem
}

export interface ILikeItem {
  like_added: number
  like_count: number
  like_removed: number
  like_evolution: ILikeEvolutionItem[]
}

export interface ILikeEvolutionItem {
  date: string
  count: number
}

// ================== Fans
export interface IGenderStats {
  current_gender_age_partition: IGenderItem
  last_year_gender_age_partition: IGenderItem
}

export interface IGenderStatsForDashboard extends IGenderStats {
  currentManTotal: number
  currentWomanTotal: number
  previousManTotal: number
  previousWomanTotal: number
}

export interface IGenderItem {
  // 13-17 ans
  PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_FEMALE: number
  PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_MALE: number

  // 18-20 ans
  PAGE_FANS_GENDER_AGE_EIGHTEEN_TO_TWENTY_FOUR_FEMALE: number
  PAGE_FANS_GENDER_AGE_EIGHTEEN_TO_TWENTY_FOUR_MALE: number

  // 25-34 ans
  PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_FEMALE: number
  PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_MALE: number

  // 35-44 ans
  PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_FEMALE: number
  PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_MALE: number

  // 45-54 ans
  PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_FEMALE: number
  PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_MALE: number

  // 55-64 ans
  PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE: number
  PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE: number

  // 65+
  PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE: number
  PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE: number
}

// ================== Display Stat Type
export enum DisplayStat {
  AUTO,
  HIDE,
  SHOW,
}
