<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 8.18182C16 7.61455 15.9481 7.06909 15.8516 6.54545H8.16327V9.64364H12.5566C12.3636 10.64 11.7848 11.4836 10.9165 12.0509V14.0655H13.5659C15.1095 12.6691 16 10.6182 16 8.18182Z"
    />
    <path
      d="M8.16327 16C10.3673 16 12.2152 15.2873 13.5659 14.0655L10.9165 12.0509C10.1892 12.5309 9.2616 12.8218 8.16327 12.8218C6.04082 12.8218 4.23748 11.4182 3.59184 9.52727H0.875696V11.5927C2.21892 14.2036 4.97217 16 8.16327 16Z"
    />
    <path
      d="M3.59184 9.52C3.42857 9.04 3.3321 8.53091 3.3321 8C3.3321 7.46909 3.42857 6.96 3.59184 6.48V4.41455H0.875696C0.31911 5.49091 0 6.70545 0 8C0 9.29455 0.31911 10.5091 0.875696 11.5855L2.99072 9.97091L3.59184 9.52Z"
    />
    <path
      d="M8.16327 3.18545C9.36549 3.18545 10.4341 3.59273 11.2876 4.37818L13.6252 2.08727C12.2078 0.792727 10.3673 0 8.16327 0C4.97217 0 2.21892 1.79636 0.875696 4.41455L3.59184 6.48C4.23748 4.58909 6.04082 3.18545 8.16327 3.18545Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconGoogle",
  props: ["height", "width"],
}
</script>
