<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.72402 1.72402C3.97407 1.47397 4.31321 1.3335 4.66683 1.3335C5.02045 1.3335 5.35959 1.47397 5.60964 1.72402C5.85969 1.97407 6.00016 2.31321 6.00016 2.66683C6.00016 3.02045 5.85969 3.35959 5.60964 3.60964C5.35959 3.85969 5.02045 4.00016 4.66683 4.00016C4.31321 4.00016 3.97407 3.85969 3.72402 3.60964C3.47397 3.35959 3.3335 3.02045 3.3335 2.66683C3.3335 2.31321 3.47397 1.97407 3.72402 1.72402Z"
    />
    <path
      d="M10.3905 1.72402C10.6406 1.47397 10.9797 1.3335 11.3333 1.3335C11.687 1.3335 12.0261 1.47397 12.2761 1.72402C12.5262 1.97407 12.6667 2.31321 12.6667 2.66683C12.6667 3.02045 12.5262 3.35959 12.2761 3.60964C12.0261 3.85969 11.687 4.00016 11.3333 4.00016C10.9797 4.00016 10.6406 3.85969 10.3905 3.60964C10.1405 3.35959 10 3.02045 10 2.66683C10 2.31321 10.1405 1.97407 10.3905 1.72402Z"
    />
    <path
      d="M3.72402 7.05703C3.97407 6.80698 4.31321 6.6665 4.66683 6.6665C5.02045 6.6665 5.35959 6.80698 5.60964 7.05703C5.85969 7.30708 6.00016 7.64622 6.00016 7.99984C6.00016 8.35346 5.85969 8.6926 5.60964 8.94265C5.35959 9.1927 5.02045 9.33317 4.66683 9.33317C4.31321 9.33317 3.97407 9.1927 3.72402 8.94265C3.47397 8.6926 3.3335 8.35346 3.3335 7.99984C3.3335 7.64622 3.47397 7.30708 3.72402 7.05703Z"
    />
    <path
      d="M10.3905 7.05703C10.6406 6.80698 10.9797 6.6665 11.3333 6.6665C11.687 6.6665 12.0261 6.80698 12.2761 7.05703C12.5262 7.30708 12.6667 7.64622 12.6667 7.99984C12.6667 8.35346 12.5262 8.6926 12.2761 8.94265C12.0261 9.1927 11.687 9.33317 11.3333 9.33317C10.9797 9.33317 10.6406 9.1927 10.3905 8.94265C10.1405 8.6926 10 8.35346 10 7.99984C10 7.64622 10.1405 7.30708 10.3905 7.05703Z"
    />
    <path
      d="M3.72402 12.3905C3.97407 12.1405 4.31321 12 4.66683 12C5.02045 12 5.35959 12.1405 5.60964 12.3905C5.85969 12.6406 6.00016 12.9797 6.00016 13.3333C6.00016 13.687 5.85969 14.0261 5.60964 14.2761C5.35959 14.5262 5.02045 14.6667 4.66683 14.6667C4.31321 14.6667 3.97407 14.5262 3.72402 14.2761C3.47397 14.0261 3.3335 13.687 3.3335 13.3333C3.3335 12.9797 3.47397 12.6406 3.72402 12.3905Z"
    />
    <path
      d="M10.3905 12.3905C10.6406 12.1405 10.9797 12 11.3333 12C11.687 12 12.0261 12.1405 12.2761 12.3905C12.5262 12.6406 12.6667 12.9797 12.6667 13.3333C12.6667 13.687 12.5262 14.0261 12.2761 14.2761C12.0261 14.5262 11.687 14.6667 11.3333 14.6667C10.9797 14.6667 10.6406 14.5262 10.3905 14.2761C10.1405 14.0261 10 13.687 10 13.3333C10 12.9797 10.1405 12.6406 10.3905 12.3905Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconDragDrop",
  props: ["height", "width"],
}
</script>
