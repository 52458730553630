<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.6004 12C21.6004 14.5461 20.589 16.9879 18.7886 18.7882C16.9883 20.5886 14.5465 21.6 12.0004 21.6C9.45431 21.6 7.01252 20.5886 5.21217 18.7882C3.41182 16.9879 2.40039 14.5461 2.40039 12C2.40039 9.45395 3.41182 7.01215 5.21217 5.2118C7.01252 3.41145 9.45431 2.40002 12.0004 2.40002C14.5465 2.40002 16.9883 3.41145 18.7886 5.2118C20.589 7.01215 21.6004 9.45395 21.6004 12ZM13.2004 16.8C13.2004 17.1183 13.074 17.4235 12.8489 17.6486C12.6239 17.8736 12.3187 18 12.0004 18C11.6821 18 11.3769 17.8736 11.1519 17.6486C10.9268 17.4235 10.8004 17.1183 10.8004 16.8C10.8004 16.4818 10.9268 16.1765 11.1519 15.9515C11.3769 15.7265 11.6821 15.6 12.0004 15.6C12.3187 15.6 12.6239 15.7265 12.8489 15.9515C13.074 16.1765 13.2004 16.4818 13.2004 16.8ZM12.0004 6.00002C11.6821 6.00002 11.3769 6.12645 11.1519 6.3515C10.9268 6.57654 10.8004 6.88176 10.8004 7.20002V12C10.8004 12.3183 10.9268 12.6235 11.1519 12.8486C11.3769 13.0736 11.6821 13.2 12.0004 13.2C12.3187 13.2 12.6239 13.0736 12.8489 12.8486C13.074 12.6235 13.2004 12.3183 13.2004 12V7.20002C13.2004 6.88176 13.074 6.57654 12.8489 6.3515C12.6239 6.12645 12.3187 6.00002 12.0004 6.00002Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconExclamationCircle",
  props: ["height", "width"],
}
</script>
