<template>
  <div
    v-if="rating !== null"
    class="flex"
  >
    <Icon
      v-for="item in rating"
      :key="`star-${item}`"
      icon-name="IconStar"
      :height="16"
      :width="16"
      class="text-tertiary-500"
    />
    <Icon
      v-for="item in 5 - rating"
      :key="`startOutline-${item}`"
      icon-name="IconStarOutline"
      :height="16"
      :width="16"
      class="text-tertiary-500"
    />
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
// Layout
//

// Components
import Icon from "@/components/Icon.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "NotificationBadge",

  components: { Icon },

  props: {
    rating: {
      type: Number,
      default: 0,
    },
  },
})
</script>
