<template>
  <Dropdown>
    <template #title>
      <div
        v-if="authenticationStore.user"
        class="flex flex-col items-start no-event max-w-[80%]"
      >
        <span
          v-if="authenticationStore.user?.first_name || authenticationStore.user?.last_name"
          class="text-xl text-start break-words max-w-[100%] line-clamp-1"
        >
          {{ authenticationStore.user?.first_name }}
          {{ authenticationStore.user?.last_name }}
        </span>
        <span
          v-else
          class="text-base text-start max-w-[90%] truncate"
        >
          {{ authenticationStore.user?.email }}
        </span>
        <span class="text-gray-400 text-sm">
          {{ $t("common.roles." + authenticationStore.user?.role.code) }}
        </span>
      </div>
      <span
        v-else
        class="text-gray-400 text-sm"
        >Aucun utilisateur connecté</span
      >
    </template>

    <DropdownItem
      v-if="showProfilItem"
      to="user-profil"
    >
      <Icon icon-name="IconUser" />
      {{ $t("menu.dropdown.profile." + currentRoleMenu) }}
    </DropdownItem>

    <DropdownItem
      v-if="showSubscriptionitem"
      to="subscription-management"
    >
      <Icon icon-name="IconCreditCard" />
      {{ $t("menu.dropdown.subscription." + currentRoleMenu) }}
    </DropdownItem>

    <DropdownItem
      v-if="showContactItem"
      to="freemium-contact"
    >
      <Icon icon-name="IconChatOutline" />
      {{ $t("menu.dropdown.contact." + currentRoleMenu) }}
    </DropdownItem>

    <DropdownItem
      v-if="showUsersItem"
      to="user-management"
    >
      <Icon icon-name="IconUsers" />
      <span class="relative">
        {{ $t("menu.dropdown.management." + currentRoleMenu) }}
        <Icon
          v-if="accountStore.isFreemium"
          icon-name="IconCrown"
          class="!text-tertiary-500 h-[14px] w-[14px] absolute -right-[24px] top-0"
        />
      </span>
    </DropdownItem>

    <DropdownItem
      v-if="showNewEstablishmentItem"
      to="new-establishment"
    >
      <Icon icon-name="IconPlus" />
      {{ $t("menu.dropdown.newestablishment." + currentRoleMenu) }}
    </DropdownItem>

    <DropdownItem
      v-if="showPremiumItem"
      to="freemium-comparison"
      variant="primary"
      :background="true"
    >
      <Icon icon-name="IconCrownOutlined" />
      {{ $t("common.short_premium_button") }}
    </DropdownItem>

    <div
      v-if="!config && !blocked"
      class="h-px bg-gray-100 my-xs mx-md shadow-bordered"
    ></div>

    <DropdownItem @click="logout">
      <Icon icon-name="IconLogout" />
      {{ $t("common.logout") }}
    </DropdownItem>
  </Dropdown>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import Dropdown from "@/components/Dropdown.vue"
import DropdownItem from "@/components/DropdownItem.vue"
import Button from "@/components/Button.vue"

// Stores
import { useAuthenticationStore } from "@/stores/authentication"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "AuthenticatedUser",

  components: {
    Icon,
    Dropdown,
    DropdownItem,
    Button,
  },

  props: {
    /**
     * If this component is used in configuration mode, the dropdown list will be different
     */
    config: {
      type: Boolean,
      default: false,
    },

    blocked: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapStores(useAuthenticationStore, useAccountStore),

    currentRoleMenu() {
      if (this.authenticationStore.isAdmin) {
        return "admin"
      } else {
        return "editor"
      }
    },

    showProfilItem() {
      return !this.config && !this.blocked
    },

    showSubscriptionitem() {
      return !this.config && !this.blocked
    },

    showContactItem() {
      return !this.config && !this.accountStore.hasPremium && !this.blocked
    },

    showUsersItem() {
      return !this.config && (this.accountStore.hasPremium || this.accountStore.isFreemium) && !this.blocked
    },

    showNewEstablishmentItem() {
      return !this.config && this.authenticationStore.isAdmin && !this.blocked
    },

    showPremiumItem() {
      return !this.config && this.accountStore.isFreemium
    },
  },

  methods: {
    logout() {
      this.authenticationStore.logout()
      this.authenticationStore.clear()
      this.$router.push({ name: "auth" })
    },
  },
})
</script>
