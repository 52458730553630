<template>
  <StatCard
    :title="$t(i18nbase + '.likes.title')"
    :description="$t(i18nbase + '.likes.description')"
    icon="IconLike"
    :custom-style="showGraph ? '!h-fit' : ''"
  >
    <template
      v-if="showGraph"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".likes.total", {
              nb: $string.formatNumberToHumanString(stats.current.like_count),
            })
          }}
        </span>
        <span
          v-if="showPreviousData"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".likes.previous_total", {
              nb: $string.formatNumberToHumanString(stats.last_year.like_count),
            })
          }}
        </span>
      </div>
    </template>

    <div
      id="dashboard-circle-progress"
      class="flex justify-around gap-sm md:gap xl:gap-xl items-center"
      :class="!showGraph && '!hidden'"
    >
      <div class="flex flex-col gap-sm items-center">
        <div class="relative">
          <CircleProgress
            :percent="(stats.current.like_removed * 100) / (stats.current.like_removed + stats.current.like_added)"
            fill-color="#EF4444"
            empty-color="#ECEDF0"
            :size="100"
            :border-width="6"
            :border-bg-width="6"
            :transition="2000"
            :is-shadow="false"
            :show-percent="false"
          />
          <div
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[80px] w-[80px] bg-error-100 rounded-full flex items-center justify-center"
          >
            <span class="text-error-500 text-base">
              {{ stats.current.like_removed }}
            </span>
          </div>
        </div>
        <Icon
          icon-name="IconMinus"
          class="text-error-500"
        />
        <span class="text-base text-center w-min md:w-max text-gray-700">
          {{ $t(i18nbase + ".likes.like_removed") }}
        </span>

        <div
          v-if="showPreviousData"
          class="opacity-30 relative md:absolute md:top-0 md:left-0 flex flex-col items-center gap-sm"
        >
          <div class="relative">
            <CircleProgress
              :percent="
                (stats.last_year.like_removed * 100) / (stats.last_year.like_removed + stats.last_year.like_added)
              "
              fill-color="#EF4444"
              empty-color="#ECEDF0"
              :size="50"
              :border-width="4"
              :border-bg-width="4"
              :transition="2000"
              :is-shadow="false"
              :show-percent="false"
            />
            <span class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-error-500 text-base">
              {{ stats.last_year.like_removed }}
            </span>
          </div>
          <span class="text-sm text-center w-min text-gray-700">
            {{ $t(i18nbase + ".likes.previous_title") }}
          </span>
        </div>
      </div>
      <div class="flex flex-col gap-sm items-center">
        <div class="relative">
          <CircleProgress
            :percent="(stats.current.like_added * 100) / (stats.current.like_removed + stats.current.like_added)"
            fill-color="#34CAA1"
            empty-color="#ECEDF0"
            :size="100"
            :border-width="6"
            :border-bg-width="6"
            :transition="2000"
            :is-shadow="false"
            :show-percent="false"
          />
          <div
            class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[80px] w-[80px] bg-secondary-200 rounded-full flex items-center justify-center"
          >
            <span class="text-secondary-500 text-base">
              {{ stats.current.like_added }}
            </span>
          </div>
        </div>
        <Icon
          icon-name="IconPlus"
          class="text-secondary-500"
        />
        <span class="text-base text-center w-min md:w-max text-gray-700">
          {{ $t(i18nbase + ".likes.like_added") }}
        </span>

        <div
          v-if="showPreviousData"
          class="opacity-30 relative md:absolute md:top-0 md:right-0 flex flex-col items-center gap-sm"
        >
          <div class="relative">
            <CircleProgress
              :percent="
                (stats.last_year.like_added * 100) / (stats.last_year.like_removed + stats.last_year.like_added)
              "
              fill-color="#34CAA1"
              empty-color="#ECEDF0"
              :size="50"
              :border-width="4"
              :border-bg-width="4"
              :transition="2000"
              :is-shadow="false"
              :show-percent="false"
            />
            <span class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-secondary-500 text-base">
              {{ stats.last_year.like_added }}
            </span>
          </div>
          <span class="text-sm text-center w-min text-gray-700">
            {{ $t(i18nbase + ".likes.previous_title") }}
          </span>
        </div>
      </div>
    </div>
    <div
      :class="showGraph && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + noDataKey) }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { defineComponent, type PropType } from "vue"
import CircleProgress from "vue3-circle-progress"

// Layout
//

// Components
import StatCard from "../StatCard.vue"
import Icon from "@/components/Icon.vue"

// Stores
import {mapStores} from "pinia"
import {useListingStore} from "@/stores/listing"

// Types
import type { ILikeStats } from "@/types/Stats.types"


// Data
//

export default defineComponent({
  name: "MentionsNumber",

  components: { StatCard, CircleProgress, Icon },

  props: {
    stats: {
      type: Object as PropType<ILikeStats>,
      required: true,
    },

    showPreviousData: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      i18nbase: "home.facebook",
    }
  },

  computed: {
    ...mapStores(
      useListingStore
    ),

    showGraph() {
      return this.stats.current.like_evolution.length > 0
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus('FACEBOOK') === 'CONNECTED' ? 'likes.no_data' : 'not_connected'
    }
  },
})
</script>
