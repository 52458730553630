<template>
  <div
    v-if="isLoading"
    class="w-full h-full border border-gray-100 rounded-sm p-md flex flex-col justify-center items-center"
  >
    <LottieAnimation
      variant="buttonPrimary"
      :height="50"
      :width="50"
    />
  </div>

  <div
    v-else
    class="relative min-w-[100px] w-full h-full border border-gray-100 rounded-sm p-sm flex flex-col justify-center items-center gap-xs xl:gap-md"
  >
    <div class="relative">
      <RoundedDiffuserIcon
        :diffuser="diffuser"
        :logo="item.logo"
        :size="40"
      />
      <div class="absolute -right-3 -bottom-1">
        <ListingItemSyncState
          v-if="item.status !== 'ERROR_NOT_FOUND' && !isLocalDiffuser"
          :state="item.status"
          :is-dashboard="true"
          :i18nbase="i18nbase + '.states'"
          :show-title="false"
        />
      </div>
    </div>

    <div class="w-full flex items-center justify-center">
      <ListingItemConnectState
        v-if="connectUrl && showAction"
        :connected="item.status !== 'ERROR_NOT_FOUND'"
        :url="connectUrl"
        :disabled="isFreemium"
        :i18nbase="i18nbase"
      >
        {{ $t(i18nbase + ".connect_button") }}
      </ListingItemConnectState>

      <ListingItemConnectState
        v-else-if="claimUrl && showAction"
        :connected="item.status !== 'ERROR_NOT_FOUND'"
        :url="connectUrl"
        :i18nbase="i18nbase"
      >
        Vérifier
      </ListingItemConnectState>

      <ListingItemConnectState
        v-else-if="!connectUrl && showAction"
        :connected="item.status !== 'ERROR_NOT_FOUND'"
        :i18nbase="i18nbase"
        @invite="sendInvite"
      >
        {{ $t(i18nbase + ".connect_button") }}
      </ListingItemConnectState>
    </div>

    <FreemiumBlur
      v-if="disabled"
      color="white"
    />
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import type { PropType } from "vue"

// Layout
//

// Components
import RoundedDiffuserIcon from "@/components/RoundedDiffuserIcon.vue"
import ListingItemConnectState from "@/modules/establishment/components/ListingItemConnectState.vue"
import LottieAnimation from "@/components/LottieAnimation.vue"
import Button from "@/components/Button.vue"
import ListingItemSyncState from "@/modules/establishment/components/ListingItemSyncState.vue"
import FreemiumBlur from "@/components/freemium/FreemiumBlur.vue"

// Stores
import { useDiffuserStore } from "@/stores/diffuser"
import { useDashboardStore } from "@/stores/dashboard"
import { useEstablishmentStore } from "@/stores/establishment"
import { useLocalDiffuserStore } from "@/stores/localDiffuser"
import { useAccountStore } from "@/stores/account"
import { mapStores } from "pinia"

// Types
import type { ISync } from "@/types/Dashboard.types"
import { AllDiffuser, DiffuserType, ConnectRedirectionUrl } from "@/static/Diffuser"
import {ListingStatus} from "@/static/Listing";

// Data
//

export default defineComponent({
  name: "SyncDiffuserItem",

  components: {
    RoundedDiffuserIcon,
    ListingItemConnectState,
    LottieAnimation,
    Button,
    ListingItemSyncState,
    FreemiumBlur,
  },

  props: {
    item: {
      type: Object as PropType<ISync>,
      required: true,
    },

    isLocalDiffuser: {
      type: Boolean,
      default: false,
    },

    showAction: {
      type: Boolean,
      default: true,
    },

    isFreemium: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["invite"],

  data() {
    return {
      i18nbase: "home.synchronisation",
      connectUrl: "",
      claimUrl: "",
      isLoading: true,
    }
  },

  computed: {
    ...mapStores(useDiffuserStore, useEstablishmentStore, useDashboardStore, useLocalDiffuserStore, useAccountStore),

    diffuser() {
      if (this.isLocalDiffuser) {
        let obj = this.item.diffuser
        obj.name = this.establishmentStore.currentEstablishment.local_diffusers[0].label
        this.item.logo = this.localDiffuserStore.freemiumLocalDiffuserLogoUrl
        return obj
      } else {
        return this.item.diffuser
      }
    },
  },

  created() {
    if (!this.isLocalDiffuser && !this.hasDiffuserConnected()) {
      if (this.item.type === AllDiffuser.GOOGLE) {
        this.diffuserStore.getDiffuserConnexionUrl(
          this.establishmentStore.currentEstablishment.id,
          DiffuserType.GOOGLE,
          ConnectRedirectionUrl.HOME,
          (connect) => {
            this.setUrls(connect)
            this.isLoading = false
          }
        )
      } else if (this.item.type === AllDiffuser.FACEBOOK) {
        this.diffuserStore.getDiffuserConnexionUrl(
          this.establishmentStore.currentEstablishment.id,
          DiffuserType.FACEBOOK,
          ConnectRedirectionUrl.HOME,
          (connect) => {
            this.setUrls(connect)
            this.isLoading = false
          }
        )
      } else {
        this.isLoading = false
      }
    } else {
      this.isLoading = false
    }
  },

  methods: {
    sendInvite() {
      this.localDiffuserStore.sendInvite(this.item.id, this.establishmentStore.currentEstablishment.id, () => {
        this.$emit("invite")
      })
    },

    setUrls(connect: { connect_url: string; claim_url: string }) {
      if (connect.connect_url) {
        this.connectUrl = connect.connect_url
      }
      if (connect.claim_url) {
        this.claimUrl = connect.claim_url
      }
    },

    hasDiffuserConnected() {
      return [
          ListingStatus.WAIT_FOR_SYNC,
          ListingStatus.SYNCHRONIZED,
          ListingStatus.SUBMITTED,
          ListingStatus.BEING_UPDATED,
          ListingStatus.SYNC_IN_PROGRESS,
          ListingStatus.SUBMITTED
      ].includes(this.item.status)
    }
  },
})
</script>
