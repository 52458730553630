<template>
  <div
    class="relative bg-white rounded-md p flex flex-col gap"
    :class="disabled && 'border'"
  >
    <div class="flex flex-col">
      <div class="flex justify-between items-start">
        <div class="flex flex-col gap-sm">
          <div class="flex gap-sm items-center">
            <span class="text-xl text-gray-700">{{ title }}</span>
            <Tooltip
              v-if="info"
              variant-x="left"
              variant-y="down"
              variant-mobile-x="left"
              variant-mobile-y="down"
              size="tiny"
            >
              <template #main>
                <Icon
                  icon-name="IconInformationCircle"
                  class="text-gray-700 min-w-[18px]"
                  :height="18"
                  :width="18"
                />
              </template>
              <template #content> {{ info }} </template>
            </Tooltip>
          </div>

          <span class="text-sm text-gray-500">{{ description }}</span>
        </div>
        <slot name="actions"></slot>
      </div>
    </div>
    <slot></slot>

    <FreemiumBlur v-if="disabled" />
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"

// Layout
//

// Components
import Tooltip from "@/components/Tooltip.vue"
import Icon from "@/components/Icon.vue"
import FreemiumBlur from "@/components/freemium/FreemiumBlur.vue"

// Stores
//

// Types
//

// Data
//

export default defineComponent({
  name: "StatisticCard",

  components: {
    Tooltip,
    Icon,
    FreemiumBlur,
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    description: {
      type: String,
      required: false,
      default: "",
    },

    info: {
      type: String,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
