import LocalDiffuserDataService from "@/services/LocalDiffuserDataService"
import { defineStore } from "pinia"
import { useAuthenticationStore } from "./authentication"
import { useAlertStore } from "./alert"
import { i18n } from "@/i18n"
import { AlertType } from "@/static/Alert"
import type { ILocalListing } from "@/types/Listing.types"
import type { ISolicitationItem } from "@/types/Solicitation.types"
import { SolicitationState } from "@/static/Solicitation"
import { useEstablishmentStore } from "./establishment"
import { useAccountStore } from "./account"
import { EstablishmentProductPlan, freemiumPlans } from "@/static/Establishment"

export const useLocalDiffuserStore = defineStore("localDiffuser", {
  state: () => ({
    draftSolicitations: [] as ISolicitationItem[],

    currentSolicitations: [] as ISolicitationItem[],

    archivedSolicitations: [] as ISolicitationItem[],

    isLoading: false,

    freemiumLocalDiffuserLogoUrl: null as String | null,
  }),

  actions: {
    clear() {
      this.draftSolicitations = [] as ISolicitationItem[]
      this.currentSolicitations = [] as ISolicitationItem[]
      this.archivedSolicitations = [] as ISolicitationItem[]

      this.isLoading = false

      this.freemiumLocalDiffuserLogoUrl = null as String | null
    },

    sendInvite(ticId: number, establishmentId: number, successCallback?: () => void) {
      const authStore = useAuthenticationStore()
      const alertStore = useAlertStore()
      LocalDiffuserDataService.createLocalDiffuserConnexion(ticId, establishmentId, authStore.currentHeaders).then(
        () => {
          alertStore.push(AlertType.success, "Demande envoyée")
          if (successCallback) {
            successCallback()
          }
        },
        (e) => {
          switch (e.response.status) {
            case 401:
              break
            case 503:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.hubo_server_error"), e.message)
              break
          }
        }
      )
    },

    solicitations(callback?: (archivedSolicitations: ISolicitationItem[]) => void) {
      const establishmentStore = useEstablishmentStore()

      this.isLoading = true

      this.draftSolicitations = [] as ISolicitationItem[]
      this.currentSolicitations = [] as ISolicitationItem[]
      this.archivedSolicitations = [] as ISolicitationItem[]

      const localDiffusers: ILocalListing[] = establishmentStore.currentEstablishment.local_diffusers

      if (localDiffusers.length > 0) {
        const promises: Promise<any>[] = []
        localDiffusers.forEach((localDiffuser) => {
          const promise = LocalDiffuserDataService.getLocalDiffuserSolicitations(
            Number(localDiffuser.external_id)
          ).then(
            (res) => {
              const resSolicitations: ISolicitationItem[] = res.data.data.solicitations

              if (resSolicitations && resSolicitations.length) {
                let resDraft: ISolicitationItem[] = []
                let resCurrent: ISolicitationItem[] = []

                resSolicitations.forEach((solicitation) => {
                  if (solicitation.state === SolicitationState.DRAFT) {
                    resDraft.push(solicitation)
                  } else {
                    resCurrent.push(solicitation)
                  }
                })

                this.currentSolicitations = this.currentSolicitations.concat(resCurrent)
                this.draftSolicitations = this.draftSolicitations.concat(resDraft)
              }

              const resArchivedSolicitations: ISolicitationItem[] = res.data.data.old_solicitations
              if (resArchivedSolicitations && resArchivedSolicitations.length) {
                this.archivedSolicitations = this.archivedSolicitations.concat(resArchivedSolicitations)
              }
            },
            (e) => {
              const authStore = useAuthenticationStore()
              const alertStore = useAlertStore()
              switch (e.response.status) {
                case 401:
                  break
                case 503:
                  alertStore.push(AlertType.error, i18n.global.t("common.errors.hubo_server_error"), e.message)
                  break
                default:
                  alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
                  break
              }
            }
          )
          promises.push(promise)
        })

        Promise.all(promises).then(() => {
          this.currentSolicitations = this._sortSolicitationBydate(this.currentSolicitations)
          this.draftSolicitations = this._sortSolicitationBydate(this.draftSolicitations)
          this.archivedSolicitations = this._sortSolicitationBydate(this.archivedSolicitations)

          if (callback) {
            callback(this.archivedSolicitations)
          }

          this.isLoading = false
        })
      } else {
        this.isLoading = false
      }
    },

    logo() {
      const authStore = useAuthenticationStore()
      const accountStore = useAccountStore()
      const freemiumEstablishment = accountStore.establishments.filter((establishment) =>
        freemiumPlans.includes(establishment.product_plan.code)
      )[0]
      LocalDiffuserDataService.getFreemiumLogo(freemiumEstablishment.id, authStore.currentHeaders).then((res) => {
        if (res.data.data.logo) {
          this.freemiumLocalDiffuserLogoUrl = res.data.data.logo
        }
      })
    },

    _sortSolicitationBydate(solicitations: ISolicitationItem[]) {
      return solicitations.sort((a: ISolicitationItem, b: ISolicitationItem) => {
        return +new Date(b.created_at) - +new Date(a.created_at)
      })
    },
  },

  persist: {
    enabled: true,
  },
})
