<template>
  <StatCard
    :title="$t(i18nbase + '.gender.title')"
    :description="$t(i18nbase + '.gender.description')"
    icon="IconHeart"
  >
    <template
      v-if="showGraph.show"
      #actions
    >
      <div class="flex items-center gap-sm">
        <FilterItem
          v-for="(legend, index) in legendItems"
          :key="'legend-' + index"
          :legend="legend"
          :index="index"
          @clicked="legendClicked(index, legend, modelValue)"
        >
          {{ $t(i18nbase + ".gender.legend." + legend.label) }}
        </FilterItem>
      </div>
    </template>

    <!-- <template
      v-if="showGraph"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".total", {
              nb: total,
            })
          }}
        </span>
        <span
          v-if="modelValue && previousTotal !== null"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".previous_total", {
              nb: previousTotal,
            })
          }}
        </span>
      </div>
    </template> -->

    <Bar
      ref="chartRef"
      :class="!showGraph.show || selectedLegends.length === 0 ? '!hidden' : ''"
      :data="data"
      :options="chartOptions"
    />

    <EmptyData
      v-if="!showGraph.show && showGraph.message === 'empty_data'"
      :message="$t(i18nbase + '.' + noDataKey)"
    />

    <div
      v-else-if="!showGraph.show"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + showGraph.message) }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { ref, type PropType } from "vue"
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, TimeScale } from "chart.js"
import { Bar, type ChartComponentRef } from "vue-chartjs"

// Layout
//

// Components
import StatCard from "../StatCard.vue"
import FilterItem from "../FilterItem.vue"
import EmptyData from "../EmptyData.vue"

// Stores
import {mapStores} from "pinia"
import {useListingStore} from "@/stores/listing"

// Types
//

// Data
import * as chartConfig from "@/static/dashboard/Gender"
import { DisplayStat, type IGenderStatsForDashboard } from "@/types/Stats.types"
import { initShowingPreviousData, displayStats } from "@/plugins/GraphPlugin"

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, TimeScale)

export default {
  name: "GenderNumber",

  components: { Bar, StatCard, FilterItem, EmptyData },

  props: {
    stats: {
      type: Object as PropType<IGenderStatsForDashboard>,
      required: true,
    },

    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const chartRef = ref<ChartComponentRef>(null)
    const legendItems = ref(
      chartConfig.legends
        .filter((item) => !item.label.includes("-1"))
        .map((item) => {
          item.hidden = false
          return item
        })
    )

    const setupPreviousStats = (modelValue: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      if (chart.data.datasets.length > 0) {
        initShowingPreviousData(chart, chartConfig.legends, modelValue, legendItems.value)
      }
    }

    const legendClicked = (index: number, legend, previousStat: Boolean) => {
      const {
        value: { chart },
      } = chartRef

      if (!chart) {
        return
      }

      // Hide current stats
      const realIndex = chartConfig.legends.findIndex((item) => item.label === legend.label)
      displayStats(chart, realIndex, DisplayStat.AUTO, (action) => {
        if (action === "hide") {
          legendItems.value[index].hidden = true
        } else {
          legendItems.value[index].hidden = false
        }
      })

      // Hide previous stats
      if (previousStat) {
        const indexLastYear = chartConfig.legends.findIndex((item) => item.label === legend.label + " n-1")
        displayStats(chart, indexLastYear, DisplayStat.AUTO)
      }
    }

    return {
      chartRef,
      legendItems,
      setupPreviousStats,
      legendClicked,
      ...chartConfig,
    }
  },

  data() {
    return {
      i18nbase: "home.facebook",
      chartConfig: chartConfig,
      isLoading: true,
    }
  },

  computed: {
    ...mapStores(
      useListingStore
    ),

    data() {
      // ATTENTION : l'ordre des datasets doit correspondre à l'ordre des legends
      return {
        labels: ["13-17", "18-20", "25-34", "35-44", "45-54", "55-64", "55-64", "65+"],
        datasets: [
          {
            data: [
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE,
            ],
            ...chartConfig.previousManConfig,
          },
          {
            data: [
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_MALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_MALE,
            ],
            ...chartConfig.manConfig,
          },
          {
            data: [
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE,
              this.stats.last_year_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE,
            ],
            ...chartConfig.previousWomanConfig,
          },
          {
            data: [
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTEEN_TO_SEVENTEEN_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_TWENTY_FIVE_TO_THIRTY_FOUR_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_THIRTY_FIVE_TO_FORTY_FOUR_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FORTY_FIVE_TO_FIFTY_FOUR_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_FIFTY_FIVE_TO_SIXTY_FOUR_FEMALE,
              this.stats.current_gender_age_partition.PAGE_FANS_GENDER_AGE_SIXTY_FIVE_PLUS_FEMALE,
            ],
            ...chartConfig.womanConfig,
          },
        ],
      }
    },

    chartOptions() {
      let opts = this.chartConfig.options
      opts.plugins = {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = this.$t(this.i18nbase + ".gender.legend." + context.dataset.label) || ""

              if (label) {
                label += ": "
              }
              if (context.parsed.y !== null) {
                label += this.$string.formatNumberToHumanString(context.parsed.y)
              }
              return label
            },
          },
        },
        ...opts.plugins,
      }
      return opts
    },

    showGraph() {
      const currentStats = Object.values(this.stats.current_gender_age_partition)
      const previousStats = Object.values(this.stats.last_year_gender_age_partition)

      // if(currentStats.length > 0) {

      // }

      let no_data = currentStats.filter((item) => item === null).length === currentStats.length
      let empty_data = currentStats.filter((item) => item === 0).length === currentStats.length

      if (currentStats.length === 0) {
        no_data = true
      }

      if (this.modelValue) {
        no_data = no_data && previousStats.filter((item) => item === null).length === previousStats.length
        empty_data = empty_data && previousStats.filter((item) => item === 0).length === previousStats.length

        if (currentStats.length === 0 && previousStats.length === 0) {
          no_data = true
        }
      }

      if (no_data) {
        return { show: false, message: this.noDataKey }
      } else if (empty_data) {
        return { show: false, message: "gender.no_data" }
      } else if (this.selectedLegends.length) {
        return { show: false, message: "gender.no_filter" }
      } else {
        return { show: true, message: "" }
      }
    },

    total() {
      let total = 0
      const available = this.legendItems.filter((item) => item.hidden === false)

      available.forEach((element) => {
        if (element.label === "male" && this.stats.currentManTotal !== null) {
          total += this.stats.currentManTotal
        }
        if (element.label === "female" && this.stats.currentWomanTotal !== null) {
          total += this.stats.currentWomanTotal
        }
      })

      return this.$string.formatNumberToHumanString(total)
    },

    previousTotal() {
      let total = 0
      const available = this.legendItems.filter((item) => item.hidden === false)

      available.forEach((element) => {
        if (element.label === "male" && this.stats.previousManTotal !== null) {
          total += this.stats.previousManTotal
        }
        if (element.label === "female" && this.stats.previousWomanTotal !== null) {
          total += this.stats.previousWomanTotal
        }
      })

      return this.$string.formatNumberToHumanString(total)
    },

    selectedLegends() {
      return this.legendItems.filter((item) => item.hidden === false)
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus('FACEBOOK') === 'CONNECTED' ? 'gender.no_data' : 'not_connected'
    }
  },

  watch: {
    modelValue() {
      this.setupPreviousStats(this.modelValue)
    },
  },

  mounted() {
    this.$waiting.simple(() => {
      this.setupPreviousStats(this.modelValue)
      this.isLoading = false
    })
  },
}
</script>
@/static/dashboard/Gender
