<template>
  <div
    v-if="display !== 'bottom'"
    class="absolute -top-[26px] -left-[24px] -z-10"
  >
    <svg
      width="429"
      height="489"
      viewBox="0 0 429 489"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.506104 0.958496L428.506 0.958496C410.968 57.736 358.478 96.4585 299.054 96.4585L192.357 96.4585C167.895 96.4585 143.539 99.6627 119.909 105.989L77.2827 117.402C38.5429 127.774 0.506104 98.5819 0.506104 58.4777L0.506104 0.958496Z"
        fill="#8FD6E0"
      />
      <path
        d="M0 488.542L0 1L95.5 1L90.1687 90.6336C86.4591 153.002 100.213 215.169 129.884 270.153L135.578 280.703C173.228 350.472 140.199 437.405 65.7193 464.571L0 488.542Z"
        fill="#FFC9A3"
      />
    </svg>
  </div>
  <div
    v-if="display !== 'top'"
    class="absolute -bottom-[24px] -right-[24px] -z-10"
  >
    <svg
      width="399"
      height="432"
      viewBox="0 0 399 432"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M398.492 432.418L0.403893 432.416C3.14461 378.962 47.2768 337.022 100.801 337.006L215.973 336.972C235.596 336.966 255.13 334.357 274.066 329.212L398.451 295.418L398.492 432.418Z"
        fill="url(#paint0_linear_55_1293)"
      />
      <path
        d="M398.888 0.499988L398.888 432.958L187 432.958C247.627 401.714 292.671 346.832 311.491 281.276L314.357 271.294C321.648 245.898 321.783 218.981 314.746 193.513L306.433 163.429C287.087 93.4113 327.761 20.8797 397.592 0.871161L398.888 0.499988Z"
        fill="url(#paint1_linear_55_1293)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_55_1293"
          x1="398.012"
          y1="390.458"
          x2="63.0542"
          y2="390.46"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#1398B5" />
          <stop
            offset="1"
            stop-color="#8FD6E0"
          />
        </linearGradient>
        <linearGradient
          id="paint1_linear_55_1293"
          x1="374.507"
          y1="81.9592"
          x2="358.007"
          y2="420.459"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#FC8F70" />
          <stop
            offset="1"
            stop-color="#FFC9A3"
          />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "PremiumBackground",

  props: {
    display: {
      type: String,
      validators: (value: string) => ["default", "top", "bottom"].includes(value),
      default: "default",
    },
  },
})
</script>
