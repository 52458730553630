<template>
  <Tooltip
    v-if="content"
    variant-x="left"
    variant-y="centerY"
    variant-mobile-x="left"
    variant-mobile-y="centerY"
    size="small"
  >
    <template #main>
      <button
        :id="id"
        :type="type"
        :class="getButtonClass"
        :disabled="disabled"
        @click="$emit('click')"
      >
        <div v-if="loading">
          <LottieAnimation
            variant="button"
            :height="24"
            :width="24"
          />
        </div>
        <slot></slot>
      </button>
    </template>
    <template #content>{{ content }}</template>
  </Tooltip>
  <button
    v-else
    :id="id"
    :type="type"
    :class="getButtonClass"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <div v-if="loading">
      <LottieAnimation
        variant="button"
        :height="24"
        :width="24"
      />
    </div>

    <slot></slot>
  </button>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import LottieAnimation from "./LottieAnimation.vue"
import Tooltip from "./Tooltip.vue"

export default defineComponent({
  name: "Button",

  components: {
    LottieAnimation,
    Tooltip,
  },

  props: {
    id: {
      type: String,
      default: "",
    },

    /**
     * Define the type of button
     * @default button
     */
    type: {
      type: String,
      validators: (value: string) => ["submit", "button"].includes(value),
      default: "button",
    },

    /**
     * Define the main color of button
     * @default primary
     */
    color: {
      type: String,
      validators: (value: string) =>
        ["primary", "secondary", "gray", "white", "error", "warning", "tertiary"].includes(value),
      default: "primary",
    },

    /**
     * Set outline mode
     * @default false
     */
    outline: {
      type: Boolean,
      default: false,
    },

    /**
     * Set disabled button style
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * Set the button brightning effect style
     * @default false
     */
    bright: {
      type: Boolean,
      default: false,
    },

    /**
     * Set the tooltip content
     * Tooltip will not exists if content is empty
     */
    content: {
      type: String,
      default: null,
    },

    class: {
      type: String,
      default: null,
    },

    /**
     * Define the width of button
     * @default false
     *
     * if false -> width: fit-content
     * if true -> width: 100%
     */
    fullWidth: {
      type: Boolean,
      default: false,
    },

    /**
     * Define the padding large size for action button
     * @default false
     */
    large: {
      type: Boolean,
      default: false,
    },

    /**
     * Define the width of button
     * if true -> width: maw-content
     * @default false
     */
    unbreakable: {
      type: Boolean,
      default: false,
    },

    /**
     * Show loading animation
     * @default false
     */
    loading: {
      type: Boolean,
      default: false,
    },

    /**
     * Remove outline
     * @default false
     */
    iconButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click"],

  computed: {
    /**
     * Concatenate all modes to set classname
     */
    getButtonClass() {
      let className = ""
      // Outline mode
      if (this.outline) {
        className = "button-outline-" + this.color
      } else {
        className = "button-" + this.color
      }
      // Width mode
      if (this.fullWidth) {
        className += " button-lg"
      }
      // Width mode
      if (this.large) {
        className += " button-action"
      }
      // Width mode
      if (this.unbreakable) {
        className += " button-unbreakable"
      }
      // Disabled style
      if (this.disabled || this.loading) {
        className += " disabled pointer-events-none"
      } else if (this.bright) {
        className += " button-bright"
      }
      if (this.iconButton) {
        className += " button-with-icon" + " button-outline-" + this.color
      }
      return className + " " + this.class
    },
  },
})
</script>
