<template>
  <div id="home">
    <AppLayout variant="dashboard">
      <template #header>
        <span>{{ $t(i18nbase + ".main_title") }}</span>
      </template>

      <template #actions>
        <div class="flex flex-row-reverse items-end md:flex-row gap-sm">
          <Filters
            v-if="establishmentStore.currentEstablishment && !isFreemiumAndNotTrial"
            :period-filters="periodFilters"
            :platform-filters="platformFilters"
            :has-at-least-one-diffuser-connected="hasAtLeastOneDiffuserConnected"
          />

          <BellNotifications />
        </div>
      </template>

      <template #mobile>
        <!-- Laisser vide pour ne pas afficher le template #actions en mode mobile -->
        <BellNotifications />
      </template>

      <template #main>
        <!-- Checklist -->
        <div v-if="!isLoading && dashboardStore.isChecklistLoading"></div>
        <div
          v-else-if="displayCheckList"
          class="max-w-[1200px] m-auto"
        >
          <span class="text-xl text-gray-900"> Terminez la configuration de votre compte </span>
          <div class="relative h-[200px] md:h-[110px] flex flex-col justify-center">
            <template
              v-for="(step, index) in steps"
              :key="step.code"
            >
              <Transition name="checklist">
                <ChecklistItem
                  :show="index === currentChecklistStep"
                  :current-step="currentChecklistStep"
                  :step="step"
                  :previous-step="prev ? prev as IChecklistStep : undefined"
                  :next-step="next ? next as IChecklistStep : undefined"
                  :total="steps.length"
                  :i18nbase="i18nbase"
                  @prev="goPrev"
                  @next="goNext"
                />
              </Transition>
            </template>
          </div>
        </div>

        <!-- Dashboard -->
        <div v-if="isLoading">
          <LottieAnimation
            variant="ping"
            :height="250"
            :width="250"
          />
          <Anecdotes />
        </div>
        <EmptyScreenMessage
          v-else-if="showMiddlwareMessage"
          :show-premium-button="false"
        >
          <template #title>{{ establishmentStore.currentEstablishment.name }}</template>
          {{ middlwareMessage }}
        </EmptyScreenMessage>

        <div
          v-else
          class="flex flex-col gap max-w-[1200px] m-auto pb"
        >
          <Filters
            v-if="!isFreemiumAndNotTrial"
            class="md:hidden"
            :period-filters="periodFilters"
            :platform-filters="platformFilters"
            :has-at-least-one-diffuser-connected="hasAtLeastOneDiffuserConnected"
          />

          <div v-if="accountStore.isFreemium"></div>

          <Alert
            v-else-if="
              !hasAtLeastOneDiffuserConnected && !establishmentStore.currentEstablishment.first_diffuser_connection
            "
            color="warning"
            type="outlined"
            class="!items-start !justify-start mb"
            icon="IconLogin"
          >
            {{ $t(i18nbase + ".temporary_message_connect") }}
          </Alert>

          <Alert
            v-if="
              !(
                !hasAtLeastOneDiffuserConnected && !establishmentStore.currentEstablishment.first_diffuser_connection
              ) && showTemporaryMessage
            "
            color="warning"
            type="outlined"
            class="!items-start !justify-start mb"
            icon="IconClock"
          >
            {{ $t(i18nbase + ".temporary_message_stats_delay") }}
          </Alert>

          <GlobalStatistics
            v-if="!isFreemiumAndNotTrial"
            :one-connection="accountStore.isFreemium ? hasAtLeastOneDiffuserConnected : true"
          >
            <template #period>
              <span class="text-sm text-gray-500">{{
                $t(i18nbase + ".global_stats.description", {
                  startDate: dashboardStore.currentPeriodFilter.startDate,
                  endDate: dashboardStore.currentPeriodFilter.endDate,
                  compareStartDate: dashboardStore.currentPeriodFilter.comparedStartDate,
                  compareEndDate: dashboardStore.currentPeriodFilter.comparedEndDate,
                })
              }}</span>
            </template>
          </GlobalStatistics>

          <Statistics />

          <div class="flex flex-col gap">
            <GoogleSection :disabled="accountStore.isFreemium" />
            <FacebookSection :disabled="accountStore.isFreemium" />
          </div>
        </div>
      </template>
    </AppLayout>

    <Modal
      v-model="showModalSuccess"
      variant="small"
    >
      <template #header>
        <div class="flex flex-col items-center justify-center gap-sm">
          <Icon
            icon-name="IconCheck"
            class="text-success-500"
            :height="30"
            :width="30"
          />
          <span class="text-xl text-center text-success-500">
            {{ $t("home.success_connexion_message", { diffuser: $route.params.diffuserType }) }}
          </span>
        </div>
      </template>
    </Modal>

    <ModalConnexionError
      :show="showModalError"
      :code="connexionErrorCode"
      :diffuser="connexionDiffuser"
    />
  </div>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import moment from "moment"
import { useRoute } from "vue-router"

// Layout
import AppLayout from "@/layouts/AppLayout.vue"

// Components
import GlobalStatistics from "@/modules/app/components/dashboard/GlobalStatistics.vue"
import Statistics from "@/modules/app/components/dashboard/Statistics.vue"
import LottieAnimation from "@/components/LottieAnimation.vue"
import Icon from "@/components/Icon.vue"
import ModalConnexionError from "@/modules/establishment/ModalConnexionError.vue"
import Alert from "@/components/Alert.vue"
import Modal from "@/components/Modal.vue"
import Button from "@/components/Button.vue"
import EmptyScreenMessage from "@/components/EmptyScreenMessage.vue"
import ChecklistItem from "@/modules/app/components/dashboard/ChecklistItem.vue"
import GoogleSection from "@/components/dashboard/google/GoogleSection.vue"
import FacebookSection from "@/components/dashboard/facebook/FacebookSection.vue"
import Filters from "@/modules/app/components/dashboard/Filters.vue"
import BellNotifications from "@/components/BellNotifications.vue"
import Anecdotes from "@/components/Anecdotes.vue"

// Stores
import { useDiffuserStore } from "@/stores/diffuser"
import { useDashboardStore } from "@/stores/dashboard"
import { useEstablishmentStore } from "@/stores/establishment"
import { useAccountStore } from "@/stores/account"
import { useLocalDiffuserStore } from "@/stores/localDiffuser"
import { mapStores } from "pinia"

// Types
import type { IChecklistStep, IFilterPeriod } from "@/types/Dashboard.types"

// Data
import { langs } from "@/static/Lang"
import { FilterCode, FilterPlatformCode, checklistSteps, freemiumChecklistSteps } from "@/static/Dashboard"
import { charteDiffusers } from "@/static/Diffuser"
import type { IGoogleStats, IFacebookStats } from "@/types/Stats.types"
import { EstablishmentProductPlan } from "@/static/Establishment"

export default defineComponent({
  name: "Home",

  components: {
    Button,
    AppLayout,
    GlobalStatistics,
    Statistics,
    LottieAnimation,
    Icon,
    ModalConnexionError,
    Alert,
    Modal,
    EmptyScreenMessage,
    ChecklistItem,
    GoogleSection,
    FacebookSection,
    Filters,
    BellNotifications,
    Anecdotes,
  },

  data() {
    return {
      i18nbase: "home",

      items: [1, 2, 3, 4, 5],
      langs: langs,

      charteDiffusers,

      isHomeLoading: true,

      // Diffuser connexion
      connexionErrorCode: "",
      connexionDiffuser: "",
      showModalError: false,
      showModalSuccess: false,

      // Checklist
      checklistSteps: checklistSteps,
      freemiumChecklistSteps: freemiumChecklistSteps,
      currentChecklistStep: 0,
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useEstablishmentStore, useDiffuserStore, useAccountStore, useLocalDiffuserStore),

    steps() {
      if (this.accountStore.isFreemium) {
        let steps = this.freemiumChecklistSteps

        // écarte l'affichage des connexions Google et FB en FREEMIUM (dispo seulement en freemium_trial)
        if (!this.isFreemiumAndNotTrial) {
          steps = steps.filter((step) => !["connectedGoogle", "connectedFacebook"].includes(step.code))
        }

        return steps.filter(step => step.reached !== undefined)
      } else {
        return this.checklistSteps
      }
    },

    googleData() {
      return {
        clickEvolution: this.dashboardStore.clickEvolution,
        viewEvolution: this.dashboardStore.viewEvolution,
        reviews: this.dashboardStore.reviewsStats,
      } as IGoogleStats
    },

    facebookData() {
      return {} as IFacebookStats
    },

    isLoading(): Boolean {
      if (!this.isHomeLoading) {
        return this.dashboardStore.isLoading
      } else {
        return this.isHomeLoading
      }
    },

    /**
     * Get the current selected app language
     */
    currentLang(): { value: string; icon: string } {
      return this.langs.find((lang) => lang.value === this.$i18n.locale) as { value: string; icon: string }
    },

    /**
     * ========================
     * Messages, alerts and middleware
     */

    /**
     * Is it necessary to show message to explain why we can't show statistics ?
     */
    showTemporaryMessage(): Boolean {
      if (this.establishmentStore.currentEstablishment.first_diffuser_connection) {
        const firstConnexion = moment(
          this.establishmentStore.currentEstablishment.first_diffuser_connection!,
          "YYYY-MM-DD"
        )

        const today = moment()

        if (today.diff(firstConnexion, "days") > 7) {
          return false
        } else {
          return true
        }
      } else {
        return true
      }
    },

    showMiddlwareMessage() {
      if (this.establishmentStore.currentEstablishment) {
        return !this.establishmentStore.currentEstablishment.validated
      } else {
        return false
      }
    },

    /**
     * Middleware message can be different is establishment is already validated
     */
    middlwareMessage(): String {
      if (!this.establishmentStore.currentEstablishment.validated) {
        return this.$t(this.i18nbase + ".middleware_message.unvalidated_establishment")
      } else {
        return this.$t(this.i18nbase + ".middleware_message.default")
      }
    },

    /**
     * ========================
     * Filters
     */

    /**
     * Return plateforms filter array
     */
    platformFilters(): Array<{ name: String; code: FilterPlatformCode }> {
      return [
        {
          name: this.$t("home.filter.platform.all"),
          code: FilterPlatformCode.ALL,
        },
        {
          name: this.$t("home.filter.platform.google"),
          code: FilterPlatformCode.GOOGLE,
        },
        {
          name: this.$t("home.filter.platform.facebook"),
          code: FilterPlatformCode.FACEBOOK,
        },
      ]
    },

    /**
     * Get all period filters and the corresponding periods analysed and compared to
     */
    periodFilters(): Array<IFilterPeriod> {
      // DaysGap is the number of days between the today date and the date closest to today with statistics
      const daysGap = 7

      // Get the number of days for the 7 last days
      const oneWeekInDays = 6

      // Get the number of days for the 30 last days
      const onMonthInDays = 30

      // Get the number of days for the 12 last months
      const oneYearInDays = 365

      // Get the number of days for the 6 last months
      const sixMonthsInDays = oneYearInDays / 2

      // All filters are calculated in days
      const filters = [
        {
          name: this.$t("home.filter.period.last_week"),
          code: FilterCode.LAST_WEEK,
          startDate: this.$date.format(
            moment()
              .subtract(oneWeekInDays + daysGap, "days")
              .toString()
          ),
          endDate: this.$date.format(moment().subtract(daysGap, "days").toString()),
          comparedStartDate: this.$date.format(
            moment()
              // .subtract(2 * oneWeekInDays + daysGap,"days")
              .subtract(oneWeekInDays + oneYearInDays + daysGap, "days")
              .toString()
          ),
          comparedEndDate: this.$date.format(
            moment()
              // .subtract(oneWeekInDays + daysGap, "days")
              .subtract(oneYearInDays + daysGap, "days")
              .toString()
          ),
        },
        {
          name: this.$t("home.filter.period.last_month"),
          code: FilterCode.LAST_MONTH,
          startDate: this.$date.format(
            moment()
              .subtract(onMonthInDays + daysGap, "days")
              .toString()
          ),
          endDate: this.$date.format(moment().subtract(daysGap, "days").toString()),
          comparedStartDate: this.$date.format(
            moment()
              // .subtract(2 * onMonthInDays + daysGap,"days")
              .subtract(onMonthInDays + oneYearInDays + daysGap, "days")
              .toString()
          ),
          comparedEndDate: this.$date.format(
            moment()
              // .subtract(onMonthInDays + daysGap, "days")
              .subtract(oneYearInDays + daysGap, "days")
              .toString()
          ),
        },
        {
          name: this.$t("home.filter.period.last_6_month"),
          code: FilterCode.LAST_6_MONTH,
          startDate: this.$date.format(
            moment()
              .subtract(sixMonthsInDays + daysGap, "days")
              .toString()
          ),
          endDate: this.$date.format(moment().subtract(daysGap, "days").toString()),
          comparedStartDate: this.$date.format(
            moment()
              // .subtract(2 * sixMonthsInDays + daysGap,"days")
              .subtract(sixMonthsInDays + oneYearInDays + daysGap, "days")
              .toString()
          ),
          comparedEndDate: this.$date.format(
            moment()
              // .subtract(sixMonthsInDays + daysGap, "days")
              .subtract(oneYearInDays + daysGap, "days")
              .toString()
          ),
        },
        {
          name: this.$t("home.filter.period.last_year"),
          code: FilterCode.LAST_YEAR,
          startDate: this.$date.format(
            moment()
              .subtract(oneYearInDays + daysGap, "days")
              .toString()
          ),
          endDate: this.$date.format(moment().subtract(daysGap, "days").toString()),
          comparedStartDate: this.$date.format(
            moment()
              .subtract(2 * oneYearInDays + daysGap, "days")
              .toString()
          ),
          comparedEndDate: this.$date.format(
            moment()
              .subtract(oneYearInDays + daysGap, "days")
              .toString()
          ),
        },
      ] as IFilterPeriod[]

      return filters
    },

    hasAtLeastOneDiffuserConnected(): boolean {
      return this.dashboardStore.hasAtLeatOnePlateformConnected()
    },

    /**
     * ========================
     * Checklist
     */

    /**
     * Get previous checklist step
     * Return false if current step is already the first
     */
    prev(): IChecklistStep | Boolean {
      if (this.currentChecklistStep > 0) {
        return this.steps[this.currentChecklistStep - 1]
      } else {
        return false
      }
    },

    /**
     * Get next checklist step
     * Return false if current step is already the last
     */
    next(): IChecklistStep | Boolean {
      if (this.currentChecklistStep < this.steps.length - 1) {
        return this.steps[this.currentChecklistStep + 1]
      } else {
        return false
      }
    },

    filterPosition() {
      let top = 120
      if (this.dashboardStore.showchecklist && this.steps.length > 0) {
        top -= 140
      }
      return `h-[${top}px]`
    },

    isFreemiumAndNotTrial() {
      if (this.establishmentStore.currentEstablishment && this.establishmentStore.currentEstablishment.product_plan) {
        return this.establishmentStore.currentEstablishment.product_plan.code === EstablishmentProductPlan.FREEMIUM
      } else {
        return true
      }
    },

    displayCheckList() {
      return this.accountStore.isFreemium &&
        !this.isLoading &&
        this.dashboardStore.showchecklist && this.checklistSteps.length > 0 &&
        this.currentChecklistStep !== -1
    }
  },

  created() {
    this.isHomeLoading = true
    this.$waiting.simple(() => {
      this.isHomeLoading = false
    }, 1000)
  },

  mounted() {
    /**
     * Initialize statistics
     * if there is no current establishment set, init current establishment first
     */
    this.establishmentStore.initEstablishment(this.establishmentStore.currentEstablishment.id, () => {
      Promise.all([this.initStats(), this.initRouteQuery(), this.initChecklist()])
    })
  },

  methods: {
    /**
     * Change locale lang
     * @param lang
     */
    changeLang(lang: string) {
      this.$i18n.locale = lang
      this.$traduction.changeLang(lang)
    },

    /**
     * ========================
     * Checklist
     */

    /**
     * Initialize checklist steps and current step
     */
    initChecklist() {
      if (this.accountStore.isFreemium) {
        this.dashboardStore.checklist(this.establishmentStore.currentEstablishment.id, () => {
          const steps = this.steps.map((step) => {
            step.reached = this.dashboardStore.checklistStatus[step.code]
            return step
          })

          const current = steps.findIndex((item) => item.reached === false)
          if (current) {
            this.currentChecklistStep = current
          }
        })
      }
    },

    /**
     * Change current step to previous step
     * If current step is already the first, do nothing
     */
    goPrev() {
      if (this.currentChecklistStep > 0) {
        this.currentChecklistStep -= 1
      }
    },

    /**
     * Change current step to next step
     * If current step is already the last, do nothing
     */
    goNext() {
      if (this.currentChecklistStep < this.steps.length - 1) {
        this.currentChecklistStep += 1
      }
    },

    /**
     * ========================
     * Statistics
     */

    /**
     * Initialize statistics and filters
     */
    initStats() {
      this.dashboardStore.currentPeriodFilter = this.periodFilters[0]
      this.dashboardStore.currentPlatformFilter = this.platformFilters[0]
      this.dashboardStore.statistics()
    },

    /**
     * Check if diffuser type is given and check if this diffuser has been connected successfully
     */
    initRouteQuery() {
      const route = useRoute()
      if (route) {
        this.$diffuser.initRouteQuery(route, (response) => {
          if (response.success) {
            this.showModalSuccess = true
          } else {
            this.connexionDiffuser = response.diffuserType!
            this.connexionErrorCode = response.connexionErrorCode!
            this.showModalError = true
          }
        })
      }
    },
  },
})
</script>
