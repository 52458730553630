export const langs = [
  {
    value: "fr",
    icon: "IconFlagFr",
  },
  {
    value: "en",
    icon: "IconFlagEn",
  },
]
