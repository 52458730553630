import iconFacebook from "@/assets/images/socialmedia/facebook.svg"
import iconTripadvisor from "@/assets/images/socialmedia/tripadvisor.svg"
import iconInstagram from "@/assets/images/socialmedia/instagram.svg"
import iconGoogle from "@/assets/images/socialmedia/google.svg"
import iconTic from "@/assets/images/socialmedia/tic.png"
import imageYoutube from "@/assets/images/socialmedia/youtube.png"
import imageLinkedin from "@/assets/images/socialmedia/linkedin.png"
import imagePinterest from "@/assets/images/socialmedia/pinterest.png"
import imageTwitter from "@/assets/images/socialmedia/twitter.png"

import imageTic from "@/assets/images/listing/tic.png"
import imageFacebook from "@/assets/images/listing/facebook.jpg"
import imageGoogle from "@/assets/images/listing/google.jpg"
import imageInstagram from "@/assets/images/listing/instagram.jpg"
import imageTripadvisor from "@/assets/images/listing/tripadvisor.png"
import imageWaze from "@/assets/images/listing/waze.jpg"
import imageMaps from "@/assets/images/listing/google_maps.jpg"
import imageTrustpilot from "@/assets/images/listing/trustpilot.png"
import imageHoodspot from "@/assets/images/listing/hoodspot.png"
import imageFoursquare from "@/assets/images/listing/foursquare.png"
import imageOrange from "@/assets/images/listing/orange.png"
import imageHere from "@/assets/images/listing/here.png"
import imageNavmii from "@/assets/images/listing/navmii.png"
import imageTomtom from "@/assets/images/listing/tomtom.png"
import imageAbc from "@/assets/images/listing/abc.png"
import imageAlexa from "@/assets/images/listing/alexa.png"
import imageAppleMaps from "@/assets/images/listing/apple_maps.png"
import imageAudi from "@/assets/images/listing/audi.jpg"
import imageBaidu from "@/assets/images/listing/baidu.jpg"
import imageBmw from "@/assets/images/listing/bmw.jpg"
import imageBonial from "@/assets/images/listing/bonial.jpg"
import imageBrownbook from "@/assets/images/listing/brownbook.jpg"
import imageCylex from "@/assets/images/listing/cylex.jpg"
import imageFiat from "@/assets/images/listing/fiat.jpg"
import imageFindopen from "@/assets/images/listing/findopen.jpg"
import imageFord from "@/assets/images/listing/ford.jpg"
import imageGeneralMotors from "@/assets/images/listing/general_motors.jpg"
import imageGoogleAssistant from "@/assets/images/listing/google_assistant.jpg"
import imageGuideLocal from "@/assets/images/listing/guidelocal.jpg"
import imageHotfrog from "@/assets/images/listing/hotfrog.jpg"
import imageIGlobal from "@/assets/images/listing/iglobal.jpg"
import imageInfobel from "@/assets/images/listing/infobel.jpg"
import imageInfoisinfo from "@/assets/images/listing/infoisinfo.jpg"
import imageJelloo from "@/assets/images/listing/jelloo.jpg"
import imageJustacote from "@/assets/images/listing/justacote.jpg"
import imageLeshoraires from "@/assets/images/listing/leshoraires.jpg"
import imageMercedes from "@/assets/images/listing/mercedes.jpg"
import imageMoneyvox from "@/assets/images/listing/moneyvox.jpg"
import imageNextdoor from "@/assets/images/listing/nextdoor.jpg"
import imageOffnungszeitenbuch from "@/assets/images/listing/offnungszeitenbuch.jpg"
import imageStadtbranchenbuch from "@/assets/images/listing/stadtbranchenbuch.jpg"
import imagePages24 from "@/assets/images/listing/pages24.jpg"
import imagePetalsearch from "@/assets/images/listing/petal_search.jpg"
import imageSiri from "@/assets/images/listing/siri.jpg"
import imageToyota from "@/assets/images/listing/toyota.jpg"
import imageTupalo from "@/assets/images/listing/tupalo.jpg"
import imageUber from "@/assets/images/listing/uber.jpg"
import imageVolkswagen from "@/assets/images/listing/volkswagen.jpg"
import imageYalwa from "@/assets/images/listing/yalwa.jpg"
import imageYellbo from "@/assets/images/listing/yellbo.jpg"
import imageHolidayCheck from "@/assets/images/listing/holiday_check.jpg"
import imageWhereTo from "@/assets/images/listing/whereto.jpg"
import imagePratique from "@/assets/images/listing/pratique.jpg"

import type { IDiffuser, ISpecialRule } from "@/types/Diffuser.types"

export enum ConnectRedirectionUrl {
  CONFIG = import.meta.env.VITE_CONNECT_REDIRECT_CONFIG,
  LISTING = import.meta.env.VITE_CONNECT_REDIRECT_LISTING,
  HOME = import.meta.env.VITE_CONNECT_REDIRECT_HOME,
}

/**
 * All diffuser type to be sync with
 */
export enum AllDiffuser {
  TIC = "TIC",
  GOOGLE = "GOOGLE",
  GOOGLE_SEARCH = "GOOGLE_SEARCH",
  GOOGLE_MAPS = "GOOGLE_MAPS",
  WAZE = "WAZE",
  FACEBOOK = "FACEBOOK",
  TWITTER = "TWITTER",
  LINKEDIN = "LINKEDIN",
  PINTEREST = "PINTEREST",
  TRIP_ADVISOR = "TRIP_ADVISOR",
  INSTAGRAM = "INSTAGRAM",
  TRUSTPILOT = "TRUSTPILOT",
  ANNUAIRE = "ANNUAIRE",
  FOURSQUARE = "FOURSQUARE",
  ORANGE_118_712 = "ORANGE_118_712",
  NOKIA_HERE = "NOKIA_HERE",
  NAVMII = "NAVMII",
  TOMTOM = "TOMTOM",
  ABCLOCAL = "ABCLOCAL",
  ALEXA = "ALEXA",
  YOUTUBE = "YOUTUBE",
  APPLE_MAPS = "APPLE_MAPS",
  AUDI = "AUDI",
  BAIDU = "BAIDU",
  BMW = "BMW",
  BONIAL_FR = "BONIAL_FR",
  BROWNBOOK = "BROWNBOOK",
  CYLEX = "CYLEX",
  FIAT = "FIAT",
  FIND_OPEN = "FIND_OPEN",
  FORD = "FORD",
  GM = "GM",
  GOOGLE_ASSISTANT = "GOOGLE_ASSISTANT",
  GUIDELOCAL = "GUIDELOCAL",
  HOTFROG = "HOTFROG",
  I_GLOBAL = "I_GLOBAL",
  INFOBEL = "INFOBEL",
  INFO_IS_INFO = "INFO_IS_INFO",
  JELLOO = "JELLOO",
  JUSTACOTE = "JUSTACOTE",
  SHOPPING_TIME_NETWORK = "SHOPPING_TIME_NETWORK",
  MERCEDES = "MERCEDES",
  CBANQUE = "CBANQUE",
  NEXT_DOOR = "NEXT_DOOR",
  OEFFNUNGSZEITENBUCH = "OEFFNUNGSZEITENBUCH",
  STADTBRANCHENBUCH = "STADTBRANCHENBUCH",
  PAGES24 = "PAGES24",
  HUAWEI = "HUAWEI",
  SIRI = "SIRI",
  TOYOTA = "TOYOTA",
  TUPALO = "TUPALO",
  UBER = "UBER",
  VW = "VW",
  YALWA = "YALWA",
  YELLBO = "YELLBO",
  HOLIDAY_CHECK = "HOLIDAY_CHECK",
  WHERE_TO = "WHERE_TO",
  PRATIQUE = "PRATIQUE",
}

export const charteDiffusers: { [name: string]: IDiffuser } = {
  [AllDiffuser[AllDiffuser.TIC]]: {
    name: "Votre Office de Tourisme",
    icon: iconTic,
    image: imageTic,
    bgcolor: "bg-tic",
    textcolor: "text-gray-700",
  },
  [AllDiffuser[AllDiffuser.FACEBOOK]]: {
    name: "Facebook",
    icon: iconFacebook,
    image: imageFacebook,
    bgcolor: "bg-facebook",
    textcolor: "text-white",
  },
  [AllDiffuser[AllDiffuser.TRIP_ADVISOR]]: {
    name: "Tripadvisor",
    icon: iconTripadvisor,
    image: imageTripadvisor,
    bgcolor: "bg-tripadvisor",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.INSTAGRAM]]: {
    name: "Instagram",
    icon: iconInstagram,
    image: imageInstagram,
    bgcolor: "bg-instagram",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.TWITTER]]: {
    name: "Twitter",
    icon: imageTwitter,
    image: imageTwitter,
    bgcolor: "bg-white",
    textcolor: "text-gray-700",
  },
  [AllDiffuser[AllDiffuser.GOOGLE]]: {
    name: "Google",
    icon: iconGoogle,
    image: imageGoogle,
    bgcolor: "bg-google",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.LINKEDIN]]: {
    name: "Linkedin",
    icon: imageLinkedin,
    image: imageLinkedin,
    bgcolor: "bg-linkedin",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.PINTEREST]]: {
    name: "Pinterest",
    icon: imagePinterest,
    image: imagePinterest,
    bgcolor: "bg-pinterest",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.GOOGLE_SEARCH]]: {
    name: "Google Search",
    icon: iconGoogle,
    image: imageGoogle,
    bgcolor: "bg-google",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.GOOGLE_MAPS]]: {
    name: "Google Maps",
    icon: imageMaps,
    image: imageMaps,
    bgcolor: "bg-white",
    textcolor: "text-gray-900",
  },
  [AllDiffuser[AllDiffuser.WAZE]]: {
    image: imageWaze,
  },
  [AllDiffuser[AllDiffuser.YOUTUBE]]: {
    name: "Youtube",
    icon: imageYoutube,
    image: imageYoutube,
    bgcolor: "bg-youtube",
  },
  [AllDiffuser[AllDiffuser.TRUSTPILOT]]: {
    image: imageTrustpilot,
  },
  [AllDiffuser[AllDiffuser.ANNUAIRE]]: {
    name: "Hoodspot",
    image: imageHoodspot,
  },
  [AllDiffuser[AllDiffuser.FOURSQUARE]]: {
    image: imageFoursquare,
  },
  [AllDiffuser[AllDiffuser.ORANGE_118_712]]: {
    name: "Orange",
    image: imageOrange,
  },
  [AllDiffuser[AllDiffuser.NOKIA_HERE]]: {
    image: imageHere,
  },
  [AllDiffuser[AllDiffuser.NAVMII]]: {
    image: imageNavmii,
  },
  [AllDiffuser[AllDiffuser.TOMTOM]]: {
    image: imageTomtom,
  },
  [AllDiffuser[AllDiffuser.ABCLOCAL]]: {
    image: imageAbc,
  },
  [AllDiffuser[AllDiffuser.ALEXA]]: {
    image: imageAlexa,
  },
  [AllDiffuser[AllDiffuser.APPLE_MAPS]]: {
    image: imageAppleMaps,
  },
  [AllDiffuser[AllDiffuser.AUDI]]: {
    image: imageAudi,
  },
  [AllDiffuser[AllDiffuser.BAIDU]]: {
    image: imageBaidu,
  },
  [AllDiffuser[AllDiffuser.BMW]]: {
    image: imageBmw,
  },
  [AllDiffuser[AllDiffuser.BONIAL_FR]]: {
    image: imageBonial,
  },
  [AllDiffuser[AllDiffuser.BROWNBOOK]]: {
    image: imageBrownbook,
  },
  [AllDiffuser[AllDiffuser.CYLEX]]: {
    image: imageCylex,
  },
  [AllDiffuser[AllDiffuser.FIAT]]: {
    image: imageFiat,
  },
  [AllDiffuser[AllDiffuser.FIND_OPEN]]: {
    image: imageFindopen,
  },
  [AllDiffuser[AllDiffuser.FORD]]: {
    image: imageFord,
  },
  [AllDiffuser[AllDiffuser.GM]]: {
    name: "General Motors",
    image: imageGeneralMotors,
  },
  [AllDiffuser[AllDiffuser.GOOGLE_ASSISTANT]]: {
    image: imageGoogleAssistant,
  },
  [AllDiffuser[AllDiffuser.GUIDELOCAL]]: {
    image: imageGuideLocal,
  },
  [AllDiffuser[AllDiffuser.HOTFROG]]: {
    image: imageHotfrog,
  },
  [AllDiffuser[AllDiffuser.I_GLOBAL]]: {
    image: imageIGlobal,
  },
  [AllDiffuser[AllDiffuser.INFOBEL]]: {
    image: imageInfobel,
  },
  [AllDiffuser[AllDiffuser.INFO_IS_INFO]]: {
    image: imageInfoisinfo,
  },
  [AllDiffuser[AllDiffuser.JELLOO]]: {
    image: imageJelloo,
  },
  [AllDiffuser[AllDiffuser.JUSTACOTE]]: {
    image: imageJustacote,
  },
  [AllDiffuser[AllDiffuser.SHOPPING_TIME_NETWORK]]: {
    image: imageLeshoraires,
  },
  [AllDiffuser[AllDiffuser.MERCEDES]]: {
    image: imageMercedes,
  },
  [AllDiffuser[AllDiffuser.CBANQUE]]: {
    image: imageMoneyvox,
  },
  [AllDiffuser[AllDiffuser.NEXT_DOOR]]: {
    image: imageNextdoor,
  },
  [AllDiffuser[AllDiffuser.OEFFNUNGSZEITENBUCH]]: {
    name: "Opendi",
    image: imageOffnungszeitenbuch,
  },
  [AllDiffuser[AllDiffuser.STADTBRANCHENBUCH]]: {
    name: "Stadtbranchenbuch",
    image: imageStadtbranchenbuch,
  },
  [AllDiffuser[AllDiffuser.PAGES24]]: {
    image: imagePages24,
  },
  [AllDiffuser[AllDiffuser.HUAWEI]]: {
    image: imagePetalsearch,
  },
  [AllDiffuser[AllDiffuser.SIRI]]: {
    image: imageSiri,
  },
  [AllDiffuser[AllDiffuser.TOYOTA]]: {
    image: imageToyota,
  },
  [AllDiffuser[AllDiffuser.TUPALO]]: {
    image: imageTupalo,
  },
  [AllDiffuser[AllDiffuser.UBER]]: {
    image: imageUber,
  },
  [AllDiffuser[AllDiffuser.VW]]: {
    name: "Volkswagen",
    image: imageVolkswagen,
  },
  [AllDiffuser[AllDiffuser.YALWA]]: {
    image: imageYalwa,
  },
  [AllDiffuser[AllDiffuser.YELLBO]]: {
    image: imageYellbo,
  },
  [AllDiffuser[AllDiffuser.HOLIDAY_CHECK]]: {
    image: imageHolidayCheck,
  },
  [AllDiffuser[AllDiffuser.WHERE_TO]]: {
    name: "Où Trouver ?",
    image: imageWhereTo,
  },
  [AllDiffuser[AllDiffuser.PRATIQUE]]: {
    image: imagePratique,
  },
}

/**
 * All diffuser type to be connected with
 * The order of types is important, the first has to be the main diffuser to be connected with
 */
export enum DiffuserType {
  GOOGLE = "GOOGLE",
  FACEBOOK = "FACEBOOK",
}

export const longDescriptionSpecials: ISpecialRule[] = [
  {
    diffuser: charteDiffusers[AllDiffuser.GOOGLE],
    rule: "establishments/formulaire.generic_informations.long_description.special_rules." + AllDiffuser.GOOGLE,
  },
]
