<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.5999 9.9999C19.5999 12.546 18.5885 14.9878 16.7881 16.7881C14.9878 18.5885 12.546 19.5999 9.9999 19.5999C7.45382 19.5999 5.01203 18.5885 3.21168 16.7881C1.41133 14.9878 0.399902 12.546 0.399902 9.9999C0.399902 7.45382 1.41133 5.01203 3.21168 3.21168C5.01203 1.41133 7.45382 0.399902 9.9999 0.399902C12.546 0.399902 14.9878 1.41133 16.7881 3.21168C18.5885 5.01203 19.5999 7.45382 19.5999 9.9999ZM11.1999 14.7999C11.1999 15.1182 11.0735 15.4234 10.8484 15.6484C10.6234 15.8735 10.3182 15.9999 9.9999 15.9999C9.68164 15.9999 9.37642 15.8735 9.15137 15.6484C8.92633 15.4234 8.7999 15.1182 8.7999 14.7999C8.7999 14.4816 8.92633 14.1764 9.15137 13.9514C9.37642 13.7263 9.68164 13.5999 9.9999 13.5999C10.3182 13.5999 10.6234 13.7263 10.8484 13.9514C11.0735 14.1764 11.1999 14.4816 11.1999 14.7999ZM9.9999 3.9999C9.68164 3.9999 9.37642 4.12633 9.15137 4.35137C8.92633 4.57642 8.7999 4.88164 8.7999 5.1999V9.9999C8.7999 10.3182 8.92633 10.6234 9.15137 10.8484C9.37642 11.0735 9.68164 11.1999 9.9999 11.1999C10.3182 11.1999 10.6234 11.0735 10.8484 10.8484C11.0735 10.6234 11.1999 10.3182 11.1999 9.9999V5.1999C11.1999 4.88164 11.0735 4.57642 10.8484 4.35137C10.6234 4.12633 10.3182 3.9999 9.9999 3.9999Z"
      fill="#FF9800"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2 14.8C11.2 15.1183 11.0736 15.4235 10.8486 15.6485C10.6235 15.8736 10.3183 16 10 16C9.68179 16 9.37656 15.8736 9.15152 15.6485C8.92648 15.4235 8.80005 15.1183 8.80005 14.8C8.80005 14.4817 8.92648 14.1765 9.15152 13.9515C9.37656 13.7264 9.68179 13.6 10 13.6C10.3183 13.6 10.6235 13.7264 10.8486 13.9515C11.0736 14.1765 11.2 14.4817 11.2 14.8ZM10 4C9.68179 4 9.37656 4.12643 9.15152 4.35147C8.92648 4.57652 8.80005 4.88174 8.80005 5.2V10C8.80005 10.3183 8.92648 10.6235 9.15152 10.8485C9.37656 11.0736 9.68179 11.2 10 11.2C10.3183 11.2 10.6235 11.0736 10.8486 10.8485C11.0736 10.6235 11.2 10.3183 11.2 10V5.2C11.2 4.88174 11.0736 4.57652 10.8486 4.35147C10.6235 4.12643 10.3183 4 10 4Z"
      fill="white"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconExclamationCircleColored",
  props: ["height", "width"],
}
</script>
