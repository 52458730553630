import TourismInformationCenterDataService from "@/services/TourismInformationCenterDataService"
import { defineStore } from "pinia"
import { useAlertStore } from "./alert"
import { i18n } from "@/i18n"
import { AlertType } from "@/static/Alert"
import type { IConfigTourismInformationCenter } from "@/types/TourismInformationCenter.types"

export const useTourismInformationCenterStore = defineStore("tourismInformationCenter", {
  state: () => ({}),

  actions: {
    getByEstablishment(
      establishmentId: number,
      successCallback: (ot: IConfigTourismInformationCenter) => void,
      errorCallback?: () => void
    ) {
      TourismInformationCenterDataService.getTourismInformationCenterByEstablishment(establishmentId).then(
        (res) => {
          if (res.data.success) {
            successCallback(res.data.tourismInformationCenter)
          }
        },
        (e) => {
          const alertStore = useAlertStore()
          switch (e.response.status) {
            case 401:
              break
            default:
              alertStore.push(AlertType.error, i18n.global.t("common.errors.default"), e.message)
              break
          }

          if (errorCallback) {
            errorCallback()
          }
        }
      )
    },
  },
})
