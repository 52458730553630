import axios from "axios"
import packageJson from "../package.json"
import router from "./router"
import { useAlertStore } from "@/stores/alert"
import { AlertType } from "@/static/Alert"
import { i18n } from "@/i18n"

const apiClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: { "X-App-Version": packageJson.version },
})

apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token")

    if (token) {
      config.headers.Authorization = "Bearer " + token
    }

    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    const alerts = useAlertStore()

    if (error.response.status === 401 && error.config.url !== "/auth/login") {
      router.push({ name: "auth" })
      alerts.push(AlertType.info, "Votre session a expirée")
    }

    return Promise.reject(error)
  }
)

export default apiClient
