<template>
  <StatisticCard
    :title="$t(i18nbase + '.title')"
    :description="
      dashboardStore.lastSync !== '' && !accountStore.isFreemium
        ? $t(i18nbase + '.description', { nb: dashboardStore.platformsStatus.total })
        : ''
    "
    :info="$t(i18nbase + '.info_bubble')"
  >
    <template #actions>
      <Button
        color="gray"
        :outline="true"
        :icon-button="mobileStore.isMobileSize ? true : false"
        @click="$router.push({ name: 'establishment-listing' })"
      >
        <span class="hidden md:block">
          {{ $t(i18nbase + ".button") }}
        </span>
        <Icon
          icon-name="IconClipboardCheck"
          class="md:hidden"
        />
      </Button>
    </template>

    <div
      v-if="dashboardStore.lastSync !== '' && !accountStore.isFreemium"
      id="dashboard-circle-progress"
      class="grid grid-cols-1 md:grid-cols-3 gap-xl md:gap"
    >
      <div
        class="grid grid-cols-2 md:grid-cols-1 items-center gap md:gap-sm"
        :class="dashboardStore.platformsStatus.success === 0 && 'opacity-30 grayscale'"
      >
        <div class="flex justify-end md:justify-center">
          <div class="relative w-[80px]">
            <CircleProgress
              :percent="(dashboardStore.platformsStatus.success * 100) / dashboardStore.platformsStatus.total"
              fill-color="#7AC943"
              empty-color="#ECEDF0"
              :size="80"
              :border-width="6"
              :border-bg-width="6"
              :transition="2000"
              :is-shadow="false"
              :show-percent="false"
            />
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[64px] w-[64px] bg-success-100 rounded-full flex items-center justify-center"
            >
              <span class="text-success-500 text-sm">
                {{ dashboardStore.platformsStatus.success }} / {{ dashboardStore.platformsStatus.total }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex md:justify-center">
          <div class="flex gap-sm items-center">
            <Icon
              icon-name="IconCheckCircle"
              class="text-success-500"
            />
            <span class="text-base text-success-500">
              {{ $t(i18nbase + ".states.success") }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-2 md:grid-cols-1 items-center gap md:gap-sm"
        :class="dashboardStore.platformsStatus.warning === 0 && 'opacity-30 grayscale'"
      >
        <div class="flex justify-end md:justify-center">
          <div class="relative w-[80px]">
            <CircleProgress
              :percent="(dashboardStore.platformsStatus.warning * 100) / dashboardStore.platformsStatus.total"
              fill-color="#F8BA43"
              empty-color="#ECEDF0"
              :size="80"
              :border-width="6"
              :border-bg-width="6"
              :transition="2000"
              :is-shadow="false"
              :show-percent="false"
            />
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[64px] w-[64px] bg-tertiary-100 rounded-full flex items-center justify-center"
            >
              <span class="text-tertiary-500 text-sm">
                {{ dashboardStore.platformsStatus.warning }} / {{ dashboardStore.platformsStatus.total }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex md:justify-center">
          <div class="flex gap-sm items-center">
            <Icon
              icon-name="IconDotsHorizontal"
              class="text-tertiary-500"
            />
            <span class="text-base text-tertiary-500">
              {{ $t(i18nbase + ".states.warning") }}
            </span>
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-2 md:grid-cols-1 items-center gap md:gap-sm"
        :class="dashboardStore.platformsStatus.error === 0 && 'opacity-30 grayscale'"
      >
        <div class="flex justify-end md:justify-center">
          <div class="relative w-[80px]">
            <CircleProgress
              :percent="(dashboardStore.platformsStatus.error * 100) / dashboardStore.platformsStatus.total"
              fill-color="#EF4444"
              empty-color="#ECEDF0"
              :size="80"
              :border-width="6"
              :border-bg-width="6"
              :transition="2000"
              :is-shadow="false"
              :show-percent="false"
            />
            <div
              class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 h-[64px] w-[64px] bg-error-100 rounded-full flex items-center justify-center"
            >
              <span class="text-error-500 text-sm">
                {{ dashboardStore.platformsStatus.error }} / {{ dashboardStore.platformsStatus.total }}
              </span>
            </div>
          </div>
        </div>
        <div class="flex md:justify-center">
          <div class="flex gap-sm items-center">
            <Icon
              icon-name="IconXCircle"
              class="text-error-500"
            />
            <span class="text-base text-error-500">
              {{ $t(i18nbase + ".states.error") }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="flex items-center justify-center min-h-[80px]"
    >
      <div v-if="accountStore.isFreemium">
        <Button @click="$router.push({ name: 'freemium-comparison' })">
          <Icon icon-name="IconCrownOutlined" />
          {{ $t("common.premium_button") }}
        </Button>
      </div>
      <div
        v-else
        class="flex gap-xs items-center"
      >
        <Icon
          icon-name="IconHourglass"
          class="text-gray-400"
        />
        <span class="text-base text-gray-400">
          {{ $t(i18nbase + ".no_data") }}
        </span>
      </div>
    </div>
  </StatisticCard>
</template>

<script lang="ts">
// Vue
import { defineComponent } from "vue"
import CircleProgress from "vue3-circle-progress"

// Layout
//

// Components
import Icon from "../Icon.vue"
import StatisticCard from "@/modules/app/components/dashboard/StatisticCard.vue"
import Button from "../Button.vue"

// Stores
import { useDashboardStore } from "@/stores/dashboard"
import { useAccountStore } from "@/stores/account"
import { useMobileStore } from "@/stores/mobile"
import { mapStores } from "pinia"

// Types
//

// Data
//

export default defineComponent({
  name: "SyncStatus",

  components: { CircleProgress, StatisticCard, Icon, Button },

  data() {
    return {
      i18nbase: "home.plateformes",
    }
  },

  computed: {
    ...mapStores(useDashboardStore, useAccountStore, useMobileStore),
  },
})
</script>
