<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.098 2.61761C10.6967 0.775083 13.3034 0.775089 13.9022 2.61761L15.421 7.29152L20.3355 7.29171C22.2729 7.29178 23.0784 9.77088 21.5111 10.9097L17.5353 13.7985L19.0537 18.4725C19.6524 20.3151 17.5435 21.8473 15.9761 20.7086L12.0001 17.8201L8.02405 20.7086C6.45664 21.8473 4.3478 20.3151 4.94641 18.4725L6.46489 13.7985L2.48909 10.9097C0.921767 9.77088 1.72728 7.29178 3.66465 7.29171L8.57915 7.29152L10.098 2.61761ZM12.0001 3.23572L10.4812 7.90963C10.2135 8.73361 9.44562 9.29149 8.57922 9.29152L3.66472 9.29171L7.64053 12.1805C8.34144 12.6898 8.63473 13.5925 8.36703 14.4165L6.84854 19.0905L10.8246 16.202C11.5255 15.6928 12.4746 15.6928 13.1756 16.202L17.1516 19.0905L15.6331 14.4165C15.3654 13.5925 15.6587 12.6898 16.3596 12.1805L20.3354 9.29171L15.4209 9.29152C14.5545 9.29149 13.7867 8.73361 13.5189 7.90963L12.0001 3.23572Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconStarOutline",
  props: ["height", "width"],
}
</script>
