<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9C19 9.55228 18.5523 10 18 10H6C5.44772 10 5 9.55228 5 9Z"
      fill="#272C3D"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 15C5 14.4477 5.44772 14 6 14H18C18.5523 14 19 14.4477 19 15C19 15.5523 18.5523 16 18 16H6C5.44772 16 5 15.5523 5 15Z"
      fill="#272C3D"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconEqual",
  props: ["height", "width"],
}
</script>
