<template>
  <label class="switch">
    <input
      v-model="content"
      :type="type"
      :disabled="disabled"
      :name="name"
      @input="update"
    />
    <span class="slider"></span>
  </label>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "Switch",

  props: {
    checked: {
      type: [Boolean, String],
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "checkbox",
    },

    name: {
      type: String,
    },

    trueValue: {
      type: [Boolean, String],
      default: undefined,
    },
  },

  data() {
    return {
      content: this.trueValue !== undefined ? this.trueValue === this.checked : this.checked,
    }
  },

  watch: {
    checked() {
      this.content = this.trueValue !== undefined ? this.trueValue === this.checked : this.checked
    },
  },

  methods: {
    update(event) {
      this.$emit("update", event.target.checked)
    },
  },
})
</script>
