<template>
  <div
    @blur="open = false"
    v-if="$slots.action"
  >
    <div
      class="cursor-pointer w-fit"
      @click="!disabled && (open = !open)"
    >
      <slot name="action"></slot>
    </div>
  </div>

  <Transition :name="variant === 'fullbottom' ? 'bottom-fade' : 'dropdown'">
    <div
      v-if="open"
      class="modal flex"
      :class="variant === 'fullbottom' ? 'items-end p-0' : 'pb-lg md:pt-0 items-center justify-center'"
      @click="onClose('bg')"
    >
      <div
        class="modal-card bg-white rounded-sm"
        :class="[variant, noMaxHeight && '!max-h-[90vh] overflow-y-hidden']"
      >
        <div
          :id="id"
          class="container w-full h-full"
          :class="[variant, scrollable && 'scrollable', close && 'closable']"
          @click.stop
        >
          <div class="close-modal-container">
            <button
              v-if="close"
              type="button"
              class="close-modal"
              @click="onClose('x')"
            >
              <Icon
                icon-name="IconX"
                data-close
                class="text-gray-600"
              />
            </button>
          </div>

          <div
            v-if="$slots.header"
            class="modal-header bg-white px-lg pt pb-0 rounded-sm"
          >
            <slot name="header"></slot>
          </div>
          <div
            class="p-md md:p-lg"
            :class="[{ 'overflow-y-auto max-h-[90vh]': noMaxHeight }]"
          >
            <slot></slot>
          </div>

          <div
            v-if="$slots.footer"
            class="modal-footer bg-white px md:px-lg pb pt-md border-t border-gray-50 flex flex-col md:flex-row justify-center gap-sm md:gap items-center rounded-sm z-10"
          >
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script lang="ts">
import Icon from "./Icon.vue"

export default {
  name: "Modal",

  components: {
    Icon,
  },

  props: {
    id: {
      type: String,
      default: "",
    },

    modelValue: {
      type: Boolean,
      default: false,
    },

    variant: {
      type: String,
      validators: (value: string) => ["small", "large", "fullbottom"].includes(value),
      default: "",
    },

    close: {
      type: Boolean,
      default: true,
    },

    closeOnBg: {
      type: Boolean,
      default: true,
    },

    closeOnlyOnBg: {
      type: Boolean,
      default: false,
    },

    scrollable: {
      type: Boolean,
      default: true,
    },

    noMaxHeight: {
      type: Boolean,
      required: false,
      default: false,
    },

    adaptSize: {
      type: Boolean,
      required: false,
      default: false,
    },

    /**
     * Disable the action click
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    setBodyScroll: {
      type: Boolean,
      default: true,
    },
  },

  emits: ["close", "update:modelValue"],

  data() {
    return {
      open: this.modelValue,
    }
  },

  watch: {
    modelValue(newValue) {
      this.open = newValue
    },

    open(newValue) {
      this.$emit("update:modelValue", newValue)
      if (this.setBodyScroll) {
        const body = document.getElementsByTagName("body")[0]
        if (newValue) {
          body.style.overflow = "hidden"
        } else {
          body.style.overflow = "auto"
        }
      }
    },
  },

  methods: {
    onClose(from: string) {
      if (!this.closeOnBg && from === "bg") {
        //
      } else if ((from === "bg" && this.closeOnlyOnBg) || this.close) {
        this.open = false
        this.$emit("close", from)
      }
    },
  },
}
</script>
