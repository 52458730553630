<template>
  <div
    class="input-group"
    :class="{
      required: required,
      'group-check': checkbox,
      compact: compact,
      'has-error': errors.length > 0 || $slots.error,
    }"
  >
    <slot></slot>
    <span
      v-if="errors.length > 0"
      class="error"
    >
      <span
        v-for="error of errors"
        :key="error.$uid"
      >
        {{ error.$message }}
      </span>
    </span>

    <div v-else-if="$slots.error">
      <span class="error">
        <slot name="error"></slot>
      </span>
    </div>

    <div
      v-if="$slots.legend"
      class="input-legend"
    >
      <slot name="legend"></slot>
    </div>
    <span
      v-if="$slots.message"
      class="message-max-length"
    >
      <slot name="message"></slot>
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from "vue"

type Error = {
  $uid: string
  $message: string
}

export default defineComponent({
  name: "FormWidget",
  props: {
    /**
     * Define if widget input(s) are required
     * @default false
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * Define if widget input(s) is in error mode (display error message)
     * @default false
     */
    errors: {
      type: Array as PropType<Error[]>,
      default: [] as Error[],
    },
    /**
     * Set the input checkbox widget mode
     * @default false
     */
    checkbox: {
      type: Boolean,
      default: false,
    },
    /**
     * Set the input compact style
     * @default false
     */
    compact: {
      type: Boolean,
      default: false,
    },
  },
})
</script>
