<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7 4C6.73478 4 6.48043 4.10536 6.29289 4.29289C6.10536 4.48043 6 4.73478 6 5V19.382L11.5528 16.6056C11.8343 16.4648 12.1657 16.4648 12.4472 16.6056L18 19.382V5C18 4.73478 17.8946 4.48043 17.7071 4.29289C17.5196 4.10536 17.2652 4 17 4H7ZM4.87868 2.87868C5.44129 2.31607 6.20435 2 7 2H17C17.7956 2 18.5587 2.31607 19.1213 2.87868C19.6839 3.44129 20 4.20435 20 5V21C20 21.3466 19.8205 21.6684 19.5257 21.8507C19.2309 22.0329 18.8628 22.0494 18.5528 21.8944L12 18.618L5.44721 21.8944C5.13723 22.0494 4.76909 22.0329 4.47427 21.8507C4.17945 21.6684 4 21.3466 4 21V5C4 4.20435 4.31607 3.44129 4.87868 2.87868Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconBookmark",
  props: ["height", "width"],
}
</script>
