<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 3.905C10 2.85272 10.8527 2 11.905 2H12C12.7956 2 13.5587 2.31607 14.1213 2.87868C14.6839 3.44129 15 4.20435 15 5V9H18.764C19.2753 9.00002 19.7781 9.13072 20.2247 9.37968C20.6713 9.62865 21.0469 9.98763 21.3157 10.4225C21.5846 10.8575 21.7378 11.3539 21.7609 11.8646C21.7839 12.3754 21.6761 12.8836 21.4475 13.341L17.9475 20.341C17.9475 20.3411 17.9476 20.3409 17.9475 20.341C17.6983 20.8396 17.315 21.2591 16.8408 21.5521C16.3667 21.8451 15.8203 22.0002 15.263 22C15.2628 22 15.2632 22 15.263 22H11.246C11.0018 22 10.7573 21.9701 10.5185 21.9102C10.5185 21.9102 10.5185 21.9102 10.5185 21.9102C10.518 21.91 10.5175 21.9099 10.517 21.9098L6.87693 21H5C4.20435 21 3.44129 20.6839 2.87868 20.1213C2.31607 19.5587 2 18.7957 2 18V12C2 11.2044 2.31607 10.4413 2.87868 9.87868C3.44129 9.31607 4.20435 9 5 9H7.13129L9.5606 5.35533C9.8473 4.92636 10 4.42185 10 3.905ZM6 11H5C4.73478 11 4.48043 11.1054 4.29289 11.2929C4.10536 11.4804 4 11.7348 4 12V18C4 18.2652 4.10536 18.5196 4.29289 18.7071C4.48043 18.8946 4.73478 19 5 19H6V11ZM8 19.2192V11.3027L11.2234 6.46667C11.2232 6.46692 11.2236 6.46642 11.2234 6.46667C11.7127 5.73421 11.981 4.87771 11.999 4C12.2642 4 12.5196 4.10536 12.7071 4.29289C12.8946 4.48043 13 4.73478 13 5V9H12C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11H18.764C18.764 11 18.764 11 18.764 11C18.9344 11 19.102 11.0436 19.2509 11.1266C19.3997 11.2096 19.5249 11.3292 19.6145 11.4742C19.7041 11.6192 19.7552 11.7846 19.7629 11.9549C19.7706 12.1251 19.7347 12.2944 19.6586 12.4468C19.6585 12.4469 19.6586 12.4467 19.6586 12.4468L16.1586 19.4468C16.0755 19.613 15.9477 19.753 15.7896 19.8507C15.6315 19.9484 15.4493 20.0001 15.2635 20H11.246C11.1647 20 11.0837 19.99 11.005 19.9702L8 19.2192Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconThumbUp",
  props: ["height", "width"],
}
</script>
