// https://www.notion.so/dahubio/Status-f17f11eaa9b44e4aa2b2de0b5464d969

/**
 * ListingSyncStatus
 * Uberall diffuser sync status
 */
export enum ListingSyncStatus {
  /**
   * Impossible de vérifier la synchronisation des données sur le diffuser
   */
  NOT_FOUND = "NOT_FOUND",

  /**
   * Certains champs ne sont pas synchronisés
   * Peut-être que le diffuser doit vérifier manuellemnt la donnée
   */
  NOT_IN_SYNC = "NOT_IN_SYNC",

  /**
   * Toutes les données ont été transmises
   * On peut pas confirmer la mise à jour des données
   */
  NO_ONLINE_LISTING = "NO_ONLINE_LISTING",

  /**
   * Toutes les données sont à jour
   * La localisation est synchronisée, vérifiée et mise à jour
   */
  IN_SYNC = "IN_SYNC",

  /**
   * Statut de mise à jour configuré manuellement sur nova
   * Mise à jour terminée mais indiquer que certains champs ne le sont pas tout à fait encore
   * -
   */
  SYNC_IN_PROGRESS = "SYNC_IN_PROGRESS",
}

/**
 * ListingConnectStatus
 * Uberall diffuser connect status
 */
export enum ListingConnectStatus {
  NOT_CONNECTED = "NOT_CONNECTED",
  NOT_NEEDED = "NOT_NEEDED",
  CONNECTED = "CONNECTED",
}

/**
 * Uberall diffuser claim status
 */
export enum ListingClaimStatus {
  /**
   * Le diffuser est introuvable
   */
  UNKNOWN = "UNKNOWN",

  /**
   * -
   */
  NOT_CLAIMABLE = "NOT_CLAIMABLE",

  /**
   * Diffuser géré par uberall / dahub
   */
  CLAIMED_BY_US = "CLAIMED_BY_US",

  /**
   * L'utilisateur doit revendiquer car géré par personne
   * Google uniquement
   */
  CLAIMABLE = "CLAIMABLE",

  /**
   * Revendication en cours
   * Peut prendre jusqu'à 8 jours pour certains diffuseurs
   */
  CLAIMING_IN_PROGRESS = "CLAIMING_IN_PROGRESS",

  /**
   * Diffuser géré par autre entité
   * L'utilisateur doit annuler la revendication pour la léguer à uberall / dahub
   */
  CLAIMED_BY_OTHERS = "CLAIMED_BY_OTHERS",

  /**
   * Diffuser géré par un partenaire
   * Il faut contacter l'api uberall pour revendiquer la fiche (ce n'est pas une action de l'utilisateur)
   */
  CLAIMED_BY_ANOTHER_US = "CLAIMED_BY_ANOTHER_US",
}

/**
 * ListingGoogleStatus
 * Our google connect status
 */
export enum ListingGoogleStatus {
  /**
   * Non connecté
   * L'utilisateur doit se connecter
   * connectStatus = NOT_CONNECTED && claimStatus = UNKNOWN
   */
  NOT_CONNECTED = "NOT_CONNECTED",

  /**
   * Pas de liste trouvée
   * -
   * connectStatus = CONNECTED && claimStatus = UNKNOWN
   */
  NOT_FOUND = "NOT_FOUND",

  /**
   * Pas de liste vérifiée
   * -
   * connectStatus = CONNECTED && claimStatus = CLAIMABLE
   */
  NOT_VERIFIED = "NOT_VERIFIED",

  /**
   * Liste en cours de vérification
   * -
   * connectStatus = CONNECTED && claimStatus = CLAIMING_IN_PROGRESS
   */
  IN_VERIFICATION = "IN_VERIFICATION",

  /**
   * Liste vérifiée
   * Tout va bien, aucune action à effectuer
   * connectStatus = CONNECTED && claimStatus = CLAIMED_BY_US
   */
  VERIFIED = "VERIFIED",

  /**
   * Liste vérifiée mais non connecté
   * L'utilisateur doit demander les droits à l'administrateur ou se connecter avec un autre compte
   * connectStatus = CONNECTED && claimStatus = CLAIMED_BY_OTHERS
   */
  NOT_CONNECTED_VERIFIED = "NOT_CONNECTED_VERIFIED",

  /**
   * Combinaison autre, erreur inconnue
   * -
   */
  ERROR = "ERROR",
}

/**
 * ListingFacebookStatus
 * Our facebook connect status
 */
export enum ListingFacebookStatus {
  /**
   * Non connecté
   * L'utilisateur doit se connecter
   * connectStatus = NOT_CONNECTED && claimStatus != CLAIMED_BY_US
   */
  NOT_CONNECTED = "NOT_CONNECTED",

  /**
   * Pas de liste trouvée
   * -
   * connectStatus = CONNECTED && claimStatus != CLAIMED_BY_US
   */
  NOT_FOUND = "NOT_FOUND",

  /**
   * Liste trouvée
   * -
   * connectStatus = CONNECTED && claimStatus = CLAIMED_BY_US
   */
  FOUNDED = "FOUNDED",

  /**
   * Combinaison autre, erreur inconnue
   * -
   */
  ERROR = "ERROR",
}

/**
 * ListingStatus
 * Our diffuser status
 */
export enum ListingStatus {
  /**
   * En attente du lancement de la première synchro, aucune synchro n'a jamais été lancée
   * (listingStatus !== erreur) && currentEstablishment.last_sync === null
   */
  WAIT_FOR_SYNC = "WAIT_FOR_SYNC",

  /**
   * Synchronisé : tout est à jour
   * syncStatus = IN_SYNC
   */
  SYNCHRONIZED = "SYNCHRONIZED",

  /**
   * Soumis : en attente de maj par le diffuser
   * syncStatus = NO_ONLINE_LISTING
   */
  SUBMITTED = "SUBMITTED",

  /**
   * En attente de validation par le diffuser
   * syncStatus = NOT_IN_SYNC || (claimStatus = UNKNOWN && syncStatus = NOT_FOUND) || (claimStatus = NOT_CLAIMABLE && syncStatus = NOT_FOUND)
   */
  BEING_UPDATED = "BEING_UPDATED",

  /**
   * Non synchronisé : revendiqué par un tiers
   * claimStatus = CLAIMED_BY_OTHERS
   */
  ERROR_CLAIMED_BY_OTHERS = "ERROR_CLAIMED_BY_OTHERS",

  /**
   * Non synchronisé : revendiqué par un partenaire
   * claimStatus = CLAIMED_BY_ANOTHER_US
   */
  ERROR_CLAIMED_BY_ANOTHER_US = "ERROR_CLAIMED_BY_ANOTHER_US",

  /**
   * Non synchronisé : manque des champs obligatoires
   * mandatoryFields.length > 1
   */
  ERROR_FIELDS = "ERROR_FIELDS",

  /**
   * Non synchronisé : pas encore connecté ou manque d'infos (horaires, catégorie)
   * connectStatus = NOT_CONNECTED
   */
  ERROR_NOT_CONNECTED = "ERROR_NOT_CONNECTED",

  /**
   * Non synchronisé : google
   * googleStatus != VERIFIED
   *
   * ou
   *
   * Non synchronisé : facebook
   * facebookStatus != FOUNDED
   */
  ERROR_NOT_FOUND = "ERROR_NOT_FOUND",

  /**
   * Combinaison autre, erreur inconnue
   * -
   */
  ERROR = "ERROR",

  /**
   * Attente de validation de connexion diffuseur local OT
   * -
   */
  PENDING_CONNECTION = "PENDING_CONNECTION",

  /**
   * Statut de mise à jour configuré manuellement sur nova
   * Mise à jour terminée mais indiquer que certains champs ne le sont pas tout à fait encore
   * -
   */
  SYNC_IN_PROGRESS = "SYNC_IN_PROGRESS",

  /**
   * Statut de mise à jour configuré manuellement sur nova
   * A surveiller, pas d'affichage spécifique en front (jamais renvoyé par l'api normalement)
   * -
   */
  PROCESSING = "PROCESSING",
}
