<template>
  <div class="tooltip relative">
    <div
      class="tooltip-main"
      :class="[height]"
    >
      <slot name="main"></slot>
    </div>
    <div
      class="tooltip-content"
      :style="[top !== null ? 'top:' + top : '']"
      :class="[xVariant, yVariant, size]"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "Tooltip",
  props: {
    variantX: {
      type: String,
      validators: (value: string) => ["left", "right", "centerX"].includes(value),
      default: "right",
    },
    variantMobileX: {
      type: String,
      validators: (value: string) => ["left", "right", "centerX"].includes(value),
      default: "right",
    },
    variantY: {
      type: String,
      validators: (value: string) => ["up", "down", "centerY"].includes(value),
      default: "centerY",
    },
    variantMobileY: {
      type: String,
      validators: (value: string) => ["up", "down", "centerY"].includes(value),
      default: "centerY",
    },
    size: {
      type: String,
      validators: (value: string) => ["default", "small", "tiny"].includes(value),
      default: "default",
    },
    height: {
      type: String,
      validators: (value: string) => ["fit", "full"].includes(value),
      default: "fit",
    },
    top: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      xVariant: "",
      yVariant: "",
    }
  },

  mounted() {
    // This component has been reworked with event listeners
    // so the tooltip position can be updated when viewport
    // is repositionX (in exemple on mobile)
    window.addEventListener("resize", this.getX)
    window.addEventListener("resize", this.getY)
    this.getX()
    this.getY()
  },

  beforeUnmount() {
    window.removeEventListener("resize", this.getX)
    window.removeEventListener("resize", this.getY)
  },

  methods: {
    getX() {
      if (window.innerWidth < 768) {
        this.xVariant = this.variantMobileX
      } else {
        this.xVariant = this.variantX
      }
    },
    getY() {
      if (window.innerWidth < 768) {
        this.yVariant = this.variantMobileY
      } else {
        this.yVariant = this.variantY
      }
    },
  },
}
</script>
