<template>
  <div class="flex w-full justify-center">
    <template
      v-for="(anecdote, index) in anecdotes"
      :key="index"
    >
      <Transition name="anecdote">
        <span
          v-if="index == currentIndex"
          class="text-center text-base text-gray-600 absolute w-full max-w-[400px]"
          >{{ currentAnecdote }}</span
        >
      </Transition>
    </template>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  name: "Anecdotes",

  data() {
    return {
      currentIndex: 0,
      interval: 4000,
    }
  },

  computed: {
    anecdotes() {
      return this.$tm("anecdotes") as String[]
    },

    currentAnecdote() {
      return this.$rt(this.anecdotes[this.currentIndex])
    },
  },

  mounted() {
    this.randomizeAnecdotes()
    const max = this.anecdotes.length - 1

    setTimeout(() => {
      setInterval(() => {
        if (this.currentIndex === max) {
          this.randomizeAnecdotes()
        } else {
          this.currentIndex += 1
        }
      }, this.interval)
    }, 400)
  },

  methods: {
    randomizeAnecdotes() {
      this.anecdotes.sort((a, b) => 0.5 - Math.random())
    },
  },
})
</script>
