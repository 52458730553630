<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 2C5.4683 2 2.00002 5.24178 2.00002 9C2.00002 10.3513 2.43831 11.6186 3.20915 12.6993C3.40238 12.9702 3.44819 13.3195 3.33134 13.6311L2.56804 15.6666L5.5489 15.0704C5.75979 15.0282 5.97865 15.0552 6.17302 15.1472C7.36799 15.7131 8.67443 16.0045 9.99661 16L10 16C14.5317 16 18 12.7582 18 9C18 5.24178 14.5317 2 10 2ZM1.52069e-05 9C1.52069e-05 3.92222 4.59173 0 10 0C15.4083 0 20 3.92222 20 9C20 14.0772 15.4093 17.9992 10.0017 18C10.0012 18 10.0006 18 10 18V17L10.0034 18C10.0029 18 10.0023 18 10.0017 18C8.49463 18.0049 7.00427 17.6961 5.62501 17.0948L5.94113 17.0316L5.74502 16.051L5.31701 16.9548C5.41902 17.0031 5.5217 17.0498 5.62501 17.0948L1.19613 17.9806C0.840093 18.0518 0.47343 17.9244 0.238216 17.6478C0.00300182 17.3712 -0.0638032 16.9888 0.063686 16.6489L1.27881 13.4085C1.37477 13.5622 1.47553 13.713 1.58088 13.8607L2.39502 13.28L1.45869 12.9289L1.27881 13.4085C0.468275 12.1107 1.52069e-05 10.6065 1.52069e-05 9ZM5.00002 9C5.00002 8.44772 5.44773 8 6.00002 8H6.01002C6.5623 8 7.01002 8.44772 7.01002 9C7.01002 9.55229 6.5623 10 6.01002 10H6.00002C5.44773 10 5.00002 9.55229 5.00002 9ZM9.00002 9C9.00002 8.44772 9.44773 8 10 8H10.01C10.5623 8 11.01 8.44772 11.01 9C11.01 9.55229 10.5623 10 10.01 10H10C9.44773 10 9.00002 9.55229 9.00002 9ZM13 9C13 8.44772 13.4477 8 14 8H14.01C14.5623 8 15.01 8.44772 15.01 9C15.01 9.55229 14.5623 10 14.01 10H14C13.4477 10 13 9.55229 13 9Z"
      fill="currentColor"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconComment",
  props: ["height", "width"],
}
</script>
