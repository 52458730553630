<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.9678 3.90893C11.3214 2.94931 12.6786 2.94929 13.0322 3.90893L15.898 11.6877L20.1222 7.46355L20.8245 8.16585L20.1222 7.46354C20.8151 6.7706 22 7.26136 22 8.24137V18.4999C22 19.6045 21.1046 20.4999 20 20.4999H4C2.89543 20.4999 2 19.6045 2 18.4999V8.24137C2 7.26137 3.18486 6.77059 3.87782 7.46355C3.87782 7.46355 3.87782 7.46355 3.87782 7.46355L8.10196 11.6877L10.9678 3.90893ZM9.09603 12.6818L9.09659 12.6823L8.4602 13.3187L9.0966 12.6823C9.09641 12.6821 9.09622 12.6819 9.09603 12.6818ZM12 6.89258L9.49237 13.699C9.21485 14.4523 8.25001 14.6642 7.68238 14.0965L4 10.4142V18.4999H20V10.4142L16.3176 14.0965C15.75 14.6642 14.7852 14.4523 14.5076 13.699L15.446 13.3533L14.5076 13.699L12 6.89258Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconCrownOutlinedOutlined",
  props: ["height", "width"],
}
</script>
