<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.0004 21.5997C14.5465 21.5997 16.9883 20.5882 18.7886 18.7879C20.589 16.9875 21.6004 14.5457 21.6004 11.9997C21.6004 9.45358 20.589 7.01178 18.7886 5.21143C16.9883 3.41108 14.5465 2.39966 12.0004 2.39966C9.45431 2.39966 7.01251 3.41108 5.21217 5.21143C3.41182 7.01178 2.40039 9.45358 2.40039 11.9997C2.40039 14.5457 3.41182 16.9875 5.21217 18.7879C7.01251 20.5882 9.45431 21.5997 12.0004 21.5997ZM10.4488 8.75126C10.2225 8.53267 9.91935 8.41172 9.60471 8.41445C9.29007 8.41718 8.9891 8.54339 8.76661 8.76588C8.54412 8.98837 8.41792 9.28934 8.41518 9.60398C8.41245 9.91861 8.5334 10.2217 8.75199 10.4481L10.3036 11.9997L8.75199 13.5513C8.63738 13.662 8.54596 13.7944 8.48307 13.9408C8.42018 14.0872 8.38707 14.2446 8.38569 14.404C8.38431 14.5633 8.41467 14.7213 8.475 14.8688C8.53534 15.0163 8.62444 15.1503 8.73712 15.2629C8.84979 15.3756 8.98377 15.4647 9.13125 15.525C9.27872 15.5854 9.43674 15.6157 9.59607 15.6144C9.75541 15.613 9.91287 15.5799 10.0593 15.517C10.2057 15.4541 10.3381 15.3627 10.4488 15.2481L12.0004 13.6965L13.552 15.2481C13.7783 15.4666 14.0814 15.5876 14.3961 15.5849C14.7107 15.5821 15.0117 15.4559 15.2342 15.2334C15.4567 15.011 15.5829 14.71 15.5856 14.3953C15.5883 14.0807 15.4674 13.7776 15.2488 13.5513L13.6972 11.9997L15.2488 10.4481C15.4674 10.2217 15.5883 9.91861 15.5856 9.60398C15.5829 9.28934 15.4567 8.98837 15.2342 8.76588C15.0117 8.54339 14.7107 8.41718 14.3961 8.41445C14.0814 8.41172 13.7783 8.53267 13.552 8.75126L12.0004 10.3029L10.4488 8.75126Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconXCircle",
  props: ["height", "width"],
}
</script>
