<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M13.3362 2.0582C13.7343 2.2003 14 2.57733 14 3V9H20C20.3864 9 20.7382 9.2226 20.9037 9.57177C21.0691 9.92094 21.0186 10.3342 20.774 10.6332L11.774 21.6332C11.5063 21.9604 11.0619 22.0839 10.6638 21.9418C10.2658 21.7997 10 21.4227 10 21V15H4.00001C3.61362 15 3.2618 14.7774 3.09634 14.4282C2.93088 14.0791 2.98138 13.6658 3.22605 13.3668L12.2261 2.36676C12.4937 2.03963 12.9381 1.91611 13.3362 2.0582ZM6.11025 13H11C11.5523 13 12 13.4477 12 14V18.1986L17.8898 11H13C12.4477 11 12 10.5523 12 10V5.80141L6.11025 13Z"
    />
  </svg>
</template>

<script lang="ts">
export default {
  name: "IconLightningBolt",
  props: ["height", "width"],
}
</script>
