<template>
  <StatCard
    :title="$t(i18nbase + '.reviews_answers.title')"
    :description="$t(i18nbase + '.reviews_answers.description')"
    icon="IconAnnotation"
    :custom-style="modelValue ? 'pl-[30%]' : 'pl-0'"
  >
    <template
      v-if="showGraph && stats.current_total > 0"
      #actions
    >
      <div class="flex flex-col">
        <div
          v-for="(legend, index) in chartConfig.labels"
          :key="'legend-' + index"
          class="flex items-center justify-end gap-sm"
        >
          <small class="text-xs text-right text-gray-500 !font-light">
            {{ $t(i18nbase + ".reviews_answers.legend." + legend) }}
          </small>
          <div
            class="h-2 w-6 rounded-sm"
            :style="{ 'background-color': chartConfig.reviewConfig.backgroundColor[index] }"
          ></div>
        </div>
      </div>
    </template>

    <template
      v-if="showGraph && stats.current_total > 0"
      #subtitle
    >
      <div class="flex flex-col">
        <span class="text-sm text-gray-500">
          {{
            $t(i18nbase + ".reviews_answers.total", {
              nb: $string.formatNumberToHumanString(stats.current_total),
            })
          }}
        </span>
        <span
          v-if="showPreviousData"
          class="text-sm text-gray-400"
        >
          {{
            $t(i18nbase + ".reviews_answers.previous_total", {
              nb: $string.formatNumberToHumanString(stats.previous_total),
            })
          }}
        </span>
      </div>
    </template>

    <div :class="!showGraph && '!hidden'">
      <Pie
        v-if="stats.current_total > 0"
        ref="chartRef"
        :data="data"
        :options="chartOptions"
      />
      <div
        v-else
        class="flex flex-col items-center gap"
      >
        <div
          class="relative flex items-center justify-center text-gray-400 h-[130px] w-[130px] rounded-full bg-gray-100"
        >
          <Icon
            icon-name="IconChatAlt2"
            class="h-[30px] w-[30px]"
          />
          <span class="text-xl">0</span>
        </div>
        <span class="text-base text-gray-400">
          {{ $t(i18nbase + "." + noDataKey) }}
        </span>
      </div>
      <div
        v-if="modelValue"
        class="absolute top-0 left-0 h-[70px] md:h-[100px] opacity-50 flex flex-col items-center"
      >
        <Pie
          v-if="stats.previous_total"
          ref="chartRef"
          :data="previousData"
          :options="chartOptions"
        />
        <EmptyData
          v-else
          :message="$t(i18nbase + '.reviews_answers.no_reviews')"
        />
        <small class="text-sm text-gray-700 w-min text-center md:w-max">
          {{ $t(i18nbase + ".reviews_answers.previous_title") }}
        </small>
      </div>
    </div>

    <div
      :class="showGraph && 'hidden'"
      class="flex flex-col h-full items-center justify-center p"
    >
      <span class="text-base text-gray-400 text-center">
        {{ $t(i18nbase + "." + noDataKey) }}
      </span>
    </div>
  </StatCard>
</template>

<script lang="ts">
// Vue
import { ref, type PropType } from "vue"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie, type ChartComponentRef } from "vue-chartjs"

// Layout
//

// Components
import Icon from "@/components/Icon.vue"
import StatCard from "../StatCard.vue"
import EmptyData from "../EmptyData.vue"

// Stores
import {mapStores} from "pinia"
import {useListingStore} from "@/stores/listing"

// Types
import type { IReviewStatsForDashboard } from "@/types/Stats.types"

// Data
import * as chartConfig from "@/static/dashboard/ReviewAnswer"


ChartJS.register(ArcElement, Tooltip, Legend)

export default {
  name: "ReviewAnswer",

  components: {
    Pie,
    Icon,
    StatCard,
    EmptyData,
  },

  props: {
    stats: {
      type: Object as PropType<IReviewStatsForDashboard>,
      required: true,
    },

    modelValue: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const chartRef = ref<ChartComponentRef>(null)

    return {
      chartRef,
      ...chartConfig,
    }
  },

  data() {
    return {
      i18nbase: "home.google",
      chartConfig: chartConfig,
      isLoading: true,
      showPreviousData: this.modelValue,
    }
  },

  computed: {
    ...mapStores(
      useListingStore
    ),
    data() {
      return {
        datasets: [
          {
            data: [this.stats.current.reviews_answered, this.stats.current.reviews_not_answered],
            ...chartConfig.reviewConfig,
          },
        ],
      }
    },
    previousData() {
      return {
        datasets: [
          {
            data: [this.stats.last_year.reviews_answered, this.stats.last_year.reviews_not_answered],
            ...chartConfig.reviewConfig,
          },
        ],
      }
    },
    chartOptions() {
      let opts = this.chartConfig.options
      opts.plugins = {
        tooltip: {
          callbacks: {
            label: (context) => {
              let label = this.$t(this.i18nbase + ".reviews_answers.legend." + this.chartConfig.labels[context.dataIndex]) || ""

              if (label) {
                label += ": "
              }

              if (context.parsed !== null) {
                label += this.$string.formatNumberToHumanString(context.parsed)
              }
              return label
            },
          },
        },
        ...opts.plugins,
      }
      return opts
    },

    showGraph() {
      return this.stats.current.reviews_answered !== null || this.stats.current.reviews_not_answered !== null
    },

    noDataKey() {
      return this.listingStore.getListingConnexionStatus('GOOGLE') === 'CONNECTED' ? 'reviews_answers.no_reviews' : 'not_connected'
    }
  },
}
</script>
