export const previousLikeConfig = {
  label: "likes n-1",
  backgroundColor: "#5AB7CB",
  borderColor: "#5AB7CB",
  legendColor: "#5AB7CB",
  pointStyle: "circle",
  pointRadius: 1,
  borderWidth: 2.5,
  cubicInterpolationMode: "monotone",
  borderDash: [5, 5],
}

export const likeConfig = {
  label: "likes",
  backgroundColor: function (context: { chart: any }) {
    const chart = context.chart
    const { ctx, chartArea } = chart

    if (!chartArea) {
      // This case happens on initial chart load
      return
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 400)
    gradient.addColorStop(0, "rgba(90, 183, 203, 0.6)")
    gradient.addColorStop(0.5, "rgba(90, 183, 203, 0.00)")

    return gradient
  },
  borderColor: "#5AB7CB",
  legendColor: "#5AB7CB",
  pointStyle: "circle",
  pointRadius: 3,
  pointHoverRadius: 8,
  cubicInterpolationMode: "monotone",
  fill: true,
}

export const legends = [likeConfig, previousLikeConfig]

export const options = {
  maintainAspectRatio: false,
  animation: false,
  scales: {
    x: {
      grid: {
        display: false,
      },
      beginAtZero: true,
      type: "time",
      display: true,
      offset: false,
      ticks: {
        source: "data",
      },
      time: {
        // unit: "day",
        displayFormats: {
          day: "DD MMM",
          week: "DD MMM",
        },
        tooltipFormat: "YYYY-MM-DD", // Format des tooltips
      },
      title: {
        display: false,
      },
    },
    y: {
      grid: {
        display: false,
      },
      ticks: {
        beginAtZero: false,
        precision: 0, // Spécifiez la précision (nombre de décimales)
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
}
